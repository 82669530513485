import {
  formatDateStringToDateTimeZero,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
  usDollarNoDigitsCurrency,
} from '@bluefox/lib/formatters';
import { BillingInvoice } from '@bluefox/models/Billing';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

type Props = {
  billingInvoice: BillingInvoice;
  onShowPDF?: (billingInvoice: BillingInvoice) => void;
};

const InvoiceListTableRow = ({ billingInvoice, onShowPDF }: Props) => {
  return (
    <Table.Row>
      <Table.Cell collapsing>
        {billingInvoice?.practicesData
          ?.map((p) => p.name)
          .sort()
          .join(', ')}
      </Table.Cell>
      <Table.Cell collapsing>
        {billingInvoice?.date
          ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(
              formatDateStringToDateTimeZero(
                billingInvoice?.date as unknown as string
              )
            )
          : '-'}
      </Table.Cell>
      <Table.Cell collapsing>
        {usDollarNoDigitsCurrency(billingInvoice?.amount)}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {billingInvoice.pdfData && (
          <Link to="#" onClick={() => onShowPDF?.(billingInvoice)}>
            Show PDF
          </Link>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default InvoiceListTableRow;
