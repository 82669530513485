import { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Header, Loader, Message, Segment } from 'semantic-ui-react';
import { ResponsiveBar, BarDatum } from '@nivo/bar';

import styled from 'styled-components';
import { chartColors } from './configTheme';

const InventoryCountsQuery = gql`
  query {
    vaccines(where: { inventories: { doses: { _gt: 0 } } }) {
      name
      types
      vfc: inventories_aggregate(
        where: { vfc: { _eq: true }, doses: { _gt: 0 } }
      ) {
        aggregate {
          sum {
            doses
          }
        }
      }
    }
  }
`;

interface InventoryCount {
  types: string[];
  vfc: {
    aggregate: {
      sum: {
        doses: number | null;
      };
    };
  };
  nonVfc: {
    aggregate: {
      sum: {
        doses: number | null;
      };
    };
  };
}

interface InventoryCountsData {
  vaccines: InventoryCount[];
}

const ChartInventory = () => {
  const [chartData, setChartData] = useState<BarDatum[]>([]);
  const { data, loading, error } =
    useQuery<InventoryCountsData>(InventoryCountsQuery);

  useEffect(() => {
    if (!data?.vaccines) return;
    const vaccines: InventoryCount[] = data?.vaccines;
    let myMap = new Map<string, BarDatum>();

    vaccines.forEach((count) => {
      const key: string = count.types.join('-');
      let datum = myMap.get(key);

      if (datum) {
        datum.vfc =
          Number(datum.vfc) + Number(count.vfc.aggregate.sum.doses || 0);
      } else {
        const newObj: BarDatum = {
          key,
          id: key,
          label: count.types.join(', '),
          vfc: count.vfc.aggregate.sum.doses || 0,
        };
        myMap.set(key, newObj);
      }
    });

    const _chartData = Array.from(myMap.values());

    setChartData(
      _chartData.filter(
        (i) => i.vfc !== 0 && i.key !== 'COVID-19' && i.key !== 'FLU'
      )
    );
  }, [data?.vaccines]);

  const getColor = (bar: any) => chartColors[bar.id];

  return (
    <StyledSegement>
      <Header textAlign="center">VFC Inventory</Header>
      <StyledContent>
        {loading && <Loader />}
        {error && <Message error>{error?.message}</Message>}
        {chartData.length > 0 && (
          <ResponsiveBar
            data={chartData}
            indexBy="label"
            keys={['vfc', 'notVfc']}
            colors={getColor}
            margin={{ top: 10, right: 60, bottom: 85, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.3]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: -45,
            }}
            axisLeft={{
              format: (e) => Math.floor(e) === e && e,
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Doses',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 2.5]],
            }}
            animate={true}
            role="application"
            ariaLabel="Inventory"
          />
        )}
      </StyledContent>
    </StyledSegement>
  );
};

export default ChartInventory;

const StyledSegement = styled(Segment)`
  height: 25rem;
`;
const StyledContent = styled.div`
  height: 19rem;
`;
