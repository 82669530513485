import { useQuery } from '@apollo/client';
import { usePractice } from '@bluefox/contexts/Practice';
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import {
  InsuranceCompaniesQuery,
  SelectedInsuranceCompaniesQuery,
} from '@bluefox/graphql/settings';

interface Company {
  id: string;
  cpid: string;
  name: string;
}

interface SelectedInsuranceCompaniesData {
  companies: Company[];
}

interface InsuranceCompaniesData {
  companies: Company[];
  favoriteCompanies: Company[];
}

interface InsuranceCompanyPickerProps {
  value?: string | string[];
  valueData?: SelectedInsuranceCompaniesData;
  valueLoading?: boolean;
  onChange: (value: string | string[]) => void;
  disabled?: boolean;
  multiple?: boolean;
}

const InsuranceCompanyPicker = ({
  value,
  onChange = () => {},
  disabled,
  multiple,
}: InsuranceCompanyPickerProps) => {
  const practice = usePractice();

  const [input, setInput] = useState('');
  const [options, setOptions] = useState<DropdownItemProps[]>([]);

  const [selectedValue, setSelectedValue] = useState<
    string | string[] | undefined
  >(multiple ? [] : undefined);

  const [favoriteCompaniesIds, setFavoriteCompaniesId] = useState<
    string | string[]
  >([]);

  useEffect(() => {
    setFavoriteCompaniesId(
      practice.publicSettings?.favoriteInsuranceCompanies ?? []
    );
  }, [practice]);

  const { data: valueData, loading: valueLoading } =
    useQuery<SelectedInsuranceCompaniesData>(SelectedInsuranceCompaniesQuery, {
      variables: {
        ids: Array.isArray(value) ? value : [value],
      },
      skip: !value?.length,
    });

  const { data, loading } = useQuery<InsuranceCompaniesData>(
    InsuranceCompaniesQuery,
    {
      variables: {
        input: input.length > 2 ? `%${input}%` : '',
        favoriteInput: `%${input}%`,
        favoriteCompaniesIds,
      },
      skip: input.length < 3 && !favoriteCompaniesIds.length,
    }
  );

  useEffect(() => {
    if (data) {
      setOptions([
        ...(valueData
          ? valueData.companies.map((company) => ({
              key: `v-${company.id}`,
              value: company.id,
              text: company.name,
            }))
          : []),
        ...(data.favoriteCompanies.length
          ? [
              ...data.favoriteCompanies.map((c) => ({
                key: `f-${c.id}`,
                value: c.id,
                text: c.name,
                icon: 'star',
              })),
              { key: 'divider', value: 'divider', disabled: true, text: '...' },
            ]
          : []),
        ...data.companies.map((c) => ({
          key: c.id,
          value: c.id,
          text: c.name,
          icon: favoriteCompaniesIds.includes(c.id) ? 'star' : undefined,
        })),
      ]);
    } else if (valueData) {
      setOptions(
        valueData.companies.map((company) => ({
          key: `v-${company.id}`,
          value: company.id,
          text: company.name,
        }))
      );
    }
  }, [valueData, data, favoriteCompaniesIds]);

  useEffect(() => {
    if (!valueData) {
      return;
    }
    setSelectedValue(
      multiple
        ? valueData?.companies.map((company) => company.id) || undefined
        : valueData?.companies[0].id
    );
  }, [valueData, multiple]);

  return (
    <Dropdown
      selection
      search
      fluid
      multiple={multiple}
      onSearchChange={(_, { searchQuery }) => setInput(searchQuery as string)}
      value={selectedValue}
      disabled={disabled || valueLoading || loading}
      onChange={(_, { value }) => {
        const formattedValue = Array.isArray(value)
          ? (value as string[])
          : (value as string);
        setSelectedValue(formattedValue);
        onChange(formattedValue);
      }}
      options={options}
      loading={valueLoading || loading}
    />
  );
};

export default InsuranceCompanyPicker;
