// import FullLayout from "@components/FullLayout";
import { useState, useEffect } from 'react';
import InsuranceCompanyPicker from '@components/InsuranceCompanyPicker';
import { Button, Icon, Grid } from 'semantic-ui-react';
import { useMutation, useQuery, resetCaches } from '@apollo/client';
import { usePractice, PracticeQuery } from '@bluefox/contexts/Practice';
import { toast } from 'react-semantic-toasts';
import { UpdateFavoriteInsuranceCompaniesMutation } from '@bluefox/graphql/settings';
import { SelectedInsuranceCompaniesQuery } from '@bluefox/graphql/settings';

interface Company {
  id: string;
  cpid: string;
  name: string;
}

interface SelectedInsuranceCompaniesData {
  companies: Company[];
}

const FavoriteInsurances = () => {
  const [companiesSelected, setCompaniesSelected] = useState<string[]>([]);
  const [favoriteInsurances, setFavoriteInsurances] = useState<string[]>([]);
  const practice = usePractice();
  const [saveFavoriteCompanies] = useMutation(
    UpdateFavoriteInsuranceCompaniesMutation,
    {
      refetchQueries: [
        {
          query: PracticeQuery,
          variables: { handler: practice?.handler ?? '' },
        },
      ],
    }
  );
  const { data, loading } = useQuery<SelectedInsuranceCompaniesData>(
    SelectedInsuranceCompaniesQuery,
    {
      variables: {
        ids: Array.isArray(favoriteInsurances)
          ? favoriteInsurances
          : [favoriteInsurances],
      },
    }
  );

  const handleOnCompanyPick = (value: string[]) => {
    setCompaniesSelected(value);
  };

  useEffect(() => {
    if (practice) {
      const insurances =
        practice?.publicSettings?.favoriteInsuranceCompanies ?? [];
      setFavoriteInsurances(insurances);
      setCompaniesSelected(insurances);
    }
  }, [practice]);

  const handleOnSaveClick = () => {
    saveFavoriteCompanies({
      variables: {
        practiceId: practice.id,
        favoriteInsuranceCompanies: companiesSelected,
      },
    })
      .then((response) => {
        if (response?.data?.update_practices_by_pk?.id) {
          setFavoriteInsurances([...favoriteInsurances, ...companiesSelected]);
          resetCaches();
        }
        toast({
          title: 'Success',
          type: 'success',
          description: 'Favorite insurances saved!',
          time: 5000,
        });
      })
      .catch((error) => {
        toast({
          title: 'Error',
          type: 'error',
          description: 'Something happen when saving favorites',
          time: 5000,
        });
      });
  };

  return (
    <>
      Favorite Companies:
      <InsuranceCompanyPicker
        multiple
        valueData={data}
        valueLoading={loading}
        value={companiesSelected}
        onChange={(value) => handleOnCompanyPick(value as string[])}
        data-automation-id="favorite-insurances-picker"
      />
      <Grid style={{ paddingTop: '15px' }}>
        <Grid.Row>
          <Grid.Column width={6}>
            <Button
              positive
              fluid
              icon
              onClick={handleOnSaveClick}
              labelPosition="right"
              data-automation-id="favorite-insurances-button"
            >
              Save
              <Icon name="save" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default FavoriteInsurances;
