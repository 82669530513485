import { useCallback, useState, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment-timezone';
import { Confirm, Icon, Label, Table, Popup } from 'semantic-ui-react';

import { ImmunizationEntry } from '@bluefox/store/vaccinations';
import {
  vaccineTypeNames,
  RoutineTypes,
  NonRoutineTypes,
} from '@bluefox/store/vaccines';
import {
  DeleteVaccinationMutation,
  VaccinationsByPatientQuery,
  VaccinationsByPracticePatientQuery,
} from '@bluefox/graphql/vaccinations';
import { useApplicationState } from '@bluefox/contexts';
import { Vaccination } from '@bluefox/models/Vaccination';
import { DateFormats } from '@bluefox/models/Dates';
import { parseToMMDDYYYY } from '@utils/dates';
import ImmunizationCardWeb from '@components/VaxHistory/ImmunizationCardWeb';
import ImmunizationCardChromeExtension from './ImmunizationCardChromeExtension';
import { usePracticePatient } from '@bluefox/contexts/PracticePatientProvider';
import { vaccinationUploadedToEmr } from '@bluefox/lib/vaccinationUploadedToEmr';

export interface VaccinationsData {
  vaccinations: Vaccination[];
}

interface SortedVaccinations {
  dueVaccines: ImmunizationEntry[];
  regVaccines: ImmunizationEntry[];
  notEligibleVaccines: ImmunizationEntry[];
}
interface VaxHistoryProps {
  vaccinationsData: VaccinationsData | undefined;
  vaccines: ImmunizationEntry[];
  patientAgeInMonths: number;
  onUpdatedVaccinations?: () => {};
}

const VaxHistory = ({
  vaccines,
  onUpdatedVaccinations,
  vaccinationsData,
  patientAgeInMonths,
}: VaxHistoryProps) => {
  const { isEmbedded } = useApplicationState();
  const practicePatient = usePracticePatient();

  const [windowWidth, setWindowWidth] = useState(1650);
  const [vaccinationToBeDeleted, setVaccinationToBeDeleted] =
    useState<string>();

  const [deleteVaccination] = useMutation(DeleteVaccinationMutation);

  const handleDeleteClick = useCallback(() => {
    deleteVaccination({
      variables: {
        id: vaccinationToBeDeleted,
      },
      refetchQueries: [
        {
          query: VaccinationsByPracticePatientQuery,
          variables: {
            practicePatientId: practicePatient?.id,
          },
        },
        {
          query: VaccinationsByPatientQuery,
          variables: {
            practicePatientId: practicePatient?.id,
          },
        },
      ],
    }).then(() => {
      setVaccinationToBeDeleted(undefined);
      if (onUpdatedVaccinations) onUpdatedVaccinations();
    });
  }, [
    deleteVaccination,
    vaccinationToBeDeleted,
    practicePatient?.id,
    onUpdatedVaccinations,
  ]);

  const filteredVaccines = useMemo(() => {
    const nonRoutineVaccinations = vaccinationsData?.vaccinations.filter(
      (v) => {
        return v.vaccine?.types?.some((t) => NonRoutineTypes.includes(t));
      }
    );

    const nonRoutineVaxTypes = nonRoutineVaccinations?.map(
      (v) => v.vaccine?.types?.map((t) => t)
    );
    const filteredTypes = [
      ...RoutineTypes,
      ...(nonRoutineVaxTypes || []),
    ].flat();

    return vaccines.filter((v) => {
      return filteredTypes.includes(v.vaccineType);
    });
  }, [vaccines, vaccinationsData]);

  const dueVaccines = useMemo(() => {
    return filteredVaccines.filter((immunization) => {
      const isEligible =
        immunization?.ranges.length === 0 ||
        immunization?.ranges[0]?.start <= patientAgeInMonths;

      return (
        isEligible &&
        immunization.givenDosesCount < immunization.totalRecommentationsCount
      );
    });
  }, [filteredVaccines, patientAgeInMonths]);

  const groupedVaccines = useMemo(() => {
    const groupedVaccinesAcc: SortedVaccinations = {
      dueVaccines: [],
      regVaccines: [],
      notEligibleVaccines: [],
    };

    return filteredVaccines.reduce((acc, curr) => {
      const isEligible =
        curr?.ranges.length === 0 ||
        curr?.ranges[0]?.start <= patientAgeInMonths;

      if (isEligible && curr.givenDosesCount < curr.totalRecommentationsCount) {
        acc.dueVaccines.push(curr);
        return acc;
      }

      if (!isEligible) {
        acc.notEligibleVaccines.push(curr);
        return acc;
      }

      acc.regVaccines.push(curr);
      return acc;
    }, groupedVaccinesAcc);
  }, [filteredVaccines, patientAgeInMonths]);

  const sortedVaccines = useMemo(
    () => [
      ...groupedVaccines.dueVaccines,
      ...groupedVaccines.regVaccines,
      ...groupedVaccines.notEligibleVaccines,
    ],
    [groupedVaccines]
  );

  const truncateString = (text: string, letters: number) => {
    if (text.length <= 6) return text;

    return `${text.substring(0, letters)}...`;
  };

  const getWindowWidth = () => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  };

  useEffect(() => {
    getWindowWidth();
  }, []);

  return (
    <>
      <Table definition fixed celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Vaccination</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">1</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">2</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">3</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">4</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">5</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortedVaccines.map((vaccine, keyRow) => {
            const givenDoses = Object.values(vaccine.givenDoses)
              .flat()
              .sort(
                (a, b) =>
                  new Date(a.givenAt).getTime() - new Date(b.givenAt).getTime()
              );

            //take last 5 vaccinations
            const visibleVaccinations = givenDoses.slice(-5);

            return (
              <Table.Row key={keyRow}>
                <Table.Cell>
                  {isEmbedded ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Popup
                        trigger={
                          <div>
                            {truncateString(
                              vaccineTypeNames[vaccine.vaccineType],
                              4
                            )}
                          </div>
                        }
                        content={vaccineTypeNames[vaccine.vaccineType]}
                      />
                      {dueVaccines.find(
                        (v) => v.vaccineType === vaccine.vaccineType
                      ) && <Label color="orange" content="Due" size="mini" />}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {windowWidth <= 1640 ? (
                        <Popup
                          trigger={
                            <div>
                              {truncateString(
                                vaccineTypeNames[vaccine.vaccineType],
                                6
                              )}
                            </div>
                          }
                          content={vaccineTypeNames[vaccine.vaccineType]}
                        />
                      ) : (
                        vaccineTypeNames[vaccine.vaccineType]
                      )}
                      {dueVaccines.find(
                        (v) => v.vaccineType === vaccine.vaccineType
                      ) && (
                        <Label size="mini" color="orange">
                          <Icon name="warning sign" />
                          Due
                        </Label>
                      )}
                    </div>
                  )}
                </Table.Cell>
                {[...Array(5)].map((_, keyCell) => {
                  let givenAt;
                  if (visibleVaccinations[keyCell]) {
                    givenAt = moment(
                      visibleVaccinations[keyCell].givenAt
                    ).format(DateFormats.DATE);
                    if (visibleVaccinations[keyCell].historic) {
                      givenAt = parseToMMDDYYYY(
                        String(visibleVaccinations[keyCell].givenAt)
                      );
                    }
                  }

                  return (
                    <Table.Cell key={keyCell}>
                      {visibleVaccinations[keyCell] && (
                        <>
                          {isEmbedded ? (
                            <ImmunizationCardChromeExtension
                              visibleVaccination={visibleVaccinations[keyCell]}
                              selectVaccinationToDelete={(vaccinationId) =>
                                setVaccinationToBeDeleted(vaccinationId)
                              }
                              wasUploadedToEmr={vaccinationUploadedToEmr(
                                visibleVaccinations[keyCell]
                              )}
                            />
                          ) : (
                            <ImmunizationCardWeb
                              visibleVaccination={visibleVaccinations[keyCell]}
                              selectVaccinationToDelete={(vaccinationId) =>
                                setVaccinationToBeDeleted(vaccinationId)
                              }
                              immunization={vaccine}
                              wasUploadedToEmr={vaccinationUploadedToEmr(
                                visibleVaccinations[keyCell]
                              )}
                            />
                          )}
                        </>
                      )}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Confirm
        size="tiny"
        content="Are you sure you want to delete this vaccination?"
        open={!!vaccinationToBeDeleted}
        onCancel={() => setVaccinationToBeDeleted(undefined)}
        onConfirm={handleDeleteClick}
      />
    </>
  );
};

export default VaxHistory;
