import React, { useState } from 'react';
import { BorrowingSummary } from './BorrowingSummary';
import BorrowingSummaryDetailClaims from './BorrowingSummaryDetailClaims';
import { Button } from 'semantic-ui-react';
import { BorrowingSummaryDetails } from './BorrowingSummaryDetails';

interface BorrowingHistoryViewHandlerProps {
  practiceId: string;
}

interface ViewHandler {
  index: number;
  date: string;
  id: string;
}

const HistoryViewDict = {
  INVENTORY_ADJUSTMENT: 0,
  INVENTORY_ADJUSTMENT_DETAILS: 1,
  VFC_INCONSISTENCY_CLAIMS: 2,
};

export const BorrowingHistoryViewHandler = ({
  practiceId,
}: BorrowingHistoryViewHandlerProps) => {
  const [activeHistoryView, setActiveHistoryView] = useState<ViewHandler>({
    index: HistoryViewDict.INVENTORY_ADJUSTMENT,
    date: '',
    id: '',
  });

  const handleHistoryViewChange = (
    activeIndex: number,
    date?: string,
    id?: string
  ) => {
    setActiveHistoryView((prev) => {
      return {
        index: activeIndex,
        date: date ? date : prev.date,
        id: id ? id : prev.id,
      };
    });
  };

  const viewSelector = () => {
    if (activeHistoryView.index === HistoryViewDict.INVENTORY_ADJUSTMENT) {
      return (
        <BorrowingSummary
          practiceId={practiceId}
          viewChange={handleHistoryViewChange}
        />
      );
    } else if (
      activeHistoryView.index === HistoryViewDict.INVENTORY_ADJUSTMENT_DETAILS
    ) {
      return (
        <BorrowingSummaryDetails
          date={activeHistoryView.date}
          practiceId={practiceId}
          viewChange={handleHistoryViewChange}
        />
      );
    } else if (
      activeHistoryView.index === HistoryViewDict.VFC_INCONSISTENCY_CLAIMS
    ) {
      return (
        <BorrowingSummaryDetailClaims
          inventoryAdjustmentId={activeHistoryView.id}
        />
      );
    }
  };

  return (
    <div>
      {activeHistoryView.index > 0 && (
        <Button
          content="Go Back"
          onClick={() =>
            setActiveHistoryView((prev) => {
              return {
                ...prev,
                index: prev.index - 1,
              };
            })
          }
        />
      )}
      {viewSelector()}
    </div>
  );
};
