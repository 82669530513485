import React, { useState, useCallback, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { tsXLXS } from 'ts-xlsx-export';
import moment from 'moment-timezone';
import { BillingClaimsForPracticePortalView } from '@graphql/billing';
import { PracticePortalBillingClaim } from '@bluefox/models/Billing';
import { Modal, Button, Progress, Icon } from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import { DateFormats } from '@bluefox/models/Dates';
import { toast } from 'react-semantic-toasts';
import { simplifiedClaimStatusesFunction } from '@bluefox/models/Billing';
import { ProcedureAmountsQuery } from '@bluefox/graphql/billing';

interface Cpt {
  cpt: string;
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

interface BillingClaimsViewData {
  billingClaims: PracticePortalBillingClaim[];
}

interface BillingExcelExportModalProps {
  criteria?: object;
}

const showInventoryUsed = (claim: PracticePortalBillingClaim) => {
  let inventories = '';

  if (claim.inventoryVfcCount > 0) {
    inventories += `VFC: ${claim.inventoryVfcCount} ${
      claim.inventoryPrivateCount > 0 ? ', ' : ''
    }`;
  }

  if (claim.inventoryPrivateCount > 0) {
    inventories += `Private: ${claim.inventoryPrivateCount}`;
  }

  return inventories;
};

const BillingExcelExportModal = ({
  criteria,
}: BillingExcelExportModalProps) => {
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState<any[] | undefined>([]);
  const [adminCptCodes, setAdminCptsCodes] = useState<string[]>([]);

  useQuery<ProcedureAmountsData>(ProcedureAmountsQuery, {
    variables: {
      type: 'Administration',
    },
    onCompleted: (data) => {
      setAdminCptsCodes(data.cptCodes.map((c) => c.cpt));
    },
  });

  const [getClaims, { loading, error }] = useLazyQuery<BillingClaimsViewData>(
    BillingClaimsForPracticePortalView
  );

  const handleLoadXlsx = useCallback(() => {
    if (!excelData) return;
    tsXLXS()
      .exportAsExcelFile(excelData)
      .saveAsExcelFile(
        `Canid-Billing-Claims-List-${moment().format(
          DateFormats.DATE_WITH_TIME_SECONDS
        )}`
      );
    setOpen(false);
  }, [excelData]);

  const buildExcelData = useCallback(
    (claim: PracticePortalBillingClaim) => {
      const adminTotalAmount = claim.vaccinationsCptCodes
        ? claim.vaccinationsCptCodes
            .filter((cpt) => adminCptCodes?.includes(cpt.cptCode))
            .map((cpt) => cpt.paidAmount ?? 0)
            .reduce((accum, curr) => (accum += curr), 0)
        : '-';

      const inventoryTotalAmount = claim.vaccinationsCptCodes
        ? claim.vaccinationsCptCodes
            .filter((cpt) => !adminCptCodes?.includes(cpt.cptCode))
            .map((cpt) => cpt.paidAmount ?? 0)
            .reduce((accum, curr) => (accum += curr), 0)
        : '-';

      const vaccines = claim.vaccines.map((v) => v.name);

      const cptCodes = claim.vaccinationsCptCodes.map((cpt) => cpt.cptCode);

      return {
        Practice: claim.practiceName || '-',
        Patient: claim.fullName || '-',
        Insurance: claim.insuranceName || '-',
        'Member ID': claim.insuranceMemberId || '-',
        'VFC Eligibility': claim.insuranceVfcEligible ? 'VFC' : 'Private',
        'Service Date': claim.givenAt
          ? moment(claim.givenAt).format(DateFormats.DATE)
          : '-',
        Vaccines: vaccines.join(', ') || '-',
        'Inventory Used': showInventoryUsed(claim),
        'CPT Codes': cptCodes.join(', ') || '-',
        'Charged Amount': claim.totalClaimAmount,
        'Inventory Paid Amount': inventoryTotalAmount,
        'Administration Paid Amount': adminTotalAmount,
        Status: simplifiedClaimStatusesFunction(
          claim.status,
          claim.insuranceVfcEligible,
          claim.revisionStatus
        ),
        'EOB Link': claim.note?.url || '-',
      };
    },
    [adminCptCodes]
  );

  const onClickHandler = useCallback(async () => {
    try {
      const response = await getClaims({
        variables: {
          criteria,
        },
      });

      const billingList = await response.data?.billingClaims.map((claim) => {
        return buildExcelData(claim);
      });

      setExcelData(billingList?.flat());
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [buildExcelData, criteria, getClaims]);

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={
        <Button
          onClick={onClickHandler}
          floated="right"
          icon="file excel outline"
          content="Export Excel file"
        />
      }
    >
      <Modal.Header>Export as Excel file?</Modal.Header>
      <Modal.Content>
        {loading ? (
          <div>
            Loading Excel data...
            <Progress size="small" color="teal" indicating percent={100} />
          </div>
        ) : error ? (
          <div>
            <Icon name="warning sign" color="red" />
            There was an error trying to load Excel data
          </div>
        ) : (
          <div>
            Excel data is ready to be exported{' '}
            <Icon name="check" color="olive" />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>No</Button>
        <Button
          primary
          content="Yes"
          onClick={() => handleLoadXlsx()}
          disabled={loading || !excelData}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default BillingExcelExportModal;
