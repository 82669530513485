export const vaccinationsByDosesCoordsMap: {
  [key: string]: object[];
} = {
  MMR: [
    {
      x: 166,
      y: 577,
    },
    {
      x: 166 + 55,
      y: 577,
    },
    {
      x: 166 + 106,
      y: 577,
    },
  ],
  MEASLES: [
    {
      x: 166,
      y: 560,
    },
    {
      x: 166 + 55,
      y: 560,
    },
    {
      x: 166 + 106,
      y: 560,
    },
  ],
  MUMPS: [
    {
      x: 166,
      y: 542,
    },
    {
      x: 166 + 55,
      y: 542,
    },
    {
      x: 166 + 106,
      y: 542,
    },
  ],
  RUBELLA: [
    {
      x: 166,
      y: 524,
    },
    {
      x: 166 + 55,
      y: 524,
    },
    {
      x: 166 + 106,
      y: 524,
    },
  ],
  MENING: [
    {
      x: 166,
      y: 506,
    },
    {
      x: 166 + 55,
      y: 506,
    },
    {
      x: 166 + 106,
      y: 506,
    },
  ],
  TDAP: [
    {
      x: 206,
      y: 431,
    },
    {
      x: 206 + 55,
      y: 431,
    },
    {
      x: 206 + 110,
      y: 431,
    },
    {
      x: 206 + 165,
      y: 431,
    },
    {
      x: 206 + 220,
      y: 431,
    },
    {
      x: 206 + 275,
      y: 431,
    },
  ],
  TD: [
    {
      x: 206,
      y: 414,
    },
    {
      x: 206 + 55,
      y: 414,
    },
    {
      x: 206 + 110,
      y: 414,
    },
    {
      x: 206 + 165,
      y: 414,
    },
    {
      x: 206 + 220,
      y: 414,
    },
    {
      x: 206 + 275,
      y: 414,
    },
    {
      x: 206 + 330,
      y: 414,
    },
  ],
  POLIO: [
    {
      x: 206,
      y: 396,
    },
    {
      x: 206 + 55,
      y: 396,
    },
    {
      x: 206 + 110,
      y: 396,
    },
    {
      x: 206 + 165,
      y: 396,
    },
    {
      x: 206 + 220,
      y: 396,
    },
    {
      x: 206 + 275,
      y: 396,
    },
  ],
  HepA: [
    {
      x: 206,
      y: 378,
    },
    {
      x: 206 + 55,
      y: 378,
    },
    {
      x: 206 + 110,
      y: 378,
    },
    {
      x: 206 + 165,
      y: 378,
    },
    {
      x: 206 + 220,
      y: 378,
    },
    {
      x: 206 + 275,
      y: 378,
    },
    {
      x: 206 + 330,
      y: 378,
    },
  ],
  HepB: [
    {
      x: 206,
      y: 360,
    },
    {
      x: 206 + 55,
      y: 360,
    },
    {
      x: 206 + 110,
      y: 360,
    },
    {
      x: 206 + 165,
      y: 360,
    },
    {
      x: 206 + 220,
      y: 360,
    },
    {
      x: 206 + 275,
      y: 360,
    },
  ],
  MeningB: [
    {
      x: 206,
      y: 342,
    },
    {
      x: 206 + 55,
      y: 342,
    },
    {
      x: 206 + 110,
      y: 342,
    },
    {
      x: 206 + 165,
      y: 342,
    },
    {
      x: 206 + 220,
      y: 342,
    },
    {
      x: 206 + 275,
      y: 342,
    },
  ],
  HPV: [
    {
      x: 206,
      y: 324,
    },
    {
      x: 206 + 55,
      y: 324,
    },
    {
      x: 206 + 110,
      y: 324,
    },
    {
      x: 206 + 165,
      y: 324,
    },
    {
      x: 206 + 220,
      y: 324,
    },
    {
      x: 206 + 275,
      y: 324,
    },
  ],
  FLU: [
    {
      x: 206,
      y: 306,
    },
    {
      x: 206 + 55,
      y: 306,
    },
    {
      x: 206 + 110,
      y: 306,
    },
    {
      x: 206 + 165,
      y: 306,
    },
    {
      x: 206 + 220,
      y: 306,
    },
    {
      x: 206 + 275,
      y: 306,
    },
  ],
  'COVID-19': [
    {
      x: 206,
      y: 288,
    },
    {
      x: 206 + 55,
      y: 288,
    },
    {
      x: 206 + 110,
      y: 288,
    },
    {
      x: 206 + 165,
      y: 288,
    },
    {
      x: 206 + 220,
      y: 288,
    },
    {
      x: 206 + 275,
      y: 288,
    },
  ],
  VARICELLA: [
    {
      x: 206,
      y: 270,
    },
    {
      x: 206 + 55,
      y: 270,
    },
  ],
};
