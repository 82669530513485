import { Icon, SemanticICONS, Menu, Dropdown, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { COLORS_BY_NAME } from '@bluefox/constants';

interface PatientMenuDropdownType {
  icon?: SemanticICONS;
  label?: string;
  onClick?: () => void;
  minimalistic?: boolean;
  items: React.ReactNode[];
}

const PatientMenuDropdown = ({
  label,
  minimalistic,
  items,
}: PatientMenuDropdownType) => {
  return (
    <Popup
      content={label}
      disabled={!minimalistic}
      trigger={
        <Dropdown
          pointing="top right"
          icon={null}
          trigger={
            <StyledMenuItem>
              <Icon name="download" />
              {!minimalistic && label}
            </StyledMenuItem>
          }
        >
          <Dropdown.Menu>{items}</Dropdown.Menu>
        </Dropdown>
      }
    />
  );
};

export default PatientMenuDropdown;

const StyledMenuItem = styled(Menu.Item)`
  &:hover {
    background-color: ${COLORS_BY_NAME['Anti-Flash White']} !important;
  }
`;
