import moment from 'moment';

export const calculateAge = (_date1: string, _date2: string): number => {
  const [date1YYYYMMDD] = _date1.split('T');
  const [date2YYYYMMDD] = _date2.split('T');

  const [date1, date2] = [moment(date1YYYYMMDD), moment(date2YYYYMMDD)];
  const diff = date1.diff(date2, 'years', true);

  return Number(diff.toFixed(1)); // format N.n
};
