import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Search, SearchResultData } from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts/Practice';
import { PatientsQuery } from '@bluefox/graphql/patients';
import { PatientsData } from '@bluefox/models/Patient';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';

interface SearchValue {
  value: string;
}

const SearchPatient = () => {
  const history = useHistory();
  const practice = usePractice();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [typing, setTyping] = useState(false);
  const [criteria, setCriteria] = useState<object>({});

  useEffect(() => {
    setTyping(!!searchQuery && searchQuery.length < 3);
  }, [searchQuery]);

  useEffect(() => {
    let _criteria = {};
    if (searchQuery) {
      _criteria = {
        _or: [
          {
            patientData: {
              lookup: {
                _ilike: `%${searchQuery}%`,
              },
            },
          },
          { mrn: { _ilike: `%${searchQuery}%` } },
        ],
        practice: {
          handler: {
            _in: practice.organization?.practices.map(
              (practice) => practice.handler
            ),
          },
        },
        active: { _in: [true] },
      };
    }
    setCriteria(_criteria);
  }, [searchQuery]);

  const { data, loading } = useQuery<PatientsData>(PatientsQuery, {
    variables: {
      criteria,
      limit: 25,
      offset: 0,
    },
    skip: !searchQuery.length || typing,
    fetchPolicy: 'no-cache',
  });

  const handleResultSelect = ({ result }: SearchResultData) => {
    history.push(`/${practice.handler}/patients/${result.id}`);
    setSearchQuery('');
    setTyping(false);
    setCriteria({});
  };

  const results = data?.patients.map((patient) => {
    return {
      id: patient.id,
      description: moment(patient.patientData.birthdate).format(
        DateFormats.DATE
      ),
      title: `${patient.patientData.firstName} ${patient.patientData.lastName}`,
    };
  });

  return (
    <StyledSearch
      className="sidebar-patient-search"
      size="mini"
      fluid
      placeholder="Find Patient"
      minCharacters={3}
      loading={loading}
      onResultSelect={(
        _: React.MouseEvent<HTMLDivElement, MouseEvent>,
        result: SearchResultData
      ) => handleResultSelect(result)}
      onSearchChange={(_: React.ChangeEvent, { value }: SearchValue) => {
        setSearchQuery(value ?? '');
      }}
      results={results}
      value={searchQuery}
      noResultsDescription={loading ? 'Finding...' : 'No results found.'}
      noResultsMessage={''}
    />
  );
};

const StyledSearch = styled(Search)`
  div {
    width: 12rem !important;
  }
`;

export default SearchPatient;
