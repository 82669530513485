import { PracticePatient } from './Patient';

export enum AppointmentStatus {
  Active = 'Active',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Missed = 'Missed',
  Cancelled = 'Cancelled',
  Arrived = 'Arrived',
}

export interface AppointmentMetadata {
  vaccine?: {
    name: string;
    dose: number;
  };
  sendEmail?: boolean;
  athenaAppointmentId?: string;
  develoAppointmentId?: string;
  ecwAppointmentId?: number;
  rawAppointmentId?: string;
}

export interface Appointment {
  id: string;
  status: AppointmentStatus;
  time: Date;
  patient: PracticePatient;
  verifiedInsurance: boolean;
  childrenAppointments?: Appointment[];
  metadata?: AppointmentMetadata;
}
