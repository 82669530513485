import React, { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';
import { SemanticToastContainer } from 'react-semantic-toasts';

import { ApolloProvider } from '@bluefox/contexts/Apollo';
import {
  ApplicationStateProvider,
  useApplicationState,
} from '@bluefox/contexts/ApplicationState';
import { AuthProvider } from '@bluefox/contexts/Auth';
import { LocalStorageProvider } from '@bluefox/contexts/LocalStorage';
import {
  PracticeContextProvider,
  usePractice,
} from '@bluefox/contexts/Practice';

import {
  AppointmentsScreen,
  InventoryScreen,
  PatientScreen,
  PatientsScreen,
  SignInScreen,
  SettingsScreen,
  LandingPage,
  BillingScreen,
  VaccinationsListScreen,
  SelectUserScreen,
} from '@screens/index';

import ConditionalRoute from '@bluefox/ui/ConditionalRoute';
import PageBlurrer from '@bluefox/ui/PageBlurrer';
import CustomModal from '@bluefox/ui/CustomModal';
import PatientForm from '@bluefox/ui/PatientForm';
import InsurancesList from '@bluefox/ui/InsurancesList';
import InsurancesForm from '@bluefox/ui/InsurancesForm';
import Appointments from '@screens/patient/Appointments';
import ProofOfImmunization from '@screens/patient/ProofOfImmunization';
import { ADMIN_ROLE, BILLER_ROLE, USER_ROLE } from './generalSettings';
import { useClearCache } from 'react-clear-cache';
import { Button, Icon, Message } from 'semantic-ui-react';
import PatientByMrn from '@components/PatientByMrn';
import EOBByClaimId from '@components/EOBByClaimId';
import AdjustmentSelector from '@screens/inventory/adjustmentSelector';
import ScrollToTop from '@bluefox/lib/ScrollToTop';
import PatientGuardiansList from '@bluefox/ui/PatientGuardiansList';
import { BorrowingScreen } from '@components/borrowing/BorrowingScreen';
import Notifications from '@screens/notifications';
import InventoryOrdersScreen from '@screens/inventoryOrders/inventoryOrdersScreen';

moment.updateLocale('en', {
  relativeTime: {
    d: '1 day',
    M: '1 month',
    y: '1 year',
  },
});

function isEmbedded() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function App() {
  const [signedIn, setSignedIn] = useState(false);
  const [isIdle, setIsIdle] = useState(false);

  const props = {
    duration: 60 * 1000,
    auto: false,
    // storageKey: STORAGE_KEY,
    basePath: '',
    filename: 'build.json',
  };

  const { isLatestVersion, emptyCacheStorage } = useClearCache(props);

  return (
    <PageBlurrer blurred={signedIn && isIdle}>
      <div
        style={{
          marginTop: '10px',
          marginRight: '10px',
          position: 'fixed',
          zIndex: '999',
          right: 0,
          top: 0,
        }}
      >
        {!isLatestVersion && signedIn && (
          <Message color="olive" size="mini">
            <div
              style={{
                display: 'flex',
              }}
            >
              <Icon name="bullhorn" />
              <Message.Header>New Canid version available!</Message.Header>
            </div>
            <div
              style={{
                marginTop: '10px',
              }}
            >
              <Button
                icon="refresh"
                size="mini"
                basic
                onClick={(e) => {
                  e.preventDefault();
                  emptyCacheStorage();
                }}
                content="Refresh"
              />
            </div>
          </Message>
        )}
      </div>
      <Router>
        <Route
          path="/:handler"
          render={({ match }) => {
            const { handler } = match.params;

            return (
              <LocalStorageProvider partition={handler}>
                <ApplicationStateProvider
                  onIdleChange={(isIdle) => {
                    setIsIdle(isIdle);
                  }}
                  isEmbedded={isEmbedded()}
                >
                  <ApolloProvider>
                    <SemanticToastContainer />

                    <PracticeContextProvider handler={handler}>
                      <AuthProvider
                        handler={handler}
                        onLoad={(isAuthenticated) =>
                          setSignedIn(isAuthenticated)
                        }
                        onSignedIn={() => {
                          setSignedIn(true);
                        }}
                        onSignedOut={() => {
                          setSignedIn(false);
                        }}
                      >
                        <ScrollToTop />
                        <Switch>
                          <Route path={[`/${handler}`, `/${handler}/`]} exact>
                            <Redirect
                              to={`/${handler}/sign-in`}
                              from={`/${handler}/claims`}
                            />
                          </Route>
                          <Route path={`/${handler}/sign-in`}>
                            <SignInScreen />
                          </Route>
                          <Route path={`/${handler}/select-user`}>
                            <SelectUserScreen />
                          </Route>
                          <PrivateRoute
                            path={[
                              `/${handler}/patients`,
                              `/${handler}/patients/_new`,
                              `/${handler}/patients/_new-basic/:mrn?`,
                            ]}
                            exact
                            userOnly
                          >
                            <PatientsScreen />
                          </PrivateRoute>
                          {/* <PrivateRoute
                            path={`/${handler}/analytics`}
                            userOnly
                            exact
                          >
                            <AnalyticsScreen />
                          </PrivateRoute> */}
                          <PrivateRoute
                            path={`/${handler}/patients/mrn/:mrn`}
                            userOnly
                            exact
                          >
                            <PatientByMrn />
                          </PrivateRoute>

                          <PrivateRoute
                            path={`/${handler}/eob/:claimId`}
                            userOnly
                            exact
                          >
                            <EOBByClaimId />
                          </PrivateRoute>

                          <PrivateRoute
                            userOnly
                            path={`/${handler}/patients/:practicePatientId/`}
                          >
                            <PatientScreen pageBlurred={isIdle} />
                            <PrivateRoute
                              userOnly
                              path={`/${handler}/patients/:practicePatientId/info`}
                            >
                              <CustomModal modalHeader="Patient Info">
                                <PatientForm
                                  rightActions={[
                                    {
                                      'data-automation-id':
                                        'patient-basic-submit',
                                      primary: true,
                                      type: 'submit',
                                      content: 'Save',
                                      icon: 'save',
                                      name: 'save',
                                    },
                                  ]}
                                />
                              </CustomModal>
                            </PrivateRoute>
                            <PrivateRoute
                              userOnly
                              exact
                              path={`/${handler}/patients/:practicePatientId/insurances`}
                            >
                              <CustomModal
                                scrolling
                                modalHeader="Insurance List"
                              >
                                <InsurancesList />
                              </CustomModal>
                            </PrivateRoute>
                            <PrivateRoute
                              userOnly
                              path={`/${handler}/patients/:practicePatientId/appointments`}
                            >
                              <CustomModal modalHeader="Patient Appointments">
                                <Appointments />
                              </CustomModal>
                            </PrivateRoute>
                            <PrivateRoute
                              userOnly
                              path={`/${handler}/patients/:practicePatientId/immunizations`}
                            >
                              <ProofOfImmunization />
                            </PrivateRoute>
                            <PrivateRoute
                              userOnly
                              path={`/practices/${handler}/insurances/patients/:practicePatientId/insurances/:insuranceId`}
                            >
                              <InsurancesForm />
                            </PrivateRoute>
                            <PrivateRoute
                              userOnly
                              path={`/${handler}/patients/:practicePatientId/guardians`}
                            >
                              <CustomModal modalHeader="Patient Guardians List">
                                <PatientGuardiansList />
                              </CustomModal>
                            </PrivateRoute>
                          </PrivateRoute>
                          <PrivateRoute
                            path={`/${handler}/appointments`}
                            userOnly
                          >
                            <AppointmentsScreen />
                          </PrivateRoute>

                          <PrivateRoute
                            path={`/${handler}/vaccinations`}
                            //showVaxListToBiller
                          >
                            <VaccinationsListScreen pageBlurred={isIdle} />
                          </PrivateRoute>

                          <PrivateRoute path={`/${handler}/orders`}>
                            <InventoryOrdersScreen />
                          </PrivateRoute>

                          <PrivateRoute
                            path={`/${handler}/inventory/adjustment`}
                            userOnly
                          >
                            <AdjustmentSelector />
                          </PrivateRoute>

                          <PrivateRoute path={`/${handler}/inventory`} userOnly>
                            <InventoryScreen />
                          </PrivateRoute>

                          <PrivateRoute path={`/${handler}/borrowing`} userOnly>
                            <BorrowingScreen />
                          </PrivateRoute>

                          <PrivateRoute
                            userOnly
                            path={`/${handler}/settings`}
                            adminOnly={true}
                          >
                            <SettingsScreen />
                          </PrivateRoute>
                          <PrivateRoute
                            path={`/${handler}/billing`}
                            billerOnly={true}
                          >
                            <BillingScreen
                              handler={handler}
                              pageBlurred={isIdle}
                            />
                          </PrivateRoute>
                          <PrivateRoute path={`/${handler}/notifications`}>
                            <Notifications />
                          </PrivateRoute>
                        </Switch>
                      </AuthProvider>
                    </PracticeContextProvider>
                  </ApolloProvider>
                </ApplicationStateProvider>
              </LocalStorageProvider>
            );
          }}
        ></Route>
        <Route
          exact
          path="/"
          render={({ match }) => {
            return <LandingPage />;
          }}
        ></Route>
      </Router>
    </PageBlurrer>
  );
}

interface PrivateRouteProps extends RouteProps {
  adminOnly?: boolean;
  billerOnly?: boolean;
  userOnly?: boolean;
  showVaxListToBiller?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  adminOnly = false,
  billerOnly = false,
  userOnly = false,
  showVaxListToBiller = false,
  ...rest
}) => {
  const { token, session } = useApplicationState();
  const practice = usePractice();

  // const { data } = useQuery(ShowVaxListToBillerQuery, {
  //   variables: {
  //     practiceId: practice.id,
  //   },
  // });

  //const showVaxListToBillerFlag = data?.practices[0]?.showVaxListToBiller;

  const predicate = useCallback(() => {
    if (adminOnly) {
      return session?.account?.role === ADMIN_ROLE && !!token;
    }
    if (billerOnly) {
      return (
        (session?.account?.role === ADMIN_ROLE ||
          session?.account?.role === BILLER_ROLE) &&
        !!token
      );
    }
    if (userOnly) {
      return (
        (session?.account?.role === ADMIN_ROLE ||
          session?.account?.role === USER_ROLE) &&
        !!token
      );
    }
    // if (showVaxListToBiller) {
    //   return (
    //     (session?.account?.role === ADMIN_ROLE ||
    //       session?.account?.role === USER_ROLE ||
    //       (session?.account?.role === BILLER_ROLE &&
    //         showVaxListToBillerFlag)) &&
    //     !!token
    //   );
    // }
    return !!token;
  }, [
    adminOnly,
    billerOnly,
    userOnly,
    //showVaxListToBiller,
    token,
    session?.account?.role,
    //showVaxListToBillerFlag,
  ]);

  return (
    <ConditionalRoute
      predicate={predicate}
      fallbackTo={`/${practice.handler}/sign-in`}
      {...rest}
    />
  );
};

export default App;
