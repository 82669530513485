import styled from 'styled-components';

type StyledCardProps = {
  borderColor?: string;
  boxShadowColor?: string;
};

type StyledMessageProps = {
  margin?: string;
  textAlign?: string;
};

export const StyledCard = styled.div<StyledCardProps>`
  border-radius: 8px;
  border: 1px solid ${(props) => props.borderColor};
  ${(props) =>
    props.boxShadowColor
      ? ` box-shadow:  0px 1px 4px 0px ${props.boxShadowColor}`
      : null};
  width: 100%;
  margin: 1rem 0;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const StyledCardContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledCardItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  color: #64748b;
`;

export const StyledCardItemText = styled.div`
  color: #333b47;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${(props) => props.color};
`;

export const StyledMessage = styled.div<StyledMessageProps>`
  background-color: ${(props) => props.color};
  color: #c92a2a;
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  justify-content: ${(props) => props.textAlign};
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 4px;
  margin: ${(props) => props.margin};
`;
