import { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import { useAuth } from '@bluefox/contexts/Auth';
import { usePractice } from '@bluefox/contexts/Practice';
import { Redirect, useLocation } from 'react-router-dom';
import { Container, Grid, Header, Image, Message } from 'semantic-ui-react';
import { useApplicationState } from '@bluefox/contexts/ApplicationState';
import { useLSHandler } from '@bluefox/contexts';
import SignInForm, { SignInFormValuesProps } from '@bluefox/ui/SignInForm';

import { DEFAULT_INITIAL_PAGE } from '../generalSettings';
interface LocationState {
  from: {
    pathname: string;
    search: string;
  };
}

const SignInScreen = () => {
  const lsHandler = useLSHandler();
  const location = useLocation<LocationState>();
  const practice = usePractice();
  const { messages, token } = useApplicationState();

  const { isAuthenticated, actions } = useAuth();
  const [signIn, { loading }] = actions.signIn;

  const [loginInfo, setLoginInfo] = useState<SignInFormValuesProps>(
    {} as SignInFormValuesProps
  );

  const handleSignIn = useCallback(
    async (loginValues: SignInFormValuesProps) => {
      //TODO: refactor any type
      const singInResponse: any = await signIn(
        loginValues.email,
        loginValues.password
      );
      const currentTimeZone = practice.timezone || moment.tz.guess();
      moment.tz.setDefault(currentTimeZone);

      if (singInResponse?.errors)
        setLoginInfo((prevValue) => ({
          ...prevValue,
          error: 'Incorrect username or password',
        }));

      return singInResponse;
    },
    [signIn]
  );

  const handleRedirect = (defaultRedirect: string): string => {
    let redirectTo: string = defaultRedirect;

    if (location.state?.from.pathname) {
      const queryParams = location.state?.from?.search || '';
      redirectTo = `${location.state.from.pathname}${queryParams}`;
    }

    return redirectTo;
  };

  if (isAuthenticated && token) {
    const userData = JSON.parse(atob(token.split('.')[1]));

    const lsData = lsHandler.getItem('logged-accounts');
    let parsedLsData = lsData ? JSON.parse(lsData) : [];

    parsedLsData = [
      ...parsedLsData.filter(
        (item: any) => item?.email !== userData.account.email
      ),
      userData.account,
    ];

    lsHandler.setItem(`logged-accounts`, JSON.stringify(parsedLsData));

    const redirectsTo = DEFAULT_INITIAL_PAGE.find(
      (p) => p.role === userData.account.role
    );

    return (
      <Redirect
        to={handleRedirect(`/${practice.handler}/${redirectsTo?.page}`)}
      />
    );
  }

  return (
    <Container>
      <Grid
        textAlign="center"
        style={{ height: '80vh' }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{
            maxWidth: 450,
          }}
        >
          {messages.map(({ content, ...type }, i) => (
            <Message key={i} {...type} content={content} />
          ))}
          <Header
            as="h2"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Image
              src="/canid-dark-logo.png"
              alt=""
              style={{ width: '140px' }}
            />{' '}
            Sign in
          </Header>
          <SignInForm
            onSubmit={() => handleSignIn(loginInfo)}
            loading={loading}
            signInFormValues={loginInfo}
            setSignInFormValues={setLoginInfo}
          />
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default SignInScreen;
