import {
  useApplicationState,
  usePractice,
  useSession,
} from '@bluefox/contexts';
import { useAuth } from '@bluefox/contexts/Auth';
import { ReactNode } from 'react';
import { Dimmer, Grid, Icon, Loader } from 'semantic-ui-react';
import SidebarComponent from './Sidebar';
import { useSubscription } from '@apollo/client';
import { logoutOnSuspendedPracticeSubscription } from '@graphql/users';
import { useHistory } from 'react-router-dom';
import ErrorReportModal from './ErrorReportModal';
import { BILLER_ROLE } from '../generalSettings';

interface FullLayoutProps {
  children: ReactNode;
  loading?: boolean;
}

interface PracticeSubData {
  id: string;
  suspended: boolean;
}

const FullLayout = ({ children, loading }: FullLayoutProps) => {
  const { isEmbedded } = useApplicationState();
  const { actions } = useAuth();
  const practice = usePractice();
  const history = useHistory();
  const session = useSession();

  useSubscription(logoutOnSuspendedPracticeSubscription, {
    onSubscriptionData: (data) => {
      if (
        data.subscriptionData.data.practices.find(
          (p: PracticeSubData) => p.id === practice.id
        )
      ) {
        actions.signOut(() => history.push(`/${practice.handler}`));
      }
    },
  });

  if (isEmbedded) {
    return loading ? (
      <Dimmer active inverted>
        <Loader inverted size="massive">
          Loading
        </Loader>
      </Dimmer>
    ) : (
      <>
        {children}
        {session?.account?.role !== BILLER_ROLE && (
          <ErrorReportModal>
            <div className="fixed-support-bubble">
              <Icon name="chat" className="bubble-support-icon" />
            </div>
          </ErrorReportModal>
        )}
      </>
    );
  }

  return (
    <>
      <Grid style={{ flex: 1 }}>
        <Grid.Row>
          <Grid.Column
            only="computer"
            stretched
            width={3}
            style={{
              paddingBottom: 0,
              height: '100vh',
              // position: "fixed",
              zIndex: '100',
            }}
          >
            <SidebarComponent version="computer" />
          </Grid.Column>
          <Grid.Column only="tablet" width={3}>
            <SidebarComponent version="tablet" />
          </Grid.Column>
          <Grid.Column only="mobile" width={3}>
            <SidebarComponent version="mobile" />
          </Grid.Column>
          <Grid.Column width={12}>
            {/* <TopbarComponent /> */}
            {loading ? (
              <Dimmer active inverted>
                <Loader inverted size="massive">
                  Loading
                </Loader>
              </Dimmer>
            ) : (
              children
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {session?.account?.role !== BILLER_ROLE && (
        <ErrorReportModal>
          <div className="fixed-support-bubble">
            <Icon name="chat" className="bubble-support-icon" />
          </div>
        </ErrorReportModal>
      )}
    </>
  );
};

export default FullLayout;
