import React, { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { VaccinationsByTypeQuery } from '@bluefox/graphql/vaccinations';
import {
  RoutineTypes,
  NonRoutineTypes,
  VACCINE_TYPES,
} from '@bluefox/store/vaccines';
import { Vaccination } from '@bluefox/models/Vaccination';

import { getImmunization } from '@bluefox/store/vaccinations';

import { Modal, Header } from 'semantic-ui-react';
import VaxHistory from '@components/VaxHistory/VaxHistory';
import { usePracticePatient } from '@bluefox/contexts/PracticePatientProvider';
import { differenceInMonths } from '@utils/validations/inRange';

export interface VaccinationsData {
  vaccinations: Vaccination[];
}

interface VaxHistoryModalProps {
  trigger: React.ReactNode;
  practicePatientId: string;
}

const VaxHistoryModal = ({
  trigger,
  practicePatientId,
}: VaxHistoryModalProps) => {
  const practicePatient = usePracticePatient();
  const patientData = practicePatient?.patientData;

  const [open, setOpen] = useState(false);

  const { data: vaccinationsData, refetch } = useQuery<VaccinationsData>(
    VaccinationsByTypeQuery,
    {
      variables: {
        practicePatientId,
        types: VACCINE_TYPES,
      },
    }
  );

  const scheduleVaccines = useMemo(
    () =>
      patientData
        ? getImmunization(
            RoutineTypes,
            vaccinationsData?.vaccinations || [],
            patientData
          )
        : [],
    [vaccinationsData?.vaccinations, patientData]
  );

  const notScheduledVaccines = useMemo(
    () =>
      patientData
        ? getImmunization(
            NonRoutineTypes,
            vaccinationsData?.vaccinations || [],
            patientData
          )
        : [],
    [vaccinationsData?.vaccinations, patientData]
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeIcon
      trigger={trigger}
    >
      <Modal.Content>
        <Modal.Description>
          <Header>Vax History</Header>
        </Modal.Description>
        <VaxHistory
          vaccinationsData={vaccinationsData}
          vaccines={[...scheduleVaccines, ...notScheduledVaccines]}
          patientAgeInMonths={
            patientData?.birthdate
              ? differenceInMonths(patientData?.birthdate, new Date())
              : 0
          }
          onUpdatedVaccinations={refetch}
        />
      </Modal.Content>
    </Modal>
  );
};

export default VaxHistoryModal;
