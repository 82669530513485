import EchartsContainer from '@bluefox/ui/EchartsContainer';
import { compactNumber } from '@bluefox/lib/formatters';
import { SemanticColorToString } from '@bluefox/constants';

type Props = {
  inventoryAmount: number;
  administrationAmount: number;
};

const TotalRecoveredChart = ({
  inventoryAmount,
  administrationAmount,
  ...rest
}: Props) => {
  return (
    <EchartsContainer
      options={{
        tooltip: {},
        grid: {
          top: '10%',
          bottom: '10%',
          left: '5%',
          right: '1%',
        },
        xAxis: {
          data: ['Inventory', 'Admin Fees'],
          axisLabel: {
            fontWeight: 'bold',
          },
        },
        yAxis: {
          axisLabel: {
            formatter: (value: number) => compactNumber(value),
          },
        },
        series: [
          {
            name: 'Total Recovered',
            type: 'bar',
            data: [
              {
                value: inventoryAmount,
                itemStyle: {
                  color: SemanticColorToString('blue'),
                  borderRadius: 5,
                },
              },
              {
                value: administrationAmount,
                itemStyle: {
                  color: SemanticColorToString('teal'),
                  borderRadius: 5,
                },
              },
            ],
          },
        ],
      }}
      {...rest}
    />
  );
};

export default TotalRecoveredChart;
