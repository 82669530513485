import { Vaccination } from '@bluefox/models/Vaccination';

export const vaccinationUploadedToEmr = (v: Vaccination): boolean => {
  if (!v) return false;
  if (v.historic) return false;
  if (
    v.bot?.status === 'completed' ||
    v.bot?.status === 'completed_iis' ||
    v.athena?.status === 'completed' ||
    v.athena?.status === 'finished' ||
    v.develo?.status === 'completed' ||
    v.develo?.status === 'finished'
  ) {
    return true;
  }

  return v.emrUploadedBy !== 'pending';
};
