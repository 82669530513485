import FullLayout from '@components/FullLayout';
import {
  Accordion,
  Button,
  Card,
  Header,
  Icon,
  Segment,
  Tab,
} from 'semantic-ui-react';
import React, { useState } from 'react';
import InventoryOrdersTable from '@screens/inventoryOrders/InventoryOrdersTable';
import {
  InventoryOrdersFilter,
  IsVFC,
  SelectAll,
} from '@bluefox/models/InventoryOrders';
import { usePractice } from '@bluefox/contexts';
import { InventoryOrderTableAdvancedFilters } from '@screens/inventoryOrders/inventoryOrdersTableAdvancedFilters';
import { Link } from 'react-router-dom';

const InventoryOrdersScreen = () => {
  const practice = usePractice();

  const initialBalanceMovementFilter: InventoryOrdersFilter = {
    vaccine: undefined,
    isFVC: IsVFC.NO,
    status: SelectAll.ALL,
    practiceId: practice.id,
    dateFrom: undefined,
    dateTo: undefined,
    orderNumber: undefined,
  };

  const [inventoryOrdersFilter, setInventoryOrdersFilter] =
    useState<InventoryOrdersFilter>(initialBalanceMovementFilter);

  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);
  return (
    <FullLayout>
      <Segment padded basic>
        <Header as="h1">
          <Icon name="file alternate" />
          Inventory Orders
        </Header>
        <Button
          primary
          size="small"
          content="Go Back"
          as={Link}
          to={`/${practice.handler}/inventory`}
          onClick={(e) => {
            //stopPropagation for the "Place Order" button. So when it's clicked, the "Placed orders" accordion desn't open.
            e.stopPropagation();
          }}
        />
      </Segment>

      <Tab
        menu={{ secondary: true, pointing: true }}
        activeIndex={inventoryOrdersFilter.isFVC === IsVFC.NO ? 0 : 1}
        onTabChange={(_, data) => {
          setInventoryOrdersFilter((prevState: InventoryOrdersFilter) => {
            return {
              ...prevState,
              isFVC: data.activeIndex === 0 ? IsVFC.NO : IsVFC.YES,
            };
          });
        }}
        panes={[
          {
            menuItem: 'Private',
          },
          {
            menuItem: 'VFC',
          },
        ]}
      />
      <br />
      <Accordion styled fluid>
        <Accordion.Title
          active={isAdvancedFiltersOpen}
          onClick={() => {
            setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen);
          }}
        >
          <Icon name="dropdown" />
          Advanced Filters
        </Accordion.Title>
        <Accordion.Content active={isAdvancedFiltersOpen}>
          <Card fluid>
            <Card.Content>
              <InventoryOrderTableAdvancedFilters
                setInventoryOrdersFilter={setInventoryOrdersFilter}
                inventoryOrdersFilter={inventoryOrdersFilter}
                resetFilters={() =>
                  setInventoryOrdersFilter((prevState) => {
                    return {
                      ...initialBalanceMovementFilter,
                      isFVC: prevState.isFVC,
                    };
                  })
                }
              />
            </Card.Content>
          </Card>
        </Accordion.Content>
      </Accordion>
      <InventoryOrdersTable inventoryOrdersFilter={inventoryOrdersFilter} />
    </FullLayout>
  );
};

export default InventoryOrdersScreen;
