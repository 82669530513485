import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { GetEOBLink } from '@bluefox/graphql/billing';

const EOBByClaimId = () => {
  const { claimId } = useParams<{ claimId: string }>();
  const { data } = useQuery(GetEOBLink, {
    variables: { claimId },
  });

  useEffect(() => {
    if (!data) return;
    window.location.href = data.claim.EOBLink;
  }, [data]);

  return (
    <div>
      <Segment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Segment>
    </div>
  );
};

export default EOBByClaimId;
