import { InvoicesListScreen } from '@screens';
import { CSSProperties, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

type Props = {
  practiceIds: string[];
  style?: CSSProperties;
};

const OldBillingInvoiceListModal = ({ practiceIds, style }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="fullscreen"
      closeIcon
      trigger={<Button style={style}>View Invoice History</Button>}
    >
      <Modal.Header>Invoice History</Modal.Header>
      <Modal.Content>
        <InvoicesListScreen practiceIds={practiceIds} />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OldBillingInvoiceListModal;
