import { DateFormats } from '@bluefox/models/Dates';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { Dispatch, SetStateAction } from 'react';
import { Card, Form } from 'semantic-ui-react';

export type SearchValuesProps = {
  dateFrom?: Date;
  dateTo?: Date;
};

type Props = {
  searchValues: SearchValuesProps;
  setSearchValues: Dispatch<SetStateAction<SearchValuesProps>>;
};

const SearchForm = ({ searchValues, setSearchValues }: Props) => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Description>
          <Form>
            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '11rem' }}>Filter by date range:</label>
              </Form.Field>
              <Form.Field>
                <DateTimePicker
                  forFilter
                  placeholderText="From:"
                  selected={searchValues.dateFrom}
                  onChange={(value) => {
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateFrom: value ? (value as Date) : undefined,
                    }));
                  }}
                  onSelect={(value) =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateFrom: value ? (value as Date) : undefined,
                    }))
                  }
                  onClear={() =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateFrom: undefined,
                    }))
                  }
                  maxDate={new Date()}
                  dateFormat={DateFormats.DATE}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  isClearable
                />
              </Form.Field>
              <Form.Field>
                <DateTimePicker
                  forFilter
                  placeholderText="To:"
                  selected={searchValues.dateTo}
                  onChange={(value) => {
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateTo: value ? (value as Date) : undefined,
                    }));
                  }}
                  onSelect={(value) =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateTo: value ? (value as Date) : undefined,
                    }))
                  }
                  onClear={() =>
                    setSearchValues((prevVal) => ({
                      ...prevVal,
                      dateTo: undefined,
                    }))
                  }
                  maxDate={new Date()}
                  dateFormat={DateFormats.DATE}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  isClearable
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default SearchForm;
