import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Segment, Form, Icon, Message, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import UserCircularIcon from './UserCircularIcon';
import { notifyPendingNotificationCountToExtension } from '@messaging/extension';

export type SignInFormValuesProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  error: string;
  isAuthenticated: boolean;
  token: string;
};

type SignInFormProps = {
  onSubmit: () => void;
  loading: boolean;
  signInFormValues: SignInFormValuesProps;
  setSignInFormValues: Dispatch<SetStateAction<SignInFormValuesProps>>;
  showUserIcon?: boolean;
};

const SignInForm = ({
  onSubmit,
  loading,
  signInFormValues,
  setSignInFormValues,
  showUserIcon,
}: SignInFormProps) => {
  const [showRecoverPassword, setShowRecoverPassword] = useState(false);

  useEffect(() => {
    // Reset Pending notification count in Google Extension
    notifyPendingNotificationCountToExtension(0);
  }, []);

  return (
    <Form
      size="large"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      error={!!signInFormValues.error}
      data-automation-id="sing-in-form"
    >
      {showUserIcon && (
        <StyledUserContainer>
          <UserCircularIcon
            color="#5dbcd1"
            hoverable={false}
          >{`${signInFormValues.firstName
            .charAt(0)
            .toUpperCase()} ${signInFormValues.lastName
            .charAt(0)
            .toUpperCase()}`}</UserCircularIcon>
          <StyledUserTitle>{`${signInFormValues.firstName} ${signInFormValues.lastName}`}</StyledUserTitle>
        </StyledUserContainer>
      )}
      <Segment textAlign="left" basic={showUserIcon}>
        {!showUserIcon && (
          <Form.Field>
            <label>Email</label>
            <Form.Input
              required
              type="email"
              data-automation-id="sing-in-form-email"
              onChange={(_, { value }) =>
                setSignInFormValues((prevValue) => ({
                  ...prevValue,
                  email: value,
                }))
              }
              value={signInFormValues?.email || ''}
              placeholder="Your Email..."
              disabled={loading}
            />
          </Form.Field>
        )}
        <Form.Field>
          <label>Password</label>
          <Form.Input
            required
            data-automation-id="sing-in-form-password"
            type="password"
            value={signInFormValues?.password || ''}
            onChange={(_, { value }) =>
              setSignInFormValues((prevValue) => ({
                ...prevValue,
                password: value,
              }))
            }
            placeholder="Your password..."
            disabled={loading}
          />
        </Form.Field>

        {!!signInFormValues.error && (
          <Message negative>
            <Icon name="warning sign" />
            There was an error loggin in. Wrong information.
          </Message>
        )}
        <Form.Button
          style={{ backgroundColor: '#16324F', color: '#ffffff' }}
          fluid
          size="large"
          icon
          labelPosition="right"
          loading={loading}
          disabled={loading}
          data-automation-id="sing-in-form-sing-button"
        >
          Sign in
          <Icon name="sign in" />
        </Form.Button>
        {showRecoverPassword && (
          <Message
            warning
            visible
            content="Please contact the practice manager to recover you password."
          />
        )}
        <Button
          style={{
            width: '100%',
            display: showRecoverPassword ? 'none' : 'inline',
          }}
          size="small"
          type="button"
          basic
          content="Forgot my password"
          onClick={() => setShowRecoverPassword(true)}
        />
      </Segment>
    </Form>
  );
};

const StyledUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledUserTitle = styled.p`
  margin-top: 0.6rem;
  font-size: 18px;
`;

export default SignInForm;
