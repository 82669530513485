import React from 'react';
import { humanizeText } from '@bluefox/lib/humanize';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';
import styled from 'styled-components';
import { ImmunizationEntry } from '@bluefox/store/vaccinations';
import { Vaccination, VaccinationRoutes } from '@bluefox/models/Vaccination';

interface ImmunizationDetailProps {
  visibleVaccination: Vaccination;
}

const ImmunizationDetail = (props: ImmunizationDetailProps) => {
  const { visibleVaccination } = props;
  const lot =
    visibleVaccination.lot || visibleVaccination.inventory?.lot || undefined;
  return (
    <>
      {!!visibleVaccination.provider && (
        <>
          <StyledCustomLabel>
            <b>Given by:</b>{' '}
            <span>
              {`${visibleVaccination.provider.account.firstName} ${visibleVaccination.provider.account.lastName}`}
            </span>
          </StyledCustomLabel>
          <br />
        </>
      )}

      {!!visibleVaccination.prescriber && (
        <StyledCustomLabel>
          <>
            <b>Prescribed By:</b>{' '}
            <span>
              {`${visibleVaccination.prescriber.account.firstName} ${visibleVaccination.prescriber.account.lastName}`}
            </span>
            <br />
          </>
        </StyledCustomLabel>
      )}

      {lot && (
        <>
          <StyledCustomLabel>
            <b>Lot:</b> <span>{lot}</span>
          </StyledCustomLabel>
          <br />
        </>
      )}

      {!visibleVaccination.historic &&
        !!visibleVaccination.inventory?.expiration && (
          <>
            <StyledCustomLabel>
              <b>Exp:</b>{' '}
              <span>
                {moment(visibleVaccination.inventory?.expiration).format(
                  DateFormats.DATE
                )}
              </span>
            </StyledCustomLabel>
            <br />
          </>
        )}
      {!!visibleVaccination.site && (
        <>
          <StyledCustomLabel>
            <b>Site:</b>{' '}
            <span>
              {visibleVaccination.route === VaccinationRoutes.IN
                ? '-'
                : humanizeText(
                    visibleVaccination.siteDescription?.description as string,
                    {
                      delimiter: '_',
                      capitalize: 'all',
                    }
                  )}
            </span>
          </StyledCustomLabel>
          <br />
        </>
      )}

      {!!visibleVaccination.route && (
        <>
          <StyledCustomLabel>
            <b>Route:</b> <span>{visibleVaccination.route}</span>
          </StyledCustomLabel>
          <br />
        </>
      )}
      {!!visibleVaccination.vaccine?.manufacturer && (
        <>
          <StyledCustomLabel>
            <b>Manufacturer:</b>{' '}
            <span>{visibleVaccination.vaccine?.manufacturer}</span>
          </StyledCustomLabel>
          <br />
        </>
      )}
    </>
  );
};

const StyledCustomLabel = styled.label`
  font-size: 12px;
`;

export default ImmunizationDetail;
