import React from 'react';
import { Button, Label, Message, Popup, Table } from 'semantic-ui-react';
import { VFCInconsistency } from '@bluefox/models/VFCInconsistency';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import BorrowedVaccinesReportAnIssue from './BorrowedVaccinesReportAnIssue';
import BorrowingRedundantWithBox from './BorrowingRedundantWithBox';
import { MagicWordsDict } from './BorrowedVaccinesTable';
import { useMutation } from '@apollo/client';
import {
  GetInconsistenciesToSwap,
  ReadyToBeSwappedMutation,
} from '@bluefox/graphql/billing';
import { toast } from 'react-semantic-toasts';
import BorrowingReportCodeModal from '@bluefox/ui/BorrowingReportCodeModal';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import { humanizeText } from '@bluefox/lib/humanize';
import { StateKey } from '@bluefox/constants/vaccineBorrowingReportCodes';

interface BorrowedVaccinesTableRowProps {
  inconsistency: VFCInconsistency;
  activeOpenTicket?: boolean;
  isStateSupported?: boolean;
  summary?: boolean;
}

export const BorrowedVaccinesTableRow = ({
  inconsistency,
  activeOpenTicket,
  isStateSupported,
  summary,
}: BorrowedVaccinesTableRowProps) => {
  const [readyToBeSwapped] = useMutation(ReadyToBeSwappedMutation, {
    refetchQueries: [GetInconsistenciesToSwap],
  });

  const handleReadyToSwap = async () => {
    try {
      await readyToBeSwapped({
        variables: {
          id: inconsistency.id,
          readyToBeSwapped: !inconsistency.readyToBeSwapped,
        },
      });
      toast({
        title: `${
          !inconsistency.readyToBeSwapped
            ? 'Ready to be swapped!'
            : 'Not ready to be swapped.'
        }`,
        type: 'success',
        time: 2000,
      });
    } catch (error) {
      toast({
        title: `${
          !inconsistency.readyToBeSwapped
            ? 'There was an error trying to mark the case as ready to be swapped'
            : 'An error occurred and the case is still not ready to be swapped'
        }. Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  return (
    <Table.Row key={inconsistency.id}>
      <Table.Cell textAlign="center">
        {`${inconsistency.claim?.practicePatient.patientData
          .firstName} ${inconsistency.claim?.practicePatient.patientData
          .lastName} (${
          inconsistency.claim?.practicePatient.patientData.birthdate
            ? formatDatetimeToMMDDYYY(
                inconsistency.claim?.practicePatient.patientData.birthdate
              )
            : '-'
        })`}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.claim?.updates[0]?.insuranceName || ''}{' '}
        <Label
          content={
            inconsistency.claim?.updates[0]?.vfcEligible
              ? 'VFC Eligible'
              : 'Private'
          }
          size="tiny"
          color={
            inconsistency.claim?.updates[0]?.vfcEligible ? 'orange' : 'teal'
          }
        />
        <Label
          basic
          size="tiny"
          content={`Member ID: ${
            inconsistency.claim?.updates[0]?.memberId || '-'
          }`}
          style={{ marginTop: '0.2rem' }}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.claim?.givenAt
          ? formatDatetimeToMMDDYYY(inconsistency.claim?.givenAt).replaceAll(
              '-',
              '/'
            )
          : '-'}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.inventory?.vfc ? (
          <>
            <Label
              content="VFC Eligible"
              size="tiny"
              color="orange"
              style={{ marginTop: '0.2rem' }}
            />
          </>
        ) : (
          <>
            <Label
              content={'Private'}
              size="tiny"
              color={'teal'}
              style={{ marginTop: '0.2rem' }}
            />
          </>
        )}
        <hr />
        {inconsistency.inventory?.vaccine.saleNdc}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.inventory?.vaccine.name}
        <hr />
        {inconsistency.inventory?.lot}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.status === MagicWordsDict.STATUS.REDUNDANT && (
          <BorrowingRedundantWithBox
            redundantWith={inconsistency.redundantWith}
          />
        )}
      </Table.Cell>
      {!summary ? (
        <>
          <Table.Cell>
            <BorrowingReportCodeModal
              inconsistency={inconsistency}
              state={humanizeText(
                inconsistency.practice?.profile.address?.state!,
                {
                  capitalize: 'all',
                }
              )}
            />
          </Table.Cell>
          <Table.Cell textAlign="center">
            {activeOpenTicket ? (
              <Message warning content="Review in progress" />
            ) : (
              <>
                <Popup
                  content={
                    inconsistency.readyToBeSwapped
                      ? 'Ready to swap'
                      : 'Mark as ready to swap?'
                  }
                  trigger={
                    <Button
                      color={inconsistency.readyToBeSwapped ? 'teal' : 'grey'}
                      basic={!inconsistency.readyToBeSwapped}
                      disabled={activeOpenTicket}
                      onClick={handleReadyToSwap}
                      icon={
                        inconsistency.readyToBeSwapped ? 'checkmark' : 'minus'
                      }
                    />
                  }
                />
                <BorrowedVaccinesReportAnIssue
                  inconsistency={inconsistency}
                  disabled={!!inconsistency.readyToBeSwapped}
                />
              </>
            )}
          </Table.Cell>
        </>
      ) : (
        <Table.Cell>{inconsistency.borrowingReasonCode}</Table.Cell>
      )}
    </Table.Row>
  );
};
