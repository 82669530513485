import { useCallback, useState } from 'react';
import { Card, Dimmer, Loader } from 'semantic-ui-react';
import Legends from '@bluefox/ui/Dashboard/Legends';
import TotalClaimsChart from '@components/charts/TotalClaimsChart';
import TotalAmount from '@bluefox/ui/Dashboard/TotalAmount';
import { usNumberNoDigits } from '@bluefox/lib/formatters';
import { useQuery } from '@apollo/client';
import { BillingDashboardClaimsByStatus } from '@graphql/billing';
import ClaimDropdown from '@bluefox/ui/Dashboard/ClaimDropdown';
import { ClaimStatusEnum } from '@bluefox/models/Dashboard';
import { pixelToRem } from '@bluefox/constants';
import ChartErrorMessage from '@bluefox/ui/Dashboard/ChartErrorMessage';
import { CardContent, CardHeader } from '@bluefox/ui/Dashboard/CommonStyles';

type Props = {
  practiceIds: string[];
  month: number | undefined;
  year: number | undefined;
};

const dropdownOptionsToStatus: { [key: string]: boolean[] } = {
  all: [true, false],
  vfc: [true],
  private: [false],
};

const TotalClaimsCard = ({ practiceIds, month, year }: Props) => {
  const [claimVfcEligible, setClaimVfcEligible] = useState('');
  const vfcEligible = dropdownOptionsToStatus[claimVfcEligible];
  const { data, loading, error } = useQuery(BillingDashboardClaimsByStatus, {
    variables: {
      practiceIds,
      vfcEligible,
      month: month !== undefined ? month + 1 : undefined,
      year,
    },
    skip:
      !practiceIds || !vfcEligible || month === undefined || year === undefined,
  });

  type DataProps = {
    practiceId: string;
    claimVfcEligible: boolean;
    claimsCount: number;
    status: string;
  };

  const renderData = useCallback((data: DataProps[]) => {
    const vfcEligibleData = Object.values(ClaimStatusEnum)
      .map((s) => ({
        status: s,
        claims: data
          .filter((item) => item.status === s)
          .reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.claimsCount,
            0
          ),
      }))
      .reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          ...{
            [currentValue.status]: currentValue.claims,
            total: accumulator.total + currentValue.claims,
          },
        }),
        { total: 0 } as { [key: string]: number }
      );

    const vfcEligibleDataPercentage = Object.values(ClaimStatusEnum).reduce(
      (accumulator, s) => ({
        ...accumulator,
        [s]: Math.round(
          ((vfcEligibleData[s] || 0) / vfcEligibleData.total) * 100
        ),
      }),
      {} as { [key: string]: number }
    );

    return (
      <>
        {vfcEligibleData.total > 0 && (
          <>
            <TotalAmount
              amount={usNumberNoDigits(vfcEligibleData.total)}
              style={{ marginTop: `-${pixelToRem(14)}rem` }}
            />
            <div style={{ height: `${pixelToRem(250)}rem` }}>
              <TotalClaimsChart
                paidTotal={vfcEligibleData?.paid || 0}
                inProgressTotal={vfcEligibleData?.in_progress || 0}
                deniedTotal={vfcEligibleData?.denied || 0}
              />
            </div>
            <Legends
              legends={[
                {
                  icon: {
                    name: 'dollar',
                    color: 'green',
                  },
                  title: 'Paid',
                  value: `${vfcEligibleDataPercentage?.paid || 0}%`,
                  subValue: `(${vfcEligibleData?.paid})`,
                },
                {
                  icon: {
                    name: 'hourglass outline',
                    color: 'blue',
                  },
                  title: 'In progress',
                  value: `${vfcEligibleDataPercentage?.in_progress || 0}%`,
                  subValue: `(${vfcEligibleData?.in_progress})`,
                },
                {
                  icon: {
                    name: 'ban',
                    color: 'grey',
                  },
                  title: 'Denied',
                  value: `${vfcEligibleDataPercentage?.denied || 0}%`,
                  subValue: `(${vfcEligibleData?.denied})`,
                },
              ]}
            />
          </>
        )}
        {vfcEligibleData.total === 0 && (
          <ChartErrorMessage>
            <p>There is not enough data to draw this chart</p>
          </ChartErrorMessage>
        )}
      </>
    );
  }, []);

  return (
    <Card fluid style={{ height: '100%' }}>
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader>Total Claims</CardHeader>
          <ClaimDropdown onChange={(value) => setClaimVfcEligible(value)} />
        </div>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        {error && (
          <ChartErrorMessage>
            <p>Error: {error?.message}</p>
          </ChartErrorMessage>
        )}
        {!loading &&
          !error &&
          data?.billing_dashboard_claims_by_status &&
          renderData(data?.billing_dashboard_claims_by_status)}
      </CardContent>
    </Card>
  );
};

export default TotalClaimsCard;
