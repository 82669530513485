import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Header, Loader, Message, Segment } from 'semantic-ui-react';
import { ResponsiveBar } from '@nivo/bar';

import { MisusedInventoryPerMonth } from '@bluefox/graphql/vaccinations';
import {
  MisusedInventoryCountsPerMonth,
  VaccinationCountPerMonth,
} from '@bluefox/models/Vaccination';

import { VaccinationsDatum } from './models';

import { getColor } from './configTheme';
import CelebrationIcon from './CelebrationIcon';
import { DateFormats } from '@bluefox/models/Dates';

const ChartVFCvsPrivate = () => {
  const [chartDataVFC, setChartDataVFC] = useState<VaccinationsDatum[]>([]);
  const [chartDataPrivate, setChartDataPrivate] = useState<VaccinationsDatum[]>(
    []
  );
  const today = moment().add(1, 'day').format(DateFormats.DATE);
  const since = moment().subtract(3, 'months').format(DateFormats.DATE);

  const { data, loading, error } = useQuery<MisusedInventoryCountsPerMonth>(
    MisusedInventoryPerMonth,
    {
      variables: {
        startingDate: since,
        endingDate: today,
      },
    }
  );

  useEffect(() => {
    if (!data) return;

    const vaccinationsVFC: VaccinationCountPerMonth[] = data?.misusedVfc ?? [];
    const vaccinationsPrivate: VaccinationCountPerMonth[] =
      data?.misusedPrivate ?? [];

    const _privateBarArr: VaccinationsDatum[] = [];
    vaccinationsPrivate.forEach((vax: any) => {
      if (vax.count > 0) {
        _privateBarArr.push({
          id: moment()
            .month(vax.month - 1)
            .format(DateFormats.MONTH_FULL_NAME)
            .toString(),
          notVfc: vax.count,
          vfc: 0,
        });
      }
    });

    setChartDataPrivate(_privateBarArr);

    if (!vaccinationsVFC.length) return;
    const _vfcBarArr: VaccinationsDatum[] = [];
    vaccinationsVFC.forEach((vax: any) => {
      if (vax.count > 0) {
        _vfcBarArr.push({
          id: moment()
            .month(vax.month - 1)
            .format(DateFormats.MONTH_FULL_NAME)
            .toString(),
          vfc: vax.count,
          notVfc: 0,
        });
      }
    });
    setChartDataVFC(_vfcBarArr);
  }, [data]);

  return (
    <StyledSegement>
      <StyledSplitedContent>
        <Header textAlign="center" as="h5">
          VFC Patients that used Private Inventory
        </Header>
        <StyledContent>
          {loading && <Loader />}
          {error && <Message error>{error?.message}</Message>}
          {!chartDataPrivate.length ? (
            <CelebrationIcon />
          ) : (
            <ResponsiveBar
              data={chartDataPrivate}
              keys={['notVfc']}
              colors={getColor}
              margin={{ top: 10, right: 10, bottom: 35, left: 30 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={{
                format: (e) => Math.floor(e) === e && e,
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [['darker', 2.5]],
              }}
              animate={true}
              role="application"
              ariaLabel="Inventory"
            />
          )}
        </StyledContent>
      </StyledSplitedContent>
      <StyledSplitedContent>
        <Header textAlign="center" as="h5">
          Private Patients that used VFC Inventory
        </Header>
        <StyledContent>
          {loading && <Loader />}
          {error && <Message error>{error?.message}</Message>}
          {!chartDataVFC.length ? (
            <CelebrationIcon />
          ) : (
            <ResponsiveBar
              data={chartDataVFC}
              keys={['vfc']}
              colors={getColor}
              margin={{ top: 10, right: 10, bottom: 35, left: 30 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={{
                format: (e) => Math.floor(e) === e && e,
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [['darker', 2.5]],
              }}
              animate={true}
              role="application"
              ariaLabel="Inventory"
            />
          )}
        </StyledContent>
      </StyledSplitedContent>
    </StyledSegement>
  );
};

export default ChartVFCvsPrivate;

const StyledSegement = styled(Segment)`
  display: flex;
  align-content: space-between;
  height: 25rem;
`;
const StyledSplitedContent = styled.div`
  width: 50%;
  height: 100%;
`;
const StyledContent = styled.div`
  height: 90%;
  width: 100%;
  display: flex;
`;
