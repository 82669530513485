export function getFirstObjectParamOrSecondObjectParamOrEmptyObject<T>(
  propName: keyof T,
  firstObject: T,
  secondObject: T
): Partial<T> | {} {
  const firstObjectPropVal = firstObject?.[propName];
  if (firstObjectPropVal !== undefined)
    return { [propName]: firstObjectPropVal };
  const secondObjectPropVal = secondObject?.[propName];
  if (secondObjectPropVal !== undefined)
    return { [propName]: secondObjectPropVal };
  return {};
}
