import { BillingInvoice } from '@bluefox/models/Billing';
import { Message, Placeholder, Segment, Table } from 'semantic-ui-react';
import InvoiceListTableRow from './InvoiceListTableRow';

type Props = {
  invoices?: BillingInvoice[];
  loading: boolean;
  onShowPDF?: (billingInvoice: BillingInvoice) => void;
};

const InvoiceListTable = ({ invoices, loading, onShowPDF }: Props) => {
  return (
    <Table selectable columns={4}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell collapsing>Practices</Table.HeaderCell>
          <Table.HeaderCell collapsing>Invoice Date</Table.HeaderCell>
          <Table.HeaderCell collapsing>Amount</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell colSpan={4}>
              <Segment basic>
                <Placeholder fluid>
                  <Placeholder.Header>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Segment>
            </Table.Cell>
          </Table.Row>
        ) : invoices && invoices.length > 0 ? (
          invoices.map((invoice) => {
            return (
              <InvoiceListTableRow
                key={invoice.id}
                billingInvoice={invoice}
                onShowPDF={(billingInvoice: BillingInvoice) => {
                  onShowPDF?.(billingInvoice);
                }}
              />
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan={10}>
              <Message>No invoices found.</Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default InvoiceListTable;
