import { useEffect, useState, useRef } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { usePractice } from '@bluefox/contexts/Practice';
import moment from 'moment-timezone';
import { useReactToPrint } from 'react-to-print';
import { useApplicationState } from '@bluefox/contexts';
import FullLayout from '@components/FullLayout';
import {
  Container,
  Header,
  Icon,
  Label,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  Button,
  Pagination,
  Dropdown,
  Input,
  Divider,
  Popup,
} from 'semantic-ui-react';

import { VaccinationsPerDay } from '@bluefox/graphql/vaccinations';
import { Vaccination, VaccinationRoutes } from '@bluefox/models/Vaccination';
import { DateFormats } from '@bluefox/models/Dates';
import { humanizeText } from '@bluefox/lib/humanize';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { debounce } from '@bluefox/lib/debounce';
import VaccinationListExcelExportModal from './vaccinationListExcelExportModal';
import { formatDateToMMhDDhYYYY } from '@bluefox/lib/formatters';
import { InventoryLotsByPracticeId } from '@bluefox/graphql/inventory';
import { Link } from 'react-router-dom';
import PatientEligibilityScreeningRecordPdf from '@components/celebrationPediatrics/PatientEligibilityScreeningRecordPdf';
import { ScannedVacinationConsentPDF } from '@screens/patient/Scan';
import { PracticeSettings } from '@bluefox/models/Practice';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';
import { vaccinationUploadedToEmr } from '@bluefox/lib/vaccinationUploadedToEmr';
import GS1 from '@bluefox/lib/gs1';

interface SettingsData {
  practice: {
    settings: PracticeSettings;
  };
}

interface InventoryLotOption {
  key: string;
  text: string;
  value: string;
}

interface InventoryLot {
  id: string;
  lot: string;
}

interface InventoryLotsData {
  inventoryLots: InventoryLot[];
}

export interface VaccinationsData {
  vaccinations: Vaccination[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface GroupedVaccinationsData {
  patientName: string;
  patientId: string | undefined;
  patientBoD?: Date;
  firstVaccination: Vaccination;
  insuranceData: {
    name: string;
    memberId: string;
    vfcEligible: boolean | undefined;
  };
  givenAt: Date;
  vaccinations: Vaccination[];
}

const vfcEligibilityOptions = [
  { text: 'VFC eligible', value: 'vfcEligible' },
  { text: 'Not eligible', value: 'notEligible' },
];

const inventoryUsedOptions = [
  { text: 'VFC', value: 'vfc' },
  { text: 'Private', value: 'private' },
];

type VaccinationsListScreenProps = {
  pageBlurred: boolean;
};

const ENTRIES_PER_PAGE = 15;

const VaccinationsListScreen = ({
  pageBlurred,
}: VaccinationsListScreenProps) => {
  const { isEmbedded } = useApplicationState();
  const practice = usePractice();

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const [isPdf, setIsPdf] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date | undefined>();
  const [searchVfcEligibility, setSearchVfcEligibility] = useState<boolean>();
  const [searchPatientInput, setSearchPatientInput] = useState<string>('');
  const [searchPatientQueryValue, setSearchPatientQueryValue] =
    useState<string>('');
  const [searchPrescriberInput, setSearchPrescriberInput] =
    useState<string>('');
  const [searchPrescriberQueryValue, setSearchPrescriberQueryValue] =
    useState<string>('');
  const [searchProviderInput, setSearchProviderInput] = useState<string>('');
  const [searchProviderQueryValue, setSearchProviderQueryValue] =
    useState<string>('');
  const [searchLot, setSearchLot] = useState<[string]>();
  const [searchInventoryUsed, setSearchInventoryUsed] = useState<boolean[]>();

  const [inventoryLotOptions, setInventoryLotOptions] = useState<
    InventoryLotOption[]
  >([]);

  const [page, setPage] = useState(0);

  const [criteria, setCriteria] = useState<object>({});

  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const { data: practiceSettings } = useQuery<SettingsData>(
    PracticeSettingsQuery,
    {
      variables: {
        id: practice.id,
      },
    }
  );

  const [getVaccinations, { data, loading, error }] =
    useLazyQuery<VaccinationsData>(VaccinationsPerDay);

  useQuery<InventoryLotsData>(InventoryLotsByPracticeId, {
    variables: {
      practiceId: practice.id,
    },
    onCompleted: (data) => {
      setInventoryLotOptions(
        data.inventoryLots.map((lot) => {
          return {
            key: lot.id,
            text: lot.lot,
            value: lot.lot,
          };
        })
      );
    },
  });

  const [groupedVaccinations, setGroupedVaccinations] = useState<
    GroupedVaccinationsData[]
  >([]);

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  function getScannedData(barcode: string) {
    try {
      const gs1 = new GS1(barcode);
      return {
        lot: gs1.getLot(),
        exp: gs1.getExp(),
        ndc: gs1.getNdc(),
      };
    } catch {
      return null;
    }
  }

  const handleLotValue = (value: [string]) => {
    const lotValue =
      !value?.length || value.includes('all') ? undefined : value;
    setSearchLot(lotValue);
  };

  const handleVfcEligibleValue = (value: string | undefined) => {
    const vfcEligibleValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setSearchVfcEligibility(
      vfcEligibleValue !== undefined
        ? vfcEligibleValue === 'vfcEligible'
        : undefined
    );
  };

  const handleInventoryUsedValue = (value: string) => {
    switch (value) {
      case 'all':
        setSearchInventoryUsed(undefined);
        break;

      case 'private':
        setSearchInventoryUsed([false]);
        break;

      case 'vfc':
        setSearchInventoryUsed([true]);
        break;
    }
  };

  const handleUseMoreFilters = () => {
    setToDate(undefined);
    setSearchVfcEligibility(undefined);
    setSearchInventoryUsed(undefined);
    setSearchPatientInput('');
    setSearchPatientQueryValue('');
    setSearchPrescriberInput('');
    setSearchPrescriberQueryValue('');
    setSearchProviderInput('');
    setSearchProviderQueryValue('');
    setSearchLot(undefined);

    setShowMoreFilters(!showMoreFilters);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setIsPdf(false);
    },
    documentTitle: `${practice.name} - Vaccination list`,
  });

  const concatVaccineTypes = (types: string[] | undefined) => {
    if (types === undefined || types.length === 0) {
      return '-';
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {types.map((type) => {
          return (
            <Label
              key={type}
              size="small"
              content={type}
              style={{
                margin: '0.1rem',
              }}
            />
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (data) {
      if (!isPdf) return;
      handlePrint();
    }
  }, [data, handlePrint, isPdf]);

  useEffect(() => {
    const from = new Date(date.getTime());
    from.setUTCHours(0, 0, 0, 0);
    const to = new Date(date.getTime());
    to.setUTCHours(23, 59, 59, 999);

    const _toDate = toDate ? new Date(toDate.getTime()) : new Date();
    _toDate.setUTCHours(23, 59, 59, 999);

    let _criteria = {
      historic: { _eq: false },
      practiceId: { _eq: practice.id },
      _and: [
        {
          givenAt: {
            _gte: from,
            _lte: showMoreFilters && toDate ? _toDate : to,
          },
        },

        {
          ...(showMoreFilters && searchVfcEligibility !== undefined
            ? {
                practicePatient: {
                  insurances: {
                    primary: { _eq: true },
                    vfcEligible: { _eq: searchVfcEligibility },
                  },
                },
              }
            : {}),
        },
        {
          ...(showMoreFilters && searchInventoryUsed
            ? { inventory: { vfc: { _in: searchInventoryUsed } } }
            : {}),
        },
        {
          ...(showMoreFilters && searchLot
            ? {
                inventory: {
                  lot: { _in: searchLot },
                },
              }
            : {}),
        },
        {
          ...(searchPatientQueryValue
            ? {
                practicePatient: {
                  patientData: {
                    fullName: {
                      _ilike: whereLikeInput(searchPatientQueryValue),
                    },
                  },
                },
              }
            : {}),
        },
        {
          ...(showMoreFilters && searchPrescriberQueryValue
            ? {
                prescriber: {
                  account: {
                    _or: [
                      {
                        firstName: {
                          _ilike: whereLikeInput(searchPrescriberQueryValue),
                        },
                      },
                      {
                        lastName: {
                          _ilike: whereLikeInput(searchPrescriberQueryValue),
                        },
                      },
                    ],
                  },
                },
              }
            : {}),
        },
        {
          ...(showMoreFilters && searchProviderQueryValue
            ? {
                provider: {
                  account: {
                    _or: [
                      {
                        firstName: {
                          _ilike: whereLikeInput(searchProviderQueryValue),
                        },
                      },
                      {
                        lastName: {
                          _ilike: whereLikeInput(searchProviderQueryValue),
                        },
                      },
                    ],
                  },
                },
              }
            : {}),
        },
      ],
    };

    setCriteria(_criteria);
    setPage(0);
  }, [
    practice.id,
    date,
    toDate,
    searchVfcEligibility,
    searchInventoryUsed,
    showMoreFilters,
    searchLot,
    searchPatientQueryValue,
    searchPrescriberQueryValue,
    searchProviderQueryValue,
  ]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  useEffect(() => {
    getVaccinations({
      variables: {
        limit: !isPdf ? ENTRIES_PER_PAGE : null,
        offset: !isPdf && !!page ? ENTRIES_PER_PAGE * (page - 1) : null,
        criteria,
      },
    });
  }, [criteria, getVaccinations, isPdf, page, practice.id]);

  useEffect(() => {
    if (!data || data.vaccinations.length === 0) {
      setGroupedVaccinations([]);
      return;
    }

    const groupedVaccinations = data.vaccinations.reduce(
      (
        groupedVaccinations: GroupedVaccinationsData[],
        currentVaccination: Vaccination
      ) => {
        const currentName =
          currentVaccination.practicePatient?.patientData?.fullName;
        const patientId = currentVaccination.practicePatient?.id;
        const currentBoD =
          currentVaccination.practicePatient?.patientData?.birthdate;
        const insuranceData = {
          name:
            currentVaccination.practicePatient?.insurances[0]?.company?.name ||
            '-',
          memberId:
            currentVaccination.practicePatient?.insurances[0]?.memberId || '-',
          vfcEligible:
            currentVaccination.practicePatient?.insurances[0]?.vfcEligible,
        };

        if (groupedVaccinations.length === 0) {
          const data: GroupedVaccinationsData = {
            patientName: currentName ? currentName : 'Name Not Found',
            patientId,
            patientBoD: currentBoD,
            insuranceData,
            firstVaccination: currentVaccination,
            givenAt: currentVaccination.givenAt,
            vaccinations: [],
          };

          groupedVaccinations.push(data);
          return groupedVaccinations;
        }

        const group = groupedVaccinations.find((group) => {
          const currentGivenAt = group.givenAt.toString().split('T')[0];
          const currentVaccinationGivenAt = currentVaccination.givenAt
            .toString()
            .split('T')[0];

          return (
            group.patientName === currentName &&
            currentGivenAt === currentVaccinationGivenAt
          );
        });

        if (!group) {
          const data: GroupedVaccinationsData = {
            patientName: currentName ? currentName : 'Name Not Found',
            patientId,
            patientBoD: currentBoD,
            insuranceData,
            firstVaccination: currentVaccination,
            givenAt: currentVaccination.givenAt,
            vaccinations: [],
          };
          groupedVaccinations.push(data);
          return groupedVaccinations;
        }

        group.vaccinations.push(currentVaccination);
        return groupedVaccinations;
      },
      []
    );
    setGroupedVaccinations(groupedVaccinations);
  }, [data]);

  const componentRef = useRef<HTMLDivElement>(null);

  const buildRowCellLabel = (vaccination: Vaccination) => {
    if (vaccination.inventory) {
      return (
        <Label
          content={vaccination.inventory?.vfc ? 'VFC' : 'Private'}
          color={vaccination.inventory?.vfc ? 'orange' : 'teal'}
        />
      );
    }

    if (!vaccination.vaccineId || !vaccination.inventoryId) {
      return (
        <Popup
          size="small"
          trigger={<Icon size="large" name="clock outline" />}
          content="Inconsistent vaccination. Currently under review."
        />
      );
    }

    return '-';
  };

  const buildRowCells = (vaccination: Vaccination) => {
    const routeIsNotINorPO =
      vaccination.route !== VaccinationRoutes.IN &&
      vaccination.route !== VaccinationRoutes.PO;

    const scannedBarcodeData = vaccination.barcode
      ? getScannedData(vaccination.barcode)
      : null;

    return (
      <>
        <Table.Cell>
          {formatDateToMMhDDhYYYY(new Date(vaccination.givenAt))}
        </Table.Cell>
        <Table.Cell>
          {vaccination.vaccine?.name}
          {concatVaccineTypes(vaccination.vaccine?.types)}
        </Table.Cell>
        <Table.Cell>
          <Label
            basic
            content={`${vaccination.route}${
              vaccination.site && routeIsNotINorPO
                ? ` - ${humanizeText(vaccination.site, {
                    capitalize: 'first',
                    delimiter: '_',
                  })}`
                : ''
            }`}
          />
        </Table.Cell>
        <Table.Cell>
          {vaccination.prescriber
            ? `${vaccination.prescriber?.account.firstName} ${vaccination.prescriber?.account.lastName}`
            : '-'}
        </Table.Cell>
        <Table.Cell>
          {vaccination.provider
            ? `${vaccination.provider?.account.firstName} ${vaccination.provider?.account.lastName}`
            : '-'}
        </Table.Cell>
        <Table.Cell>
          {vaccination.vaccine?.useNdc || scannedBarcodeData?.ndc || '-'}
        </Table.Cell>
        <Table.Cell>
          {vaccination.inventory?.lot || scannedBarcodeData?.lot || '-'}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {buildRowCellLabel(vaccination)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {vaccinationUploadedToEmr(vaccination) ? (
            <Icon name="check" color="olive" />
          ) : (
            <Icon name="x" color="red" />
          )}
        </Table.Cell>
      </>
    );
  };

  const renderTableBody = () => {
    return (
      <Table.Body>
        {groupedVaccinations.map((group: GroupedVaccinationsData) => {
          const {
            patientName,
            patientId,
            firstVaccination,
            vaccinations,
            patientBoD,
            insuranceData,
          } = group;

          return (
            <>
              <Table.Row key={firstVaccination.id}>
                <Table.Cell
                  style={{ borderRight: '1px solid #DEDEDF' }}
                  rowSpan={vaccinations.length + 1}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Link
                      to={`/${practice.handler}/patients/${patientId}`}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'black',
                      }}
                    >
                      {patientName}
                    </Link>
                    {practiceSettings?.practice.settings.forms?.florida
                      ?.consentForm?.enabled && (
                      <PatientEligibilityScreeningRecordPdf
                        isIcon
                        pageBlurred={pageBlurred}
                        practicePatient={firstVaccination.practicePatient}
                        providerFullName={`${firstVaccination.provider?.account.firstName} ${firstVaccination.provider?.account.lastName}`}
                        vaccinations={[firstVaccination, ...vaccinations].map(
                          ({
                            inventory,
                            vaccine,
                            site,
                            route,
                            givenAt,
                            provider,
                          }): ScannedVacinationConsentPDF => {
                            return {
                              vaccine,
                              ...(inventory ? { inventory: inventory } : {}),
                              givenAt: givenAt,
                              providerId: provider?.id,
                              site: route === 'PO' ? null : site,
                              route: route,
                            };
                          }
                        )}
                      />
                    )}
                  </div>
                  ({moment(patientBoD).format('MM-DD-YYYY')})
                  {insuranceData.vfcEligible !== undefined ? (
                    <div style={{ marginTop: '0.5rem' }}>
                      <p style={{ margin: '0' }}>{insuranceData.name || '-'}</p>

                      {insuranceData && (
                        <div>
                          <Label
                            basic
                            size="tiny"
                            content={`Member ID: ${
                              insuranceData.memberId || '-'
                            }`}
                          />
                          <Label
                            size="tiny"
                            content={
                              insuranceData.vfcEligible ? 'VFC' : 'Private'
                            }
                            color={
                              insuranceData.vfcEligible ? 'orange' : 'teal'
                            }
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                </Table.Cell>
                {buildRowCells(firstVaccination)}
              </Table.Row>

              {group.vaccinations.map((vaccination) => (
                <Table.Row key={vaccination.id}>
                  {buildRowCells(vaccination)}
                </Table.Row>
              ))}
            </>
          );
        })}
      </Table.Body>
    );
  };

  const renderTable = () => {
    if (error) {
      return <Message error>{error.message}</Message>;
    }

    return (
      <div
        ref={componentRef}
        style={
          isPdf
            ? {
                margin: '2rem 3rem 2rem 3rem',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }
            : {}
        }
      >
        {isPdf && (
          <Header as="h1" textAlign="center">
            {practice.name}
            <Header.Subheader>{`${date.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })} - Vaccination list`}</Header.Subheader>
          </Header>
        )}
        <Table selectable style={{ padding: isPdf ? '0 5.5rem' : '0' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ borderRight: '1px solid #DEDEDF' }}>
                Patient
              </Table.HeaderCell>
              <Table.HeaderCell>Service Date</Table.HeaderCell>
              <Table.HeaderCell>Vaccine</Table.HeaderCell>
              <Table.HeaderCell>Route/Site</Table.HeaderCell>
              <Table.HeaderCell>Prescriber</Table.HeaderCell>
              <Table.HeaderCell>Provider</Table.HeaderCell>
              <Table.HeaderCell>NDC</Table.HeaderCell>
              <Table.HeaderCell>Lot Number</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Inventory</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Uploaded to EMR
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {loading && data === undefined ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={10}>
                  <Segment basic>
                    <Placeholder fluid>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Segment>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            renderTableBody()
          )}
          {!isPdf && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                <Table.HeaderCell colSpan={10} textAlign="right">
                  <Pagination
                    activePage={page || 1}
                    disabled={!total || total < ENTRIES_PER_PAGE}
                    boundaryRange={0}
                    siblingRange={1}
                    onPageChange={(e, { activePage }) =>
                      setPage(activePage as number)
                    }
                    totalPages={totalPages}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </div>
    );
  };

  return (
    <FullLayout>
      <Container fluid>
        <Segment padded basic>
          <Header as="h1">
            <Icon name="calendar" />
            Vaccinations By Date
          </Header>
        </Segment>

        <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <Menu.Item>
              {showMoreFilters && (
                <label style={{ marginRight: '0.4rem' }}>
                  <b>From:</b>
                </label>
              )}
              <DateTimePicker
                selected={date}
                onChange={(value) => {
                  setPage(0);
                  return value instanceof Date ? setDate(value) : null;
                }}
                tz={practice.timezone}
                dateFormat={DateFormats.DATE}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
              />
            </Menu.Item>
            {showMoreFilters && (
              <Menu.Item>
                <label style={{ marginRight: '0.4rem' }}>
                  <b>To:</b>
                </label>
                <DateTimePicker
                  selected={toDate}
                  onChange={(value) => {
                    setPage(0);
                    return value instanceof Date ? setToDate(value) : null;
                  }}
                  tz={practice.timezone}
                  dateFormat={DateFormats.DATE}
                  scrollableYearDropdown
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />
              </Menu.Item>
            )}
            <Menu.Item>
              <label style={{ marginRight: '0.4rem' }}>
                <b>Patient:</b>
              </label>
              <Input
                style={{ maxWidth: '16rem' }}
                icon="search"
                placeholder="Search patient..."
                value={searchPatientInput}
                onChange={(_, { value }) => {
                  setSearchPatientInput(value);
                  debouncedRef.current?.cancel();
                  debouncedRef.current = debounce(() => {
                    setSearchPatientQueryValue(value);
                  }, 500);
                  debouncedRef.current();
                }}
              />
            </Menu.Item>
            <Menu.Item>
              <Button
                basic
                content={showMoreFilters ? 'Hide filters' : 'More filters...'}
                onClick={handleUseMoreFilters}
              />
            </Menu.Item>
          </div>
          {showMoreFilters && (
            <>
              <Divider />
              <Menu.Menu
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <Menu.Item>
                  <label style={{ marginRight: '0.4rem' }}>
                    <b>VFC Eligibility:</b>
                  </label>
                  <Dropdown
                    style={{ maxWidth: '15rem', minWidth: '15rem' }}
                    placeholder="Filter by VFC eligibility"
                    fluid
                    selection
                    onChange={(_, { value }) => {
                      handleVfcEligibleValue(value?.toString() || '');
                    }}
                    options={[
                      { text: 'All', value: 'all' },
                      ...vfcEligibilityOptions,
                    ]}
                  />
                </Menu.Item>
                <Menu.Item>
                  <label style={{ marginRight: '0.4rem' }}>
                    <b>Inventory Used:</b>
                  </label>
                  <Dropdown
                    clearable
                    style={{ maxWidth: '15rem', minWidth: '15rem' }}
                    placeholder="Filter by inventory used"
                    fluid
                    selection
                    onChange={(_, { value }) => {
                      handleInventoryUsedValue(value?.toString() || '');
                    }}
                    options={[
                      { text: 'All', value: 'all' },
                      ...inventoryUsedOptions,
                    ]}
                  />
                </Menu.Item>
              </Menu.Menu>
              <Menu.Menu
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <Menu.Item>
                  <label style={{ marginRight: '0.4rem' }}>
                    <b>Prescriber:</b>
                  </label>
                  <Input
                    style={{ maxWidth: '16rem' }}
                    icon="search"
                    placeholder="Search prescriber..."
                    value={searchPrescriberInput}
                    onChange={(_, { value }) => {
                      setSearchPrescriberInput(value);
                      debouncedRef.current?.cancel();
                      debouncedRef.current = debounce(() => {
                        setSearchPrescriberQueryValue(value);
                      }, 500);
                      debouncedRef.current();
                    }}
                  />
                </Menu.Item>
                <Menu.Item>
                  <label style={{ marginRight: '0.4rem' }}>
                    <b>Provider:</b>
                  </label>
                  <Input
                    style={{ maxWidth: '16rem' }}
                    icon="search"
                    placeholder="Search provider..."
                    value={searchProviderInput}
                    onChange={(_, { value }) => {
                      setSearchProviderInput(value);
                      debouncedRef.current?.cancel();
                      debouncedRef.current = debounce(() => {
                        setSearchProviderQueryValue(value);
                      }, 500);
                      debouncedRef.current();
                    }}
                  />
                </Menu.Item>
                <Menu.Item>
                  <label style={{ marginRight: '0.4rem' }}>
                    <b>Lot:</b>
                  </label>
                  <Dropdown
                    style={{ minWidth: '15rem' }}
                    placeholder="Search lot number..."
                    search
                    fluid
                    selection
                    multiple
                    onChange={(_, { value }) => {
                      handleLotValue(value as [string]);
                    }}
                    options={[
                      {
                        text: 'All',
                        value: 'all',
                      },
                      ...inventoryLotOptions,
                    ]}
                  />
                </Menu.Item>
              </Menu.Menu>
            </>
          )}
        </Menu>
        {renderTable()}
        {!isEmbedded && (
          <Button
            style={{ marginTop: '10px' }}
            onClick={() => setIsPdf(true)}
            floated="right"
            icon="print"
            content="Print / Save as PDF"
            disabled={showMoreFilters}
          />
        )}
        {!isEmbedded && <VaccinationListExcelExportModal criteria={criteria} />}
      </Container>
    </FullLayout>
  );
};

export default VaccinationsListScreen;
