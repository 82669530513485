import { useCallback } from 'react';
import { Card, Dimmer, Loader } from 'semantic-ui-react';
import { usDollarNoDigitsCurrency } from '@bluefox/lib/formatters';
import Legends from '@bluefox/ui/Dashboard/Legends';
import AdministrativeFeesChart from '@components/charts/AdministrativeFeesChart';
import TotalAmount from '@bluefox/ui/Dashboard/TotalAmount';
import { BillingDashboardTotalRecoveredAmountAdministration } from '@graphql/billing';
import { useQuery } from '@apollo/client';
import ChartErrorMessage from '@bluefox/ui/Dashboard/ChartErrorMessage';
import { CardContent, CardHeader } from '@bluefox/ui/Dashboard/CommonStyles';
import { pixelToRem } from '@bluefox/constants';

type Props = {
  practiceIds: string[];
  month: number | undefined;
  year: number | undefined;
};

const AdministrativeFeesCard = ({ practiceIds, month, year }: Props) => {
  const { data, loading, error } = useQuery(
    BillingDashboardTotalRecoveredAmountAdministration,
    {
      variables: {
        practiceIds: practiceIds,
        month: month !== undefined ? month + 1 : undefined,
        year: year,
      },
      skip: !practiceIds || month === undefined || year === undefined,
    }
  );

  type DataProps = {
    administrationPrivateAmount: number;
    administrationVfcAmount: number;
    totalAmount: number;
  };

  const renderData = useCallback((data: DataProps[]) => {
    const {
      administrationPrivateAmount,
      administrationVfcAmount,
      totalAmount,
    } = data.reduce(
      (accumulator, currentValue) => {
        return {
          administrationPrivateAmount:
            accumulator.administrationPrivateAmount +
            currentValue.administrationPrivateAmount,
          administrationVfcAmount:
            accumulator.administrationVfcAmount +
            currentValue.administrationVfcAmount,
          totalAmount: accumulator.totalAmount + currentValue.totalAmount,
        };
      },
      {
        administrationPrivateAmount: 0,
        administrationVfcAmount: 0,
        totalAmount: 0,
      }
    );
    return (
      <>
        <TotalAmount amount={usDollarNoDigitsCurrency(totalAmount)} />
        <div style={{ height: `${pixelToRem(250)}rem` }}>
          <AdministrativeFeesChart
            administrationPrivateAmount={administrationPrivateAmount}
            administrationVfcAmount={administrationVfcAmount}
          />
        </div>
        <Legends
          legends={[
            {
              icon: {
                name: 'syringe',
                color: 'blue',
              },
              title: 'Private',
              value: usDollarNoDigitsCurrency(administrationPrivateAmount),
            },
            {
              icon: {
                name: 'syringe',
                color: 'teal',
              },
              title: 'VFC',
              value: usDollarNoDigitsCurrency(administrationVfcAmount),
            },
          ]}
        />
      </>
    );
  }, []);

  return (
    <Card fluid style={{ height: '100%' }}>
      <CardContent>
        <CardHeader>Administrative Fees</CardHeader>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        {error && (
          <ChartErrorMessage>
            <p>Error: {error?.message}</p>
          </ChartErrorMessage>
        )}
        {!loading &&
          !error &&
          data?.billing_dashboard_total_recovered_amount_administration &&
          renderData(
            data?.billing_dashboard_total_recovered_amount_administration
          )}
      </CardContent>
    </Card>
  );
};

export default AdministrativeFeesCard;
