import { formatDateToMMDDYYYY } from '@bluefox/lib/formatters';
import { BillingClaim } from '@bluefox/models/Billing';
import { useMemo } from 'react';
import { Label, Table } from 'semantic-ui-react';

interface InvoicesListRowProps {
  billingClaim: BillingClaim;
}

const InvoicesListRow = ({ billingClaim }: InvoicesListRowProps) => {
  const totalPaidAmount = useMemo(
    () =>
      billingClaim.cptCodes.reduce((accum, cpt) => accum + cpt.paidAmount, 0),
    [billingClaim.cptCodes]
  );

  const insurance = useMemo<Record<string, string> | undefined>(() => {
    const filteredUpdates = billingClaim.updates.filter((u: any) => {
      return u.metadata.insurance.insuranceName !== '';
    });
    return filteredUpdates[0]?.metadata?.insurance;
  }, [billingClaim.updates]);

  const patientData = useMemo(
    () => billingClaim.practicePatient?.patientData,
    [billingClaim.practicePatient?.patientData]
  );

  return (
    <>
      {totalPaidAmount !== 0 && (
        <Table.Row key={billingClaim.id}>
          <Table.Cell style={{ fontWeight: '700' }}>
            {billingClaim.vaccinations[0].practice?.name}
          </Table.Cell>
          <Table.Cell>
            {`${patientData?.firstName} ${patientData?.lastName} (${patientData.birthdate})`}
          </Table.Cell>
          <Table.Cell>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {insurance?.insuranceName || '-'}
              <div>
                <Label
                  basic
                  content={`Member ID: ${insurance?.memberId || '-'}`}
                  size="tiny"
                />
                {insurance?.vfcEligible ? (
                  <Label
                    content="VFC Eligible"
                    size="tiny"
                    color="orange"
                    style={{ marginTop: '0.2rem' }}
                  />
                ) : null}
              </div>
            </div>
          </Table.Cell>
          <Table.Cell>
            {billingClaim.vaccinations && billingClaim.vaccinations.length > 0
              ? formatDateToMMDDYYYY(
                  billingClaim.vaccinations[0].givenAt as unknown as string
                )
              : '-'}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {!!billingClaim.cptCodes.length
              ? billingClaim.cptCodes.map((cpt) => {
                  return (
                    <Label
                      key={cpt.id}
                      style={{ marginBottom: '0.2rem' }}
                      content={cpt.cptCode}
                    />
                  );
                })
              : billingClaim.cptCodes.map((cpt) => {
                  return (
                    <Label
                      key={cpt.id}
                      style={{ marginBottom: '0.2rem' }}
                      content={cpt.cptCode}
                    />
                  );
                })}
          </Table.Cell>
          <Table.Cell textAlign="center" verticalAlign="middle">
            {!!billingClaim.cptCodes.length
              ? billingClaim.cptCodes.map((cpt) => {
                  return (
                    <p key={cpt.id}>
                      {cpt.paidAmount ? `$${cpt.paidAmount.toFixed(2)}` : '-'}
                    </p>
                  );
                })
              : '-'}
          </Table.Cell>
          <Table.Cell>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {billingClaim.checkNumber && (
                <p>Check Number: {billingClaim.checkNumber}</p>
              )}
              {billingClaim.note?.url && (
                <div>
                  EOB Link:{' '}
                  <a
                    href={billingClaim.note.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {billingClaim.note.url}
                  </a>
                </div>
              )}
            </div>
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

export default InvoicesListRow;
