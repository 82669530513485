import { useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PracticePatientByIdQuery } from '@bluefox/graphql/patients';
import { PracticePatient } from '@bluefox/models/Patient';
import { VaccinationsByPatientQuery } from '@bluefox/graphql/vaccinations';
import { Vaccination } from '@bluefox/models/Vaccination';
import styled from 'styled-components';
import moment from 'moment-timezone';
import {
  Container,
  Header,
  Modal,
  Button,
  ModalProps,
  Card,
  Table,
} from 'semantic-ui-react';
import ImmunizationForm from '@components/ImmunizationForm';
import { usePractice } from '@bluefox/contexts';
import { DateFormats } from '@bluefox/models/Dates';
import { parseToMMDDYYYY } from '@utils/dates';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import PrintDialogTrigger from '@components/PrintDialogTrigger';

interface RouterParamsType {
  practicePatientId?: string;
}

interface PracticePatientData {
  practicePatient: PracticePatient;
}

interface VaccinationsData {
  vaccinations: Vaccination[];
}

interface ProofOfImmunizationProps extends ModalProps {
  onImmunizationUpdate?: () => void;
}

const ProofOfImmunization = (props: ProofOfImmunizationProps) => {
  const { practicePatientId } = useParams<RouterParamsType>();
  const history = useHistory();

  const { data: practicePatientData } = useQuery<PracticePatientData>(
    PracticePatientByIdQuery(false),
    {
      variables: {
        id: practicePatientId,
      },
      skip: !practicePatientId,
    }
  );

  const patientData = practicePatientData?.practicePatient.patientData;
  const practice = usePractice();
  const immunizationRef = useRef(null);

  const { data, refetch } = useQuery<VaccinationsData>(
    VaccinationsByPatientQuery,
    {
      variables: {
        practicePatientId,
      },
    }
  );

  const handleOnSave = () => {
    refetch();
  };

  const vaxTypes = data?.vaccinations.map((v) => v.vaccine?.types) ?? [];
  const flatArr = vaxTypes.flat();
  const vaccinationTypes = [...Array.from(new Set(flatArr))];

  return (
    <Modal
      {...props}
      open
      closeIcon
      size="large"
      onClose={() => {
        const patientUrl = history.location.pathname.replace(
          '/immunizations',
          ''
        );
        // There is a bug in react-router-dom that causes history.push not working here when is the first page opened
        if (history.length > 1) {
          history.push(patientUrl);
        } else {
          document.location = patientUrl;
        }
      }}
    >
      <Modal.Content>
        <div ref={immunizationRef}>
          <Container>
            <Header size="large">
              Immunizations Record
              <Header.Subheader>{new Date().toDateString()}</Header.Subheader>
            </Header>

            <Container style={{ marginTop: '1rem', paddingLeft: '1rem' }}>
              <Table compact basic="very" celled collapsing>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h3">
                        {patientData?.firstName}
                        <Header.Subheader>First Name</Header.Subheader>
                      </Header>
                    </Table.Cell>
                    {patientData?.middleName && (
                      <Table.Cell>
                        <Header as="h3">
                          {patientData?.middleName}
                          <Header.Subheader>Middle Name</Header.Subheader>
                        </Header>
                      </Table.Cell>
                    )}
                    <Table.Cell>
                      <Header as="h3">
                        {patientData?.lastName}
                        <Header.Subheader>Last Name</Header.Subheader>
                      </Header>
                    </Table.Cell>

                    <Table.Cell>
                      <Header as="h3">
                        {moment(patientData?.birthdate).format(
                          DateFormats.DATE
                        )}
                        <Header.Subheader>Birth Date</Header.Subheader>
                      </Header>
                    </Table.Cell>

                    <Table.Cell>
                      <Header as="h3">
                        {practice.name}
                        <Header.Subheader>Reported By</Header.Subheader>
                      </Header>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Card.Group centered style={{ marginTop: '1rem' }}>
                {vaccinationTypes.map((type, index) => (
                  <VaccineItems
                    key={index}
                    data={data?.vaccinations || []}
                    type={type}
                  />
                ))}
              </Card.Group>
            </Container>
          </Container>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <StyledButtonGroup>
          <ImmunizationForm
            trigger={<Button basic content="Add Immunization History" />}
            onSave={handleOnSave}
          />
          <PrintDialogTrigger
            contentNode={immunizationRef.current}
            trigger={
              <Button primary content="Print / Save as PDF" icon="print" />
            }
            // removeAfterPrint={false}
            // suppressErrors={false}
            documentTitle="CANID - Immunization Record"
          />
        </StyledButtonGroup>
      </Modal.Actions>
    </Modal>
  );
};

interface VaccineItemsProps {
  data: Vaccination[];
  type: any;
}

function VaccineItems({ data, type }: VaccineItemsProps) {
  const { timezone } = usePractice();

  const showImmunizationDate = (vaccination: Vaccination): string => {
    if (!vaccination.historic) {
      return moment.tz(vaccination.givenAt, timezone).format(DateFormats.DATE);
    }
    return parseToMMDDYYYY(String(vaccination.givenAt));
  };

  return (
    <>
      <StyledCard>
        <StyledCardHeader>{type}</StyledCardHeader>
        {data.reduce<ReactElement[]>(
          (acc, v: Vaccination) =>
            v.vaccine?.types?.includes(type)
              ? [
                  ...acc,
                  <div key={v.id}>
                    <StyledDivider />
                    <StyledListItem>
                      <b>{v.vaccine.name}</b>
                      {showImmunizationDate(v)}
                    </StyledListItem>
                  </div>,
                ]
              : acc,
          []
        )}
      </StyledCard>
    </>
  );
}

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCard = styled.div`
  padding: 0.5rem 0.3rem;
  margin: 0.5rem;
  min-width: 16rem !important;
  border: 1px solid lightgrey;
  border-radius: 6px;
  box-shadow: 1px 1px 5px lightgrey;
`;

const StyledCardHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const StyledListItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 2px 0;
  background-color: grey;
`;

export default ProofOfImmunization;
