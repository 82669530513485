import { useEffect, useState } from 'react';
import { usePractice } from '@bluefox/contexts/Practice';
import { useQuery, useMutation } from '@apollo/client';
import { Divider, Form, Icon, Grid, Segment } from 'semantic-ui-react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import { default as ReactDatePicker } from 'react-datepicker';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles.css';
import { toast } from 'react-semantic-toasts';
import {
  PracticeSchedulerSettingsMutation,
  PracticeSchedulerSettingsQuery,
} from '@bluefox/graphql/settings';
import { DateFormats } from '@bluefox/models/Dates';

const localizer = momentLocalizer(moment);

interface OpeningHoursEvent {
  from: number;
  to: number;
  duration?: number;
  simultaneous?: number;
}

interface BigCalendarEvent {
  start: Date;
  end: Date;
  title?: string;
  resource?: {
    duration?: number;
    simultaneous?: number;
  };
  index: number;
}

interface handleOnChangeTimeType {
  originalDate: Date;
  newDate: Date;
}

interface SchedulerSettings {
  simultaneousAppointments?: number;
  appointmentDuration?: number;
  openingHours?: OpeningHoursEvent[];
  exceptions?: string[];
}

interface SchedulerSettingsData {
  schedulerSettings: SchedulerSettings;
}

const getSunday = () => {
  const today = new Date();
  const day = today.getDay(),
    diff = today.getDate() - day + (day === 0 ? -6 : 0);
  today.setDate(diff);
  today.setHours(0, 0, 0);
  return today;
};

const getRanges = (openingHours: OpeningHoursEvent[]) => {
  const sunday = getSunday();
  const openingHoursFixed = Object.entries(openingHours).reduce(
    (prev: any[], curr: any) => {
      const [weekday, hours] = curr;
      return [
        ...prev,
        ...hours.map((range: OpeningHoursEvent, rangeIndex: number) => {
          return {
            start: moment(moment(sunday).add(weekday, 'days'))
              .add(range.from, 'minutes')
              .toDate(),
            end: moment(moment(sunday).add(weekday, 'days'))
              .add(range.to, 'minutes')
              .toDate(),
            resource: {
              duration: range?.duration,
              simultaneous: range?.simultaneous,
            },
            index: rangeIndex,
          };
        }),
      ];
    },
    []
  );
  return openingHoursFixed;
};

const Availability = () => {
  const [practiceScheduleSettings, setPracticeScheduleSettings] =
    useState<SchedulerSettings>();
  const [practiceEvents, setPracticeEvents] = useState<BigCalendarEvent[]>([]);
  const [rangeSelected, setRangeSelected] = useState<BigCalendarEvent>();
  const practice = usePractice();

  const { data: practiceSchedulerData } = useQuery<SchedulerSettingsData>(
    PracticeSchedulerSettingsQuery,
    {
      variables: {
        practiceId: practice.id,
      },
    }
  );

  const [saveScheduleSettings] = useMutation(PracticeSchedulerSettingsMutation);

  const getDaysAndEvents = () => {
    const daysAndEvents = [...Array(7)].map((_, i) => {
      const arrayOfRanges = practiceEvents
        .filter((event: BigCalendarEvent) => {
          return (event.start as Date).getDay() === i;
        })
        .map((event: BigCalendarEvent) => {
          let fixedEvent: OpeningHoursEvent = {
            from: event.start.getHours() * 60 + event.start.getMinutes(),
            to: event.end.getHours() * 60 + event.end.getMinutes(),
          };
          if (event?.resource?.duration) {
            fixedEvent = { ...fixedEvent, duration: event.resource.duration };
          }
          if (event?.resource?.simultaneous) {
            fixedEvent = {
              ...fixedEvent,
              simultaneous: event.resource.simultaneous,
            };
          }
          return fixedEvent;
        });

      return arrayOfRanges.length
        ? {
            [i + '']: arrayOfRanges,
          }
        : {};
    });

    return daysAndEvents.reduce((prev, next) => {
      return { ...prev, ...next };
    }, {});
  };

  const handleSubmit = () => {
    if (!practiceScheduleSettings) return;
    saveScheduleSettings({
      variables: {
        practiceId: practice.id,
        appointmentDuration: practiceScheduleSettings.appointmentDuration,
        simultaneousAppointments:
          practiceScheduleSettings.simultaneousAppointments,
        openingHours: getDaysAndEvents(),
      },
    }).then(() => {
      toast({
        title: 'Success',
        type: 'success',
        description: 'Availability saved!',
        time: 5000,
      });
    });
  };

  const handleTitle = (e: BigCalendarEvent) => {
    return `Duration: ${
      e?.resource?.duration || practiceScheduleSettings?.appointmentDuration
    } minutes
    Simultaneous appointments: ${
      e?.resource?.simultaneous ||
      practiceScheduleSettings?.simultaneousAppointments
    }`;
  };

  const getEventIndex = (rangeSelected: BigCalendarEvent) => {
    return practiceEvents.findIndex((event) => {
      return rangeSelected === event;
    });
  };

  const handleSaveAvailability = () => {
    const newPracticeEvents: BigCalendarEvent[] = practiceEvents.map(
      (practice: BigCalendarEvent, index: number) => {
        if (rangeSelected?.index === index) {
          const { start, end, resource }: BigCalendarEvent = rangeSelected;
          return { start, end, resource, index };
        }
        return practice;
      }
    );
    setPracticeEvents(newPracticeEvents);
    setRangeSelected(undefined);
  };

  const handleDeleteAvailability = () => {
    let newArray = practiceEvents;
    newArray.splice(rangeSelected?.index as number, 1);
    setPracticeEvents(newArray);
    setRangeSelected(undefined);
  };

  const handleOnChangeTime = ({
    originalDate,
    newDate,
  }: handleOnChangeTimeType) => {
    const original = new Date(originalDate);
    const newTime = new Date(newDate);
    const [hour, minutes, seconds] = [
      newTime.getHours(),
      newTime.getMinutes(),
      newTime.getSeconds(),
    ];
    original.setHours(hour);
    original.setMinutes(minutes);
    original.setSeconds(seconds);
    return original;
  };

  useEffect(() => {
    if (!practiceSchedulerData || !practiceSchedulerData.schedulerSettings)
      return;
    const ranges = getRanges(
      practiceSchedulerData.schedulerSettings.openingHours ?? []
    );
    setPracticeScheduleSettings(practiceSchedulerData.schedulerSettings);
    setPracticeEvents(ranges);
  }, [practiceSchedulerData]);

  moment.tz.setDefault(moment.tz.guess());
  return (
    <>
      <Form size="large">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Form.Field>
                <Grid>
                  <Grid.Row>
                    <Grid.Column verticalAlign="middle" width={11}>
                      Simultaneous appointments:
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Form.Input
                        data-automation-id={`patient-appointments-number-simultaneous`}
                        size="small"
                        min="1"
                        required
                        type="number"
                        onChange={(_, { value }) =>
                          setPracticeScheduleSettings({
                            ...practiceScheduleSettings,
                            simultaneousAppointments: parseInt(value),
                          })
                        }
                        value={
                          practiceScheduleSettings?.simultaneousAppointments
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.Field>
                <Grid>
                  <Grid.Row>
                    <Grid.Column verticalAlign="middle" width={11}>
                      Appointments duration (min):
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Form.Input
                        data-automation-id={`patient-appointments-duration`}
                        size="small"
                        required
                        min="1"
                        type="number"
                        value={practiceScheduleSettings?.appointmentDuration}
                        onChange={(_, { value }) =>
                          setPracticeScheduleSettings({
                            ...practiceScheduleSettings,
                            appointmentDuration: parseInt(value),
                          })
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <Divider></Divider>
      <Calendar
        data-automation-id={`patient-appointments-range-selector`}
        localizer={localizer}
        events={practiceEvents}
        selectable={true}
        toolbar={false}
        showMultiDayTimes={false}
        view="week"
        views={['week']}
        style={{ height: rangeSelected ? 650 : 750 }}
        step={30}
        allDayAccessor={() => {
          return false;
        }}
        onSelectEvent={(e) => {
          setRangeSelected({ ...e, index: getEventIndex(e) });
        }}
        onSelectSlot={(e) => {
          if (
            !practiceScheduleSettings ||
            !practiceScheduleSettings?.simultaneousAppointments ||
            !practiceScheduleSettings?.appointmentDuration
          ) {
            toast({
              title: 'Error on selecting a slot',
              type: 'error',
              description:
                'Please select Simultaneous appointments an Appointments duration before setting a slot',
              time: 5000,
            });
            return false;
          }
          const newEvent: BigCalendarEvent = {
            start: e.start as Date,
            end: e.end as Date,
            index: practiceEvents.length,
          };
          setPracticeEvents([...practiceEvents, newEvent]);
          setRangeSelected(newEvent);
        }}
        titleAccessor={handleTitle}
        formats={{
          dayFormat: 'dddd',
        }}
      />
      {rangeSelected && (
        <Segment>
          <Form style={{ paddingTop: '15px' }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Field>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column verticalAlign="middle" width={10}>
                          From:
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <ReactDatePicker
                            data-automation-id={`patient-appointments-from`}
                            selected={rangeSelected?.start}
                            onChange={(date: Date) => {
                              setRangeSelected({
                                ...rangeSelected,
                                start: handleOnChangeTime({
                                  newDate: date,
                                  originalDate: rangeSelected?.start,
                                }),
                              });
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat={DateFormats.TIME}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column verticalAlign="middle" width={10}>
                          To:
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <ReactDatePicker
                            data-automation-id={`patient-appointments-to`}
                            selected={rangeSelected?.end}
                            onChange={(date: Date) =>
                              setRangeSelected({
                                ...rangeSelected,
                                end: handleOnChangeTime({
                                  newDate: date,
                                  originalDate: rangeSelected?.end,
                                }),
                              })
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat={DateFormats.TIME}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Field>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column verticalAlign="middle" width={10}>
                          Simultaneous appointments:
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle" width={6}>
                          <Form.Input
                            data-automation-id={`patient-appointments-simultaneous-edit`}
                            type="number"
                            min="1"
                            onChange={(_, { value }) =>
                              setRangeSelected({
                                ...rangeSelected,
                                resource: {
                                  ...rangeSelected.resource,
                                  simultaneous: value
                                    ? Math.abs(parseInt(value))
                                    : undefined,
                                },
                              })
                            }
                            value={rangeSelected?.resource?.simultaneous}
                            placeholder={
                              practiceScheduleSettings?.simultaneousAppointments
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column verticalAlign="middle" width={10}>
                          Appointments duration:
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle" width={6}>
                          <Form.Input
                            data-automation-id={`patient-appointments-duration-edit`}
                            type="number"
                            value={rangeSelected?.resource?.duration}
                            min="1"
                            placeholder={
                              practiceScheduleSettings?.appointmentDuration
                            }
                            onChange={(_, { value }) =>
                              setRangeSelected({
                                ...rangeSelected,
                                resource: {
                                  ...rangeSelected.resource,
                                  duration: value
                                    ? Math.abs(parseInt(value))
                                    : undefined,
                                },
                              })
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column floated="right" width={4}>
                  <Form.Button
                    data-automation-id={`patient-appointments-delete-button`}
                    fluid
                    negative
                    icon
                    labelPosition="right"
                    onClick={handleDeleteAvailability}
                  >
                    Delete
                    <Icon name="delete" />
                  </Form.Button>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Button
                    data-automation-id={`patient-appointments-save-edit-button`}
                    positive
                    fluid
                    icon
                    labelPosition="right"
                    onClick={handleSaveAvailability}
                  >
                    Save
                    <Icon name="save" />
                  </Form.Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
      )}
      <Grid style={{ paddingTop: '15px' }}>
        <Grid.Row centered>
          <Grid.Column width={6}>
            <Form.Button
              data-automation-id={`patient-appointments-save-button`}
              positive
              fluid
              icon
              onClick={handleSubmit}
              labelPosition="right"
            >
              Save
              <Icon name="save" />
            </Form.Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Availability;
