import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Message, Table } from 'semantic-ui-react';
import { BorrowingInventoryAdjustments } from '@graphql/inventory';
import { addDays } from '@bluefox/lib/formatters';
import { StatusLog } from '@bluefox/models/Inventory';

interface BorrowingSummaryProps {
  practiceId: string;
  viewChange: (activeIndex: number, date?: string, id?: string) => void;
}

interface InventoryAdjustmentInfo {
  createdAt: string;
  confirmedBy: string;
}

interface InventoryAdjustment {
  createdAt: string;
  id: string;
  statusLog: StatusLog[];
}

export const BorrowingSummary = ({
  practiceId,
  viewChange,
}: BorrowingSummaryProps) => {
  const [inventoryAdjustmentInfo, setInventoryAdjustmentInfo] = useState<
    InventoryAdjustmentInfo[]
  >([]);

  const MagicWordsDict = {
    STATUS: {
      APPLIED: 'applied',
    },
    REASON: {
      BORROWING: 'borrowing',
    },
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date
      .getDate()
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;
    return formattedDate;
  };

  useQuery(BorrowingInventoryAdjustments, {
    variables: {
      criteria: {
        inventoryAdjustmentDetails: {
          reason: { _eq: MagicWordsDict.REASON.BORROWING },
        },
        status: { _eq: MagicWordsDict.STATUS.APPLIED },
        practice: { id: { _eq: practiceId } },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      const processData = (adjustments: InventoryAdjustment[]) => {
        const uniqueEntries = new Map();

        adjustments.forEach((adjustment: InventoryAdjustment) => {
          const formattedDate = formatDate(adjustment.createdAt);
          if (!uniqueEntries.has(formattedDate)) {
            const confirmedByData = adjustment.statusLog.find(
              (log: StatusLog) => log.status === MagicWordsDict.STATUS.APPLIED
            );
            const confirmedBy = confirmedByData?.account?.lastName
              ? `${confirmedByData?.account?.lastName?.toUpperCase()}, ${confirmedByData
                  ?.account?.firstName}`
              : '-';
            uniqueEntries.set(formattedDate, {
              createdAt: formattedDate,
              confirmedBy,
            });
          }
        });

        return Array.from(uniqueEntries.values());
      };

      setInventoryAdjustmentInfo(processData(data.adjustments));
    },
  });

  function calculateStartingDate(index: number) {
    if (index === 0) return 'First Borrowing';
    const lastDateLastBorrow = inventoryAdjustmentInfo[index - 1].createdAt;
    const startingDate = addDays(new Date(lastDateLastBorrow), 1);
    const formattedDate = formatDate(new Date(startingDate).toString());
    return formattedDate;
  }

  return (
    <>
      {inventoryAdjustmentInfo.length ? (
        <Table celled structured selectable>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell width={1}>Cycle Starting Date</Table.HeaderCell>
              <Table.HeaderCell width={1}>Cycle Last Date</Table.HeaderCell>
              <Table.HeaderCell width={1}>Confirmed by</Table.HeaderCell>
              <Table.HeaderCell width={1}>See Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {inventoryAdjustmentInfo.map(
              (
                { createdAt, confirmedBy }: InventoryAdjustmentInfo,
                index: number
              ) => (
                <Table.Row key={createdAt} textAlign="center">
                  <Table.Cell>{calculateStartingDate(index)}</Table.Cell>
                  <Table.Cell>{formatDate(createdAt)}</Table.Cell>
                  <Table.Cell>{confirmedBy}</Table.Cell>
                  <Table.Cell>
                    <Button
                      primary
                      className="min-component-vertical-margin"
                      icon="eye"
                      size="tiny"
                      onClick={() => viewChange(1, createdAt, '')}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
      ) : (
        <Message>No swaps confirmed yet.</Message>
      )}
    </>
  );
};
