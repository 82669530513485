// send a message to chrome extension to let it know when there are pending notifications. ONLY IF EMBEBED
export type PendingNotificationPayload = {
  notificationsCount: number;
  action: string;
};

export const notifyPendingNotificationCountToExtension = async (
  notificationsCount: number
) => {
  const payload: PendingNotificationPayload = {
    notificationsCount,
    action: 'notifications',
  };

  await chrome.runtime.sendMessage(
    process.env.REACT_APP_CHROME_EXTENSION_ID,
    payload
  );
};
