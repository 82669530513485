import { useMemo, useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useMutation } from '@apollo/client';

import { Table, Icon, Label, Popup, List } from 'semantic-ui-react';

import { InsertAlertMutation } from '@bluefox/graphql/alerts';

import { Alert } from '@bluefox/models/Alert';
import { Vaccination } from '@bluefox/models/Vaccination';
import { PatientData } from '@bluefox/models/Patient';
import { usePatientData } from '@bluefox/contexts/PatientData';
import {
  RecommendedRange,
  vaccineTypeNames,
  cvxGroupMap,
} from '@bluefox/store/vaccines';
import ScrollDraggingContainer from '@bluefox/ui/ScrollDraggingContainer';

import { ColumnType } from '@components/VaccineCalendarWidget';
import { rangeToMonths } from '@utils/validations/inRange';
import { humanizeText } from '@bluefox/lib/humanize';
import { usePractice } from '@bluefox/contexts';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';
import { showImmunizationDate } from '@utils/dates';

interface Vaccine {
  vaccineType: string;
  ranges: RecommendedRange[];
  givenDoses: { [key: string]: Vaccination[] };
  totalRecommentationsCount: number;
  dueRecommentationsCount: number;
  givenDosesCount: number;
  cvx?: string;
}

export interface VaccineCalendarCategory {
  title: string;
  id: string;
  vaccines: Vaccine[];
  visible?: boolean;
}

interface VaccineCalendarProps {
  columns: ColumnType[];
  categories: VaccineCalendarCategory[];
  patientAgeInMonths: number;
}

interface ImmunizationObservation {
  cvx?: string;
  invalid?: string;
  immunizationScheduleUsed?: string;
}

const VaccineCalendar = ({
  columns,
  categories,
  patientAgeInMonths,
}: VaccineCalendarProps) => {
  const patientData = usePatientData();

  if (!patientData) return null;

  return (
    <ScrollDraggingContainer>
      <Table
        celled
        selectable
        style={{
          borderRadius: '0',
          MozUserSelect: 'none',
          WebkitUserSelect: 'none',
          msUserSelect: 'none',
          userSelect: 'none',
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{
                top: 0,
                left: 0,
                minWidth: 250,
                maxWidth: 250,
                position: 'sticky',
                zIndex: 1,
                backgroundColor: 'rgb(249, 250, 251)',
                borderRight: 'solid 1px rgba(34, 36, 38, 0.1)',
              }}
            >
              Vaccine
            </Table.HeaderCell>
            {columns.map((column: ColumnType, key: number) => (
              <Table.HeaderCell
                style={{
                  position: 'sticky',
                  top: 0,
                }}
                key={`column-${key}`}
                className="vaxdash-column"
              >
                {column.label}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {categories.map((category) => (
            <VaccineCalendarCategoryRows
              key={category.id}
              category={category}
              columns={columns}
              patientAgeInMonths={patientAgeInMonths}
              patientData={patientData}
              visible={category.visible}
            />
          ))}
        </Table.Body>
      </Table>
    </ScrollDraggingContainer>
  );
};

interface VaccineCalendarCategoryRowsProps {
  category: VaccineCalendarCategory;
  columns: ColumnType[];
  patientAgeInMonths: number;
  patientData: PatientData;
  visible?: boolean;
}

const VaccineCalendarCategoryRows = ({
  category,
  columns,
  patientAgeInMonths,
  patientData,
  visible,
}: VaccineCalendarCategoryRowsProps) => {
  const practice = usePractice();

  const [collapsed, setCollapsed] = useState(!visible);

  const [saveAlert] = useMutation(InsertAlertMutation);

  const handleSaveAlert = (alertObject: Alert) => {
    saveAlert({
      variables: {
        alertObject,
      },
    });
  };

  const colSpans = useMemo(
    () =>
      columns.reduce<{ prev: number; post: number }>(
        (acc, column: ColumnType) => {
          const columnRange = rangeToMonths({
            start: column.start,
            end: column.end,
            rangeUnit: column.rangeType ?? 'months',
          });

          let { prev, post } = acc;
          if (patientAgeInMonths >= columnRange.end) ++prev;
          if (patientAgeInMonths < columnRange.start) ++post;

          return { prev, post };
        },
        { prev: 0, post: 0 }
      ),
    [columns, patientAgeInMonths]
  );

  const showImmunizationMark = (
    vaccination: Vaccination,
    vaccineType: string
  ) => {
    const existCvxKey = cvxGroupMap[vaccination.vaccine?.cvx || ''];

    if (!existCvxKey) return '';
    const immObxs = vaccination.immunizationObservation ?? [];
    return immObxs.findIndex(
      (obx: ImmunizationObservation) =>
        obx.invalid && existCvxKey[obx.cvx || ''] === vaccineType
    ) > -1 ? (
      <Popup
        key={vaccination.id}
        trigger={<Icon name="syringe" color="yellow" inverted circular />}
      >
        <Popup.Header>
          <Icon name="syringe" />
          {vaccination.vaccine?.name}
        </Popup.Header>
        {vaccination.historic ? <p>(External)</p> : null}
        <Popup.Content>
          <List bulleted>
            {vaccination.immunizationObservation
              ?.filter(
                (obx: any) =>
                  cvxGroupMap[vaccination.vaccine?.cvx || ''][obx.cvx] ===
                  vaccineType
              )
              .map((obx: any, index: number) => (
                <List.Item key={index}>{obx.invalid}</List.Item>
              ))}
          </List>
        </Popup.Content>
      </Popup>
    ) : (
      <Popup
        key={vaccination.id}
        trigger={<Icon name="syringe" circular color="blue" inverted />}
      >
        <Popup.Header>
          <Icon name="syringe" />
          {vaccination.vaccine?.name}
        </Popup.Header>
        {vaccination.historic ? <p>(External)</p> : null}
        <Popup.Content>
          <Table collapsing compact size="small">
            <Table.Body>
              <Table.Row>
                <Table.Cell active>Age</Table.Cell>
                <Table.Cell>
                  {showImmunizationDate(vaccination, practice.timezone)} (
                  <Moment from={patientData.birthdate} ago>
                    {vaccination.givenAt}
                  </Moment>
                  )
                </Table.Cell>
              </Table.Row>
              {!!vaccination.inventory?.lot && (
                <Table.Row>
                  <Table.Cell active>Lot</Table.Cell>
                  <Table.Cell>{vaccination.inventory?.lot}</Table.Cell>
                </Table.Row>
              )}
              {!!vaccination.site && (
                <Table.Row>
                  <Table.Cell active>Site</Table.Cell>
                  <Table.Cell>
                    {humanizeText(vaccination.site, {
                      delimiter: '_',
                      capitalize: 'all',
                    })}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Popup.Content>
      </Popup>
    );
  };

  useEffect(() => {
    if (!category) return;

    category.vaccines.forEach((vax) => {
      Object.keys(vax.givenDoses).forEach((key: string) => {
        vax.givenDoses[key].forEach((vaccination) => {
          const existCvxKey = cvxGroupMap[vaccination.vaccine?.cvx || ''];
          if (!existCvxKey) {
            handleSaveAlert({
              entityRef: vaccination.vaccine?.id,
              type: 'vaccine',
              message: `CVX ${vaccination.vaccine?.cvx} is missing`,
              practiceId: practice.id,
              severity: 'high',
            });
          }
        });
      });
    });
  }, [category]);

  return (
    <>
      <Table.Row>
        <Table.Cell
          positive={!!category.vaccines.length}
          disabled={!category.vaccines.length}
          selectable
          onClick={() => setCollapsed(!collapsed)}
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 1,
            borderRight: 'solid 1px rgba(34, 36, 38, 0.1)',
            paddingLeft: 10,
            cursor: 'pointer',
          }}
          className="vaxdash-calendar-vaccines-column"
        >
          <strong>{category.title}</strong>
          <Icon
            size="large"
            name={`triangle ${collapsed ? 'right' : 'down'}`}
          />
        </Table.Cell>
        <Table.Cell
          colSpan={colSpans.prev}
          positive={!!category.vaccines.length}
          disabled={!category.vaccines.length}
        ></Table.Cell>
        <Table.Cell
          style={{ backgroundColor: 'papayawhip' }}
          disabled={!category.vaccines.length}
        ></Table.Cell>
        <Table.Cell
          colSpan={colSpans.post}
          positive={!!category.vaccines.length}
          disabled={!category.vaccines.length}
        ></Table.Cell>
      </Table.Row>
      {category.vaccines.map((vaccine, rowKey) => {
        const {
          ranges,
          givenDoses,
          totalRecommentationsCount,
          dueRecommentationsCount,
          givenDosesCount,
          vaccineType,
        } = vaccine;

        return (
          !collapsed && (
            <Table.Row key={rowKey}>
              <Table.Cell className="vaxdash-calendar-vaccines-column">
                {vaccineTypeNames[vaccineType]}
                <Label size="mini" basic>
                  <Icon
                    color={
                      givenDosesCount >= dueRecommentationsCount
                        ? 'green'
                        : 'orange'
                    }
                    name={
                      givenDosesCount >= dueRecommentationsCount
                        ? 'check'
                        : 'time'
                    }
                  />
                  {givenDosesCount} / {totalRecommentationsCount}
                </Label>
              </Table.Cell>
              {columns.map((column: ColumnType, columnKey) => {
                const columnRange = rangeToMonths({
                  start: column.start,
                  end: column.end,
                  rangeUnit: column.rangeType ?? 'months',
                });

                const vaccinations = Object.entries(givenDoses)
                  .filter(
                    ([dose]) =>
                      parseInt(dose) >= columnRange.start &&
                      parseInt(dose) < columnRange.end
                  )
                  .map(([, v]) => v)
                  .flat();

                const segments: string[] = [];

                const currentRange = ranges.find((range, i) => {
                  if (
                    range.start >= columnRange.start &&
                    range.start < columnRange.end
                  ) {
                    segments.push('start');
                  }

                  if (
                    range.start < columnRange.start &&
                    range.end > columnRange.end
                  ) {
                    segments.push('middle');
                  }

                  if (
                    range.end > columnRange.start &&
                    range.end <= columnRange.end
                  ) {
                    segments.push('end');
                  }

                  return !!segments.length;
                });

                const recommended = !!currentRange;

                const eligible =
                  currentRange && currentRange.start <= patientAgeInMonths;

                const recommendedClasses = currentRange
                  ? 'recommended-range ' +
                    segments.join(' ') +
                    (eligible // && columnRange.end > patientAgeInMonths
                      ? ''
                      : ' non-eligible')
                  : '';

                return (
                  <Table.Cell
                    key={`${vaccineType}-${columnKey}`}
                    className="vaxdash-cell"
                    style={{
                      ...(patientAgeInMonths >= columnRange.start &&
                      patientAgeInMonths < columnRange.end
                        ? {
                            backgroundColor: 'papayawhip',
                          }
                        : {}),
                    }}
                  >
                    <div
                      className={`vaxdash-cell ${recommendedClasses} ${
                        vaccinations.length > 0 ? 'vaccinated' : ''
                      }`}
                    >
                      {recommended && segments[0] === 'start' ? (
                        <Label circular basic>
                          {currentRange?.label}
                          {!eligible && (
                            <>
                              {' (Due '}
                              <Moment
                                fromNow
                                add={{
                                  [currentRange.rangeUnit]: currentRange.start,
                                }}
                              >
                                {patientData.birthdate}
                              </Moment>
                              )
                            </>
                          )}
                        </Label>
                      ) : (
                        ''
                      )}
                      {vaccinations.map((vaccination) =>
                        showImmunizationMark(vaccination, vaccineType)
                      )}
                    </div>
                  </Table.Cell>
                );
              })}
            </Table.Row>
          )
        );
      })}
    </>
  );
};

export default VaccineCalendar;
