import { useState, useCallback, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import {
  Form,
  Header,
  Divider,
  Table,
  Button,
  Modal,
  Label,
} from 'semantic-ui-react';
import { usePatientData, usePractice } from '@bluefox/contexts';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import VaccinePicker from '@bluefox/ui/VaccinePicker';
import { Vaccine } from '@bluefox/models/Vaccine';
import { VACCINE_TYPES } from '@bluefox/store/vaccines';
import {
  InsertVacciationsMutation,
  VaccinationsByTypeQuery,
} from '@bluefox/graphql/vaccinations';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';

interface RouterParamsType {
  practicePatientId?: string;
}

interface VaccinationsEntry {
  vaccine: Vaccine;
  lot: string;
  givenAt: Date;
  visDate: Date;
}

interface ImmunizationFormProps {
  trigger: ReactElement;
  onSave?: () => void;
}

const ImmunizationForm = ({ trigger, onSave }: ImmunizationFormProps) => {
  const { practicePatientId } = useParams<RouterParamsType>();
  const patientData = usePatientData();
  const { timezone: practiceTimezone } = usePractice();

  const [lot, setLot] = useState<string>('');
  const [dateGiven, setDateGiven] = useState<Date | undefined | null>(null);
  const [visDate, setVisDate] = useState<Date | undefined | null>(null);
  const [vaccinations, setVaccinations] = useState<VaccinationsEntry[]>([]);

  const [open, setOpen] = useState(false);

  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>();

  const addVax = () => {
    if (!selectedVaccine) return;
    setVaccinations([
      ...vaccinations,
      {
        vaccine: selectedVaccine,
        lot,
        givenAt: dateGiven as Date,
        visDate: visDate as Date,
      },
    ]);
    setLot('');
    setDateGiven(null);
    setVisDate(null);
  };

  const [insertVaccinations, { loading }] = useMutation(
    InsertVacciationsMutation
  );

  const handleSaveVaxList = useCallback(() => {
    if (vaccinations.length === 0) return;
    insertVaccinations({
      variables: {
        vaccinations: vaccinations.map((v) => ({
          ...v,
          practicePatientId,
          vaccine: undefined,
          vaccineId: v.vaccine.id,
          historic: true,
        })),
      },
      refetchQueries: [
        {
          query: VaccinationsByTypeQuery,
          variables: {
            practicePatientId,
            types: VACCINE_TYPES,
          },
        },
      ],
    })
      .then(() => {
        if (onSave) onSave();
        toast({
          title: 'Immunization has been successfully updated',
          type: 'success',
          time: 5000,
        });
        setOpen(false);
        setSelectedVaccine(undefined);
        setVaccinations([]);
        setLot('');
        setDateGiven(null);
        setVisDate(null);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  }, [vaccinations, insertVaccinations, practicePatientId, onSave]);

  const handleCloseAndClean = () => {
    setOpen(false);
    setSelectedVaccine(undefined);
    setVaccinations([]);
    setLot('');
    setDateGiven(null);
    setVisDate(null);
  };

  return (
    <Modal
      closeIcon
      trigger={trigger}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleCloseAndClean}
    >
      <Modal.Content scrolling>
        <Form onSubmit={addVax}>
          <Header as="h1">Add Immunization History</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Vaccine</label>
              <VaccinePicker
                dropdownProps={{
                  fluid: true,
                  placeholder: 'Search Vaccine',
                }}
                onChange={({ vaccine }) => setSelectedVaccine(vaccine)}
                fallback={true}
                value={selectedVaccine?.id}
              />
            </Form.Field>
            <Form.Input
              value={lot}
              onChange={(_, { value }) => setLot(value.toUpperCase())}
              fluid
              label="Lot "
              placeholder="Lot"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Date Given</label>
              <DateTimePicker
                onChange={(value) =>
                  setDateGiven(value ? (value as Date) : undefined)
                }
                tz={practiceTimezone}
                selected={dateGiven}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                minDate={
                  patientData?.birthdate
                    ? moment(patientData?.birthdate).toDate()
                    : undefined
                }
                maxDate={new Date()}
                yearDropdownItemNumber={18}
                required
              />
            </Form.Field>
            <Form.Field required>
              <label>VIS Date</label>
              <DateTimePicker
                onChange={(value) =>
                  setVisDate(value ? (value as Date) : undefined)
                }
                tz={practiceTimezone}
                selected={visDate}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                maxDate={new Date()}
                yearDropdownItemNumber={18}
                required
              />
            </Form.Field>
          </Form.Group>
          <Button primary type="submit" content="Add" icon="add" />
        </Form>

        <Divider />

        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Vaccine</Table.HeaderCell>
              <Table.HeaderCell>Lot Number</Table.HeaderCell>
              <Table.HeaderCell>Types</Table.HeaderCell>
              <Table.HeaderCell>Date Given (MM/DD/YYYY)</Table.HeaderCell>
              <Table.HeaderCell>VIS Date (MM/DD/YYYY)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!vaccinations.length && (
              <Table.Row>
                <Table.Cell colSpan={5}>
                  Add vaccinations in the form above.
                </Table.Cell>
              </Table.Row>
            )}
            {vaccinations.map((v, i) => (
              <Table.Row key={i}>
                <Table.Cell>{v.vaccine.name}</Table.Cell>
                <Table.Cell>{v.lot}</Table.Cell>
                <Table.Cell>
                  {v.vaccine.types?.map((t) => (
                    <Label key={t} size="mini">
                      {t}
                    </Label>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  {moment(v.givenAt).format(DateFormats.DATE)}
                </Table.Cell>
                <Table.Cell>
                  {moment(v.visDate).format(DateFormats.DATE)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <StyledButtonGroup>
          <Button content="Cancel" onClick={handleCloseAndClean} />
          <Button
            primary
            content="Save immunization"
            icon="save"
            onClick={handleSaveVaxList}
            disabled={!vaccinations.length || loading}
          />
        </StyledButtonGroup>
      </Modal.Actions>
    </Modal>
  );
};

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ImmunizationForm;
