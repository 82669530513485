import {
  CSSProperties,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
} from 'react';

interface ScrollDraggingContainerProps {
  children: ReactNode;
  style?: CSSProperties;
  divProps?: HTMLAttributes<HTMLDivElement>;
}

const ScrollDraggingContainer = ({
  children,
  style = {},
  divProps = {},
}: ScrollDraggingContainerProps) => {
  const ele = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ele.current) return;

    ele.current.scrollTop = 100;
    ele.current.scrollLeft = 150;
  }, []);

  let pos = { top: 0, left: 0, x: 0, y: 0 };

  const mouseDownHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!ele.current) return;

    pos = {
      left: ele.current.scrollLeft,
      top: ele.current.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };

    ele.current.style.cursor = 'grabbing';
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = (e: MouseEvent) => {
    if (!ele.current) return;

    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    ele.current.scrollTop = pos.top - dy;
    ele.current.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = () => {
    if (!ele.current) return;

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    ele.current.style.cursor = 'grab';
    ele.current.style.removeProperty('user-select');
  };

  return (
    <div
      ref={ele}
      unselectable="on"
      onSelect={() => false}
      style={{
        MozUserSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
        cursor: 'grab',
        overflow: 'auto',
        ...style,
      }}
      onMouseDown={(e) => mouseDownHandler(e)}
      {...divProps}
    >
      {children}
    </div>
  );
};

export default ScrollDraggingContainer;
