import moment from 'moment-timezone';

import { Vaccination } from '@bluefox/models/Vaccination';
import { PatientData } from '@bluefox/models/Patient';
import { RecommendedImmunizations, RecommendedRange } from './vaccines';

export const differenceInMonths = (DoB: string | Date, ref: string | Date) => {
  const DoBDate = new Date(DoB);
  const refDate = new Date(ref);

  return moment(refDate).diff(DoBDate, 'months');
};

export interface ImmunizationEntry {
  vaccineType: string;
  ranges: RecommendedRange[];
  givenDoses: {
    [key: number]: Vaccination[];
  };
  totalRecommentationsCount: number;
  dueRecommentationsCount: number;
  givenDosesCount: number;
}

export const getImmunization = (
  vaccineList: ReadonlyArray<string>,
  vaccinations: Vaccination[],
  patientData: PatientData,
): ImmunizationEntry[] => {
  const patientAgeInMonths = differenceInMonths(
    patientData.birthdate,
    new Date(),
  );

  return vaccineList.map((vaccineType) => {
    let givenDosesCount = 0;
    const givenDoses =
      vaccinations
        .filter((v) => v.vaccine?.types?.includes(vaccineType))
        .reduce<{ [key: number]: Vaccination[] }>((acc, v) => {
          const month = differenceInMonths(patientData?.birthdate, v.givenAt);
          ++givenDosesCount;
          return {
            ...acc,
            ...(acc[month]
              ? {
                  [month]: [...acc[month], v],
                }
              : { [month]: [v] }),
          };
        }, {}) || {};

    if (!RecommendedImmunizations[vaccineType]) {
      return {
        vaccineType,
        ranges: [],
        givenDoses,
        totalRecommentationsCount: 0,
        dueRecommentationsCount: 0,
        givenDosesCount,
      };
    }

    let visibleRanges: RecommendedRange[] = [];
    let totalRecommentationsCount = 0;
    let dueRecommentationsCount = 0;

    const { annual, minimumAge, efectivePeriod, ranges } =
      RecommendedImmunizations[vaccineType];

    if (annual) {
      if (patientAgeInMonths >= minimumAge) {
        const lastDose = Math.max(...Object.keys(givenDoses).map(Number), 0);

        const nextDose = lastDose
          ? lastDose + efectivePeriod
          : patientAgeInMonths;

        if (lastDose) {
          givenDosesCount = 1;
          if (lastDose + efectivePeriod > patientAgeInMonths) {
            dueRecommentationsCount = 0;
          } else {
            dueRecommentationsCount = 1;
          }
        } else {
          dueRecommentationsCount = 1;
          givenDosesCount = 0;
        }
        totalRecommentationsCount = 1;

        visibleRanges = [
          {
            label: dueRecommentationsCount ? 'Due' : 'Next',
            start: nextDose,
            end: nextDose + 1,
            rangeUnit: 'month',
          },
        ];
      } else {
        visibleRanges = [
          {
            label: '1st',
            start: minimumAge,
            end: minimumAge + 1,
            rangeUnit: 'month',
          },
        ];
      }
    } else {
      visibleRanges = Array.from(
        RecommendedImmunizations[vaccineType].ranges ?? [],
      );
      totalRecommentationsCount = Object.keys(ranges).length;
      dueRecommentationsCount = ranges.filter(
        (r) => r.start <= patientAgeInMonths,
      ).length;
      // remove a recommendation for each given dose
      visibleRanges.splice(0, givenDosesCount);
    }

    return {
      vaccineType,
      ranges: visibleRanges,
      givenDoses,
      totalRecommentationsCount,
      dueRecommentationsCount,
      givenDosesCount,
    };
  });
};
