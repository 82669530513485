export const vaccinationsByDosesAndDatePartCoordsMap: {
  [key: string]: object[];
} = {
  HepA: [
    {
      month: {
        x: 107,
        y: 347,
      },
      day: {
        x: 128,
        y: 347,
      },
      year: {
        x: 148,
        y: 347,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 347,
      },
      day: {
        x: 128 + 59,
        y: 347,
      },
      year: {
        x: 148 + 59,
        y: 347,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 347,
      },
      day: {
        x: 128 + 118,
        y: 347,
      },
      year: {
        x: 148 + 118,
        y: 347,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 347,
      },
      day: {
        x: 128 + 177,
        y: 347,
      },
      year: {
        x: 148 + 177,
        y: 347,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 347,
      },
      day: {
        x: 128 + 236,
        y: 347,
      },
      year: {
        x: 148 + 236,
        y: 347,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 347,
      },
      day: {
        x: 128 + 295,
        y: 347,
      },
      year: {
        x: 148 + 295,
        y: 347,
      },
    },
  ],
  HepB: [
    {
      month: {
        x: 107,
        y: 514,
      },
      day: {
        x: 128,
        y: 514,
      },
      year: {
        x: 148,
        y: 514,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 514,
      },
      day: {
        x: 128 + 59,
        y: 514,
      },
      year: {
        x: 148 + 59,
        y: 514,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 514,
      },
      day: {
        x: 128 + 118,
        y: 514,
      },
      year: {
        x: 148 + 118,
        y: 514,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 514,
      },
      day: {
        x: 128 + 177,
        y: 514,
      },
      year: {
        x: 148 + 177,
        y: 514,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 514,
      },
      day: {
        x: 128 + 236,
        y: 514,
      },
      year: {
        x: 148 + 236,
        y: 514,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 514,
      },
      day: {
        x: 128 + 295,
        y: 514,
      },
      year: {
        x: 148 + 295,
        y: 514,
      },
    },
  ],
  MENING: [
    {
      month: {
        x: 107,
        y: 472,
      },
      day: {
        x: 128,
        y: 472,
      },
      year: {
        x: 148,
        y: 472,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 472,
      },
      day: {
        x: 128 + 59,
        y: 472,
      },
      year: {
        x: 148 + 59,
        y: 472,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 472,
      },
      day: {
        x: 128 + 118,
        y: 472,
      },
      year: {
        x: 148 + 118,
        y: 472,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 348,
      },
      day: {
        x: 128 + 177,
        y: 472,
      },
      year: {
        x: 148 + 177,
        y: 472,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 472,
      },
      day: {
        x: 128 + 236,
        y: 472,
      },
      year: {
        x: 148 + 236,
        y: 472,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 472,
      },
      day: {
        x: 128 + 295,
        y: 472,
      },
      year: {
        x: 148 + 295,
        y: 472,
      },
    },
  ],
  VARICELLA: [
    {
      month: {
        x: 107,
        y: 327,
      },
      day: {
        x: 128,
        y: 327,
      },
      year: {
        x: 148,
        y: 327,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 327,
      },
      day: {
        x: 128 + 59,
        y: 327,
      },
      year: {
        x: 148 + 59,
        y: 327,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 327,
      },
      day: {
        x: 128 + 118,
        y: 327,
      },
      year: {
        x: 148 + 118,
        y: 327,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 327,
      },
      day: {
        x: 128 + 177,
        y: 327,
      },
      year: {
        x: 148 + 177,
        y: 327,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 327,
      },
      day: {
        x: 128 + 236,
        y: 327,
      },
      year: {
        x: 148 + 236,
        y: 327,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 327,
      },
      day: {
        x: 128 + 295,
        y: 327,
      },
      year: {
        x: 148 + 295,
        y: 327,
      },
    },
  ],
  ROTAVIRUS: [
    {
      month: {
        x: 107,
        y: 284,
      },
      day: {
        x: 128,
        y: 284,
      },
      year: {
        x: 148,
        y: 284,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 284,
      },
      day: {
        x: 128 + 59,
        y: 284,
      },
      year: {
        x: 148 + 59,
        y: 284,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 284,
      },
      day: {
        x: 128 + 118,
        y: 284,
      },
      year: {
        x: 148 + 118,
        y: 284,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 284,
      },
      day: {
        x: 128 + 177,
        y: 284,
      },
      year: {
        x: 148 + 177,
        y: 284,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 284,
      },
      day: {
        x: 128 + 236,
        y: 284,
      },
      year: {
        x: 148 + 236,
        y: 284,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 284,
      },
      day: {
        x: 128 + 295,
        y: 284,
      },
      year: {
        x: 148 + 295,
        y: 284,
      },
    },
  ],
  PneumoPCV: [
    {
      month: {
        x: 107,
        y: 431,
      },
      day: {
        x: 128,
        y: 431,
      },
      year: {
        x: 148,
        y: 431,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 431,
      },
      day: {
        x: 128 + 59,
        y: 431,
      },
      year: {
        x: 148 + 59,
        y: 431,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 431,
      },
      day: {
        x: 128 + 118,
        y: 431,
      },
      year: {
        x: 148 + 118,
        y: 431,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 431,
      },
      day: {
        x: 128 + 177,
        y: 431,
      },
      year: {
        x: 148 + 177,
        y: 431,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 431,
      },
      day: {
        x: 128 + 236,
        y: 431,
      },
      year: {
        x: 148 + 236,
        y: 431,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 431,
      },
      day: {
        x: 128 + 295,
        y: 431,
      },
      year: {
        x: 148 + 295,
        y: 431,
      },
    },
  ],
  POLIO: [
    {
      month: {
        x: 107,
        y: 536,
      },
      day: {
        x: 128,
        y: 536,
      },
      year: {
        x: 148,
        y: 536,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 536,
      },
      day: {
        x: 128 + 59,
        y: 536,
      },
      year: {
        x: 148 + 59,
        y: 536,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 536,
      },
      day: {
        x: 128 + 118,
        y: 536,
      },
      year: {
        x: 148 + 118,
        y: 536,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 536,
      },
      day: {
        x: 128 + 177,
        y: 536,
      },
      year: {
        x: 148 + 177,
        y: 536,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 536,
      },
      day: {
        x: 128 + 236,
        y: 536,
      },
      year: {
        x: 148 + 236,
        y: 536,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 536,
      },
      day: {
        x: 128 + 295,
        y: 536,
      },
      year: {
        x: 148 + 295,
        y: 536,
      },
    },
  ],
  DTAP: [
    {
      month: {
        x: 107,
        y: 557,
      },
      day: {
        x: 128,
        y: 557,
      },
      year: {
        x: 148,
        y: 557,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 557,
      },
      day: {
        x: 128 + 59,
        y: 557,
      },
      year: {
        x: 148 + 59,
        y: 557,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 557,
      },
      day: {
        x: 128 + 118,
        y: 557,
      },
      year: {
        x: 148 + 118,
        y: 557,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 557,
      },
      day: {
        x: 128 + 177,
        y: 557,
      },
      year: {
        x: 148 + 177,
        y: 557,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 557,
      },
      day: {
        x: 128 + 236,
        y: 557,
      },
      year: {
        x: 148 + 236,
        y: 557,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 557,
      },
      day: {
        x: 128 + 295,
        y: 557,
      },
      year: {
        x: 148 + 295,
        y: 557,
      },
    },
  ],
  MEASLES: [
    {
      month: {
        x: 107,
        y: 411,
      },
      day: {
        x: 128,
        y: 411,
      },
      year: {
        x: 148,
        y: 411,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 411,
      },
      day: {
        x: 128 + 59,
        y: 411,
      },
      year: {
        x: 148 + 59,
        y: 411,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 411,
      },
      day: {
        x: 128 + 118,
        y: 411,
      },
      year: {
        x: 148 + 118,
        y: 411,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 411,
      },
      day: {
        x: 128 + 177,
        y: 411,
      },
      year: {
        x: 148 + 177,
        y: 411,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 411,
      },
      day: {
        x: 128 + 236,
        y: 411,
      },
      year: {
        x: 148 + 236,
        y: 411,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 411,
      },
      day: {
        x: 128 + 295,
        y: 411,
      },
      year: {
        x: 148 + 295,
        y: 411,
      },
    },
  ],
  MUMPS: [
    {
      month: {
        x: 107,
        y: 390,
      },
      day: {
        x: 128,
        y: 390,
      },
      year: {
        x: 148,
        y: 390,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 390,
      },
      day: {
        x: 128 + 59,
        y: 390,
      },
      year: {
        x: 148 + 59,
        y: 390,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 390,
      },
      day: {
        x: 128 + 118,
        y: 390,
      },
      year: {
        x: 148 + 118,
        y: 390,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 390,
      },
      day: {
        x: 128 + 177,
        y: 390,
      },
      year: {
        x: 148 + 177,
        y: 390,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 390,
      },
      day: {
        x: 128 + 236,
        y: 390,
      },
      year: {
        x: 148 + 236,
        y: 390,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 390,
      },
      day: {
        x: 128 + 295,
        y: 390,
      },
      year: {
        x: 148 + 295,
        y: 390,
      },
    },
  ],
  RUBELLA: [
    {
      month: {
        x: 107,
        y: 369,
      },
      day: {
        x: 128,
        y: 369,
      },
      year: {
        x: 148,
        y: 369,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 369,
      },
      day: {
        x: 128 + 59,
        y: 369,
      },
      year: {
        x: 148 + 59,
        y: 369,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 369,
      },
      day: {
        x: 128 + 118,
        y: 369,
      },
      year: {
        x: 148 + 118,
        y: 369,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 369,
      },
      day: {
        x: 128 + 177,
        y: 369,
      },
      year: {
        x: 148 + 177,
        y: 369,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 369,
      },
      day: {
        x: 128 + 236,
        y: 369,
      },
      year: {
        x: 148 + 236,
        y: 369,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 369,
      },
      day: {
        x: 128 + 295,
        y: 369,
      },
      year: {
        x: 148 + 295,
        y: 369,
      },
    },
  ],
  FLU: [
    {
      month: {
        x: 107,
        y: 242,
      },
      day: {
        x: 128,
        y: 242,
      },
      year: {
        x: 148,
        y: 242,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 242,
      },
      day: {
        x: 128 + 59,
        y: 242,
      },
      year: {
        x: 148 + 59,
        y: 242,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 242,
      },
      day: {
        x: 128 + 118,
        y: 242,
      },
      year: {
        x: 148 + 118,
        y: 242,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 242,
      },
      day: {
        x: 128 + 177,
        y: 242,
      },
      year: {
        x: 148 + 177,
        y: 242,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 242,
      },
      day: {
        x: 128 + 236,
        y: 242,
      },
      year: {
        x: 148 + 236,
        y: 242,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 242,
      },
      day: {
        x: 128 + 295,
        y: 242,
      },
      year: {
        x: 148 + 295,
        y: 242,
      },
    },
  ],
  TDAP: [
    {
      month: {
        x: 107,
        y: 493,
      },
      day: {
        x: 128,
        y: 493,
      },
      year: {
        x: 148,
        y: 493,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 493,
      },
      day: {
        x: 128 + 59,
        y: 493,
      },
      year: {
        x: 148 + 59,
        y: 493,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 493,
      },
      day: {
        x: 128 + 118,
        y: 493,
      },
      year: {
        x: 148 + 118,
        y: 493,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 493,
      },
      day: {
        x: 128 + 177,
        y: 493,
      },
      year: {
        x: 148 + 177,
        y: 493,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 493,
      },
      day: {
        x: 128 + 236,
        y: 493,
      },
      year: {
        x: 148 + 236,
        y: 493,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 493,
      },
      day: {
        x: 128 + 295,
        y: 493,
      },
      year: {
        x: 148 + 295,
        y: 493,
      },
    },
  ],
  HIB: [
    {
      month: {
        x: 107,
        y: 451,
      },
      day: {
        x: 128,
        y: 451,
      },
      year: {
        x: 148,
        y: 451,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 451,
      },
      day: {
        x: 128 + 59,
        y: 451,
      },
      year: {
        x: 148 + 59,
        y: 451,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 451,
      },
      day: {
        x: 128 + 118,
        y: 451,
      },
      year: {
        x: 148 + 118,
        y: 451,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 451,
      },
      day: {
        x: 128 + 177,
        y: 451,
      },
      year: {
        x: 148 + 177,
        y: 451,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 451,
      },
      day: {
        x: 128 + 236,
        y: 451,
      },
      year: {
        x: 148 + 236,
        y: 451,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 451,
      },
      day: {
        x: 128 + 295,
        y: 451,
      },
      year: {
        x: 148 + 295,
        y: 451,
      },
    },
  ],
  HPV: [
    {
      month: {
        x: 107,
        y: 263,
      },
      day: {
        x: 128,
        y: 263,
      },
      year: {
        x: 148,
        y: 263,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 263,
      },
      day: {
        x: 128 + 59,
        y: 263,
      },
      year: {
        x: 148 + 59,
        y: 263,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 263,
      },
      day: {
        x: 128 + 118,
        y: 263,
      },
      year: {
        x: 148 + 118,
        y: 263,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 263,
      },
      day: {
        x: 128 + 177,
        y: 263,
      },
      year: {
        x: 148 + 177,
        y: 263,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 263,
      },
      day: {
        x: 128 + 236,
        y: 263,
      },
      year: {
        x: 148 + 236,
        y: 263,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 263,
      },
      day: {
        x: 128 + 295,
        y: 263,
      },
      year: {
        x: 148 + 295,
        y: 263,
      },
    },
  ],
  MeningB: [
    {
      month: {
        x: 107,
        y: 204,
      },
      day: {
        x: 128,
        y: 204,
      },
      year: {
        x: 148,
        y: 204,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 204,
      },
      day: {
        x: 128 + 59,
        y: 204,
      },
      year: {
        x: 148 + 59,
        y: 204,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 204,
      },
      day: {
        x: 128 + 118,
        y: 204,
      },
      year: {
        x: 148 + 118,
        y: 204,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 204,
      },
      day: {
        x: 128 + 177,
        y: 204,
      },
      year: {
        x: 148 + 177,
        y: 204,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 204,
      },
      day: {
        x: 128 + 236,
        y: 204,
      },
      year: {
        x: 148 + 236,
        y: 204,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 204,
      },
      day: {
        x: 128 + 295,
        y: 204,
      },
      year: {
        x: 148 + 295,
        y: 204,
      },
    },
  ],
  TD: [
    {
      month: {
        x: 107,
        y: 557,
      },
      day: {
        x: 128,
        y: 557,
      },
      year: {
        x: 148,
        y: 557,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 557,
      },
      day: {
        x: 128 + 59,
        y: 557,
      },
      year: {
        x: 148 + 59,
        y: 557,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 557,
      },
      day: {
        x: 128 + 118,
        y: 557,
      },
      year: {
        x: 148 + 118,
        y: 557,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 557,
      },
      day: {
        x: 128 + 177,
        y: 557,
      },
      year: {
        x: 148 + 177,
        y: 557,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 557,
      },
      day: {
        x: 128 + 236,
        y: 557,
      },
      year: {
        x: 148 + 236,
        y: 557,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 557,
      },
      day: {
        x: 128 + 295,
        y: 557,
      },
      year: {
        x: 148 + 295,
        y: 557,
      },
    },
  ],
  TDBooster: [
    {
      month: {
        x: 107,
        y: 222,
      },
      day: {
        x: 128,
        y: 222,
      },
      year: {
        x: 148,
        y: 222,
      },
    },
    {
      month: {
        x: 107 + 59,
        y: 222,
      },
      day: {
        x: 128 + 59,
        y: 222,
      },
      year: {
        x: 148 + 59,
        y: 222,
      },
    },
    {
      month: {
        x: 107 + 118,
        y: 222,
      },
      day: {
        x: 128 + 118,
        y: 222,
      },
      year: {
        x: 148 + 118,
        y: 222,
      },
    },
    {
      month: {
        x: 107 + 177,
        y: 222,
      },
      day: {
        x: 128 + 177,
        y: 222,
      },
      year: {
        x: 148 + 177,
        y: 222,
      },
    },
    {
      month: {
        x: 107 + 236,
        y: 222,
      },
      day: {
        x: 128 + 236,
        y: 222,
      },
      year: {
        x: 148 + 236,
        y: 222,
      },
    },
    {
      month: {
        x: 107 + 295,
        y: 222,
      },
      day: {
        x: 128 + 295,
        y: 222,
      },
      year: {
        x: 148 + 295,
        y: 222,
      },
    },
  ],
};

export const totalDosesCoords: { [key: string]: { x: number; y: number } } = {
  HepA: {
    x: 466,
    y: 347,
  },
  HepB: {
    x: 466,
    y: 514,
  },
  MENING: {
    x: 466,
    y: 472,
  },
  VARICELLA: {
    x: 466,
    y: 327,
  },
  ROTAVIRUS: {
    x: 466,
    y: 284,
  },
  PneumoPCV: {
    x: 466,
    y: 431,
  },
  POLIO: {
    x: 466,
    y: 536,
  },
  DTAP: {
    x: 466,
    y: 557,
  },
  MEASLES: {
    x: 466,
    y: 411,
  },
  MUMPS: {
    x: 466,
    y: 390,
  },
  RUBELLA: {
    x: 466,
    y: 369,
  },
  FLU: {
    x: 466,
    y: 242,
  },
  TDAP: {
    x: 466,
    y: 493,
  },
  HIB: {
    x: 466,
    y: 451,
  },
  HPV: {
    x: 466,
    y: 263,
  },
  MeningB: {
    x: 466,
    y: 204,
  },
  TD: {
    x: 466,
    y: 563,
  },
  TDBooster: {
    x: 466,
    y: 222,
  },
};
