import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  BillingReportQuery,
  BillingInvoicesQuery,
} from '@bluefox/graphql/billing';
import { BillingClaim } from '@bluefox/models/Billing';
import {
  Header,
  Segment,
  Menu,
  Table,
  Message,
  Placeholder,
  Dropdown,
} from 'semantic-ui-react';
import InvoicesListRow from './InvoicesListRow';
import { formatDateToMMDDYYYY } from '@bluefox/lib/formatters';

interface BillingInvoiceData {
  id: string;
  invoiceNo: string;
  date: Date;
  cptCodesCount: {
    aggregate: {
      count: number;
    };
  };
}

interface BillingInvoicesData {
  invoices: BillingInvoiceData[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface BillingReportData {
  billingReport: BillingClaim[];
  totalAmountCalculated: {
    aggregate: {
      sum: {
        totalAmount: number;
      };
    };
  };
}

interface InvoicesListProps {
  practiceIds: string[];
}

const InvoicesList = ({ practiceIds }: InvoicesListProps) => {
  const DROPDOWN_ENTRIES_PER_PAGE = 1500;

  const [page, setPage] = useState(1);

  const [invoiceId, setInvoiceId] = useState<string>();

  const { data: billingInvoicesData, loading: billingInvoicesLoading } =
    useQuery<BillingInvoicesData>(BillingInvoicesQuery, {
      variables: {
        criteria: {
          _and: {
            practiceId: { _in: practiceIds },
          },
        },
        limit: DROPDOWN_ENTRIES_PER_PAGE,
        offset: !!page ? DROPDOWN_ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      skip: !practiceIds,
    });

  const {
    data: invoicesData,
    loading: invoicesloading,
    error: invoicesError,
  } = useQuery<BillingReportData>(BillingReportQuery, {
    variables: {
      criteria: {
        cptCodes: {
          invoice: { _eq: invoiceId },
        },
      },
      criteriaCptCodes: {
        invoice: { _eq: invoiceId },
      },
    },
    skip: !invoiceId,
  });

  const invoiceOptions = useMemo(
    () =>
      billingInvoicesData?.invoices?.map((inv) => ({
        key: inv.id,
        text: `${formatDateToMMDDYYYY(inv.date as unknown as string)} - (${
          inv.cptCodesCount.aggregate.count
        } cpt codes)`,
        value: inv.id,
      })) || [],
    [billingInvoicesData?.invoices]
  );

  return (
    <>
      <Segment padded basic>
        <Header as="h2">Invoices</Header>
      </Segment>
      <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Menu.Item>
          <Dropdown
            loading={billingInvoicesLoading}
            style={{ minWidth: '25rem', marginRight: '1rem' }}
            placeholder={
              billingInvoicesData?.invoices.length
                ? 'Select an invoice'
                : 'There are no invoices for this practice yet'
            }
            value={invoiceId}
            fluid
            selection
            defaultValue={invoiceId}
            onChange={(_, { value }) => {
              setInvoiceId(value as string);
            }}
            options={invoiceOptions}
          />
        </Menu.Item>
      </Menu>
      {invoicesError ? (
        <Message error>{invoicesError.message}</Message>
      ) : (
        <Table selectable style={{ marginBottom: 0 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Practice</Table.HeaderCell>
              <Table.HeaderCell width={3}>Patient</Table.HeaderCell>
              <Table.HeaderCell>Insurance</Table.HeaderCell>
              <Table.HeaderCell width={2}>Date of Service</Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                CPT Codes
              </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                Paid Amount
              </Table.HeaderCell>
              <Table.HeaderCell>Reference</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {invoicesloading ? (
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <Segment basic>
                    <Placeholder fluid>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Segment>
                </Table.Cell>
              </Table.Row>
            ) : !!invoicesData?.billingReport.length && invoiceId ? (
              invoicesData?.billingReport.map((bc) => {
                return <InvoicesListRow key={bc.id} billingClaim={bc} />;
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <Message>
                    {!invoiceId
                      ? 'Please select an invoice from the invoices list.'
                      : 'No invoices found.'}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={7} textAlign="right">
                <b>{`Total: $${
                  invoicesData?.totalAmountCalculated.aggregate.sum
                    .totalAmount ?? 0
                }`}</b>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
    </>
  );
};

export default InvoicesList;
