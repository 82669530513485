import GS1 from '@bluefox/lib/gs1';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import GunScanner from '@bluefox/ui/GunScanner';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

export type FormValues = {
  lot?: string;
  fillLot?: boolean;
  expiration?: Date;
  fillExpiration?: boolean;
  timeZone?: string;
};

type Props = {
  open?: boolean;
  formValues?: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues | undefined>>;
  onClose?: () => void;
  onSubmit?: (formValues: FormValues) => void;
};

const InventoryOrderMissingDataModal = ({
  open,
  formValues,
  setFormValues,
  onClose,
  onSubmit,
}: Props) => {
  const handleonScan = (value: string) => {
    if (!value) return;
    const gs1 = new GS1(value);
    if (gs1) {
      setFormValues((prevValue) => ({
        ...prevValue,
        ...(formValues?.fillLot ? { lot: gs1.lot } : {}),
        ...(formValues?.fillExpiration ? { expiration: gs1.exp } : {}),
      }));
    }
  };

  return (
    <Modal closeIcon open={open} onClose={onClose}>
      <Modal.Header>Missing Inventory Order Data</Modal.Header>
      <Modal.Content>
        <p>
          <b>Please, fill the missing data:</b>
        </p>
        <p>
          Use the scanner:{' '}
          <GunScanner
            data-automation-id={`inventory-order-missing-scan-button`}
            component={Button}
            renderOn={
              <>
                <Icon name="stop" />
                Scanning
              </>
            }
            renderOff={
              <>
                <Icon name="qrcode" />
                Scan
              </>
            }
            onScan={handleonScan}
            icon
            labelPosition="left"
          />
        </p>
        <Form onSubmit={(e) => e.preventDefault()}>
          {formValues?.fillLot && (
            <Form.Input
              value={formValues?.lot}
              onChange={(_, { value }) =>
                setFormValues((prevValue) => ({
                  ...prevValue,
                  lot: value.toUpperCase(),
                }))
              }
              required
              fluid
              label="Lot #"
              placeholder="Lot #"
              data-automation-id="order-form-lot"
            />
          )}
          {formValues?.fillExpiration && (
            <Form.Field required>
              <label>Expiration</label>
              <DateTimePicker
                tz={formValues?.timeZone}
                selected={formValues?.expiration}
                onChange={(value) =>
                  setFormValues((prevValue) => ({
                    ...prevValue,
                    expiration: value as Date,
                  }))
                }
                required
              />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} secondary>
          Close
        </Button>
        <Button
          onClick={() => onSubmit?.(formValues as FormValues)}
          primary
          disabled={
            (formValues?.fillLot && !formValues?.lot) ||
            (formValues?.fillExpiration && !formValues?.expiration)
          }
        >
          Confirm Order Data & Reception
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default InventoryOrderMissingDataModal;
