import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { pixelToRem } from '@bluefox/constants';
import { BillingDashboardRecoveredTime } from '@graphql/billing';
import { Card, Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import ChartErrorMessage from '@bluefox/ui/Dashboard/ChartErrorMessage';
import { CardContent, CardHeader } from '@bluefox/ui/Dashboard/CommonStyles';

type Props = {
  practiceIds: string[];
  dateText?: string;
  month: number;
  year: number;
};

const TimeHourValueParagraph = styled.p`
  font-size: ${pixelToRem(50)}rem !important;
  font-weight: 700 !important;
  line-height: 1.25 !important;
  text-align: center;
  margin: 2rem 0 0 !important;
`;
const MinuteValueParagraph = styled.p`
  font-size: ${pixelToRem(40)}rem !important;
  font-weight: 700 !important;
  line-height: 1.25 !important;
  text-align: center;
  margin: 2rem 0 0 !important;
`;
const TimeLegendParagraph = styled.p`
  font-size: ${pixelToRem(18)}rem !important;
  font-weight: 500 !important;
  line-height: 1.25 !important;
  text-align: center;
  margin: 0 0 !important;
`;

const TimeParagraph = styled.p`
  font-size: ${pixelToRem(14)}rem !important;
  font-weight: 500 !important;
  line-height: 1.25 !important;
  text-align: center;
  margin: 2rem 0 0 !important;
`;

const TimeRestoredCard = ({ practiceIds, month, year, dateText }: Props) => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 1);

  const { data, loading, error } = useQuery<{
    recoveredTime: {
      aggregate: {
        count: number;
      };
    };
  }>(BillingDashboardRecoveredTime, {
    variables: {
      practiceIds: practiceIds,
      startDate: startDate.toISOString().slice(0, 10),
      endDate: endDate.toISOString().slice(0, 10),
    },
    skip: !practiceIds || month === undefined || year === undefined,
  });

  const renderData = useCallback(
    (vaccinationsCount: number, dateTextCb?: string) => {
      const totalMinutes = vaccinationsCount * 10.1;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.round(totalMinutes % 60);

      return (
        <>
          <TimeHourValueParagraph>{hours}</TimeHourValueParagraph>
          <TimeLegendParagraph>Hours</TimeLegendParagraph>
          <MinuteValueParagraph>{minutes}</MinuteValueParagraph>
          <TimeLegendParagraph>Minutes</TimeLegendParagraph>
          <TimeParagraph>
            By teaming up with Canid, you've saved{' '}
            <b>
              {hours} hours and {minutes} minutes
            </b>
            {dateTextCb && (
              <>
                {' '}
                this <b>{dateTextCb}</b>
              </>
            )}
            , giving you the freedom to prioritize high-quality patient care.
          </TimeParagraph>
        </>
      );
    },
    []
  );

  return (
    <Card fluid style={{ height: '100%' }}>
      <CardContent>
        <CardHeader>Time Restored</CardHeader>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        {error && (
          <ChartErrorMessage>
            <p>Error: {error?.message}</p>
          </ChartErrorMessage>
        )}
        {!loading &&
          !error &&
          !!data?.recoveredTime.aggregate &&
          renderData(data.recoveredTime.aggregate.count, dateText)}
      </CardContent>
    </Card>
  );
};

export default TimeRestoredCard;
