import { useEffect, useState } from 'react';
import axios from 'axios';
import { PDFPage, PDFDocument, rgb, PDFImage } from 'pdf-lib';
import moment from 'moment-timezone';
import { PatientData } from '@bluefox/models/Patient';
import { vaccinationsByDosesCoordsMap } from './vaccinationCoords';
import { Vaccination } from '@bluefox/models/Vaccination';
import { useApplicationState } from '@bluefox/contexts';
import PdfDrawerPreviewModal from '@utils/PdfDrawerPreviewModal';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters';

const sixDosesTypes = [
  'HepB',
  'DTAP',
  'TDAP',
  'HIB',
  'POLIO',
  'PneumoPCV',
  'MMR',
];

interface ColoradoCertificateOfImmunizationForChildrenPdfProps {
  patientData?: PatientData;
  vaccinations?: Vaccination[];
  pageBlurred?: boolean;
}

const ColoradoCertificateOfImmunizationForChildrenPdf = ({
  patientData,
  vaccinations,
  pageBlurred,
}: ColoradoCertificateOfImmunizationForChildrenPdfProps) => {
  const { isEmbedded } = useApplicationState();

  const [pdfURL, setPdfURL] = useState('');
  const [openPdfPreviewModal, setOpenPdfPreviewModal] = useState(false);
  const [error, setError] = useState<any>();

  //----------- DRAW PATIENT INFO -----------//
  const drawPatientInfo = (
    page: PDFPage,
    patientData: PatientData | undefined
  ) => {
    // Patient's firstName lastName
    page.drawText(
      patientData ? `${patientData.lastName}, ${patientData.firstName}` : '',
      {
        x: 108,
        y: 670,
        size: 9,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's birthdate
    page.drawText(
      patientData ? moment(patientData.birthdate).format('MM/DD/YYYY') : '',
      {
        x: 488,
        y: 670,
        size: 9,
        color: rgb(0, 0, 0),
      }
    );
  };

  //----------- DRAW VACCINATION DOSES -----------//
  const drawDoses = (page: PDFPage, coords: any, givenAt: string) => {
    page.drawText(formatDateToMMDDYYYYV2(givenAt), {
      x: coords.x,
      y: coords.y,
      size: 8,
      color: rgb(0, 0, 0),
    });
  };

  //----------- DRAW VACCINATION DOSES LOGIC -----------//
  const drawVaccinations = (
    page: PDFPage,
    vaccinations: Vaccination[] | undefined,
    patientData: PatientData | undefined
  ) => {
    const groupedVaccinationsByType = vaccinations?.reduce(
      (vaccinationsObject, vaccination) => {
        for (const key in vaccination.vaccine?.types) {
          const type = vaccination.vaccine?.types[parseInt(key)] || 0;

          if (!vaccinationsObject.groupedTypes[type]) {
            vaccinationsObject.groupedTypes[type] = [];
          }

          // If the type already has 6 vaccinations, remove the oldest one (the first element)
          if (vaccinationsObject.groupedTypes[type].length >= 7) {
            vaccinationsObject.groupedTypes[type].shift();
          }

          // If the type is included in the threeDosesTypes array and already has 3 vaccinations, remove the oldest one
          if (
            sixDosesTypes.some((item) => item === type) &&
            vaccinationsObject.groupedTypes[type].length >= 6
          ) {
            vaccinationsObject.groupedTypes[type].shift();
          }

          vaccinationsObject.groupedTypes[type].push(vaccination);
        }

        return vaccinationsObject;
      },
      { groupedTypes: {}, totalDoses: {} } as any
    );

    for (const type in groupedVaccinationsByType.groupedTypes) {
      const vaccinations = groupedVaccinationsByType.groupedTypes[type];

      vaccinations.forEach((vaccination: Vaccination, index: number) => {
        const givenAt = vaccination.givenAt;

        const vaccinationCoordsByTypes = vaccinationsByDosesCoordsMap[type];

        if (!vaccinationCoordsByTypes) return;

        const pdfCoords = vaccinationCoordsByTypes[index];

        drawDoses(
          page,
          pdfCoords,
          vaccination.historic
            ? givenAt.toString()
            : moment(givenAt).format('MM/DD/YY')
        );
      });
    }
  };

  const drawSignature = (pngSignature: PDFImage, page: PDFPage) => {
    if (pngSignature) {
      const { width, height } = pngSignature.scale(0.15);
      page.drawImage(pngSignature, {
        x: 380,
        y: 67,
        width: width,
        height: height,
      });
    } else {
      page.drawText('', {
        x: 380,
        y: 67,
        size: 10,
        color: rgb(0, 0, 0),
      });
    }
  };

  const fetchPdf = () => {
    const url = process.env
      .REACT_APP_COLORADO_CERTIFICATE_OF_IMMUNIZATION_FOR_CHILDREN_FORM_URL as string;
    return axios.get(url, { responseType: 'arraybuffer' });
  };

  const downloadModifiedPDF = async () => {
    const lastVaccinationArr = vaccinations ? vaccinations.slice(-1) : [];
    const lastVaccination = lastVaccinationArr[0];

    try {
      const response = await fetchPdf();

      const pdfBytes = response.data;
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const page = pdfDoc.getPages()[0];

      const signatureUrl = lastVaccination?.prescriber?.account
        .signatureUrl as string;

      if (signatureUrl) {
        const signatureResponse = await fetch(signatureUrl);
        const pngImageUint8ArraySignature = new Uint8Array(
          await signatureResponse.arrayBuffer()
        );

        const pngSignature = await pdfDoc.embedPng(pngImageUint8ArraySignature);

        drawSignature(pngSignature, page);
      }

      drawPatientInfo(page, patientData);
      drawVaccinations(page, vaccinations, patientData);

      const modifiedPdfBytes = await pdfDoc.save();

      const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
        type: 'application/pdf',
      });

      const downloadUrl = URL.createObjectURL(modifiedPdfBlob);

      // Creates a URL for te preview of the modified PDF
      return setPdfURL(downloadUrl);
    } catch (error) {
      setError(`Error while processing the PDF. ${error}`);
    }
  };

  useEffect(() => {
    fetchPdf();
  }, []);

  return (
    <PdfDrawerPreviewModal
      buttonLabel="Schools and child cares"
      onClickMenuButton={() => {
        setOpenPdfPreviewModal(true);
        downloadModifiedPDF();
      }}
      open={openPdfPreviewModal}
      onClose={() => {
        setOpenPdfPreviewModal(false);
      }}
      pdfURL={pdfURL}
      isEmbedded={isEmbedded}
      error={error}
      pageBlurred={pageBlurred}
      isDropdownItem
    />
  );
};

export default ColoradoCertificateOfImmunizationForChildrenPdf;
