import { useCallback, useState, useEffect, ReactElement } from 'react';
import { Button, Icon, Popup, Table, Message, Label } from 'semantic-ui-react';

import { StyledCardItem, StyledCardItemText } from './styles';

import {
  ScannableItem,
  useScannableItems,
} from '@bluefox/store/scannableItems';

import VaccinationSitePicker from '../VaccinationSitePicker';
import {
  Vaccination,
  VaccinationRoutes,
  VaccinationSites,
} from '@bluefox/models/Vaccination';
import VaccinationRoutePicker from '../VaccinationRoutePicker';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import moment from 'moment/moment';
import { DateFormats } from '@bluefox/models/Dates';
import { useMutation } from '@apollo/client';
import { VaccineTypeWarningValidationMutation } from '@bluefox/graphql/vaccines';
import { usePracticePatient } from '@bluefox/contexts/PracticePatientProvider';
import { useApplicationState } from '@bluefox/contexts';

type ScannedItemCardProps = {
  patientAge: number;
  item: ScannableItem;
  items: ScannableItem[];
  givenVaccinations: Vaccination[];
  showRemove: boolean;
  allowRemove: boolean;
  vfc: boolean;
  givenAt: Date;
  practiceTimezone: string;
  isAssociated?: boolean;
  warnings?: string[];
  messages?: { errors: ReactElement[]; warnings: ReactElement[] };
};

const calcExpiration = (givenAt: Date, exp: Date) => {
  const parsedGivenAt = moment(givenAt).startOf('day');
  const parsedExp = moment(exp).startOf('day');
  const diff = parsedExp.diff(parsedGivenAt, 'days');
  return diff <= 0;
};

const ScannedItemCard = ({ item, givenAt, ...props }: ScannedItemCardProps) => {
  const { _id, vaccine } = item;

  const { id: practicePatientId } = usePracticePatient()!;

  const { addWarning } = useScannableItems();

  const [vaccineTypeWarnings] = useMutation(
    VaccineTypeWarningValidationMutation
  );

  const setWarningTexts = (_id: string, texts: string[] | undefined) => {
    setWarnings(texts);
    addWarning(_id, texts);
  };

  const validateWarnings = useCallback(async () => {
    try {
      const {
        data: { vaccineTypeWarningsValidation },
      } = await vaccineTypeWarnings({
        variables: {
          practicePatientId,
          givenAt,
          vaccines: [
            {
              saleNdc: vaccine?.saleNdc,
              types: vaccine?.types,
            },
          ],
        },
      });

      if (vaccineTypeWarningsValidation.warning) {
        const [warningTexts] = vaccineTypeWarningsValidation.vaccineWarnings;
        setWarningTexts(_id, warningTexts.text);
      } else {
        setWarningTexts(_id, undefined);
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [_id, givenAt, practicePatientId]);

  useEffect(() => {
    if (!givenAt || !vaccine || !practicePatientId) return;
    validateWarnings();
  }, [givenAt, vaccine, practicePatientId, validateWarnings]);

  const [warnings, setWarnings] = useState<string[]>();

  return (
    <ScannedItemRow
      item={item}
      givenAt={givenAt}
      warnings={warnings}
      {...props}
    />
  );
};

const ScannedItemRow = ({
  item,
  items,
  vfc,
  givenAt,
  givenVaccinations = [],
  practiceTimezone,
  allowRemove,
  showRemove,
  patientAge,
  warnings: _warnings,
  isAssociated,
  messages: _messages,
}: ScannedItemCardProps) => {
  const { isEmbedded } = useApplicationState();

  const {
    changeVaccinationSite,
    changeVaccinationRoute,
    changeVaccinationVisDate,
    removeEntry,
  } = useScannableItems();

  const practicePatient = usePracticePatient();

  const { _id, inventory, vaccine, gs1 } = item;

  const lot = gs1?.getLot();
  const exp = gs1?.getExp();
  const fNDC = gs1?.getFormattedNdc10();

  const [isInventoryExpired, setIsInventoryExpired] = useState<boolean>(false);

  const conflictingInventory = inventory && inventory.length > 1;
  const conflictingVfc =
    inventory && inventory?.length === 1 && inventory[0].vfc !== vfc;

  const [visDate, setVisDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (!givenAt || !exp) {
      return;
    }
    setIsInventoryExpired(calcExpiration(givenAt, exp));
    setVisDate(new Date(givenAt));
  }, [givenAt, exp]);

  const handleOnVisDateChange = useCallback(
    (d) => {
      const vd = Array.isArray(d) ? d.at(0) : d;
      setVisDate(vd);
      changeVaccinationVisDate(_id, vd ?? null);
    },
    [_id, changeVaccinationVisDate]
  );

  const handleOnSiteChange = useCallback(
    (site: VaccinationSites) => changeVaccinationSite(_id, site),
    [_id, changeVaccinationSite]
  );

  const handleOnRouteChange = useCallback(
    (route: VaccinationRoutes) => changeVaccinationRoute(_id, route),
    [_id, changeVaccinationRoute]
  );

  const duplicatedVaccineScannedWith = items.find(
    (i) =>
      i._id !== item._id &&
      !!i.vaccine &&
      !!item.vaccine &&
      i.vaccine.id === item.vaccine.id &&
      i.vaccine.allowedAssociatedVaccinations === 0
  );

  const duplicatedTypeScannedWith = items.find(
    (i) =>
      i._id !== item._id &&
      i.vaccine?.allowedAssociatedVaccinations === 0 &&
      i.vaccine?.types?.some((t) => item.vaccine?.types?.includes(t))
  );

  const duplicatedVaccineGivenWith = givenVaccinations.find(
    (i) => !!i.vaccine && !!item.vaccine && i.vaccine.id === item.vaccine.id
  );

  const duplicatedTypeGivenWith = givenVaccinations.find(
    (i) => i.vaccine?.types?.some((t) => item.vaccine?.types?.includes(t))
  );

  const warnings: ReactElement[] = [];
  const errors: ReactElement[] = [];

  const givenDate = moment(givenAt).isSame(moment(), 'day')
    ? 'today'
    : `at ${moment(givenAt).format(DateFormats.DATE)}`;

  if (isInventoryExpired) {
    errors.push(
      <Message key={`inventory-expired-message-${_id}`} error>
        <Icon name="warning sign" />
        Vaccine has expired at the time of application. Please remove it or
        update the vaccination date to proceed.
      </Message>
    );
  }

  if (!!duplicatedVaccineScannedWith) {
    errors.push(
      <Message key={`has-duplicated-message-${_id}`} error>
        <Icon name="warning sign" />
        This Vaccine has already been scanned. Please remove it to avoid
        duplication.
      </Message>
    );
  } else if (!!duplicatedTypeScannedWith) {
    errors.push(
      <Message key={`has-duplicated-message-${_id}`} error>
        <Icon name="warning sign" />
        This Vaccine has the same component as{' '}
        {duplicatedTypeScannedWith.vaccine?.name}, also scanned.
      </Message>
    );
  }

  if (!!duplicatedVaccineGivenWith) {
    errors.push(
      <Message key={`has-duplicated-message-${_id}`} error>
        <Icon name="warning sign" />
        This Vaccine has already been given {givenDate}. Please remove it to
        avoid duplication.
      </Message>
    );
  } else if (!!duplicatedTypeGivenWith) {
    errors.push(
      <Message key={`has-duplicated-message-${_id}`} error>
        <Icon name="warning sign" />
        This Vaccine has the same component as&nbsp;
        {duplicatedTypeGivenWith.vaccine?.name}, given {givenDate}.
      </Message>
    );
  }

  if (errors.length === 0) {
    if (!item.vaccine) {
      warnings.push(
        <Message key={`no-vaccine-message-${_id}`} warning>
          <Icon name="exclamation circle" />
          This vaccine was not found in our registry. It might take 24hs to
          appear in the Vax History and Vax Dash.
        </Message>
      );
    }

    if (conflictingInventory) {
      warnings.push(
        <Message
          key={`conficting-inventory-message-${_id}`}
          warning
          style={{ alignItems: 'flex-start', display: 'flex' }}
        >
          <Icon name="exclamation circle" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div>
              There are <b>Private</b> and <b>VFC</b> inventories with the same
              Lot Number for this vaccine. Please choose wich inventory you want
              to use.
            </div>
            <div>
              {inventory?.map((i) => (
                <Button
                  key={`${i.id}-vfc-${i.vfc}`}
                  style={{ marginTop: '0.3rem' }}
                  color={!i.vfc ? 'orange' : 'teal'}
                  onClick={() => removeEntry(_id, i.id)}
                  disabled={!allowRemove}
                >
                  {!i.vfc ? 'Use VFC' : 'Use Private'}
                </Button>
              ))}
            </div>
          </div>
        </Message>
      );
    }

    if (conflictingVfc) {
      warnings.push(
        <Message key={`conficting-vfc-message-${_id}`} warning>
          <Icon name="exclamation circle" />
          The scanned vaccine doesn't match the patient's criteria (
          {practicePatient?.insurances[0]?.vfcEligible ? 'VFC' : 'Private'}).
          Please scan the appropriate vaccine or consider updating the patient's
          criteria if necessary.
        </Message>
      );
    }

    if (_warnings) {
      warnings.push(
        ..._warnings.map((w, index) => (
          <Message key={`vacc-warn-${index}`} warning>
            <Icon name="exclamation circle" />
            {w}
          </Message>
        ))
      );
    }
  }

  const associatedItemsArray = Object.values(item.associatedItems ?? {});

  const hasAssociated = associatedItemsArray.length > 0;

  return (
    <>
      <Table.Row>
        {!isAssociated ? (
          <Table.Cell
            warning={!!warnings.length && !hasAssociated}
            error={!!errors.length && !hasAssociated}
            style={hasAssociated ? { borderBottom: 'none' } : undefined}
          >
            <StyledCardItemText>{vaccine?.name || fNDC}</StyledCardItemText>
          </Table.Cell>
        ) : (
          <Table.Cell
            style={{ borderTop: 'none', borderBottom: 'none' }}
          ></Table.Cell>
        )}
        <Table.Cell warning={!!warnings.length} error={!!errors.length}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <StyledCardItemText
              style={{
                display: 'inline-flex',
                color: conflictingInventory ? 'red' : undefined,
              }}
            >
              {lot || '(unknown)'}&nbsp;
              {conflictingInventory ? (
                <Icon name="warning sign" color="red" />
              ) : (
                !!inventory &&
                inventory.length === 1 &&
                (isEmbedded ? (
                  <Popup
                    content={inventory.at(0)?.vfc ? 'VFC' : 'Private'}
                    trigger={
                      <Icon
                        name="syringe"
                        size="large"
                        color={inventory.at(0)?.vfc ? 'orange' : 'teal'}
                      />
                    }
                  />
                ) : (
                  <Label
                    color={inventory.at(0)?.vfc ? 'orange' : 'teal'}
                    horizontal
                  >
                    {inventory.at(0)?.vfc ? 'VFC' : 'Private'}
                  </Label>
                ))
              )}
            </StyledCardItemText>
          </div>
        </Table.Cell>
        <Table.Cell warning={!!warnings.length} error={!!errors.length}>
          <StyledCardItemText color={isInventoryExpired ? 'red' : ''}>
            {!!exp ? moment(exp).format(DateFormats.DATE) : '(undetermined)'}
            {isInventoryExpired && <Icon name="warning sign" color="red" />}
          </StyledCardItemText>
        </Table.Cell>
        <Table.Cell warning={!!warnings.length} error={!!errors.length}>
          <DateTimePicker
            tz={practiceTimezone}
            popperClassName="date-picker-popper"
            forFilter
            selected={visDate}
            onChange={handleOnVisDateChange}
            maxDate={new Date()}
            dateFormat={DateFormats.DATE}
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            dropdownMode="select"
            inputProps={{ style: { width: '7.8rem' } }}
            minimalistic={isEmbedded}
          />
        </Table.Cell>
        <Table.Cell warning={!!warnings.length} error={!!errors.length}>
          <VaccinationRoutePicker
            width="5rem"
            routes={vaccine?.routes?.length ? vaccine.routes : undefined}
            onChange={handleOnRouteChange}
          />
        </Table.Cell>
        <Table.Cell warning={!!warnings.length} error={!!errors.length}>
          <StyledCardItem>
            <VaccinationSitePicker
              disabled={
                item.vaccinationRoute &&
                [VaccinationRoutes.PO, VaccinationRoutes.IN].includes(
                  item.vaccinationRoute
                )
              }
              defaultValue={
                patientAge <= 3
                  ? VaccinationSites.rightThigh
                  : VaccinationSites.rightArm
              }
              onChange={handleOnSiteChange}
            />
          </StyledCardItem>
        </Table.Cell>
        <Table.Cell
          warning={!!warnings.length}
          error={!!errors.length}
          textAlign="right"
        >
          {showRemove && (
            <Popup
              trigger={
                <Button
                  basic
                  icon="trash alternate outline"
                  onClick={() => removeEntry(_id)}
                  disabled={!allowRemove}
                  size="tiny"
                />
              }
              content="Remove"
              size="tiny"
            />
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row key="vacc-messages">
        {(isAssociated || hasAssociated) && (
          <Table.Cell
            style={{
              borderTop: 'none',
              borderBottom: 'none',
              padding: errors.length + warnings.length === 0 ? 0 : undefined,
            }}
          ></Table.Cell>
        )}
        <Table.Cell
          warning={!!warnings.length}
          error={!!errors.length}
          style={{
            borderTop: 'none',
            borderBottom: 'none',
            padding: errors.length + warnings.length === 0 ? 0 : undefined,
          }}
          colSpan={isAssociated ? 6 : 7}
        >
          {!!errors.length ? (
            <>{errors}</>
          ) : (
            !!warnings.length && <>{warnings}</>
          )}
        </Table.Cell>
      </Table.Row>
      {!isAssociated &&
        hasAssociated &&
        associatedItemsArray.map((i, ix) => (
          <ScannedItemRow
            key={`${i._id}-${ix}`}
            item={i}
            items={items}
            givenVaccinations={givenVaccinations}
            vfc={vfc}
            givenAt={givenAt}
            practiceTimezone={practiceTimezone}
            allowRemove={allowRemove}
            showRemove={showRemove}
            patientAge={patientAge}
            isAssociated={true}
            warnings={_warnings}
          />
        ))}
    </>
  );
};

export default ScannedItemCard;
