import { useMutation, useQuery } from '@apollo/client';
import {
  GetBorrowingClosedAndRemovedTickets,
  UpdateBorrowingClosedAndRemovedTicketsWasReadByPp,
} from '@bluefox/graphql/tickets';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { Ticket } from '@bluefox/models/Tickets';
import React, { useState } from 'react';
import { toast } from 'react-semantic-toasts';
import {
  Accordion,
  Button,
  Confirm,
  Icon,
  Label,
  Popup,
  Table,
} from 'semantic-ui-react';

type BorrowingIssueTicketsAccordionProps = {
  practiceHandler: string;
};

const BorrowingIssueTicketsAccordion = ({
  practiceHandler,
}: BorrowingIssueTicketsAccordionProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [ticketsCount, setTicketsCount] = useState<number>(0);

  useQuery(GetBorrowingClosedAndRemovedTickets, {
    variables: {
      criteria: {
        status: { _eq: 'closed_and_removed' },
        wasReadByPp: { _eq: false },
        detail: {
          _cast: {
            String: {
              _ilike: whereLikeInput(practiceHandler),
            },
          },
        },
      },
    },
    onCompleted(data) {
      setTickets(data.tickets);
      setTicketsCount(data.aggregating.aggregate.count || 0);
    },
  });

  const [removeNotification] = useMutation(
    UpdateBorrowingClosedAndRemovedTicketsWasReadByPp,
    {
      refetchQueries: [GetBorrowingClosedAndRemovedTickets],
    }
  );

  const handleRemoveNotification = async (id: string) => {
    try {
      await removeNotification({
        variables: {
          id,
        },
      });
      toast({
        title: 'Notification removed successfully',
        type: 'success',
        time: 2000,
      });
    } catch (error) {
      toast({
        title: `There was a problem removing the notification. Error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
    setOpenConfirm(false);
  };

  return (
    <Accordion fluid styled>
      <Accordion.Title
        onClick={() => setShow((prevValue) => !prevValue)}
        active={show}
      >
        <Icon name="dropdown" />
        Inconsistencies removed after issue review ( {ticketsCount} )
      </Accordion.Title>
      <Accordion.Content active={show}>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Patient
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Insurance
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Date Of Service
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Inventory Used <hr /> NDC
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Vaccine <hr /> Lot
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tickets.map((ticket, index) => {
              const fields = ticket.detail.fields.filter(
                (field) =>
                  field.title !== 'VfcInconsistencyId' &&
                  field.title !== 'Handler'
              );
              const patient = fields.find((field) => field.title === 'Patient')
                ?.detail;
              const insurance = fields.find(
                (field) => field.title === 'Insurance'
              )?.detail;
              const memberId = fields.find(
                (field) => field.title === 'Member ID'
              )?.detail;
              const vfcEligible = fields.find(
                (field) => field.title === 'Eligibility'
              )?.detail;
              const dateOfService = fields.find(
                (field) => field.title === 'Date of Service'
              )?.detail;
              const inventoryUsed = fields.find(
                (field) => field.title === 'Inventory Used'
              )?.detail;
              const saleNdc = fields.find((field) => field.title === 'NDC')
                ?.detail;
              const vaccine = fields.find((field) => field.title === 'Vaccine')
                ?.detail;
              const lot = fields.find((field) => field.title === 'Lot')?.detail;
              return (
                <Table.Row key={index}>
                  <Table.Cell textAlign="center">{patient || '-'}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {insurance || '-'}{' '}
                    {vfcEligible !== undefined && (
                      <Label
                        content={vfcEligible ? 'VFC Eligible' : 'Private'}
                        size="tiny"
                        color={vfcEligible ? 'orange' : 'teal'}
                      />
                    )}
                    <Label
                      basic
                      size="tiny"
                      content={`Member ID: ${memberId || '-'}`}
                      style={{ marginTop: '0.2rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {dateOfService || '-'}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {inventoryUsed !== undefined && (
                      <Label
                        content={
                          inventoryUsed === 'VFC' ? 'VFC Eligible' : 'Private'
                        }
                        size="tiny"
                        color={inventoryUsed === 'VFC' ? 'orange' : 'teal'}
                        style={{ marginTop: '0.2rem' }}
                      />
                    )}
                    <hr />
                    {saleNdc || '-'}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {vaccine || '-'}
                    <hr />
                    {lot || '-'}
                  </Table.Cell>
                  <Table.Cell>
                    <Popup
                      content="Check to remove notification"
                      trigger={
                        <Button
                          onClick={() => setOpenConfirm(true)}
                          icon="check"
                          size="mini"
                          color="blue"
                        />
                      }
                    />
                    <Confirm
                      size="tiny"
                      content="Mark this notification as read and remove it."
                      open={openConfirm}
                      onCancel={() => setOpenConfirm(false)}
                      onConfirm={() =>
                        handleRemoveNotification(ticket.id || '')
                      }
                      confirmButton="Yes"
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Accordion.Content>
    </Accordion>
  );
};

export default BorrowingIssueTicketsAccordion;
