import { gql } from '@apollo/client';

export const GetInventoryOrders = gql`
  query GetInventoryOrders($where: inventory_orders_bool_exp!) {
    inventoryOrders: inventory_orders(where: $where, order_by: { date: desc }) {
      id
      orderNumber
      date
      estimatedShippingDate
      doses
      lot
      trackingNumber
      vaccine {
        name
        cvx
        manufacturer
        saleNdc
      }
      inventory {
        expiration
        lot
        vaccine {
          name
          aka
        }
      }
      orderNumber
      status
    }
  }
`;
