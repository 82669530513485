import { ReactNode, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSession } from '@bluefox/contexts/ApplicationState';
import { usePractice } from '@bluefox/contexts/Practice';
import { toast } from 'react-semantic-toasts';
import { Button, Form, Icon, Modal, Segment } from 'semantic-ui-react';
import { CreateTicketMutation } from '@bluefox/graphql/tickets';
import {
  Ticket,
  TicketDetailType,
  TicketStatusEnum,
  TicketTypes,
} from '@bluefox/models/Tickets';

interface BugReportModalType {
  children: ReactNode;
}
const BugReportModal = ({ children }: BugReportModalType) => {
  const { name, handler, id: practiceId } = usePractice();
  const session = useSession();
  const [open, setOpen] = useState(false);
  const [selectedBrowser, setSelectedBrowser] = useState<string>();
  const [errorSentence, setErrorSentence] = useState<string>();
  const [url, setUrl] = useState<string>();
  const [patient, setPatient] = useState<string>();
  const [error, setError] = useState<string>();
  const [createTicket] = useMutation(CreateTicketMutation);
  const handleChangeBrowser = (_e: any, { value }: any) => {
    setSelectedBrowser(value);
  };

  const handleSubmit = () => {
    const ticketDetail = {
      fields: [
        { title: 'Practice', detail: name },
        { title: 'Handler', detail: handler },
        { title: 'Error Short Description', detail: errorSentence },
        { title: 'Who encountered this error', detail: session.account?.email },
        { title: 'Error url', detail: url },
        { title: "Patient's record", detail: patient },
        { title: 'Error step-by-step', detail: error },
        { title: 'Browser', detail: selectedBrowser },
      ],
    };

    const ticket: Ticket = {
      practiceId,
      type: TicketTypes.BUG_REPORT,
      status: TicketStatusEnum.OPEN,
      detail: ticketDetail as TicketDetailType,
    };

    createTicket({ variables: { ticket } })
      .then(() => {
        toast({
          title: 'Your report has been successfully sent',
          type: 'success',
          time: 5000,
        });
        setOpen(false);
        setSelectedBrowser(undefined);
        setErrorSentence(undefined);
        setUrl(undefined);
        setPatient(undefined);
        setError(undefined);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Modal
      dimmer="blurring"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={children}
    >
      <Modal.Header>
        <Icon name="bug" />
        Canid Error Report
      </Modal.Header>
      <Modal.Content>
        Please use this form to report any errors or bugs you encounter while
        using the Canid platform.
        <Segment padded>
          <Form id="bug-report-form" onSubmit={handleSubmit}>
            <Form.TextArea
              required
              onChange={(e) => setErrorSentence(e.target?.value.toString())}
              label="Please describe the error in a single sentence."
              placeholder="Please describe the error in a single sentence."
            />
            <Form.Input
              onChange={(e) => setUrl(e.target?.value.toString())}
              fluid
              label="Please copy-paste the URL where you experienced the error."
              placeholder="http://canid.io"
            />
            <Form.Input
              onChange={(e) => setPatient(e.target?.value.toString())}
              fluid
              label="Did this occur within a specific patient's record? If so please enter
        the patient name below."
              placeholder="John Doe..."
            />
            <Form.TextArea
              onChange={(e) => setError(e.target?.value.toString())}
              required
              label="Please describe step-by-step what you were doing when you encountered the
      error."
              placeholder="1. Login ..."
            />
            <Form.Group inline required>
              <label>What web browser do you use?</label>
              <Form.Radio
                label="Google Chrome"
                value="chrome"
                checked={selectedBrowser === 'chrome'}
                onChange={handleChangeBrowser}
              />
              <Form.Radio
                label="Microsoft Edge"
                value="edge"
                checked={selectedBrowser === 'edge'}
                onChange={handleChangeBrowser}
              />
              <Form.Radio
                label="Safari"
                value="safari"
                checked={selectedBrowser === 'safari'}
                onChange={handleChangeBrowser}
              />
              <Form.Radio
                label="Mozilla Firefox"
                value="firefox"
                checked={selectedBrowser === 'firefox'}
                onChange={handleChangeBrowser}
              />
              <Form.Radio
                label="Other"
                value="other"
                checked={selectedBrowser === 'other'}
                onChange={handleChangeBrowser}
              />
            </Form.Group>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button primary type="submit" form="bug-report-form">
          Submit
        </Button>

        <Button secondary type="button" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BugReportModal;
