import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Dropdown, Header, Label } from 'semantic-ui-react';

import { UsersData, UserTitles } from '@bluefox/models/Users';
import { UsersQuery } from '@bluefox/graphql/users';

interface ProviderPickerProps {
  defaultValue?: string;
  onChange?: (providerId: string) => void;
  suspended?: boolean;
  placeholder?: string;
  prescriber?: boolean;
  isEmbedded?: boolean;
}

const ProviderPicker = ({
  defaultValue,
  onChange = () => {},
  suspended,
  placeholder,
  prescriber,
  isEmbedded,
}: ProviderPickerProps) => {
  const dropdownTitleOptions = prescriber
    ? [UserTitles.DO, UserTitles.MD, UserTitles.PA, UserTitles.NP]
    : [
        UserTitles.DO,
        UserTitles.MD,
        UserTitles.NP,
        UserTitles.Nurse,
        UserTitles.PA,
        UserTitles.MA,
      ];

  const { data, loading } = useQuery<UsersData>(UsersQuery, {
    variables: {
      criteria: {
        suspended: {
          _eq: false,
        },
        title: {
          _in: dropdownTitleOptions,
        },
        ...(suspended !== undefined ? { suspended } : {}),
      },
    },
  });

  const [selected, setSelected] = useState(defaultValue || '');

  useEffect(() => onChange(selected), [selected, onChange]);

  return (
    <Dropdown
      style={{
        minWidth: isEmbedded ? '0' : null,
        maxWidth: isEmbedded ? '13rem' : null,
      }}
      selection
      loading={loading}
      disabled={
        loading || !data?.practice_accounts || !data?.practice_accounts.length
      }
      onChange={(_, { value }) => setSelected(value as string)}
      value={selected}
      placeholder={
        !data?.practice_accounts ? 'No available Providers' : placeholder
      }
      clearable
      options={
        data?.practice_accounts.map((p) => {
          const text =
            p.title === UserTitles.Nurse
              ? `${p.title} ${p.account.firstName} ${p.account.lastName}`
              : `${p.account.firstName} ${p.account.lastName} ${p.title}`;
          return {
            key: p.id,
            value: p.id,
            text,
            content: (
              <Header size="tiny">
                <Header.Content>
                  {text}
                  <Header.Subheader>
                    {p.account.npi}
                    {p.suspended && (
                      <Label size="tiny" color="orange">
                        Suspended
                      </Label>
                    )}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            ),
          };
        }) || []
      }
    />
  );
};

export default ProviderPicker;
