import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Header, Loader, Message, Segment } from 'semantic-ui-react';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import styled from 'styled-components';
import { getColor } from './configTheme';
import { VaccinatedPatientsPerWeekQuery } from '@bluefox/graphql/vaccinations';
import {
  VaccinatedPatientsPerWeek,
  VaccinatedPatientsPerWeeks,
} from '@bluefox/models/Vaccination';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';

const ChartPatients = () => {
  const [chartData, setChartData] = useState<BarDatum[]>([]);

  const today = moment().add(1, 'day').format(DateFormats.DATE);
  const since = moment().subtract(1, 'months').format(DateFormats.DATE);
  const { data, loading, error } = useQuery<VaccinatedPatientsPerWeeks>(
    VaccinatedPatientsPerWeekQuery,
    {
      variables: {
        startingDate: since,
        endingDate: today,
      },
    }
  );

  useEffect(() => {
    if (!data?.vaccinatedPatientsPerWeeks.length) return;

    const vaccinatedPatientsPerWeeks: VaccinatedPatientsPerWeek[] =
      data?.vaccinatedPatientsPerWeeks;

    const _data: BarDatum[] = vaccinatedPatientsPerWeeks.map(
      (d: VaccinatedPatientsPerWeek, i) => {
        const _weeksAgo = moment(new Date()).isoWeek() - d.week;
        const index =
          _weeksAgo > 1
            ? `${_weeksAgo} weeks ago`
            : _weeksAgo === 1
            ? 'a week ago'
            : 'this week';

        return {
          id: index,
          patients: d.count,
        };
      }
    );

    setChartData(_data);
  }, [data?.vaccinatedPatientsPerWeeks]);

  return (
    <StyledSegement>
      <Header textAlign="center">Vaccinated Patients per week</Header>
      <StyledContent>
        {loading && <Loader />}
        {error && <Message error>{error?.message}</Message>}
        {chartData.length > 0 && (
          <ResponsiveBar
            data={chartData}
            keys={['patients']}
            colors={getColor}
            margin={{ top: 10, right: 10, bottom: 20, left: 30 }}
            padding={0.3}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.3]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisLeft={{
              format: (e) => Math.floor(e) === e && e,
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 2.5]],
            }}
            animate={true}
            role="application"
            ariaLabel="Inventory"
          />
        )}
      </StyledContent>
    </StyledSegement>
  );
};

export default ChartPatients;

const StyledSegement = styled(Segment)`
  height: 25rem;
`;
const StyledContent = styled.div`
  height: 19rem;
`;
