import FullLayout from '@components/FullLayout';
import React, { useState } from 'react';
import {
  Accordion,
  Container,
  Header,
  Icon,
  Menu,
  Segment,
  Tab,
  Table,
} from 'semantic-ui-react';
import { BorrowedVaccinesTable } from './BorrowedVaccinesTable';
import { usePractice } from '@bluefox/contexts/Practice';
import { BorrowingHistoryViewHandler } from './BorrowingHistoryViewHandler';
import CurrentCycle from './CurrentCycle';
import ReportPossibleInconsistency from './ReportPossibleInconsistency';
import BorrowingIssueTicketsAccordion from './BorrowingIssueTicketsAccordion';
import BorrowingRequestTicketsAccordion from './BorrowingRequestTicketsAccordion';

export const BorrowingScreen = () => {
  const practice = usePractice();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [arrayOfRedundantIds, setArrayOfRedundantIds] = useState<string[]>([]);

  const handleTabChange = (activeIndex: number) => {
    setActiveTabIndex(activeIndex);
  };

  const tabs = [
    {
      menuItem: <Menu.Item key="borrowingCases">Borrowing Cases</Menu.Item>,
      pane: (
        <Tab.Pane>
          <BorrowedVaccinesTable
            practiceId={practice.id}
            setArrayOfRedundantIds={setArrayOfRedundantIds}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: <Menu.Item key="borrowingSwap">Swap</Menu.Item>,
      pane: (
        <Tab.Pane>
          <CurrentCycle
            practiceId={practice.id}
            arrayOfRedundantIds={arrayOfRedundantIds}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: <Menu.Item key="borrowingHistory">History</Menu.Item>,
      pane: (
        <Tab.Pane>
          <BorrowingHistoryViewHandler practiceId={practice.id} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <FullLayout>
      <Container fluid>
        <Segment
          style={{ display: 'flex', justifyContent: 'space-between' }}
          padded
          basic
        >
          <Header as="h1">
            <Icon name="calendar" />
            Borrowing
          </Header>
          <ReportPossibleInconsistency />
        </Segment>
        {activeTabIndex === 0 && (
          <>
            <BorrowingRequestTicketsAccordion
              practiceHandler={practice.handler}
            />
            <br />
            <BorrowingIssueTicketsAccordion
              practiceHandler={practice.handler}
            />
            <br />
          </>
        )}
        <Tab
          panes={tabs}
          renderActiveOnly={false}
          activeIndex={activeTabIndex}
          onTabChange={(e, data) => handleTabChange(data.activeIndex as number)}
        />
      </Container>
    </FullLayout>
  );
};
