import { gql } from '@apollo/client';

export const InsurancesQuery = gql`
  query InsurancesQuery($practicePatientId: uuid!) {
    insurancesData: practice_patients_by_pk(id: $practicePatientId) {
      insurances(
        order_by: {
          primary: desc_nulls_last
          active: desc_nulls_last
          createdAt: desc
        }
      ) {
        id
        insuranceCompanyId
        company {
          name
        }
        companies: insuranceCompanyForBilling {
          name
        }
        verificationStatus
        verificationResult
        verifiedAt
        vfcEligible
        vfcCriteria
        memberId
        groupId
        relationship
        holderLastName
        holderFirstName
        holderDob
        pcp
        active
        primary
        patient {
          practice {
            id
          }
        }
      }
    }
  }
`;

export const PrimaryInsuranceQuery = gql`
  query PrimaryInsuranceQuery($practicePatientId: uuid!) {
    insurancesData: practice_patients_by_pk(id: $practicePatientId) {
      insurances(
        order_by: { createdAt: desc }
        where: { primary: { _eq: true } }
        limit: 1
      ) {
        id
        verificationStatus
        vfcEligible
        primary
      }
      isVfcConfirmed
    }
  }
`;

export const InsuranceByIdQuery = gql`
  query InsuranceByIdQuery($insuranceId: uuid!) {
    insurance: insurances_by_pk(id: $insuranceId) {
      id
      insuranceCompanyId
      company {
        name
      }
      companies: insuranceCompanyForBilling {
        name
      }
      verificationStatus
      verificationResult
      verifiedAt
      vfcEligible
      vfcCriteria
      memberId
      groupId
      relationship
      holderLastName
      holderFirstName
      holderDob
      pcp
      active
      primary
    }
  }
`;

export const InsuranceCompanyById = gql`
  query InsuranceCompanyById($id: uuid!) {
    insuranceCompanies: insurance_companies(where: { id: { _eq: $id } }) {
      id
      name
      cpid
      payerId
    }
  }
`;

export const InsuranceVerificationSubscription = gql`
  subscription InsuranceVerificationSubscription($insuranceId: uuid!) {
    insurance: insurances_by_pk(id: $insuranceId) {
      verificationStatus
      verificationResult
      verifiedAt
    }
  }
`;

export const VerifyInsuranceMutation = gql`
  mutation VerifyInsuranceMutation($insuranceId: uuid!) {
    VerifyInsurance(insuranceId: $insuranceId) {
      verificationStatus
      verificationResult
    }
  }
`;

export const InsertInsuranceMutation = (hasInsurance: boolean = true) => gql`
  mutation InsertInsuranceMutation(
    $practicePatientId: uuid!
    $vfcEligible: Boolean!
    $vfcCriteria: String
    $pcp: uuid
    $insuranceCompanyId: uuid
    $memberId: String
    $groupId: String
    $relationship: String
    $holderLastName: String
    $holderFirstName: String
    $holderDob: date
    $active: Boolean
    $oldPrimaryId: [uuid!]
    $isVfcConfirmed: Boolean = false
  ) {
    insurance: insert_insurances_one(
      object: {
        practicePatientId: $practicePatientId
        vfcEligible: $vfcEligible
        vfcCriteria: $vfcCriteria
        pcp: $pcp
        insuranceCompanyId: $insuranceCompanyId
        memberId: $memberId
        groupId: $groupId
        relationship: $relationship
        holderLastName: $holderLastName
        holderFirstName: $holderFirstName
        holderDob: $holderDob
        active: $active
        primary: true
      }
    ) {
      id
      insuranceCompanyId
      company {
        name
      }
      vfcEligible
      vfcCriteria
      pcp
      memberId
      groupId
      relationship
      holderLastName
      holderFirstName
      holderDob
      verificationStatus
      verifiedAt
      active
      primary
    }
    ${
      hasInsurance
        ? `secondaries: update_insurances(
      where: { id: { _in: $oldPrimaryId } }
      _set: { primary: false }
    ) {
      affected_rows
    }
    practicePatient: update_practice_patients_by_pk(
      pk_columns: { id: $practicePatientId }
      _set: { isVfcConfirmed: $isVfcConfirmed, isSelfPay: false }
    ) {
      id
      isVfcConfirmed
    }`
        : `practicePatient: update_practice_patients_by_pk( 
      pk_columns: { id: $practicePatientId }
      _set: {isVfcConfirmed: $isVfcConfirmed, isSelfPay: false }
    ) {
      id
      isVfcConfirmed
    }`
    }  
    
  }
`;

export const UpdateInsuranceMutation = gql`
  mutation UpdateInsuranceMutation(
    $id: uuid!
    $insuranceCompanyId: uuid
    $vfcEligible: Boolean!
    $vfcCriteria: String
    $pcp: uuid
    $memberId: String
    $groupId: String
    $relationship: String
    $holderLastName: String
    $holderFirstName: String
    $holderDob: date
    $active: Boolean
    $primary: Boolean
    $practicePatientId: uuid!
    $isSelfPay: Boolean = false
    $isVfcConfirmed: Boolean = false
  ) {
    insurance: update_insurances_by_pk(
      _set: {
        insuranceCompanyId: $insuranceCompanyId
        vfcEligible: $vfcEligible
        vfcCriteria: $vfcCriteria
        pcp: $pcp
        memberId: $memberId
        groupId: $groupId
        relationship: $relationship
        holderLastName: $holderLastName
        holderFirstName: $holderFirstName
        holderDob: $holderDob
        active: $active
        primary: $primary
      }
      pk_columns: { id: $id }
    ) {
      id
      insuranceCompanyId
      company {
        name
      }
      vfcEligible
      vfcCriteria
      pcp
      memberId
      groupId
      relationship
      holderLastName
      holderFirstName
      holderDob
      verificationStatus
      verifiedAt
      active
      primary
    }
    update_practice_patients_by_pk(
      pk_columns: { id: $practicePatientId }
      _set: { isSelfPay: $isSelfPay, isVfcConfirmed: $isVfcConfirmed }
    ) {
      id
    }
  }
`;

export const SetPrimaryInsuranceMutation = gql`
  mutation SetPrimaryInsuranceMutation(
    $practicePatientId: uuid!
    $primaryInsuranceId: uuid!
  ) {
    primary: update_insurances_by_pk(
      pk_columns: { id: $primaryInsuranceId }
      _set: { primary: true }
    ) {
      id
    }
    secondaries: update_insurances(
      where: {
        practicePatientId: { _eq: $practicePatientId }
        id: { _neq: $primaryInsuranceId }
      }
      _set: { primary: false }
    ) {
      affected_rows
    }
    update_practice_patients_by_pk(
      pk_columns: { id: $practicePatientId }
      _set: { isVfcConfirmed: false, isSelfPay: false }
    ) {
      id
    }
  }
`;

export const InsurancesByPracticeQuery = gql`
  query InsurancesByPracticeQuery(
    $practiceId: uuid!
    $status: insurance_verification_statuses_enum
    $criteria: [patients_bool_exp!]
    $limit: Int
    $offset: Int
  ) {
    aggregating: insurances_aggregate(
      where: {
        verificationStatus: { _eq: $status }
        patient: {
          practiceId: { _eq: $practiceId }
          patientData: { _or: $criteria }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    insurances(
      where: {
        verificationStatus: { _eq: $status }
        patient: {
          practiceId: { _eq: $practiceId }
          patientData: { _or: $criteria }
        }
      }
      limit: $limit
      offset: $offset
      order_by: { patient: { patientData: { fullName: asc } } }
    ) {
      id
      company {
        name
      }
      memberId
      groupId
      holderFirstName
      holderLastName
      patient {
        id
        practice {
          id
        }
        patientData {
          fullName
          birthdate
          address
        }
      }
      primary
      verificationResult
      verificationStatus
      relationship
    }
  }
`;

export const PrimaryInsurancesByPatientCount = gql`
  query PrimaryInsurancesByPatientCount($practicePatientId: uuid!) {
    insurances(
      where: {
        practicePatientId: { _eq: $practicePatientId }
        primary: { _eq: true }
      }
    ) {
      id
    }
    aggregating: insurances_aggregate(
      where: {
        practicePatientId: { _eq: $practicePatientId }
        active: { _eq: true }
        primary: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const VfcCriteriaQuery = gql`
  query VfcCriteriaQuery {
    vfcCriterias: vfc_criterias {
      value
    }
  }
`;

export const InsertInsurancesForPatientFromExtensionMutation = gql`
  mutation InsertInsurancesForPatientFromExtensionMutation(
    $practicePatientId: uuid!
    $vfcEligible: Boolean
    $vfcCriteria: String
  ) {
    insurances: insert_insurances(
      objects: {
        practicePatientId: $practicePatientId
        vfcEligible: $vfcEligible
        vfcCriteria: $vfcCriteria
        primary: true
        active: true
      }
    ) {
      affected_rows
    }
  }
`;

export const UpdatePrimaryInsuranceVfc = gql`
  mutation UpdatePrimaryInsuranceVfc(
    $insuranceId: uuid!
    $vfcEligible: Boolean
    $vfcCriteria: String
  ) {
    updateInsurance: update_insurances_by_pk(
      pk_columns: { id: $insuranceId }
      _set: { vfcEligible: $vfcEligible, vfcCriteria: $vfcCriteria }
    ) {
      id
      vfcEligible
      vfcCriteria
    }
  }
`;

export const SetPracticePatientAsSelfPaid = gql`
  mutation SetPracticePatientAsSelfPaid(
    $practicePatientId: uuid!
    $isVfcConfirmed: Boolean = false
  ) {
    update_practice_patients_by_pk(
      pk_columns: { id: $practicePatientId }
      _set: { isSelfPay: true, isVfcConfirmed: $isVfcConfirmed }
    ) {
      id
    }
    update_insurances(
      where: { practicePatientId: { _eq: $practicePatientId } }
      _set: { active: false, primary: false }
    ) {
      affected_rows
    }
  }
`;
