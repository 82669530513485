import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const CelebrationIcon = () => (
  <StyledIcon name="thumbs up" size="huge" color="grey" />
);
export default CelebrationIcon;

const StyledIcon = styled(Icon)`
  align-self: flex-end;
  height: 200px;
  width: 200px;
  margin: 60% auto 40% !important;
`;
