import { gql } from '@apollo/client';

export const ClaimsQuery = gql`
  query ClaimsQuery(
    $criteria: [billing_claims_bool_exp!]
    $limit: Int
    $offset: Int
  ) {
    aggregating: billing_claims_aggregate(
      where: { status: { _eq: submitted }, _and: $criteria }
    ) {
      aggregate {
        count
      }
    }
    claims: billing_claims(
      where: { status: { _eq: submitted }, _and: $criteria }
      limit: $limit
      offset: $offset
      order_by: { updatedAt: desc, controlNumber: desc }
    ) {
      id
      updatedAt
      practicePatient {
        patientData {
          firstName
          lastName
          birthdate
          address
        }
        insurances(where: { primary: { _eq: true } }) {
          memberId
          company {
            id
            name
          }
        }
      }
      vaccinations {
        givenAt
        vaccine {
          id
          types
          name
          manufacturer
          inventoryCptCodes
          administrationCptCodesWd
          administrationCptCodesWod
        }
      }
      cptCodes {
        id
        cptCode
        units
      }
    }
    administrationCptCodes: procedure_amounts(
      where: { type: { _eq: "Administration" } }
    ) {
      cpt
    }
  }
`;

export const BillingClaimsForPracticePortalQuery = gql`
  query BillingClaimsForPracticePortalQuery(
    $criteria: billing_claims_bool_exp
    $limit: Int!
    $offset: Int!
  ) {
    aggregating: billing_claims_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
    billingClaims: billing_claims(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      id
      createdAt
      practicePatient {
        id
        patientData {
          firstName
          lastName
          fullName
          birthdate
        }
        insurances(
          where: { primary: { _eq: true }, active: { _eq: true } }
          limit: 1
        ) {
          company {
            name
          }
          memberId
          vfcEligible
        }
      }
      updates(
        order_by: { createdAt: desc }
        where: { metadata: { _has_key: "insurance" } }
        limit: 1
      ) {
        metadata
        createdAt
      }
      vaccinations {
        id
        givenAt
        vaccine {
          name
        }
      }
      cptCodes {
        cptCode
        claimAmount
        paidAmount
      }
      totalClaimAmount
      status
      dismissedReason
      vaccinationsCptCodes
      insuranceVfcEligible
      insuranceCompanyName
      revisionStatus
    }
  }
`;

export const BillingClaimsForPracticePortalView = gql`
  query BillingClaimsForPracticePortalView(
    $criteria: billing_claims_pp_materialized_vw_bool_exp
    $limit: Int
    $offset: Int
  ) {
    billingClaims: billing_claims_pp_materialized_vw(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { givenAt: desc }
    ) {
      claimId
      totalClaimAmount
      status
      note
      practiceId
      practiceName
      firstName
      lastName
      birthdate
      fullName
      insuranceName
      insuranceMemberId
      insuranceVfcEligible
      insuranceVfcCriteria
      inventoryVfcCount
      inventoryPrivateCount
      givenAt
      vaccinationsCptCodes
      revisionStatus
      dismissedReason
      vaccines
    }
  }
`;

export const BillingClaimsCount = gql`
  query BillingClaimsCount(
    $criteria: billing_claims_pp_materialized_vw_bool_exp
  ) {
    aggregating: billing_claims_pp_materialized_vw_aggregate(
      where: $criteria
      distinct_on: claimId
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const BillingDashboardTotalRecoveredAmount = gql`
  query BillingDashboardTotalRecoveredAmount(
    $practiceIds: [uuid!]!
    $month: float8!
    $year: float8!
  ) {
    billing_dashboard_total_recovered_amount(
      where: {
        practiceId: { _in: $practiceIds }
        month: { _eq: $month }
        year: { _eq: $year }
      }
    ) {
      administrationAmount
      inventoryAmount
      totalAmount
    }
  }
`;

export const BillingDashboardTotalRecoveredAmountAdministration = gql`
  query BillingDashboardTotalRecoveredAmountAdministration(
    $practiceIds: [uuid!]!
    $month: float8!
    $year: float8!
  ) {
    billing_dashboard_total_recovered_amount_administration(
      where: {
        practiceId: { _in: $practiceIds }
        month: { _eq: $month }
        year: { _eq: $year }
      }
    ) {
      administrationPrivateAmount
      administrationVfcAmount
      totalAmount
    }
  }
`;

export const BillingDashboardClaimsByStatus = gql`
  query BillingDashboardClaimsByStatus(
    $practiceIds: [uuid!]!
    $year: float8!
    $month: float8!
    $vfcEligible: [Boolean!]!
  ) {
    billing_dashboard_claims_by_status(
      where: {
        practiceId: { _in: $practiceIds }
        month: { _eq: $month }
        year: { _eq: $year }
        claimVfcEligible: { _in: $vfcEligible }
      }
    ) {
      practiceId
      claimVfcEligible
      claimsCount
      status
    }
  }
`;

export const BillingDashboardRecoveredTime = gql`
  query BillingDashboardRecoveredTime(
    $practiceIds: [uuid!]!
    $startDate: date!
    $endDate: date!
  ) {
    recoveredTime: vaccinations_aggregate(
      where: {
        practiceId: { _in: $practiceIds }
        historic: { _eq: false }
        claim: { status: { _nin: [removed, unclaimed, pending] } }
        givenAtDate: { _gte: $startDate, _lt: $endDate }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ShowVaxListToBillerQuery = gql`
  query ShowVaxListToBillerQuery($practiceId: uuid!) {
    practices(where: { id: { _eq: $practiceId } }) {
      showVaxListToBiller: settings(path: "$.billing.showVaxListToBiller")
    }
  }
`;
