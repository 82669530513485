import FullLayout from '@components/FullLayout';
import { Header, Icon, Segment } from 'semantic-ui-react';
import NotificationList from '@components/notifications/NotificationList';
import { useQuery } from '@apollo/client';
import { SettingsData } from '@bluefox/models/Practice';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';
import { usePractice } from '@bluefox/contexts';

type Props = {};
// should search for new pending notifications
const Notifications = (props: Props) => {
  const practice = usePractice();
  const { data: practiceSettings } = useQuery<SettingsData>(
    PracticeSettingsQuery,
    {
      variables: {
        id: practice.id,
      },
    }
  );

  return (
    <FullLayout>
      <Segment padded basic>
        <Header as="h1">
          <Icon name="bell" />
          Notifications
        </Header>
      </Segment>
      {practiceSettings?.practice.settings.notifications?.enabled && (
        <NotificationList />
      )}
    </FullLayout>
  );
};

export default Notifications;
