import { Vaccination } from '@bluefox/models/Vaccination';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';

export const fillWithZero = (value: number) => `0${value}`.slice(-2);

export const parseToMMDDYYYY = (dateStr: string) => {
  const [year, month, day] = dateStr.split('T')[0].split('-');
  return `${month}-${day}-${year}`;
};

export const showImmunizationDate = (
  vaccination: Vaccination,
  timezone: string
): string => {
  if (!vaccination.historic) {
    return moment.tz(vaccination.givenAt, timezone).format(DateFormats.DATE);
  }
  return parseToMMDDYYYY(String(vaccination.givenAt));
};
