import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Dimmer, Loader } from 'semantic-ui-react';
import {
  PracticePatientByMrnQuery,
  CreatePatientMutation,
  CreatePracticePatientMutation,
} from '@bluefox/graphql/patients';
import { useMutation, useQuery } from '@apollo/client';
import { usePractice } from '@bluefox/contexts';
import { HumanizeOptions, humanizeText } from '@bluefox/lib/humanize';
import { toast } from 'react-semantic-toasts';
import { Address } from '@bluefox/models/Address';

interface PatientData {
  firstname: string;
  lastname: string;
  dob: string;
  sex: Sex;
  address?: Address;
}

type Sex = 'Male' | 'Female' | 'Unknown';

const PatientByMrn = () => {
  const { mrn } = useParams<{ mrn: string }>();
  const history = useHistory();

  const parsePatientData = (): PatientData | null => {
    const url = new URL(window.location.href.replace(/\?inExtension=true/, ''));

    let firstname = url.searchParams.get('fname')
      ? decodeURIComponent(url.searchParams.get('fname') as string)
      : null;
    let lastname = url.searchParams.get('lname')
      ? decodeURIComponent(url.searchParams.get('lname') as string)
      : null;
    const dob = url.searchParams.get('dob')
      ? String(url.searchParams.get('dob'))
      : null;
    let sex = url.searchParams.get('sex') as Sex;

    const humanizeOpts: HumanizeOptions = {
      capitalize: 'first',
      delimiter: ' ',
    };

    firstname = firstname ? humanizeText(firstname, humanizeOpts) : firstname;
    lastname = lastname ? humanizeText(lastname, humanizeOpts) : lastname;

    const hasAddress = decodeURIComponent(
      url.searchParams.get('city') as string
    );

    let address: Address | undefined;

    if (hasAddress !== 'null') {
      address = {
        city: decodeURIComponent(url.searchParams.get('city') as string),
        state: decodeURIComponent(url.searchParams.get('state') as string),
        zip: decodeURIComponent(url.searchParams.get('zip') as string),
        street: decodeURIComponent(url.searchParams.get('street') as string),
      };
    }

    return dob && firstname && lastname && sex
      ? { firstname, lastname, dob, sex, address }
      : null;
  };

  const practice = usePractice();

  const { data } = useQuery(PracticePatientByMrnQuery, {
    variables: {
      mrn,
      practiceIds: practice.organization?.practices.map((p) => p.id),
    },
  });

  const [createPatient] = useMutation(CreatePatientMutation);
  const [createPracticePatient] = useMutation(CreatePracticePatientMutation);

  useEffect(() => {
    if (!data) return;
    if (data.practicePatient.length === 0) {
      const parsedPatientData = parsePatientData();
      if (!parsedPatientData) {
        history.push(`/${practice.handler}/patients/_new-basic/${mrn}`);
        toast({
          title: 'Patient does not exist in Canid',
          type: 'warning',
          time: 3000,
        });
        return;
      }

      const newPatientParams = {
        ...parsedPatientData,
      };

      createPatient({ variables: newPatientParams })
        .then((response) => {
          const newPracticePatientParams = {
            mrn,
            patientId: response.data.createdPatient.id,
            practiceId: practice.id,
          };
          return createPracticePatient({ variables: newPracticePatientParams });
        })
        .then((response) => {
          history.push(
            `/${practice.handler}/patients/${response.data.createdPracticePatient.id}`
          );
        })
        .catch((err) => console.log('ERR', JSON.stringify(err)));
    } else {
      const [{ id }] = data.practicePatient;

      history.push(`/${practice.handler}/patients/${id}`);
    }
  }, [data]);

  return (
    <Container>
      <Dimmer active inverted>
        <Loader inverted>💉 Loading</Loader>
      </Dimmer>
    </Container>
  );
};

export default PatientByMrn;
