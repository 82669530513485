import { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import AdministrativeFeesCard from '@components/billing/dashboard/AdministrativeFeesCard';
import TotalClaimsCard from '@components/billing/dashboard/TotalClaimsCard';
import TotalRecoveredSection from '@components/billing/dashboard/TotalRecoveredCard';
import TimeRestoredCard from '@components/billing/dashboard/TimeRestoredCard';
import MonthYearDropdown from '@bluefox/ui/MonthYearDropdown';
import { buildMonthYearDropDownItem } from '@bluefox/lib/dates';

type Props = {
  practiceIds: string[];
};

const Dashboard = ({ practiceIds }: Props) => {
  const today = new Date();

  const [selectedMonthYear, setSelectedMonthYear] = useState<{
    month: number;
    year: number;
  }>({
    month: today.getMonth() + 1,
    year: today.getFullYear(),
  });

  const dateText =
    selectedMonthYear.month && selectedMonthYear.year
      ? buildMonthYearDropDownItem(
          selectedMonthYear.month as number,
          selectedMonthYear.year as number
        )?.text
      : undefined;

  return (
    <>
      <MonthYearDropdown
        style={{ textAlign: 'right' }}
        onChange={({ month, year }) => {
          setSelectedMonthYear({ month, year });
        }}
      />
      <Grid stackable columns={2} style={{ marginTop: '1rem' }}>
        <Grid.Row>
          <Grid.Column width={12}>
            <AdministrativeFeesCard
              practiceIds={practiceIds}
              month={selectedMonthYear.month}
              year={selectedMonthYear.year}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <TimeRestoredCard
              practiceIds={practiceIds}
              month={selectedMonthYear.month}
              year={selectedMonthYear.year}
              dateText={dateText}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <TotalRecoveredSection
              practiceIds={practiceIds}
              month={selectedMonthYear.month}
              year={selectedMonthYear.year}
            />
          </Grid.Column>
          <Grid.Column>
            <TotalClaimsCard
              practiceIds={practiceIds}
              month={selectedMonthYear.month}
              year={selectedMonthYear.year}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Dashboard;
