import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSession } from '@bluefox/contexts/ApplicationState';
import { ChangeUserPasswordMutation } from '@graphql/users';
import { toast } from 'react-semantic-toasts';
import { Button, Modal, Form } from 'semantic-ui-react';

interface ChangePasswordProps {
  children: React.ReactNode;
}

const ChangePasswordModal = ({ children }: ChangePasswordProps) => {
  const session = useSession();

  const [password, setPassword] = useState<string>('');
  const [open, setOpen] = useState(false);

  const [changeUserPasswordMutation, {}] = useMutation(
    ChangeUserPasswordMutation
  );

  const handleSubmitChangePassword = () => {
    changeUserPasswordMutation({
      variables: {
        password: password,
      },
    })
      .then((r) => {
        toast({
          title: `Password has been changed successfully`,
          type: 'success',
          time: 1000,
        });
        setPassword('');
        setOpen(false);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleOnClose = () => {
    setPassword('');
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      onOpen={() => setOpen(true)}
      onClose={handleOnClose}
      open={open}
      size="tiny"
      trigger={children}
    >
      <Modal.Header>
        Change {session.account?.firstName} {session.account?.lastName}'s
        Password
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmitChangePassword}>
          <Form.Input
            type="password"
            className="passwordInputContainer"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fluid
            label="Password"
            placeholder="password"
            required
          />
          <Modal.Actions>
            <Button
              style={{ marginLeft: '0' }}
              onClick={handleOnClose}
              type="button"
            >
              Cancel
            </Button>
            <Button primary type="submit" content="Save" icon="save" />
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ChangePasswordModal;
