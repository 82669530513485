import { AppointmentStatus } from '@bluefox/models/Appointment';
import { useEffect, useRef, useState } from 'react';
import { Dropdown, StrictDropdownProps } from 'semantic-ui-react';

interface AppointmentStatusPickerProps extends StrictDropdownProps {
  defaultValue?: AppointmentStatus | AppointmentStatus[];
  onSelectionChange?: (value: string | string[]) => void;
  prefixText?: string;
}

const AppointmentStatusPicker = ({
  multiple,
  defaultValue = multiple ? [] : undefined,
  placeholder,
  prefixText,
  onSelectionChange = () => {},
  icon,
  ...props
}: AppointmentStatusPickerProps) => {
  const [selected, setSelected] = useState(defaultValue);
  const previousValue = useRef(selected);

  useEffect(() => {
    if (previousValue.current !== selected) {
      previousValue.current = selected;
      onSelectionChange(selected ?? '');
    }
  }, [multiple, onSelectionChange, selected]);

  return (
    <Dropdown
      data-automation-id={`appointments-status`}
      selection
      options={Object.keys(AppointmentStatus).map((s) => ({
        key: s,
        text: s,
        value: s,
      }))}
      onChange={(_, { value }) => setSelected(value as AppointmentStatus[])}
      value={selected}
      placeholder={placeholder}
      multiple={multiple}
      className={icon ? 'icon' : undefined}
      {...props}
    />
  );
};

export default AppointmentStatusPicker;
