import { ReactNode, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

import {
  Button,
  Form,
  Header,
  Icon,
  Label,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import DateTimePicker from '@bluefox/ui/DateTimePicker';
import VaccinePicker from '@bluefox/ui/VaccinePicker';
import { Vaccine } from '@bluefox/models/Vaccine';
import { useApplicationState, usePractice } from '@bluefox/contexts';
import GunScanner from '@bluefox/ui/GunScanner';
import GS1 from '@bluefox/lib/gs1';
import { VaccineByNDC10Query } from '@bluefox/graphql/vaccines';
import { InventoryStatus } from '@bluefox/models/Inventory';
import { InsertInventoryOrderWithInventoryMutation } from '@graphql/inventory';

type RequestVaccinesModalProps = {
  children: ReactNode;
  onRefetchInventory: () => void;
};

interface VaccinesData {
  vaccines: Vaccine[];
}

const RequestVaccinesModal = ({
  children,
  onRefetchInventory,
}: RequestVaccinesModalProps) => {
  const { id: practiceId } = usePractice();
  const { session } = useApplicationState();

  const [open, setOpen] = useState<boolean>(false);
  const [expiration, setExpiration] = useState<Date | undefined>();
  const [doses, setDoses] = useState<number>();
  const [vfc, setVfc] = useState<boolean>(false);
  const [lot, setLot] = useState<string>();
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>();

  const [scannedNdc, setScannedNdc] = useState<string>();

  const [saveInventory] = useMutation(
    InsertInventoryOrderWithInventoryMutation
  );

  useQuery<VaccinesData>(VaccineByNDC10Query, {
    variables: {
      ndc: scannedNdc,
    },
    skip: !scannedNdc,
    onCompleted: (data) => {
      setSelectedVaccine(data?.vaccines.length ? data?.vaccines[0] : undefined);
    },
  });

  const handleChangeVaccine = (vaccine: Vaccine) => {
    if (!vaccine) return;
    setSelectedVaccine(vaccine);
  };

  const handleOnScan = (value: string) => {
    if (!value) return;
    const gs1NewScan = new GS1(value);

    setScannedNdc(gs1NewScan.getNdc());
    setLot(gs1NewScan.getLot());
    setExpiration(gs1NewScan.getExp());
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const now = new Date();

    const newOrder = {
      practiceId: practiceId,
      amount: 0,
      date: now,
      doses,
      inventoryExpiration: expiration,
      lot,
      orderNumber: 'created_by_practice',
      statusInventory: InventoryStatus.received,
      statusOrder: 'closed',
      statusLog: [
        {
          status: 'closed',
          date: now,
          practiceAccount: session?.account,
        },
      ],
      vaccineId: selectedVaccine?.id,
      vfc,
    };

    try {
      await saveInventory({
        variables: {
          ...newOrder,
        },
      });

      toast({
        title: 'Inventory successfully created',
        type: 'success',
        time: 5000,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    } finally {
      clearForm();
    }
  };

  const handleDoses = (value: any) => {
    setDoses(value);
  };

  const clearForm = () => {
    setDoses(0);
    setLot(undefined);
    setScannedNdc(undefined);
    setLot('');
    setVfc(false);
    setExpiration(undefined);
    setSelectedVaccine(undefined);

    onRefetchInventory();
    setOpen(false);
  };

  function allowOnlyPositiveIntegers(event: React.KeyboardEvent) {
    const regex = /[^0-9]/gi;
    if (regex.test(event.key)) {
      event.preventDefault();
    }
  }

  return (
    <Modal
      dimmer="blurring"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={clearForm}
      closeOnEscape={false}
      trigger={children}
      data-automation-id="inventory-request-vaccines"
    >
      <Modal.Header>
        <Icon.Group>
          <Icon name="box" />
          <Icon
            name="syringe"
            size="mini"
            inverted
            style={{ marginTop: '0.3rem' }}
          />
        </Icon.Group>
        Add Vaccines Form
      </Modal.Header>
      <Modal.Content>
        <Segment padded>
          <Segment
            basic
            style={{
              padding: '0',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '1rem',
            }}
          >
            <GunScanner
              data-automation-id={`inventory-scan-button`}
              component={Button}
              renderOn={
                <>
                  <Icon name="stop" />
                  Scanning
                </>
              }
              renderOff={
                <>
                  <Icon name="qrcode" />
                  Search
                </>
              }
              onScan={handleOnScan}
              icon
              labelPosition="left"
            />
            <VaccinePicker
              dropdownProps={{
                fluid: true,
                placeholder: 'Search Vaccine',
              }}
              onChange={({ vaccine }) =>
                handleChangeVaccine(vaccine as Vaccine)
              }
              value={selectedVaccine?.id}
            />
          </Segment>
          {!!selectedVaccine && (
            <Segment color="olive">
              <Header as="h4">
                <Header.Content>
                  {selectedVaccine.name}
                  {selectedVaccine.types?.map((t) => (
                    <Label key={t} color="orange" size="mini">
                      {t}
                    </Label>
                  ))}
                </Header.Content>
                <Header.Subheader>
                  {selectedVaccine.manufacturer} - {selectedVaccine.saleNdc}
                </Header.Subheader>
              </Header>
            </Segment>
          )}
          <Form id="request-vaccines-form" onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                label="Lot number"
                placeholder="XXXXXX"
                value={lot || ''}
                onChange={(_, { value }) => setLot(value.toUpperCase())}
                required
              />
              <Form.Field required>
                <label>Expiration</label>
                <DateTimePicker
                  selected={expiration}
                  onChange={(value) => setExpiration(value as Date)}
                  required
                  minDate={new Date()}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Doses"
                placeholder="0"
                type="number"
                value={doses ?? ''}
                onKeyPress={(event: React.KeyboardEvent) =>
                  allowOnlyPositiveIntegers(event)
                }
                onChange={(_, { value }) => handleDoses(value)}
                required
              />
              <Form.Field>
                <label>VFC</label>
                <Form.Checkbox
                  checked={vfc}
                  label="Public funded"
                  onChange={(_, { checked }) => setVfc(checked ?? false)}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="submit"
          content="Add"
          form="request-vaccines-form"
          primary
          style={{ float: 'right' }}
          disabled={!selectedVaccine || !doses}
        />
        <Button
          type="button"
          content="Close"
          icon="close"
          onClick={clearForm}
          style={{ float: 'right', marginBottom: '1rem' }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default RequestVaccinesModal;
