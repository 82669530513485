import { ReactNode, useEffect } from 'react';

interface PageBlurrerProps {
  children: ReactNode;
  blurred: boolean;
}

const PageBlurrer = ({ children, blurred }: PageBlurrerProps) => {
  useEffect(() => {
    if (blurred) {
      document.body.classList.add('dimmed', 'dimmable', 'blurring');
    } else {
      document.body.classList.remove('dimmed', 'dimmable', 'blurring');
    }
    // hack to blur modals (which are rendered on Portals, outside of children)
    Array.from(document.getElementsByClassName('modals')).forEach((e) => {
      blurred
        ? e.classList.add('dimmed', 'dimmable', 'blurring')
        : e.classList.remove('dimmed', 'dimmable', 'blurring');
    });
  }, [blurred]);

  return <>{children}</>;
};

export default PageBlurrer;
