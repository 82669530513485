import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { useLSHandler, usePractice, useSession } from '@bluefox/contexts';
import { useAuth } from '@bluefox/contexts/Auth';
import { Modal, Icon } from 'semantic-ui-react';
import UserCircularIcon from './UserCircularIcon';
import SignInForm, { SignInFormValuesProps } from './SignInForm';
import { UserForLocalStorage } from '@bluefox/models/Users';

const ADMIN_ROLE = 'practiceManager';
const BILLER_ROLE = 'biller';
const USER_ROLE = 'user';
const DEFAULT_INITIAL_PAGE = [
  { role: ADMIN_ROLE, page: 'patients' },
  { role: BILLER_ROLE, page: 'billing' },
  { role: USER_ROLE, page: 'patients' },
];

interface SelectedUser {
  email: string;
  firstName: string;
  lastName: string;
}

interface UsersToLoginModalProps {
  open: boolean;
  onClose?: () => void;
  closeIcon?: boolean;
}

const UsersToLoginModal = ({
  open,
  onClose,
  closeIcon,
}: UsersToLoginModalProps) => {
  const lsHandler = useLSHandler();
  const history = useHistory();
  const practice = usePractice();
  const session = useSession();

  const { actions } = useAuth();
  const [signIn, { loading, error, data }] = actions.signIn;

  const [openSignInForm, setOpenSignInForm] = useState(false);
  const [parsedLsData, setParsedLsData] = useState<UserForLocalStorage[]>([]);
  const [loginInfo, setLoginInfo] = useState<SignInFormValuesProps>(
    {} as SignInFormValuesProps
  );

  const handleChangeUser = (user: SelectedUser) => {
    if (user.email !== session.account?.email) {
      setLoginInfo({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      } as SignInFormValuesProps);
      setOpenSignInForm(true);
    }
  };

  const handleChangeToNewUser = () => {
    setLoginInfo({} as SignInFormValuesProps);
    setOpenSignInForm(true);
  };

  const handleSignIn = useCallback(
    async (email: string, password: string) => {
      //TODO: refactor any type
      const singInResponse: any = await signIn(email, password);
      const currentTimeZone = practice.timezone || moment.tz.guess();
      moment.tz.setDefault(currentTimeZone);

      if (singInResponse?.errors)
        setLoginInfo((prevValue) => ({
          ...prevValue,
          error: 'Incorrect username or password',
        }));

      return singInResponse;
    },
    [signIn]
  );

  const handleSubmit = useCallback(
    async (loginValues: SignInFormValuesProps) => {
      const signInResponse = await handleSignIn(
        loginValues.email,
        loginValues.password
      );

      if (!signInResponse) return alert('There was an error loggin in.');

      const userData = signInResponse.data
        ? JSON.parse(atob(signInResponse.data.SignIn.token.split('.')[1]))
        : {};

      if (!userData.account) return;

      const lsData = lsHandler.getItem('logged-accounts');
      let parsedLsData = lsData ? JSON.parse(lsData) : [];

      parsedLsData = [
        ...parsedLsData.filter(
          (item: any) => item?.email !== userData.account.email
        ),
        userData.account,
      ];

      lsHandler.setItem(`logged-accounts`, JSON.stringify(parsedLsData));

      const redirectsTo = DEFAULT_INITIAL_PAGE.find(
        (p) => p.role === userData.account?.role
      );

      //REVIEW THIS!
      //history.push(`/${practice.handler}/${redirectsTo?.page}`);
      window.location.replace(`/${practice.handler}/${redirectsTo?.page}`);
    },
    [handleSignIn]
  );

  useEffect(() => {
    const lsData = lsHandler.getItem('logged-accounts');
    const _parsedLsData = lsData ? JSON.parse(lsData) : [];

    setParsedLsData(_parsedLsData);
  }, []);

  const colors = ['#5DBCD1', '#EB984E', '#467597', '#17A589', '#A569BD'];

  return (
    <Modal
      onClose={() => {
        if (onClose) onClose();
      }}
      open={open}
      closeIcon={closeIcon}
      size="small"
    >
      <Modal.Header>Select user</Modal.Header>
      <Modal.Content
        scrolling
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {parsedLsData?.map((item, index) => {
            const color = colors[index % colors.length];
            const loggedUser = item.email === session.account?.email;

            return (
              <StyledUserContainer key={item.id}>
                <UserCircularIcon
                  isLoggedIn={loggedUser}
                  color={!loggedUser ? color : '#CCCCCC'}
                  onClick={() => handleChangeUser(item)}
                  hoverable
                >
                  {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                    .charAt(0)
                    .toUpperCase()}`}
                </UserCircularIcon>

                <StyledUserTitle>{`${item.firstName} ${item.lastName}`}</StyledUserTitle>
              </StyledUserContainer>
            );
          })}
          <StyledUserContainer>
            <UserCircularIcon onClick={handleChangeToNewUser} hoverable>
              <Icon name="plus" />
            </UserCircularIcon>
            <StyledUserTitle></StyledUserTitle>
          </StyledUserContainer>
        </div>
      </Modal.Content>
      <Modal
        onClose={() => setOpenSignInForm(false)}
        open={openSignInForm}
        size="mini"
      >
        <SignInForm
          onSubmit={() => handleSubmit(loginInfo)}
          loading={loading}
          signInFormValues={loginInfo}
          setSignInFormValues={setLoginInfo}
          showUserIcon={!!loginInfo.firstName}
        />
      </Modal>
    </Modal>
  );
};

const StyledUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledUserTitle = styled.p`
  margin: 0 1rem;
  width: 12rem;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 0.6rem;
  font-size: 18px;
`;

export default UsersToLoginModal;
