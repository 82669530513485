import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { AnnouncementsQuery } from '@bluefox/graphql/announcements';
import { Announcement } from '@bluefox/models/Announcements';
import { Header, Segment } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

interface AnnouncementsData {
  announcements: Announcement[];
}

const CardUpdates = () => {
  const { data, loading } = useQuery<AnnouncementsData>(AnnouncementsQuery);

  return (
    <StyledSegement>
      {data?.announcements.map((a, idx) => {
        if (a.active) {
          return (
            <StyledContent
              key={idx}
              style={{
                padding: '0 1rem',
              }}
            >
              <Header textAlign="center">{a.title}</Header>

              <ReactMarkdown children={a.description as string} />
            </StyledContent>
          );
        }
      })}
    </StyledSegement>
  );
};

export default CardUpdates;

const StyledSegement = styled(Segment)`
  height: 25rem;
  overflow-y: auto;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
