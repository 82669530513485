import PatientMenuButton from '@components/PatientMenuButton';
import React from 'react';
import { Icon, Loader, Message, Modal, SemanticICONS } from 'semantic-ui-react';

type PdfDrawerPreviewModalProps = {
  children?: React.ReactNode;
  pdfURL: string;
  loading?: boolean;
  isEmbedded?: boolean;
  onClickMenuButton: () => void;
  onClose: () => void;
  open: boolean;
  buttonIcon?: SemanticICONS;
  buttonLabel: string;
  error?: any;
  pageBlurred?: boolean;
  isDropdownItem?: boolean;
};

const PdfDrawerPreviewModal = ({
  children,
  pdfURL,
  loading,
  isEmbedded,
  onClickMenuButton,
  onClose,
  open,
  buttonIcon,
  buttonLabel,
  error,
  pageBlurred,
  isDropdownItem,
}: PdfDrawerPreviewModalProps) => {
  const renderContent = () => {
    if (error) {
      return (
        <Message error>
          <Icon name="warning sign" />
          {error}
        </Message>
      );
    }

    if (loading) {
      return <Loader />;
    }

    return (
      <object
        style={pageBlurred ? { display: 'none' } : { display: 'flex' }}
        type="application/pdf"
        data={`${pdfURL}#toolbar=1&navpanes=0&scrollbar=0`}
        width="100%"
        height="100%"
        aria-label="patient-physical-form.pdf"
      />
    );
  };

  return (
    <>
      <PatientMenuButton
        onClick={onClickMenuButton}
        icon={buttonIcon}
        label={buttonLabel}
        minimalistic={!isDropdownItem && isEmbedded}
      />
      {children}
      <Modal closeIcon basic size="fullscreen" onClose={onClose} open={open}>
        <Modal.Content>
          <div
            style={{
              backgroundColor: 'grey',
              width: '100%',
              height: '80vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {renderContent()}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default PdfDrawerPreviewModal;
