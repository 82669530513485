import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Header, Segment, Icon, Tab, Menu, Dropdown } from 'semantic-ui-react';
import FullLayout from '@components/FullLayout';
import { usePractice } from '@bluefox/contexts';
import BillingListScreen from './billingList';
import IncomingPaymentsScreen from './incomingPayments';
import Dashboard from './Dashboard';
import NewInvoiceListScreen from './NewInvoiceListScreen';
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  useHistory,
} from 'react-router-dom';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';
import { PracticeSettings } from '@bluefox/models/Practice';

interface PracticeSettingsData {
  practice: {
    settings: PracticeSettings;
  };
}

interface PracticeOption {
  text: string;
  value: string;
}

type Props = {
  handler: string;
  pageBlurred?: boolean;
};

const BillingScreen = ({ handler, pageBlurred = false }: Props) => {
  const practice = usePractice();
  const history = useHistory();

  const [orgPracticesOptions, setOrgPracticesOptions] = useState<
    PracticeOption[]
  >([]);
  const [practiceIds, setPracticeIds] = useState([practice.id]);

  const basePath = `/${handler}/billing`;

  const { data: settingsData } = useQuery<PracticeSettingsData>(
    PracticeSettingsQuery,
    {
      variables: {
        id: practice?.id,
      },
    }
  );

  const panes = useMemo(() => {
    const dashboardEnabled =
      settingsData?.practice.settings.billing?.showDashboardInPracticePortal;
    return [
      ...[
        ...(dashboardEnabled
          ? [
              {
                menuItem: {
                  as: NavLink,
                  content: 'Dashboard',
                  to: `${basePath}`,
                  exact: true,
                  key: 'dashboard',
                },
                render: () => (
                  <Route path={`${basePath}`} exact>
                    <Tab.Pane>
                      <Dashboard practiceIds={practiceIds} />
                    </Tab.Pane>
                  </Route>
                ),
              },
            ]
          : []),
        {
          menuItem: {
            as: NavLink,
            content: 'Claims',
            to: `${basePath}/${dashboardEnabled ? 'claims' : ''}`,
            exact: true,
            key: 'claims',
          },
          render: () => (
            <Route
              path={`${basePath}/${dashboardEnabled ? 'claims' : ''}`}
              exact
            >
              <Tab.Pane>
                <BillingListScreen practiceIds={practiceIds} />
              </Tab.Pane>
            </Route>
          ),
        },
        {
          menuItem: {
            as: NavLink,
            content: 'Incoming Payments',
            to: `${basePath}/incoming-payments`,
            exact: true,
            key: 'incoming-payments',
          },
          render: () => (
            <Route path={`${basePath}/incoming-payments`} exact>
              <Tab.Pane>
                <IncomingPaymentsScreen practiceIds={practiceIds} />
              </Tab.Pane>
            </Route>
          ),
        },
        {
          menuItem: {
            as: NavLink,
            content: 'Invoices',
            to: `${basePath}/invoices`,
            exact: true,
            key: 'invoices',
          },
          render: () => (
            <Route path={`${basePath}/invoices`} exact>
              <Tab.Pane>
                <NewInvoiceListScreen
                  practiceIds={practiceIds}
                  pageBlurred={pageBlurred}
                />
              </Tab.Pane>
            </Route>
          ),
        },
      ],
    ];
  }, [
    basePath,
    pageBlurred,
    practiceIds,
    settingsData?.practice.settings.billing?.showDashboardInPracticePortal,
  ]);

  const tabDefaultActiveIndex = useMemo(() => {
    const splitPath = history.location.pathname.split('/');
    const lastSplitPath = splitPath[splitPath.length - 1];
    const defaultIndex = panes.findIndex(
      (pane) => pane.menuItem.key === lastSplitPath
    );
    return defaultIndex !== -1 ? defaultIndex : 0;
  }, [history.location.pathname, panes]);

  const handlePracticeIdsValue = (value: string) => {
    const selectedPracticeIds =
      !!value && value !== 'all'
        ? [value]
        : practice.organization?.practices.map((p) => p.id);

    setPracticeIds(selectedPracticeIds || []);
  };

  useEffect(() => {
    if (
      !practice.organization?.practices ||
      practice.organization?.practices.length < 1
    )
      return;

    setOrgPracticesOptions(
      practice.organization?.practices.map((p) => ({
        text: p.name,
        value: p.id,
      }))
    );
  }, [practice]);

  return (
    <FullLayout loading={!settingsData}>
      <Segment padded basic>
        <Header as="h1">
          <Icon name="dollar" />
          Billing
        </Header>
      </Segment>
      {practice.organization?.practices &&
        practice.organization?.practices.length > 1 && (
          <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Menu.Item>
              <Dropdown
                style={{ minWidth: '15rem' }}
                placeholder="Filter by practice"
                fluid
                selection
                onChange={(e, data) => {
                  handlePracticeIdsValue(data.value?.toString() || '');
                }}
                options={[
                  {
                    text: 'All practices',
                    value: 'all',
                  },
                  ...orgPracticesOptions,
                ]}
                defaultValue={practice.id}
              />
            </Menu.Item>
            <Menu.Menu
              position="right"
              style={{ display: 'flex', flexWrap: 'wrap' }}
            ></Menu.Menu>
          </Menu>
        )}
      <Segment>
        <Router>
          <Tab
            menu={{ secondary: true, pointing: true }}
            defaultActiveIndex={tabDefaultActiveIndex}
            panes={panes}
          />
        </Router>
      </Segment>
    </FullLayout>
  );
};

export default BillingScreen;
