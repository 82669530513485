import { Insurance, VfcCriteria } from '@bluefox/models/Insurances';
import { PDFPage, rgb, PDFFont } from 'pdf-lib';

export const calculateTextWidth = (
  font: PDFFont,
  text: string,
  fontSize: number
) => font.widthOfTextAtSize(text, fontSize);

export const drawTextWithUnderLine = (
  page: PDFPage,
  coordX: number,
  coordY: number,
  font: PDFFont,
  fontSize: number,
  text: string,
  lineEndLength: number
) => {
  text = text.replace(/[\s\t]+/g, ' ');

  page.drawText(text, {
    x: coordX + 6,
    y: coordY + 2,
    size: fontSize,
    color: rgb(0, 0, 0),
    font,
  });

  page.drawLine({
    start: { x: coordX, y: coordY },
    end: {
      x: coordX + calculateTextWidth(font, text, fontSize) + lineEndLength,
      y: coordY,
    },
    color: rgb(0.4, 0.4, 0.4),
    thickness: 0.5,
  });
};

export const buildEnrolledInText = (insurance: Insurance, age: number) => {
  const floridaMedicaidVfc =
    insurance &&
    insurance.company?.name === 'FLORIDA MEDICAID' &&
    insurance.vfcEligible;
  const floridaMedicaidNotVfc =
    insurance &&
    insurance.company?.name === 'FLORIDA MEDICAID' &&
    !insurance.vfcEligible;
  const withInsuranceNotVfc = insurance && !insurance?.vfcEligible;
  const withInsuranceVfc = insurance?.vfcEligible;
  const withoutInsuranceYoungerThan19 = !insurance && age < 19;
  const withoutInsuranceOlderThan18 = !insurance && age > 18;
  const withInsuranceVfcOlderThan18 = insurance?.vfcEligible && age > 18;
  const vfcWithoutInsuranceOlderThan18 =
    !insurance?.company && insurance?.vfcEligible === true && age > 18;
  const vfcWithoutInsuranceYoungerThan19 =
    !insurance?.company && insurance?.vfcEligible === true && age < 19;
  const vfcUninsuredYoungerThan19 =
    insurance && insurance.vfcCriteria === VfcCriteria.uninsured && age < 19;
  const vfcUninsuredOlderThan18 =
    insurance && insurance.vfcCriteria === VfcCriteria.uninsured && age > 18;

  let vfcCriteriaText = '';

  switch (insurance?.vfcCriteria) {
    case VfcCriteria.ai_an:
      vfcCriteriaText = 'Native American - Alaska Native';
      break;

    case VfcCriteria.medicaid:
      vfcCriteriaText = 'Medicaid';
      break;

    case VfcCriteria.not_applicable:
      vfcCriteriaText = 'Not Applicable';
      break;

    case VfcCriteria.underinsured:
      vfcCriteriaText = 'Underinsured';
      break;

    case VfcCriteria.unknown:
      vfcCriteriaText = 'Unknown';
      break;

    case VfcCriteria.uninsured:
      vfcCriteriaText = 'Uninsured';
      break;
  }

  let textAndCoordX: { text: string; coordX: number } = {
    text: '',
    coordX: 0,
  };

  if (floridaMedicaidVfc) {
    textAndCoordX = {
      text: `A MEDICAID INSURANCE AND IS VFC ELIGIBLE ${
        vfcCriteriaText ? `/ ${vfcCriteriaText}` : ''
      }`,
      coordX: 140,
    };
  } else if (floridaMedicaidNotVfc) {
    textAndCoordX = {
      text: 'A MEDICAID INSURANCE AND IS NOT VFC ELIGIBLE',
      coordX: 140,
    };
  } else if (withInsuranceVfcOlderThan18 && !vfcUninsuredOlderThan18) {
    textAndCoordX = {
      text: 'A PRIVATE INSURANCE AND IS NOT VFC ELIGIBLE',
      coordX: 140,
    };
  } else if (withInsuranceNotVfc) {
    textAndCoordX = { text: 'A PRIVATE INSURANCE', coordX: 140 };
  } else if (insurance?.vfcCriteria === VfcCriteria.underinsured) {
    textAndCoordX = {
      text: `A PRIVATE INSURANCE AND IS VFC ELIGIBLE / ${vfcCriteriaText}`,
      coordX: 140,
    };
  } else if (vfcWithoutInsuranceYoungerThan19) {
    textAndCoordX = { text: `VFC ELIGIBLE / ${vfcCriteriaText}`, coordX: 259 };
  } else if (
    withInsuranceVfc &&
    !vfcUninsuredOlderThan18 &&
    !vfcUninsuredYoungerThan19
  ) {
    textAndCoordX = {
      text: `A MEDICAID INSURANCE AND IS VFC ELIGIBLE ${
        vfcCriteriaText ? `/ ${vfcCriteriaText}` : ''
      }`,
      coordX: 140,
    };
  } else if (withoutInsuranceYoungerThan19 || vfcUninsuredYoungerThan19) {
    textAndCoordX = { text: 'VFC ELIGIBLE / Uninsured', coordX: 259 };
  } else if (
    withoutInsuranceOlderThan18 ||
    vfcWithoutInsuranceOlderThan18 ||
    vfcUninsuredOlderThan18
  ) {
    textAndCoordX = {
      text: 'SELF PAY AND NOT VFC ELIGIBLE',
      coordX: 260,
    };
  }

  return textAndCoordX;
};

export const getVaccineNameIndexCoords = (
  index: number,
  isLongName: boolean
) => {
  let coords = {
    x: 0,
    y: 0,
  };
  switch (index) {
    case 0:
      coords = {
        x: isLongName ? 72 : 61,
        y: isLongName ? 323 : 293,
      };
      break;

    case 1:
      coords = {
        x: isLongName ? 72 : 61,
        y: isLongName ? 276 : 243,
      };
      break;

    case 2:
      coords = {
        x: isLongName ? 72 : 61,
        y: isLongName ? 227 : 197,
      };
      break;

    case 3:
      coords = {
        x: isLongName ? 72 : 61,
        y: isLongName ? 180 : 150,
      };
      break;

    case 4:
      coords = {
        x: isLongName ? 72 : 61,
        y: isLongName ? 132 : 104,
      };
      break;

    case 5:
      coords = {
        x: isLongName ? 72 : 61,
        y: isLongName ? 87 : 59,
      };
      break;
  }

  return coords;
};

export const getLotIndexCoords = (index: number) => {
  let coords = {
    x: 0,
    y: 0,
  };
  switch (index) {
    case 0:
      coords = {
        x: 182,
        y: 293,
      };
      break;

    case 1:
      coords = {
        x: 182,
        y: 243,
      };
      break;

    case 2:
      coords = {
        x: 182,
        y: 197,
      };
      break;

    case 3:
      coords = {
        x: 182,
        y: 150,
      };
      break;

    case 4:
      coords = {
        x: 182,
        y: 104,
      };
      break;

    case 5:
      coords = {
        x: 182,
        y: 59,
      };
      break;
  }

  return coords;
};

export const getRouteSiteIndexCoords = (index: number) => {
  let coords = {
    x: 0,
    y: 0,
  };
  switch (index) {
    case 0:
      coords = {
        x: 303,
        y: 293,
      };
      break;

    case 1:
      coords = {
        x: 303,
        y: 243,
      };
      break;

    case 2:
      coords = {
        x: 303,
        y: 197,
      };
      break;

    case 3:
      coords = {
        x: 303,
        y: 150,
      };
      break;

    case 4:
      coords = {
        x: 303,
        y: 104,
      };
      break;

    case 5:
      coords = {
        x: 303,
        y: 59,
      };
      break;
  }

  return coords;
};

export const getAdminByIndexCoords = (index: number) => {
  let coords = {
    x: 0,
    y: 0,
  };
  switch (index) {
    case 0:
      coords = {
        x: 424,
        y: 293,
      };
      break;

    case 1:
      coords = {
        x: 424,
        y: 243,
      };
      break;

    case 2:
      coords = {
        x: 424,
        y: 197,
      };
      break;

    case 3:
      coords = {
        x: 424,
        y: 150,
      };
      break;

    case 4:
      coords = {
        x: 424,
        y: 104,
      };
      break;

    case 5:
      coords = {
        x: 424,
        y: 59,
      };
      break;
  }

  return coords;
};
