import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GetBorrowingRequestTickets } from '@bluefox/graphql/tickets';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { Ticket, TicketStatusEnum } from '@bluefox/models/Tickets';
import { Accordion, Icon, Table } from 'semantic-ui-react';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters';
import { humanizeText } from '@bluefox/lib/humanize';
import BorrowingRequestTicketsAccordionDetails from './BorrowingRequestTicketsAccordionDetails';

enum MagicWordsDict {
  ISSUE = 'Issue',
  ISSUE_TYPE = 'issueType',
  ISSUE_DESCRIPTION = 'issueDescription',
  BORROWING_REQUEST = 'Borrowing_request',
  ALL = 'all',
  OPEN = 'open',
  CLOSED = 'closed',
}

interface OutputDetail {
  [key: string]: string | string[] | undefined;
  patient?: string;
  dateOfService?: string;
  issueType?: string;
  insurance?: string;
  correctedInsurance?: string;
  relevantVaccines?: string[];
  issueDescription?: string;
}

export interface OutputTicket {
  id: string;
  details: OutputDetail;
  status: TicketStatusEnum;
}

type BorrowingRequestTicketsAccordionProps = {
  practiceHandler: string;
};

function toCamelCase(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
}

function transformData(tickets: Ticket[]): OutputTicket[] {
  return tickets.map((ticket) => {
    const details: OutputDetail = {};
    let issueCount = 0; // To track occurrences of "issue"

    ticket.detail.fields.forEach((field) => {
      let key = toCamelCase(field.title);

      // Check for "issue" title to differentiate between types
      if (field.title === MagicWordsDict.ISSUE) {
        issueCount++;
        key =
          issueCount === 1
            ? MagicWordsDict.ISSUE_TYPE
            : MagicWordsDict.ISSUE_DESCRIPTION;
      }

      details[key] = field.detail;
    });

    return {
      id: ticket.id || '',
      status: ticket.status,
      details,
    };
  });
}

const BorrowingRequestTicketsAccordion = ({
  practiceHandler,
}: BorrowingRequestTicketsAccordionProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [ticketsCount, setTicketsCount] = useState<number>(0);

  const [tickets, setTickets] = useState<OutputTicket[]>([]);

  useQuery(GetBorrowingRequestTickets, {
    variables: {
      criteria: {
        type: { _eq: MagicWordsDict.BORROWING_REQUEST },
        status: { _eq: 'open' },
        detail: {
          _cast: {
            String: {
              _ilike: whereLikeInput(practiceHandler),
            },
          },
        },
      },
    },
    onCompleted(data) {
      setTickets(transformData(data.tickets));
      setTicketsCount(data.aggregating.aggregate.count);
    },
  });

  return (
    <Accordion fluid styled>
      <Accordion.Title
        onClick={() => setShow((prevValue) => !prevValue)}
        active={show}
      >
        <Icon name="dropdown" />
        Inconsistencies requested ( {ticketsCount} )
      </Accordion.Title>
      <Accordion.Content active={show}>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Patient (DoB)</Table.HeaderCell>
              <Table.HeaderCell>Date of Service</Table.HeaderCell>
              <Table.HeaderCell>Issue Type</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tickets.map((ticket: OutputTicket) => {
              return (
                <Table.Row key={ticket.id}>
                  <Table.Cell>{ticket.details.patient}</Table.Cell>
                  <Table.Cell>
                    {ticket.details.dateOfService
                      ? formatDateToMMDDYYYYV2(ticket.details.dateOfService)
                      : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    {ticket.details.issueType
                      ? humanizeText(ticket.details.issueType, {
                          delimiter: '_',
                          capitalize: 'first',
                        })
                      : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    {humanizeText(ticket.status, { capitalize: 'first' })}
                  </Table.Cell>
                  <Table.Cell>
                    <BorrowingRequestTicketsAccordionDetails ticket={ticket} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Accordion.Content>
    </Accordion>
  );
};

export default BorrowingRequestTicketsAccordion;
