import {
  useCallback,
  useEffect,
  useReducer,
  useState,
  createContext,
  FC,
  useContext,
} from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import GS1 from '@bluefox/lib/gs1';

import { Inventory } from '@bluefox/models/Inventory';
import { usePractice } from '@bluefox/contexts';
import { Vaccine } from '@bluefox/models/Vaccine';
import {
  VaccinationRoutes,
  VaccinationSites,
} from '@bluefox/models/Vaccination';

const ScannableInventoryQuery = gql`
  query ScannableInventoryQuery(
    $practiceId: uuid!
    $criterias: [inventories_bool_exp!]!
  ) {
    inventories(
      where: { practiceId: { _eq: $practiceId }, _or: $criterias }
      order_by: { vaccine: { name: asc } }
    ) {
      id
      lot
      expiration
      doses
      vaccineId
      status
      vfc
      vaccine {
        id
        name
        saleNdc
        manufacturer
        aka
        routes
      }
    }
  }
`;

const ScannableVaccineQuery = gql`
  query ScannableVaccineQuery($criterias: [vaccines_bool_exp!]!) {
    vaccines(where: { _or: $criterias }, order_by: { name: asc }) {
      id
      name
      saleNdc
      manufacturer
      aka
      routes
      types
      allowedAssociatedVaccinations
    }
  }
`;

interface ScannableInventoryData {
  inventories: Inventory[];
}

interface ScannableVaccineData {
  vaccines: Vaccine[];
}

class Criterias {
  gs1: GS1;
  lot?: string;
  expiration?: Date;
  ndc10?: string;
  useNdc10?: string;
  code: string;

  constructor(code: string) {
    this.code = code;
    this.gs1 = new GS1(code);

    this.lot = this.gs1.getLot();
    this.expiration = this.gs1.getExp();
    this.ndc10 = this.gs1.getNdc();
  }

  getInventoryExpresion() {
    if (!this.lot || !this.expiration) return;
    const vaccineExpresion = this.getVaccineExpresion();
    return {
      _or: [
        {
          lot: { _ilike: this.lot },
        },
        { alternativeLotNumber: { _contains: [this.lot] } },
      ],
      ...(vaccineExpresion ? { vaccine: vaccineExpresion } : {}),
      // expiration: { _eq: this.expiration.toISOString() },
      status: { _eq: 'received' },
    };
  }

  getVaccineExpresion() {
    if (!this.ndc10) return;
    return {
      _or: [
        { saleNdc10: { _eq: this.ndc10 } },
        { useNdc10: { _eq: this.ndc10 } },
      ],
    };
  }
}

export interface ScannableItem {
  _id: string;
  _ready?: boolean;
  _isSecondary?: boolean;
  _primaryId?: string;
  code: string;
  gs1?: GS1;
  vaccine?: Vaccine;
  inventory?: Inventory[];
  vaccinationSite?: VaccinationSites;
  vaccinationSiteDescription?: string;
  vaccinationRoute?: VaccinationRoutes;
  vaccinationDose?: number;
  vaccinationVisDate?: Date;
  warnings?: string[];
  associatedItems?: { [_id: string]: ScannableItem };
}

enum EntriesReducerActionType {
  CREATE_ITEM = 'CREATE_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM',
  ASSIGN_ITEM_ATTRIBUTES = 'ASSIGN_ITEM_ATTRIBUTES',
  CLEAN_ITEMS = 'CLEAN_ITEMS',
}

interface EntriesReducerPayload {
  _id: string;
  item: Partial<ScannableItem>;
  inventoryId: string;
}

interface EntriesReducerAction {
  type: EntriesReducerActionType;
  payload?: Partial<EntriesReducerPayload>;
}

function isScannableItem(i: any): i is ScannableItem {
  return 'code' in i;
}

function canBeAssociatedWith(item: ScannableItem): boolean {
  return (
    Object.keys(item.associatedItems ?? {}).length <
    (item.vaccine?.allowedAssociatedVaccinations || 0)
  );
}

const entriesReducer = (
  state: Object & { [_id: string]: ScannableItem },
  action: EntriesReducerAction
) => {
  switch (action.type) {
    case EntriesReducerActionType.CREATE_ITEM: {
      if (!action.payload?._id || !isScannableItem(action.payload.item)) {
        return state;
      }

      const { _id, item } = action.payload;

      const sameVaccineAlreadyScanned = Object.values(state).find(
        (i) => i.vaccine?.id === item?.vaccine?.id
      );

      if (sameVaccineAlreadyScanned) {
        if (canBeAssociatedWith(sameVaccineAlreadyScanned)) {
          item._isSecondary = true;
          item._primaryId = sameVaccineAlreadyScanned._id;
          const updatedSameVaccineItem = {
            ...sameVaccineAlreadyScanned,
            associatedItems: {
              ...(sameVaccineAlreadyScanned.associatedItems ?? {}),
              [_id]: item,
            },
          };

          return {
            ...state,
            [sameVaccineAlreadyScanned._id]: updatedSameVaccineItem,
            [_id]: item,
          };
        } else {
          return state;
        }
      }

      return {
        ...state,
        [_id]: item,
      };
    }

    case EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES: {
      if (!action.payload?._id || !state.hasOwnProperty(action.payload?._id)) {
        return state;
      }

      const item = state[action.payload._id] as ScannableItem;
      Object.assign(item, action.payload.item);

      return {
        ...state,
        [action.payload._id]: item,
      };
    }

    case EntriesReducerActionType.REMOVE_ITEM: {
      if (!action.payload?._id) return state;

      const filteredInventory = action.payload.inventoryId
        ? state[action.payload._id].inventory?.filter(
            (i) => i.id !== action.payload?.inventoryId
          )
        : undefined;

      const item = state[action.payload?._id] as ScannableItem;

      if (!filteredInventory) {
        if (item._isSecondary && item._primaryId) {
          const primary = state[item._primaryId];

          if (
            primary &&
            primary.associatedItems &&
            primary.associatedItems[item._id]
          ) {
            delete primary.associatedItems[item._id];
          }
        } else if (!!item.associatedItems) {
          Object.values(item.associatedItems).forEach((i) => {
            state[i._id]._isSecondary = false;
          });
        }

        delete state[item._id];
      } else {
        state[action.payload._id].inventory = filteredInventory;
      }

      return { ...state };
    }

    case EntriesReducerActionType.CLEAN_ITEMS: {
      return {};
    }
  }
};

type ScannableItems = {
  entries: ScannableItem[];
  loading: boolean;
  entriesRecord: { [_id: string]: ScannableItem };
  // methods
  addEntry: (code: string) => void;
  removeEntry: (_id: string, inventoryId?: string) => void;
  cleanEntries: () => void;
  changeVaccinationSite: (_id: string, site: VaccinationSites) => void;
  changeVaccinationRoute: (_id: string, route: VaccinationRoutes) => void;
  changeDose: (_id: string, vaccinationDose: number) => void;
  changeVaccinationVisDate: (_id: string, visDate: Date | null) => void;
  addWarning: (_id: string, warnings: string[] | undefined) => void;
  setDefaultVaccinationVisDate: (visDate: Date) => void;
  setDefaultVaccinationRoute: (route: VaccinationRoutes) => void;
  setDefaultVaccinationSite: (site: VaccinationSites) => void;
};

export const ScannableItemsContext = createContext<ScannableItems>(
  {} as ScannableItems
);

export const ScannableItemsContextProvider: FC = ({ children }) => {
  const practice = usePractice();
  const client = useApolloClient();

  const [defaultVaccinationVisDate, setDefaultVaccinationVisDate] =
    useState<Date>(new Date());
  const [defaultVaccinationRoute, setDefaultVaccinationRoute] =
    useState<VaccinationRoutes>();
  const [defaultVaccinationSite, setDefaultVaccinationSite] =
    useState<VaccinationSites>();

  const [entriesRecord, dispatch] = useReducer(entriesReducer, {});

  const [entries, setEntries] = useState<ScannableItem[]>([]);

  const [loading, setLoading] = useState(false);

  const addEntry = useCallback(
    async (code: string) => {
      if (!code) return;

      setLoading(true);

      const _id = uuidv4();
      const item: ScannableItem = {
        _id,
        code,
        gs1: new GS1(code),
        vaccinationVisDate: defaultVaccinationVisDate,
        vaccinationSite: defaultVaccinationSite,
        vaccinationRoute: defaultVaccinationRoute,
      };

      const criterias = new Criterias(code);

      const queries: Promise<void>[] = [];

      const inventoryExpresion = criterias.getInventoryExpresion();
      if (inventoryExpresion) {
        queries.push(
          (async () => {
            const { data: inventoryResult } =
              await client.query<ScannableInventoryData>({
                query: ScannableInventoryQuery,
                variables: {
                  practiceId: practice.id,
                  criterias: inventoryExpresion,
                },
              });

            if (!!inventoryResult && inventoryResult.inventories.length) {
              item.inventory = inventoryResult.inventories;
            }
          })()
        );
      }

      const vaccineExpresion = criterias.getVaccineExpresion();
      if (vaccineExpresion) {
        queries.push(
          (async () => {
            const { data: vaccineResult } =
              await client.query<ScannableVaccineData>({
                query: ScannableVaccineQuery,
                variables: {
                  criterias: vaccineExpresion,
                },
              });

            if (!!vaccineResult && vaccineResult.vaccines.length) {
              const vaccine = vaccineResult.vaccines.at(0);
              item.vaccine = vaccine;
              item.vaccinationRoute = vaccine?.routes?.at(0);
            }
          })()
        );
      }

      await Promise.all(queries);

      dispatch({
        type: EntriesReducerActionType.CREATE_ITEM,
        payload: {
          _id,
          item,
        },
      });

      setLoading(false);
    },
    [
      entriesRecord,
      dispatch,
      defaultVaccinationRoute,
      defaultVaccinationSite,
      defaultVaccinationVisDate,
    ]
  );

  const removeEntry = useCallback(
    (_id: string, inventoryId?: string) => {
      dispatch({
        type: EntriesReducerActionType.REMOVE_ITEM,
        payload: { _id, inventoryId },
      });
    },
    [entriesRecord, dispatch]
  );

  const cleanEntries = useCallback(() => {
    setEntries([]);
    dispatch({
      type: EntriesReducerActionType.CLEAN_ITEMS,
    });
  }, [entriesRecord, dispatch]);

  const changeVaccinationSite = useCallback(
    (_id: string, site: VaccinationSites) => {
      dispatch({
        type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
        payload: {
          _id,
          item: {
            vaccinationSite: site,
          },
        },
      });
    },
    [entriesRecord, dispatch]
  );

  const changeVaccinationRoute = useCallback(
    (_id: string, route: VaccinationRoutes) => {
      dispatch({
        type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
        payload: {
          _id,
          item: {
            vaccinationRoute: route,
          },
        },
      });
    },
    [entriesRecord, dispatch]
  );

  const changeVaccinationVisDate = useCallback(
    (_id: string, visDate: Date | null) => {
      dispatch({
        type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
        payload: {
          _id,
          item: {
            vaccinationVisDate: visDate ? visDate : undefined,
          },
        },
      });
    },
    [entriesRecord, dispatch]
  );

  const changeDose = useCallback(
    (_id: string, vaccinationDose: number) => {
      dispatch({
        type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
        payload: {
          _id,
          item: {
            vaccinationDose,
          },
        },
      });
    },
    [entriesRecord, dispatch]
  );

  useEffect(() => {
    const _entries = Object.values(entriesRecord).filter(
      (e) => !!e._id && !e._isSecondary
    );
    setEntries(_entries as ScannableItem[]);
  }, [entriesRecord]);

  const addWarning = useCallback(
    (_id: string, warnings: string[] | undefined) => {
      dispatch({
        type: EntriesReducerActionType.ASSIGN_ITEM_ATTRIBUTES,
        payload: {
          _id,
          item: {
            warnings,
          },
        },
      });
    },
    [entriesRecord, dispatch]
  );

  return (
    <ScannableItemsContext.Provider
      value={{
        entries,
        loading,
        entriesRecord,
        // methods
        addEntry,
        removeEntry,
        cleanEntries,
        changeVaccinationSite,
        changeVaccinationRoute,
        changeDose,
        changeVaccinationVisDate,
        addWarning,
        setDefaultVaccinationVisDate,
        setDefaultVaccinationRoute,
        setDefaultVaccinationSite,
      }}
    >
      {children}
    </ScannableItemsContext.Provider>
  );
};

export const useScannableItems = () => {
  return useContext(ScannableItemsContext);
};
