import moment from 'moment-timezone';

export type RangeType = {
  rangeUnit: string;
  start: number;
  end: number;
};

interface rangeMatchingType {
  start: number;
  end: number;
}

export const isMatchingDates = (
  startDate: number,
  endDate: number,
  startRange: number,
  endRange: number
) => startRange >= startDate && endRange <= endDate;

export const rangeToMonths = (range: RangeType) => {
  const start = range.rangeUnit === 'year' ? range.start * 12 : range.start;
  const end = range.rangeUnit === 'year' ? range.end * 12 : range.end;
  return { start, end };
};

export const matchRanges = (
  range1: rangeMatchingType[],
  range2: rangeMatchingType
) =>
  range1.map(
    (r1) =>
      (r1.start >= range2.start && r1.end <= range2.end) ||
      (r1.start <= range2.start && r1.end >= range2.end)
  );

export const differenceInMonths = (DoB: string | Date, ref: string | Date) => {
  const DoBDate = new Date(DoB);
  const refDate = new Date(ref);

  return moment(refDate).diff(DoBDate, 'months');
};
