import { Table } from 'semantic-ui-react';

import { useScannableItems } from '@bluefox/store/scannableItems';

import ScannedItemCard from './ScannedItemCard/ScannedItemCard';
import { usePractice } from '@bluefox/contexts';
import { Vaccination } from '@bluefox/models/Vaccination';

interface ScannedItemsProps {
  patientAge: number;
  givenAt: Date;
  allowRemove?: boolean;
  vfc: boolean;
  givenVaccinations: Vaccination[];
}

const ScannedItems = ({
  patientAge,
  allowRemove = true,
  vfc,
  givenAt,
  givenVaccinations = [],
}: ScannedItemsProps) => {
  const practice = usePractice();

  const { entries } = useScannableItems();

  return (
    <Table compact style={{ marginTop: 0 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Vaccine</Table.HeaderCell>
          <Table.HeaderCell>Lot</Table.HeaderCell>
          <Table.HeaderCell>Expiration</Table.HeaderCell>
          <Table.HeaderCell>VIS Date</Table.HeaderCell>
          <Table.HeaderCell>Route</Table.HeaderCell>
          <Table.HeaderCell>Site</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {entries.map((item) => (
          <ScannedItemCard
            key={item._id}
            practiceTimezone={practice.timezone}
            givenAt={givenAt}
            patientAge={patientAge}
            item={item}
            items={entries}
            givenVaccinations={givenVaccinations}
            showRemove={true}
            allowRemove={allowRemove}
            vfc={vfc}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default ScannedItems;
