export const vaccinationsByDosesCoordsMap: {
  [key: string]: object[];
} = {
  DTAP_DTP_Group: [
    {
      x: 198,
      y: 519,
    },
    {
      x: 268,
      y: 519,
    },
    {
      x: 333,
      y: 519,
    },
    {
      x: 398,
      y: 519,
    },
    {
      x: 468,
      y: 519,
    },
  ],
  DT: [
    {
      x: 198,
      y: 508,
    },
    {
      x: 268,
      y: 508,
    },
    {
      x: 333,
      y: 508,
    },
    {
      x: 398,
      y: 508,
    },
    {
      x: 468,
      y: 508,
    },
  ],
  TDAP: [
    {
      x: 198,
      y: 497,
    },
    {
      x: 268,
      y: 497,
    },
    {
      x: 333,
      y: 497,
    },
    {
      x: 398,
      y: 497,
    },
  ],
  TD: [
    {
      x: 198,
      y: 486,
    },
    {
      x: 268,
      y: 486,
    },
    {
      x: 333,
      y: 486,
    },
    {
      x: 398,
      y: 486,
    },
  ],
  POLIO: [
    {
      x: 198,
      y: 475,
    },
    {
      x: 268,
      y: 475,
    },
    {
      x: 333,
      y: 475,
    },
    {
      x: 398,
      y: 475,
    },
    {
      x: 468,
      y: 475,
    },
  ],
  HIB: [
    {
      x: 198,
      y: 464,
    },
    {
      x: 268,
      y: 464,
    },
    {
      x: 333,
      y: 464,
    },
    {
      x: 398,
      y: 464,
    },
  ],
  MMR: [
    {
      x: 198,
      y: 453,
    },
    {
      x: 268,
      y: 453,
    },
  ],
  MEASLES: [
    {
      x: 198,
      y: 442,
    },
    {
      x: 268,
      y: 442,
    },
  ],
  MUMPS: [
    {
      x: 333,
      y: 442,
    },
    {
      x: 398,
      y: 442,
    },
  ],
  RUBELLA: [
    {
      x: 198,
      y: 422,
    },
    {
      x: 268,
      y: 422,
    },
  ],
  HepB: [
    {
      x: 198,
      y: 402,
    },
    {
      x: 268,
      y: 402,
    },
    {
      x: 333,
      y: 402,
    },
  ],
  VARICELLA: [
    {
      x: 198,
      y: 390,
    },
    {
      x: 268,
      y: 390,
    },
  ],
  PneumoPCV: [
    {
      x: 198,
      y: 359,
    },
    {
      x: 268,
      y: 359,
    },
    {
      x: 333,
      y: 359,
    },
    {
      x: 398,
      y: 359,
    },
  ],
};
