import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useLazyQuery } from '@apollo/client';
import {
  Header,
  Icon,
  Input,
  Menu,
  Segment,
  Table,
  Message,
  Label,
  Placeholder,
  Dropdown,
  Popup,
  Button,
} from 'semantic-ui-react';
import {
  BillingClaimsForPracticePortalView,
  BillingClaimsCount,
} from '@graphql/billing';
import {
  BillingClaimStatus,
  claimStatusesGroups,
  simplifiedClaimStatusOptions,
  simplifiedClaimStatusesFunction,
  PracticePortalBillingClaim,
} from '@bluefox/models/Billing';
import { usePractice } from '@bluefox/contexts';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import BillingExcelExportModal from './billingExcelExportModal';

interface ClaimsCountData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}
interface BillingClaimsViewData {
  billingClaims: PracticePortalBillingClaim[];
}

interface BillingListProps {
  practiceIds: string[];
}

const ENTRIES_PER_PAGE = 15;

const BillingList = ({ practiceIds }: BillingListProps) => {
  const { timezone: practiceTimezone } = usePractice();

  const [criteria, setCriteria] = useState<object>({});
  const [searchPatient, setSearchPatient] = useState('');
  const [searchStatus, setSearchStatus] = useState<string>('');
  const [searchInventoryUsed, setSearchInventoryUsed] = useState<string>();
  const [searchInsurance, setSearchInsurance] = useState('');
  const [searchFromDate, setSearchFromDate] = useState<Date | null | undefined>(
    null
  );
  const [searchToDate, setSearchToDate] = useState<Date | null | undefined>(
    null
  );

  const [totalClaimsLoading, setTotalClaimsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [isQuerying, setIsQuerying] = useState(false);

  const [getClaims, { data, loading, error }] =
    useLazyQuery<BillingClaimsViewData>(BillingClaimsForPracticePortalView);

  const [getClaimsCount, { data: claimsCount, loading: claimsCountLoading }] =
    useLazyQuery<ClaimsCountData>(BillingClaimsCount);

  const handleSearchOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      getClaims({
        variables: {
          criteria,
          limit: ENTRIES_PER_PAGE,
          offset: !!pageCount ? ENTRIES_PER_PAGE * pageCount : null,
        },
      });
    }
  };

  const handleStatusValue = (value: string) => {
    const statusValue = !!value && value !== 'allStatus' ? value : '';
    setSearchStatus(statusValue);
  };

  const handleInventoryUsedValue = (value: string) => {
    const invetoryUsedValue = !!value && value !== 'all' ? value : undefined;
    setSearchInventoryUsed(invetoryUsedValue);
  };

  const plainStatus = (status: string) => {
    return (
      status !== BillingClaimStatus.PAID && status !== BillingClaimStatus.DENIED
    );
  };

  const getStatuses = () => {
    if (!searchStatus) {
      return {
        status: {
          _nin: ['unclaimed', 'removed'],
        },
      };
    }

    if (plainStatus(searchStatus)) {
      return {
        status: { _in: claimStatusesGroups.get(searchStatus) },
      };
    } else if (searchStatus === BillingClaimStatus.PAID) {
      return {
        _or: [
          {
            _and: [
              {
                status: { _eq: BillingClaimStatus.PAID },
              },
              {
                revisionStatus: { _nin: ['new', 'not_billable'] },
              },
            ],
          },
          {
            status: {
              _in: [BillingClaimStatus.BILLED, BillingClaimStatus.CLOSED],
            },
          },
          {
            _and: [
              {
                status: { _eq: BillingClaimStatus.DISMISSED },
              },
              {
                dismissedReason: { _eq: 'properly_denied' },
              },
            ],
          },
        ],
      };
    } else {
      return {
        status: { _eq: BillingClaimStatus.DISMISSED },
        _or: [
          {
            dismissedReason: { _eq: 'based_on_member' },
          },
          {
            dismissedReason: { _eq: 'uncorrectable_error' },
          },
        ],
      };
    }
  };

  useEffect(() => {
    setTotalClaimsLoading(false);
  }, [practiceIds]);

  useEffect(() => {
    let _criteria = {};

    _criteria = {
      _and: [
        {
          ...{
            practiceId: { _in: practiceIds },
          },
        },
        {
          ...(searchPatient
            ? {
                fullName: {
                  _ilike: `%${searchPatient}%`,
                },
              }
            : {}),
        },
        {
          ...(searchFromDate
            ? {
                givenAt: {
                  _gte: searchFromDate,
                },
              }
            : {}),
        },
        {
          ...(searchToDate
            ? {
                givenAt: {
                  _lte: searchToDate,
                },
              }
            : {}),
        },
        {
          ...(searchInsurance
            ? { insuranceName: { _ilike: `%${searchInsurance}%` } }
            : {}),
        },
        {
          ...(searchInventoryUsed === 'vfc'
            ? { inventoryVfcCount: { _gt: 0 } }
            : {}),
        },
        {
          ...(searchInventoryUsed === 'private'
            ? { inventoryPrivateCount: { _gt: 0 } }
            : {}),
        },
        {
          ...getStatuses(),
        },
      ],
    };

    setCriteria(_criteria);
    setPageCount(0);
  }, [
    searchPatient,
    searchStatus,
    searchFromDate,
    searchToDate,
    searchInsurance,
    searchInventoryUsed,
    practiceIds,
  ]);

  useEffect(() => {
    if (pageCount < 0 || !isQuerying) return;
    getClaims({
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: ENTRIES_PER_PAGE * pageCount,
      },
    });
  }, [pageCount]);

  return (
    <>
      <Segment padded basic>
        <Header as="h2">Claims</Header>
      </Segment>
      <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Menu.Menu
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Menu.Item>
              <label style={{ width: '4rem' }}>
                <b>From:</b>
              </label>
              <DateTimePicker
                forFilter
                tz={practiceTimezone}
                placeholderText="From"
                selected={searchFromDate}
                onChange={(d) => {
                  setSearchFromDate(d ? (d as Date) : undefined);
                }}
                onClear={() => setSearchFromDate(undefined)}
                maxDate={new Date()}
                dateFormat={DateFormats.DATE}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                dropdownMode="select"
                isClearable
              />
            </Menu.Item>
            <Menu.Item>
              <label style={{ width: '2.5rem' }}>
                <b>To:</b>
              </label>
              <DateTimePicker
                forFilter
                tz={practiceTimezone}
                placeholderText="To"
                selected={searchToDate}
                onChange={(d) => {
                  setSearchToDate(d ? (d as Date) : undefined);
                }}
                onClear={() => setSearchToDate(undefined)}
                maxDate={new Date()}
                dateFormat={DateFormats.DATE}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                dropdownMode="select"
                isClearable
              />
            </Menu.Item>
          </div>
          <div>
            <Menu.Item>
              <Button
                fluid
                content="Search"
                icon="search"
                color="olive"
                onClick={() => {
                  setIsQuerying(true);
                  getClaims({
                    variables: {
                      criteria,
                      limit: ENTRIES_PER_PAGE,
                      offset: !!pageCount ? ENTRIES_PER_PAGE * pageCount : null,
                    },
                  });
                }}
              />
            </Menu.Item>
            <Menu.Item>
              <BillingExcelExportModal criteria={criteria} />
            </Menu.Item>
          </div>
        </Menu.Menu>
        <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Menu.Item>
            <Input
              value={searchPatient}
              onChange={(_, { value }) => setSearchPatient(value)}
              icon="search"
              placeholder="Search patient..."
              //loading={typing || loading}
              onKeyDown={handleSearchOnKeyDown}
            />
          </Menu.Item>
          <Menu.Item>
            <Input
              value={searchInsurance}
              onChange={(_, { value }) => setSearchInsurance(value)}
              icon="search"
              placeholder="Search insurance..."
              //loading={typing || loading}
              onKeyDown={handleSearchOnKeyDown}
            />
          </Menu.Item>
          <Menu.Item>
            <Dropdown
              style={{ minWidth: '15rem' }}
              placeholder="Filter by status"
              fluid
              selection
              onChange={(e, data) => {
                handleStatusValue(data.value as string);
              }}
              onKeyDown={handleSearchOnKeyDown}
              options={simplifiedClaimStatusOptions}
            />
          </Menu.Item>
          <Menu.Item>
            <Dropdown
              style={{ minWidth: '15rem' }}
              placeholder="Filter by inventory used"
              fluid
              selection
              onChange={(e, data) => {
                handleInventoryUsedValue(data.value?.toString() || '');
              }}
              onKeyDown={handleSearchOnKeyDown}
              options={[
                {
                  key: 'all',
                  text: 'All inventories',
                  value: 'all',
                },
                {
                  key: 'vfc',
                  text: 'VFC',
                  value: 'vfc',
                },
                {
                  key: 'private',
                  text: 'Private',
                  value: 'private',
                },
              ]}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {error ? (
        <Message error>{`${error.message}. Please contact Canid.`}</Message>
      ) : (
        <Table selectable>
          <Table.Header>
            <Table.HeaderCell>Practice</Table.HeaderCell>
            <Table.HeaderCell>Patient</Table.HeaderCell>
            <Table.HeaderCell>Insurance</Table.HeaderCell>
            <Table.HeaderCell>Service Date</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Vaccine/s</Table.HeaderCell>
            <Table.HeaderCell>Inventory Used</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">CPT Codes</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Claimed Amount
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Paid Amount</Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="center">
              Status
            </Table.HeaderCell>
            <Table.HeaderCell>EOB Link</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {loading ? (
              <Table.Row>
                <Table.Cell colSpan={11}>
                  <Segment basic>
                    <Placeholder fluid>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Segment>
                </Table.Cell>
              </Table.Row>
            ) : !!data?.billingClaims.length ? (
              data.billingClaims.map((bc, idx) => {
                return <BillingListRow key={idx} data={bc} />;
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan={11}>
                  <Message>
                    {!data
                      ? 'Click the green SEARCH button on the top right corner to see claims.'
                      : 'No Claims Found.'}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>
                <Button
                  basic
                  content={
                    !totalClaimsLoading
                      ? 'Show total'
                      : `Total: ${claimsCount?.aggregating.aggregate.count}`
                  }
                  loading={claimsCountLoading}
                  onClick={() => {
                    getClaimsCount({
                      variables: {
                        criteria,
                      },
                    });
                    setTotalClaimsLoading(true);
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={11} textAlign="right">
                <Button
                  basic
                  icon="angle left"
                  onClick={() => setPageCount(pageCount - 1)}
                  disabled={pageCount === 0}
                />
                <Button
                  basic
                  icon="angle right"
                  onClick={() => setPageCount(pageCount + 1)}
                  disabled={
                    !data?.billingClaims.length ||
                    data.billingClaims.length < ENTRIES_PER_PAGE
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
    </>
  );
};

//-----------------------------------BILLING LIST ROW-------------------------------------//

interface BillingListRowProps {
  data: PracticePortalBillingClaim;
}

const BillingListRow = ({ data }: BillingListRowProps) => {
  return (
    <Table.Row>
      <Table.Cell>
        <b>{data.practiceName}</b>
      </Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>{`${data.firstName} ${data.lastName}`}</p>(
          {moment(data.birthdate).format(DateFormats.DATE)})
        </div>
      </Table.Cell>
      <Table.Cell>
        <>
          <p style={{ margin: '0' }}>{data.insuranceName || '-'}</p>
          <Label
            basic
            size="tiny"
            content={`Member ID: ${data.insuranceMemberId || '-'}`}
          />
          <Label
            color={data.insuranceVfcEligible ? 'orange' : 'teal'}
            size="tiny"
            content={data.insuranceVfcEligible ? 'VFC' : 'Private'}
          />
        </>
      </Table.Cell>
      <Table.Cell>
        {data.givenAt ? moment(data.givenAt).format(DateFormats.DATE) : '-'}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {data.vaccines?.map((vaccine) => {
            return (
              <div style={{ margin: '0.1rem 0 0.1rem 0' }}>
                <Label content={vaccine.name} />
              </div>
            );
          })}
        </div>
      </Table.Cell>
      <Table.Cell>
        {!!data.inventoryVfcCount && (
          <Label
            style={{ marginBottom: '0.2rem' }}
            size="small"
            content={`VFC: ${data.inventoryVfcCount}`}
            color="orange"
          />
        )}
        {!!data.inventoryPrivateCount && (
          <Label
            style={{ marginBottom: '0.2rem' }}
            size="small"
            content={`Private: ${data.inventoryPrivateCount}`}
            color="teal"
          />
        )}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {data.status === BillingClaimStatus.PENDING ? (
          <Popup
            position="top center"
            content="Claim processing is pending."
            trigger={<Icon size="large" color="orange" name="warning sign" />}
          />
        ) : !!data.vaccinationsCptCodes?.length ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {data.vaccinationsCptCodes?.map((cpt, idx) => (
              <Label
                key={idx}
                style={{ margin: '0.1rem 0 0.1rem 0' }}
                color={cpt.paidAmount ? 'olive' : 'grey'}
                content={cpt.cptCode}
              />
            ))}
          </div>
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {data.status === BillingClaimStatus.PENDING ? (
          '-'
        ) : !!data.vaccinationsCptCodes?.length ? (
          <Popup
            position="top center"
            content={`Total $${data.totalClaimAmount}`}
            trigger={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {data.vaccinationsCptCodes?.map((cpt, idx) => (
                  <p key={idx} style={{ margin: '0.3rem 0 0.3rem 0' }}>
                    {cpt.claimAmount ? `$${cpt.claimAmount}` : '-'}
                  </p>
                ))}
              </div>
            }
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {data.status === BillingClaimStatus.PENDING ? (
          '-'
        ) : !!data.vaccinationsCptCodes?.length ? (
          <Popup
            position="top center"
            content={`Total $${data.vaccinationsCptCodes
              .map((cpt) => cpt.paidAmount ?? 0)
              .reduce((accum, curr) => (accum += curr), 0)}`}
            trigger={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {data.vaccinationsCptCodes?.map((cpt, idx) => (
                  <p key={idx} style={{ margin: '0.3rem 0 0.3rem 0' }}>
                    {cpt.paidAmount ? `$${cpt.paidAmount}` : '-'}
                  </p>
                ))}
              </div>
            }
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Label
          basic
          content={simplifiedClaimStatusesFunction(
            data.status,
            data.insuranceVfcEligible,
            data.revisionStatus
          )}
        />
      </Table.Cell>
      <Table.Cell>
        {data.note?.url && (
          <Popup
            trigger={
              <Icon
                name="linkify"
                size="large"
                color="grey"
                style={{ cursor: 'pointer' }}
              />
            }
            on="click"
            content={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                EOB Link:{' '}
                <a href={data.note.url} target="_blank">
                  {data.note.url}
                </a>
              </div>
            }
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default BillingList;
