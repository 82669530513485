import { useState } from 'react';
import { Button, Form, Icon, Modal, Segment } from 'semantic-ui-react';
import { useSession } from '@bluefox/contexts/ApplicationState';
import { useMutation } from '@apollo/client';
import { usePractice } from '@bluefox/contexts/Practice';
import { toast } from 'react-semantic-toasts';
import { CreateTicketMutation } from '@bluefox/graphql/tickets';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import VaccineTypesPicker from '@bluefox/ui/VaccineTypesPicker';
import {
  Ticket,
  TicketDetailType,
  TicketStatusEnum,
  TicketTypes,
} from '@bluefox/models/Tickets';

interface ReportAnIssueModalType {
  isOpen: boolean;
  closeModal: () => void;
}

const ReportAnIssueModal = ({ isOpen, closeModal }: ReportAnIssueModalType) => {
  const {
    name,
    handler,
    timezone: practiceTimezone,
    id: practiceId,
  } = usePractice();

  const session = useSession();
  const [vaccineTypesInput, setVaccineTypesInput] = useState<string>('');
  const [lot, setLot] = useState<string | undefined>();
  const [selectedIssue, setSelectedIssue] = useState<string | undefined>(
    'other'
  );
  const [issueDate, setIssueDate] = useState<Date | undefined>();
  const [numberExpected, setNumberExpected] = useState<Number | undefined>();
  const [numberBroken, setNumberBroken] = useState<Number | undefined>();
  const [createTicket] = useMutation(CreateTicketMutation);
  const handleChangeIssue = (e: any, { value }: any) => {
    setSelectedIssue(value);
  };

  const textToSplit = vaccineTypesInput.toString();
  const textsplitted = textToSplit?.split(',');
  const vaccineTypes = textsplitted?.join(', ');

  const handleSubmit = () => {
    const ticketDetail: TicketDetailType = {
      fields: [
        { title: 'Practice', detail: name },
        { title: 'Handler', detail: handler },
        { title: 'Email', detail: session.account?.email || '' },
        { title: 'Vaccine Type', detail: vaccineTypes },
        { title: 'Lot Number', detail: lot || '' },
        { title: 'Issue ', detail: selectedIssue || '' },
        {
          title: 'Issue Date',
          detail: issueDate ? issueDate.toISOString() : '',
        },
        {
          title: 'Number of Expected Doses',
          detail: numberExpected ? numberExpected.toString() : '0',
        },
        {
          title: 'Number of Broken Doses',
          detail: numberBroken ? numberBroken.toString() : '0',
        },
      ],
    };

    const ticket: Ticket = {
      practiceId,
      status: TicketStatusEnum.OPEN,
      detail: ticketDetail,
      type: TicketTypes.INVENTORY_RECEIPT_ISSUES,
    };
    createTicket({
      variables: { ticket },
    })
      .then(() => {
        toast({
          title: 'Your report has been submitted',
          type: 'success',
          time: 5000,
        });
        setVaccineTypesInput('');
        setLot(undefined);
        setSelectedIssue(undefined);
        setIssueDate(undefined);
        setNumberExpected(undefined);
        setNumberBroken(undefined);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
    closeModal();
  };

  return (
    <Modal
      dimmer="blurring"
      open={isOpen}
      onClose={closeModal}
      data-automation-id="inventory-wasted-dose"
    >
      <Modal.Header>
        <Icon.Group>
          <Icon name="box" />
          <Icon name="bug" color="red" corner="top right" />
        </Icon.Group>
        Reporting Inventory Receipt Issues
      </Modal.Header>
      <Modal.Content>
        Please fill out the form below if you had any issues receiving
        inventory.
        <Segment padded>
          <Form id="issue-form" onSubmit={handleSubmit}>
            <Form.Field required>
              <label>What vaccine type is this related to?</label>
              <VaccineTypesPicker
                required
                onChange={(data) => setVaccineTypesInput(data.toString())}
              />
            </Form.Field>
            <Form.Input
              onChange={(e) => setLot(e.target?.value.toString())}
              required
              fluid
              label="What lot is this related to? (Please provide lot number and expiration date)."
              placeholder="Lot Number"
            />
            <Form.Field required>
              <label>What was the issue?</label>
              <Form.Group>
                <Form.Radio
                  label="Delayed Delivery"
                  value="delayed"
                  checked={selectedIssue === 'delayed'}
                  onChange={handleChangeIssue}
                />
                <Form.Radio
                  label="Expected inventory not received"
                  value="not-received"
                  checked={selectedIssue === 'not-received'}
                  onChange={handleChangeIssue}
                />
                <Form.Radio
                  label="We received broken/damaged doses"
                  value="broken"
                  checked={selectedIssue === 'broken'}
                  onChange={handleChangeIssue}
                />
                <Form.Radio
                  label="Other"
                  value="other"
                  checked={selectedIssue === 'other'}
                  onChange={handleChangeIssue}
                  defaultChecked
                />
              </Form.Group>
            </Form.Field>
            <Form.Group inline widths="equal">
              <Form.Field required>
                <label>When did this issue happen?</label>
                <DateTimePicker
                  placeholderText="Issue date"
                  tz={practiceTimezone}
                  selected={issueDate}
                  onChange={(d) => setIssueDate(d ? (d as Date) : undefined)}
                  onClear={() => setIssueDate(undefined)}
                  maxDate={new Date()}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  isClearable
                />
              </Form.Field>
              <Form.Input
                onChange={(e) =>
                  setNumberExpected(Number.parseInt(e.target?.value))
                }
                required
                fluid
                label="How many doses where expected to be received?"
                placeholder="Number of Expected Doses"
              />
            </Form.Group>

            <Form.Input
              onChange={(e) =>
                setNumberBroken(Number.parseInt(e.target?.value))
              }
              fluid
              label="If broken or damaged doses, how many doses where affected?"
              placeholder="Number of Broken Doses"
            />
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          type="submit"
          form="issue-form"
          disabled={!vaccineTypes || !issueDate}
        >
          Submit
        </Button>

        <Button secondary type="button" onClick={() => closeModal()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ReportAnIssueModal;
