import { usDollarCurrency } from '@bluefox/lib/formatters';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import SummaryTableItem, {
  SummaryItemProp,
  SummaryTableItemProps,
} from './SummaryTableItem';

const tableStyles = StyleSheet.create({
  table: {
    width: 'auto',
  },
  titleSection: {
    padding: '10 8',
    borderTop: '1px solid #BCCBDC',
    borderBottom: '1px solid #BCCBDC',
  },
  title: {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 16 / 15,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  headRow: {
    padding: '0 8',
    flexDirection: 'row',
    border: '1 solid #BCCBDC',
    borderLeft: 0,
    borderRight: 0,
    backgroundColor: '#F9FAFC',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  bodyRow: {
    padding: '0 8',
    flexDirection: 'row',
    borderBottom: '1 solid #EBEFF5',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  nameCol: {
    width: '70%',
    textAlign: 'left',
  },
  valueCol: {
    width: '30%',
    textAlign: 'right',
  },
  cell: {
    padding: 8,
  },
});

export type SummaryTableProps = {
  practices: SummaryTableItemProps[];
  discount?: SummaryItemProp;
  totalExtraItems?: SummaryItemProp;
  fee?: SummaryItemProp;
};

const SummaryTable = ({
  practices,
  discount,
  totalExtraItems,
  fee,
  style,
}: SummaryTableProps & { style?: Style | Style[] }) => (
  <View style={{ ...tableStyles.table, ...style }}>
    <View style={tableStyles.titleSection}>
      <Text style={tableStyles.title}>Summary</Text>
    </View>
    {practices.map((practice) => (
      <SummaryTableItem item={practice} />
    ))}
    {discount && <SummaryTableItem item={discount} highlighted indented />}
    {totalExtraItems && <SummaryTableItem item={totalExtraItems} />}
    {fee && <SummaryTableItem item={fee} />}
  </View>
);

export default SummaryTable;
