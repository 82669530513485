import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Header, Loader, Message, Segment } from 'semantic-ui-react';
import { ResponsiveBar } from '@nivo/bar';

import { VaccinationsPerWeekQuery } from '@bluefox/graphql/vaccinations';
import {
  VaccinationCountsPerWeek,
  VaccinationCountPerWeek,
} from '@bluefox/models/Vaccination';

import { getColor } from './configTheme';
import { VaccinationsDatum } from './models';
import { DateFormats } from '@bluefox/models/Dates';

const ChartVaccinations = () => {
  const [chartData, setChartData] = useState<VaccinationsDatum[]>([]);
  const today = moment().add(1, 'day').format(DateFormats.DATE);
  const lastMonth = moment().subtract(1, 'months').format(DateFormats.DATE);
  const { data, loading, error } = useQuery<VaccinationCountsPerWeek>(
    VaccinationsPerWeekQuery,
    {
      variables: {
        startingDate: lastMonth,
        endingDate: today,
      },
    }
  );

  useEffect(() => {
    if (!data?.vaccinationsPerWeek.length) return;
    const vaccinations: VaccinationCountPerWeek[] = data?.vaccinationsPerWeek;

    const _vaccinationsBarArr: VaccinationsDatum[] = vaccinations.map(
      (vax: VaccinationCountPerWeek) => {
        const _weeksAgo = moment(new Date()).isoWeek() - vax.week;
        const index =
          _weeksAgo > 1
            ? `${_weeksAgo} weeks ago`
            : _weeksAgo === 1
            ? 'a week ago'
            : 'this week';

        return {
          id: index,
          vfc: vax.vfcEligible ? vax.count : 0,
          notVfc: !!!vax.vfcEligible ? vax.count : 0,
        };
      }
    );

    setChartData(_vaccinationsBarArr);
  }, [data?.vaccinationsPerWeek]);

  return (
    <StyledSegement>
      <Header textAlign="center">Vaccinations per week</Header>
      <StyledContent>
        {loading && <Loader />}
        {error && <Message error>{error?.message}</Message>}
        {chartData.length > 0 && (
          <ResponsiveBar
            data={chartData}
            keys={['vfc', 'notVfc']}
            colors={getColor}
            margin={{ top: 0, right: 30, bottom: 60, left: 70 }}
            padding={0.3}
            groupMode="grouped"
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.3]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: (e) => Math.floor(e) === e && e,
              tickSize: 0,
              tickPadding: 0,
              tickValues: 5,
              tickRotation: 0,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 2.5]],
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 1,
                itemWidth: 80,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            role="application"
            ariaLabel="Inventory"
          />
        )}
      </StyledContent>
    </StyledSegement>
  );
};

export default ChartVaccinations;

const StyledSegement = styled(Segment)`
  height: 25rem;
`;
const StyledContent = styled.div`
  height: 19rem;
`;
