import { Dispatch, SetStateAction, CSSProperties } from 'react';
import { Pagination } from 'semantic-ui-react';

export type Props = {
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  onPageChange?: (newActivePage: number) => void;
  style?: CSSProperties;
};

const InvoiceListPagination = ({
  activePage,
  totalPages,
  setActivePage,
  onPageChange,
  style,
}: Props) => {
  return (
    <Pagination
      activePage={activePage}
      totalPages={totalPages}
      onPageChange={(_, { activePage }) => {
        setActivePage(activePage as number);
        onPageChange?.(activePage as number);
      }}
      style={style}
    />
  );
};

export default InvoiceListPagination;
