import { Header, TableCell, TableRow } from 'semantic-ui-react';
import {
  InventoryOrders,
  OrderStatuses,
} from '@bluefox/models/InventoryOrders';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters';
import React from 'react';

type InventoryOrdersTableRowProps = {
  inventoryOrder: InventoryOrders;
};

export const InventoryOrdersTableRow = ({
  inventoryOrder,
}: InventoryOrdersTableRowProps) => {
  return (
    <TableRow>
      <TableCell>
        {inventoryOrder.date
          ? formatDateToMMDDYYYYV2(inventoryOrder.date.toString())
          : '-'}
      </TableCell>
      <TableCell>{inventoryOrder.orderNumber}</TableCell>
      <TableCell>
        <Header as="h4">
          <Header.Content>{inventoryOrder.vaccine?.name}</Header.Content>
          <Header.Subheader>
            CVX: {inventoryOrder.vaccine?.cvx} ·{' '}
            {inventoryOrder.vaccine?.manufacturer} ·{' '}
            {inventoryOrder.vaccine?.saleNdc}
          </Header.Subheader>
        </Header>
      </TableCell>
      <TableCell>{inventoryOrder.doses}</TableCell>
      <TableCell>{inventoryOrder.inventory?.lot || '-'}</TableCell>
      <TableCell>
        {inventoryOrder.inventory?.expiration
          ? formatDateToMMDDYYYYV2(
              inventoryOrder.inventory?.expiration.toString()
            )
          : '-'}
      </TableCell>
      <TableCell>
        {inventoryOrder.estimatedShippingDate
          ? formatDateToMMDDYYYYV2(
              inventoryOrder.estimatedShippingDate.toString()
            )
          : '-'}
      </TableCell>
      <TableCell>{inventoryOrder.trackingNumber}</TableCell>
      <TableCell>
        {inventoryOrder.status === OrderStatuses.CLOSED
          ? OrderStatuses.RECEIVED
          : inventoryOrder.status}
      </TableCell>
    </TableRow>
  );
};
