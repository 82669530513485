import { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { useQuery } from '@apollo/client';
import {
  BillingTotalAdminPaidAmountVfcPP,
  PrivateIncomingPaymentsView,
} from '@bluefox/graphql/billing';
import {
  PrivateIncomingPayments,
  VfcIncomingPayments,
} from '@bluefox/models/Billing';
import { Header, Segment, Divider } from 'semantic-ui-react';
import IncomingPaymentsCard from '@components/billing/IncomingPaymentsCard';

interface PrivateIncomingPaymentsData {
  payments: PrivateIncomingPayments[];
}

interface VfcIncomingPaymentsData {
  payments: {
    aggregate: {
      sum: VfcIncomingPayments;
    };
  };
}

interface IncomingPaymentsProps {
  practiceIds: string[];
}

const IncomingPayments = ({ practiceIds }: IncomingPaymentsProps) => {
  const [criteriaPrivate, setCriteriaPrivate] = useState<object>({});
  const [criteriaVfc, setCriteriaVfc] = useState<object>({});

  const {
    data: privateIncomingPayments,
    loading: privLoading,
    called: privCalled,
  } = useQuery<PrivateIncomingPaymentsData>(PrivateIncomingPaymentsView, {
    variables: {
      criteria: criteriaPrivate,
    },
  });

  const {
    data: vfcIncomingPayments,
    loading: vfcLoading,
    called: vfcCalled,
  } = useQuery<VfcIncomingPaymentsData>(BillingTotalAdminPaidAmountVfcPP, {
    variables: {
      criteria: criteriaVfc,
    },
  });

  const privateIncomingPaymentsData =
    privateIncomingPayments && privateIncomingPayments.payments.length > 1
      ? privateIncomingPayments?.payments.reduce(
          (acc, payment) => {
            return {
              patientCount: acc.patientCount + payment.patientCount,
              vaccCount: acc.vaccCount + payment.vaccCount,
              paidAmountAdm: acc.paidAmountAdm + payment.paidAmountAdm,
              paidAmountInv: acc.paidAmountInv + payment.paidAmountInv,
            };
          },
          { patientCount: 0, vaccCount: 0, paidAmountAdm: 0, paidAmountInv: 0 }
        )
      : {
          patientCount: privateIncomingPayments?.payments[0]?.patientCount || 0,
          vaccCount: privateIncomingPayments?.payments[0]?.vaccCount || 0,
          paidAmountAdm:
            privateIncomingPayments?.payments[0]?.paidAmountAdm || 0,
          paidAmountInv:
            privateIncomingPayments?.payments[0]?.paidAmountInv || 0,
        };

  useEffect(() => {
    let _criteriaPrivate = {};

    let _criteriaVfc = {};

    _criteriaPrivate = {
      practiceId: { _in: practiceIds },
    };

    _criteriaVfc = {
      practiceId: { _in: practiceIds },
    };

    setCriteriaPrivate(_criteriaPrivate);
    setCriteriaVfc(_criteriaVfc);
  }, [practiceIds]);

  const totalAmountPrivate = (
    paidAmount: number,
    privateIncomingPayments?: PrivateIncomingPaymentsData
  ): number => {
    if (!privateIncomingPayments?.payments.length || !paidAmount) return 0;
    return paidAmount;
  };

  const totalPatientsOrVaccinationsPrivate = (
    paidAmount: number,
    totalToShow: number,
    privateIncomingPayments?: PrivateIncomingPaymentsData
  ): number => {
    if (totalAmountPrivate(paidAmount, privateIncomingPayments) > 0)
      return totalToShow;
    return 0;
  };

  const totalAmountVFC = (
    vfcIncomingPayments?: VfcIncomingPaymentsData
  ): number => {
    if (
      !vfcIncomingPayments?.payments?.aggregate?.sum?.paidAmountAdministration
    )
      return 0;
    return vfcIncomingPayments?.payments?.aggregate?.sum
      ?.paidAmountAdministration;
  };

  const totalPatientsOrVaccinationsVFC = (
    vfcIncomingPayments?: VfcIncomingPaymentsData,
    totalToShow?: number
  ): number | undefined => {
    if (totalAmountVFC(vfcIncomingPayments) > 0) return totalToShow;
    return 0;
  };

  return (
    <>
      <Segment padded basic>
        <Header as="h2">Incoming Payments</Header>
      </Segment>
      <StyledSegment>
        <Header as="h2" style={{ margin: '1.5rem 0 0 0' }}>
          Private Claims
        </Header>
        <StyledCardsRow>
          <IncomingPaymentsCard
            title="Administration CPT Codes"
            titleColor="#5dbcd1"
            totalAmount={`$ ${totalAmountPrivate(privateIncomingPaymentsData?.paidAmountAdm, privateIncomingPayments).toFixed(2)}`}
            totalPatients={totalPatientsOrVaccinationsPrivate(
              privateIncomingPaymentsData?.paidAmountAdm,
              privateIncomingPaymentsData?.patientCount,
              privateIncomingPayments
            )}
            totalVaccinations={totalPatientsOrVaccinationsPrivate(
              privateIncomingPaymentsData?.paidAmountAdm,
              privateIncomingPaymentsData?.vaccCount,
              privateIncomingPayments
            )}
            loading={privLoading || !privCalled}
          />
          <IncomingPaymentsCard
            title="Inventory CPT Codes"
            titleColor="#5dbcd1"
            totalAmount={`$ ${totalAmountPrivate(privateIncomingPaymentsData?.paidAmountInv, privateIncomingPayments).toFixed(2)}`}
            totalPatients={totalPatientsOrVaccinationsPrivate(
              privateIncomingPaymentsData?.paidAmountInv,
              privateIncomingPaymentsData?.patientCount,
              privateIncomingPayments
            )}
            totalVaccinations={totalPatientsOrVaccinationsPrivate(
              privateIncomingPaymentsData?.paidAmountInv,
              privateIncomingPaymentsData?.vaccCount,
              privateIncomingPayments
            )}
            loading={privLoading || !privCalled}
          />
        </StyledCardsRow>
        <Divider style={{ width: '100%' }} />
        <Header as="h2" style={{ margin: '1.5rem 0 0 0' }}>
          VFC Claims
        </Header>
        <StyledCardsRow>
          <IncomingPaymentsCard
            title="Administration CPT Codes"
            titleColor="#f5714b"
            totalAmount={`$ ${totalAmountVFC(vfcIncomingPayments).toFixed(2)}`}
            totalPatients={totalPatientsOrVaccinationsVFC(
              vfcIncomingPayments,
              vfcIncomingPayments?.payments?.aggregate?.sum?.patientCount
            )}
            totalVaccinations={totalPatientsOrVaccinationsVFC(
              vfcIncomingPayments,
              vfcIncomingPayments?.payments?.aggregate?.sum?.vaccinationCount
            )}
            loading={vfcLoading || !vfcCalled}
          />
        </StyledCardsRow>
      </StyledSegment>
    </>
  );
};

export default IncomingPayments;

const StyledSegment = styled(Segment)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCardsRow = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
`;
