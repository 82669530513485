import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import LoadingBox from '@bluefox/ui/LoadingBox';
import {
  InsertAppointmentMutation,
  PracticePatientAppointmentsQuery,
} from '@bluefox/graphql/appointments';
import { PracticePatientProfileQuery } from '@bluefox/graphql/patients';
import { Appointment } from '@bluefox/models/Appointment';
import {
  Button,
  Card,
  Container,
  Header,
  Message,
  Popup,
  Grid,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { AppointmentCard } from '@components/AppointmentWidgets';
import { usePractice } from '@bluefox/contexts';
import { DateFormats } from '@bluefox/models/Dates';

interface RouterParamsType {
  practicePatientId?: string;
}

interface AppointmentsData {
  appointments: Appointment[];
}

const Appointments = () => {
  const { practicePatientId } = useParams<RouterParamsType>();
  const { data, loading, refetch } = useQuery<AppointmentsData>(
    PracticePatientAppointmentsQuery,
    {
      variables: {
        practicePatientId,
      },
    }
  );

  const [pastAppointments, setPastAppointments] = useState<Appointment[]>([]);
  const [futureAppointments, setFutureAppointments] = useState<Appointment[]>(
    []
  );

  useEffect(() => {
    if (!data) return;
    const now = new Date();

    const past: Appointment[] = [];
    const future: Appointment[] = [];

    data.appointments.forEach((appointment) => {
      if (new Date(appointment.time).getTime() < now.getTime()) {
        past.push(appointment);
      } else {
        future.push(appointment);
      }
    });

    setPastAppointments(past);
    setFutureAppointments(future);
  }, [data]);

  if (loading) {
    return <LoadingBox />;
  }

  if (!practicePatientId) return null;

  return (
    <>
      <Header as="h1">Appointments</Header>
      <Grid columns={2}>
        <Grid.Column>
          <Container textAlign="left"></Container>
        </Grid.Column>
        <Grid.Column>
          <Container textAlign="right">
            <CreateAppointment practicePatientId={practicePatientId} />
          </Container>
        </Grid.Column>
      </Grid>
      <Header
        color="green"
        dividing
        content="Coming"
        disabled={!futureAppointments.length}
      />
      {!!futureAppointments.length ? (
        <Card.Group itemsPerRow={4}>
          {futureAppointments.map((a) => (
            <AppointmentCard
              key={a.id}
              appointment={a}
              practicePatientId={practicePatientId}
              onSave={refetch}
            />
          ))}
        </Card.Group>
      ) : (
        <Message info content="There are no coming appointments." />
      )}
      <Header
        dividing
        color="grey"
        content="Past"
        disabled={!pastAppointments.length}
      />
      {!!pastAppointments.length ? (
        <Card.Group itemsPerRow={4}>
          {pastAppointments.map((a) => (
            <AppointmentCard
              key={a.id}
              appointment={a}
              practicePatientId={practicePatientId}
              onSave={refetch}
            />
          ))}
        </Card.Group>
      ) : (
        <Message info content="There are no past appointments." />
      )}
    </>
  );
};

interface CreateAppointmentProps {
  practicePatientId: string;
}

const CreateAppointment = ({ practicePatientId }: CreateAppointmentProps) => {
  const [time, setTime] = useState<Date | null>(null);
  const { timezone: practiceTimezone } = usePractice();

  const [createAppointment, { loading }] = useMutation(
    InsertAppointmentMutation
  );

  const handleCreateAppointment = useCallback(() => {
    createAppointment({
      variables: {
        practicePatientId,
        time: time as Date,
        metadata: { sendEmail: false },
      },
      refetchQueries: [
        {
          query: PracticePatientAppointmentsQuery,
          variables: {
            practicePatientId,
          },
        },
        {
          query: PracticePatientProfileQuery,
          variables: {
            id: practicePatientId,
          },
        },
      ],
    });
    toast({
      type: 'success',
      title: 'The appointment has been created successfully.',
    });
  }, [createAppointment, practicePatientId, time]);

  return (
    <Popup
      trigger={
        <Button
          data-automation-id={`patient-appointments-create-button`}
          icon="plus"
          content="Create Appointment"
          basic
          primary
        />
      }
      pinned
      on="click"
      position="bottom right"
    >
      <DateTimePicker
        selected={time}
        onChange={(dt) => setTime(dt as Date)}
        inline
        absolute
        tz={practiceTimezone}
        dateFormat={DateFormats.DATE_WITH_TIME}
        showTimeInput
      />
      <Button
        onClick={() => {
          handleCreateAppointment();
        }}
        disabled={!time || loading}
        loading={loading}
        primary
        content="Save"
        icon="save"
        fluid
      />
    </Popup>
  );
};

export default Appointments;
