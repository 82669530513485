import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Label, Segment, Confirm } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import { Appointment, AppointmentStatus } from '@bluefox/models/Appointment';

import {
  UpdateAppointmentMutation,
  UpdateAppointmentStateMutation,
  DeleteAppointmentMutation,
} from '@bluefox/graphql/appointments';

import { PracticePatientProfileQuery } from '@bluefox/graphql/patients';

import AppointmentStatusPicker from '@components/AppointmentStatusPicker';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { usePractice } from '@bluefox/contexts';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';

interface AppointmentCardProps {
  appointment: Appointment;
  editing?: boolean;
  onSave: () => void;
  practicePatientId?: string;
}

export const AppointmentCard = ({
  appointment,
  editing,
  onSave,
  practicePatientId,
}: AppointmentCardProps) => {
  const { id, time, status } = appointment;
  const practice = usePractice();

  const [_editing, setEditing] = useState(editing || false);
  const [_time, setTime] = useState<Date | null>(new Date(time));
  const [_status, setStatus] = useState(status as AppointmentStatus);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [save, { loading }] = useMutation<Appointment>(
    UpdateAppointmentMutation
  );

  const [deleteAppointment] = useMutation<Appointment>(
    DeleteAppointmentMutation
  );

  const handleSaveClick = useCallback(() => {
    save({
      variables: {
        id,
        time: _time,
        status: _status,
        metadata: { sendEmail: false },
      },
    }).then(() => {
      setEditing(editing || false);
      toast({
        type: 'success',
        title: 'The appointment has been updated successfully.',
      });
    });
  }, [save, id, _time, _status, editing]);

  const handleDeleteAppointment = () => {
    deleteAppointment({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: PracticePatientProfileQuery,
          variables: {
            id: practicePatientId,
          },
        },
      ],
    }).then(() => {
      setOpenConfirm(false);
      onSave();
      toast({
        type: 'success',
        title: 'The appointment has been deleted successfully.',
      });
    });
  };

  const handleCancelClick = () => {
    setTime(new Date(time));
    setStatus(status as AppointmentStatus);
    setEditing(editing || false);
  };

  if (_editing) {
    return (
      <Card>
        <Card.Content>
          <Card.Header>
            <Form>
              <DateTimePicker
                data-automation-id={`appointments-widget-date`}
                onChange={(value) => {
                  setTime(value as Date);
                }}
                selected={_time ? (_time as Date) : null}
                timeInputLabel="Time: "
                absolute
                tz={practice.timezone}
                dateFormat={DateFormats.DATE_WITH_TIME}
                showTimeInput
                name="dateTime"
                inputProps={{ fluid: true }}
              />
            </Form>
          </Card.Header>
          <Card.Description>
            <AppointmentStatusPicker
              defaultValue={_status}
              onSelectionChange={(value) =>
                setStatus(value as AppointmentStatus)
              }
              fluid
              button
              disabled={loading}
            />
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button
            data-automation-id={`appointments-widget-save`}
            onClick={handleSaveClick}
            disabled={!_time}
            primary
            content="Save"
            icon="save"
            size="mini"
          />
          <Button
            data-automation-id={`appointments-widget-cancel`}
            onClick={handleCancelClick}
            basic
            secondary
            content="Cancel"
            icon="cancel"
            size="mini"
          />
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Content>
        <Card.Header>
          {moment(time).format(DateFormats.DATE_WITH_TIME)}
        </Card.Header>
        <Card.Description>{status}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            data-automation-id={`appointments-description-edit-button`}
            onClick={() => setEditing(editing || true)}
            content="Edit"
            icon="edit"
            basic
            size="mini"
          />
          <Button
            data-automation-id={`appointments-description-delete-button`}
            onClick={() => setOpenConfirm(true)}
            content="Delete"
            icon="trash"
            basic
            size="mini"
            color="red"
          />
          <Confirm
            size="tiny"
            content="Are you you want to delete this appointment?"
            open={openConfirm}
            onCancel={() => setOpenConfirm(false)}
            onConfirm={handleDeleteAppointment}
            confirmButton="Yes"
          />
        </div>
      </Card.Content>
    </Card>
  );
};

interface AppointmentBarProps {
  appointment: Appointment;
}

export const AppointmentBar = ({ appointment }: AppointmentBarProps) => {
  const [saveStatusMutation] = useMutation<Appointment>(
    UpdateAppointmentStateMutation
  );

  const saveStatus = (id: Appointment['id'], status: string) => {
    saveStatusMutation({
      variables: {
        id,
        status,
      },
    }).then((res) => {
      toast({
        title: `The appointment status has been updated successfully`,
        type: 'success',
        time: 3000,
      });
    });
  };

  return (
    <Segment textAlign="right" basic>
      <Label color="teal">
        Today's Appointment:{` `}
        {moment(appointment.time).format('MM/DD/yyyy h:mm a')}
        {` `}
        <AppointmentStatusPicker
          onSelectionChange={(status) =>
            saveStatus(appointment.id, status as string)
          }
          defaultValue={appointment.status}
          basic
          labeled
        />
      </Label>
    </Segment>
  );
};
