import React, { ReactNode } from 'react';
import styled from 'styled-components';

type UserCircularIconProps = {
  children: ReactNode;
  onClick?: () => void;
  isLoggedIn?: boolean;
  color?: string;
  hoverable?: boolean;
};

const UserCircularIcon = ({
  children,
  onClick,
  isLoggedIn,
  color,
  hoverable,
}: UserCircularIconProps) => {
  return (
    <StyledCircularBox
      style={{
        border: isLoggedIn ? '4px solid #E74C3C' : '',
        pointerEvents: isLoggedIn ? 'none' : 'auto',
      }}
      color={color ? color : '#A6ACAF'}
      onClick={onClick}
      hoverable={hoverable}
    >
      <StyledContentText>{children}</StyledContentText>
    </StyledCircularBox>
  );
};

type StyledCircularBoxProps = {
  hoverable?: boolean;
};

const StyledCircularBox = styled.div<StyledCircularBoxProps>`
  width: 100px;
  height: 100px;
  opacity: 1;
  border-radius: 50%;
  margin: 1.5rem 2.5rem 0rem 2.5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};

  &:hover {
    opacity: ${(props) => (props.hoverable ? '0.5' : '1')};
    cursor: ${(props) => (props.hoverable ? 'pointer' : 'default')};
  }
`;

const StyledContentText = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
`;

export default UserCircularIcon;
