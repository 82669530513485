import { gql } from '@apollo/client';

export const ChangeUserPasswordMutation = gql`
  mutation ChangeUserPasswordMutation($password: String!) {
    ChangePassword(password: $password) {
      responseCode
    }
  }
`;

export const logoutOnSuspendedPracticeSubscription = gql`
  subscription kickAccountOnSuspendedPractice {
    practices(where: { suspended: { _eq: true } }) {
      id
      suspended
    }
  }
`;
