import { Table } from 'semantic-ui-react';
import { useLazyQuery } from '@apollo/client';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  InventoryOrders,
  InventoryOrdersFilter,
  IsVFC,
  OrderStatuses,
  SelectAll,
} from '@bluefox/models/InventoryOrders';
import { GetInventoryOrders } from '@bluefox/graphql/orders';
import { usePractice } from '@bluefox/contexts';
import { InventoryOrdersTableRow } from '@screens/inventoryOrders/InventoryOrdersTableRow';
import { whereLikeInput } from '@bluefox/graphql/utils';

type InventoryOrdersTableProps = {
  inventoryOrdersFilter: InventoryOrdersFilter;
};

const InventoryOrdersTable = (props: InventoryOrdersTableProps) => {
  const { inventoryOrdersFilter } = props;

  const practice = usePractice();

  const [
    getInventoryOrders,
    { loading, error, data: inventoryOrdersQueryResponse },
  ] = useLazyQuery<{ inventoryOrders: [InventoryOrders] }>(GetInventoryOrders);

  const runQuery = () => {
    const buildStatusParam = () => {
      if (inventoryOrdersFilter?.status === SelectAll.ALL) {
        return undefined;
      }
      if (inventoryOrdersFilter?.status === OrderStatuses.RECEIVED) {
        return { _in: [OrderStatuses.RECEIVED, OrderStatuses.CLOSED] };
      }
      return { _eq: inventoryOrdersFilter?.status };
    };

    const buildParams = () => {
      return {
        where: {
          practiceId: { _eq: practice.id },
          vfc:
            inventoryOrdersFilter?.isFVC === SelectAll.ALL
              ? undefined
              : {
                  _eq: inventoryOrdersFilter?.isFVC !== IsVFC.NO,
                },
          vaccine: inventoryOrdersFilter?.vaccine
            ? {
                id: { _eq: inventoryOrdersFilter?.vaccine },
              }
            : undefined,
          orderNumber: inventoryOrdersFilter?.orderNumber
            ? { _ilike: whereLikeInput(inventoryOrdersFilter?.orderNumber) }
            : undefined,
          status: buildStatusParam(),
          date:
            !inventoryOrdersFilter?.dateFrom && !inventoryOrdersFilter?.dateTo
              ? undefined
              : {
                  _gte: inventoryOrdersFilter?.dateFrom || undefined,
                  _lte: inventoryOrdersFilter?.dateTo || undefined,
                },
        },
      };
    };
    const queryParams = buildParams();
    getInventoryOrders({
      variables: queryParams,
    });
  };

  useEffect(runQuery, [practice, inventoryOrdersFilter]);

  if (error) {
    return <h1>Error</h1>;
  }
  if (loading) {
    return <h1>Loading</h1>;
  }
  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Number</Table.HeaderCell>
            <Table.HeaderCell>Vaccine</Table.HeaderCell>
            <Table.HeaderCell>Doses</Table.HeaderCell>
            <Table.HeaderCell>Lot</Table.HeaderCell>
            <Table.HeaderCell>Expiration Date</Table.HeaderCell>
            <Table.HeaderCell>Shipping Date</Table.HeaderCell>
            <Table.HeaderCell>Tracking Number</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {inventoryOrdersQueryResponse?.inventoryOrders.map(
            (inventoryOrder) => (
              <InventoryOrdersTableRow
                key={inventoryOrder.id}
                inventoryOrder={inventoryOrder}
              />
            )
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default InventoryOrdersTable;
