import React, { useState } from 'react';
import { PDFPage, PDFDocument, rgb } from 'pdf-lib';
import moment from 'moment-timezone';
import { PatientData } from '@bluefox/models/Patient';
import { Vaccination } from '@bluefox/models/Vaccination';
import { vaccinationsByDosesCoordsMap } from './vaccinationCoords';
import { useApplicationState } from '@bluefox/contexts';
import PdfDrawerPreviewModal from '@utils/PdfDrawerPreviewModal';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters';

const twoDosesTypes = ['MMR', 'MEASLES', 'MUMPS', 'RUBELLA', 'VARICELLA'];

const threeDosesTypes = ['TDAP', 'TD', 'HepB'];

const fourDosesTypes = ['HIB', 'PneumoPCV'];

type Form680PdfProps = {
  patientData?: PatientData;
  vaccinations?: Vaccination[];
  pageBlurred?: boolean;
};

const Form680Pdf = ({
  patientData,
  vaccinations,
  pageBlurred,
}: Form680PdfProps) => {
  const { isEmbedded } = useApplicationState();

  const [pdfURL, setPdfURL] = useState('');
  const [openPdfPreviewModal, setOpenPdfPreviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  //----------- DRAW PATIENT INFO -----------//
  const drawPatientInfo = (page: PDFPage, patientData?: PatientData) => {
    // Patient's last name
    page.drawText(patientData ? patientData.lastName : '', {
      x: 93,
      y: 676,
      size: 10,
      color: rgb(0, 0, 0),
    });

    // Patient's first name
    page.drawText(patientData ? patientData.firstName : '', {
      x: 257,
      y: 676,
      size: 10,
      color: rgb(0, 0, 0),
    });

    // Patient's birthdate
    page.drawText(
      patientData ? moment(patientData.birthdate).format('MM/DD/YY') : '',
      {
        x: 450,
        y: 676,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );

    // Guardian's full name
    page.drawText(
      patientData && !!patientData.guardians?.length
        ? `${patientData.guardians[0].guardian.lastName}, ${patientData.guardians[0].guardian.firstName}`
        : '',
      {
        x: 93,
        y: 649,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );
  };

  //----------- DRAW VACCINATION DOSES -----------//
  const drawDoses = (page: PDFPage, coords: any, givenAt: string) => {
    page.drawText(formatDateToMMDDYYYYV2(givenAt), {
      x: coords.x,
      y: coords.y,
      size: 8,
      color: rgb(0, 0, 0),
    });
  };

  const limitDosesByType = (
    typesArr: string[],
    type: string | number,
    vaccinations: any,
    quantity: number
  ) => {
    if (
      typesArr.some((item) => item === type) &&
      vaccinations.groupedTypes[type].length >= quantity
    ) {
      vaccinations.groupedTypes[type].shift();
    }
  };

  const drawVaccinations = (
    page: PDFPage,
    vaccinations: Vaccination[] | undefined
  ) => {
    const dtapDtp = ['DTAP', 'DTP'];

    const groupedVaccinationsByType = vaccinations?.reduce(
      (vaccinationsObject, vaccination) => {
        for (const key in vaccination.vaccine?.types) {
          let type = vaccination.vaccine?.types[parseInt(key)] || 0;

          // If the type is in the DTAP, DTP list, we group them under the "DTAP_DTP_Group" name
          if (dtapDtp.includes(String(type))) {
            type = 'DTAP_DTP_Group';
          }

          if (!vaccinationsObject.groupedTypes[type]) {
            vaccinationsObject.groupedTypes[type] = [];
          }

          // If the type already has 5 vaccinations, remove the oldest one (the first element)
          if (vaccinationsObject.groupedTypes[type].length >= 5) {
            vaccinationsObject.groupedTypes[type].shift();
          }

          // If the type is included in the twoDosesTypes array and already has 2 vaccinations, remove the oldest one and show 2 doses
          limitDosesByType(twoDosesTypes, type, vaccinationsObject, 2);
          // If the type is included in the threeDosesTypes array and already has 3 vaccinations, remove the oldest one and show 3 doses
          limitDosesByType(threeDosesTypes, type, vaccinationsObject, 3);
          // If the type is included in the sixDosesTypes array and already has 4 vaccinations, remove the oldest one and show 4 doses
          limitDosesByType(fourDosesTypes, type, vaccinationsObject, 4);

          vaccinationsObject.groupedTypes[type].push(vaccination);
        }

        return vaccinationsObject;
      },
      { groupedTypes: {} } as any
    );

    for (const type in groupedVaccinationsByType.groupedTypes) {
      const vaccinations = groupedVaccinationsByType.groupedTypes[type];

      vaccinations.forEach((vaccination: Vaccination, index: number) => {
        const givenAt = vaccination.givenAt;

        const vaccinationCoordsByTypes = vaccinationsByDosesCoordsMap[type];

        if (!vaccinationCoordsByTypes) return;

        const pdfCoords = vaccinationCoordsByTypes[index];

        drawDoses(
          page,
          pdfCoords,
          vaccination.historic
            ? givenAt.toString()
            : moment(givenAt).format('MM/DD/YY')
        );
      });
    }
  };

  const downloadModifiedPDF = async () => {
    const pdfURL = process.env.REACT_APP_FLORIDA_FORM_680_PDF as string;

    try {
      const pdfBytes = await fetch(pdfURL).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const page = pdfDoc.getPages()[0];
      setLoading(false);

      drawPatientInfo(page, patientData);
      drawVaccinations(page, vaccinations);

      const modifiedPdfBytes = await pdfDoc.save();
      const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
        type: 'application/pdf',
      });

      const downloadUrl = URL.createObjectURL(modifiedPdfBlob);

      // Creates a URL for the preview of the modified PDF file
      return setPdfURL(downloadUrl);
    } catch (error) {
      setError(`Error while processing the PDF. ${error}`);
    }
  };

  return (
    <PdfDrawerPreviewModal
      buttonIcon="download"
      buttonLabel="Form 680"
      onClickMenuButton={() => {
        setLoading(true);
        setOpenPdfPreviewModal(true);
        downloadModifiedPDF();
      }}
      open={openPdfPreviewModal}
      onClose={() => {
        setOpenPdfPreviewModal(false);
      }}
      pdfURL={pdfURL}
      isEmbedded={isEmbedded}
      error={error}
      pageBlurred={pageBlurred}
      loading={loading}
      isDropdownItem
    />
  );
};

export default Form680Pdf;
