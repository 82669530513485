import { usDollarCurrency } from '@bluefox/lib/formatters';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const tableTitleStyles = StyleSheet.create({
  section: {
    padding: '10 8',
    borderTop: '1px solid #BCCBDC',
    display: 'flex',
    flexDirection: 'row',
  },
  practiceTitle: {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 16 / 15,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  practiceName: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 16 / 15,
    letterSpacing: 0.25,
    color: '#16324F',
  },
});

const tableStyles = StyleSheet.create({
  table: {
    width: 'auto',
  },
  headRow: {
    padding: '0 8',
    flexDirection: 'row',
    border: '1 solid #BCCBDC',
    borderLeft: 0,
    borderRight: 0,
    backgroundColor: '#F9FAFC',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  bodyRow: {
    padding: '0 8',
    flexDirection: 'row',
    borderBottom: '1 solid #EBEFF5',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 16 / 10,
    letterSpacing: 0.25,
    color: '#16324F',
  },
  quantityCol: {
    width: '15%',
    textAlign: 'center',
  },
  vaccineNameCol: {
    width: '55%',
    textAlign: 'left',
  },
  cptCodeCol: {
    width: '15%',
    textAlign: 'right',
  },
  amountCol: {
    width: '15%',
    textAlign: 'right',
  },
  cell: {
    padding: 8,
  },
  total: {
    padding: '12 16',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 16 / 12,
    letterSpacing: 0.25,
    textAlign: 'right',
  },
});

export type PracticeClaimsTableItemProp = {
  key: string;
  quantity: number;
  vaccineName: string;
  cptCode: string;
  amount: number;
};

export type PracticeClaimsTableProps = {
  key: string;
  practice: string;
  table: PracticeClaimsTableItemProp[];
  totalAmount: number;
};

const PracticeClaimsTable = ({
  practice,
  table,
  totalAmount,
  style,
}: PracticeClaimsTableProps & { style?: Style | Style[] }) => (
  <View style={{ ...tableStyles.table, ...style }}>
    <View style={tableTitleStyles.section}>
      <Text style={tableTitleStyles.practiceTitle}>Practice: </Text>
      <Text style={tableTitleStyles.practiceName}>{practice}</Text>
    </View>
    <View style={tableStyles.headRow} wrap={false}>
      <View style={tableStyles.quantityCol}>
        <Text style={tableStyles.cell}>Quantity</Text>
      </View>
      <View style={tableStyles.vaccineNameCol}>
        <Text style={tableStyles.cell}>Vaccine name</Text>
      </View>
      <View style={tableStyles.cptCodeCol}>
        <Text style={tableStyles.cell}>CPT Code</Text>
      </View>
      <View style={tableStyles.amountCol}>
        <Text style={tableStyles.cell}>Amount</Text>
      </View>
    </View>
    {table.map(({ key, quantity, vaccineName, cptCode, amount }) => (
      <View style={tableStyles.bodyRow} key={key} wrap={false}>
        <View style={tableStyles.quantityCol}>
          <Text style={tableStyles.cell}>{quantity}</Text>
        </View>
        <View style={tableStyles.vaccineNameCol}>
          <Text style={tableStyles.cell}>{vaccineName}</Text>
        </View>
        <View style={tableStyles.cptCodeCol}>
          <Text style={tableStyles.cell}>{cptCode}</Text>
        </View>
        <View style={tableStyles.amountCol}>
          <Text style={tableStyles.cell}>{usDollarCurrency(amount)}</Text>
        </View>
      </View>
    ))}
    <View>
      <Text style={tableStyles.total} wrap={false}>
        Total: {usDollarCurrency(totalAmount)}
      </Text>
    </View>
  </View>
);

export default PracticeClaimsTable;
