import React from 'react';
import styled from 'styled-components';
import { Card, Label, Loader, Placeholder } from 'semantic-ui-react';

interface IncomingPaymentsCardProps {
  title: string;
  titleColor?: string;
  totalAmount: any;
  totalPatients: number | undefined;
  totalVaccinations: number | undefined;
  loading?: boolean;
}

const IncomingPaymentsCard = ({
  title,
  titleColor,
  totalAmount,
  totalPatients,
  totalVaccinations,
  loading,
}: IncomingPaymentsCardProps) => {
  return (
    <Card color="olive" style={{ margin: '2rem 0 2rem 0' }}>
      <Card.Content style={{ backgroundColor: titleColor }}>
        <Card.Header textAlign="center" style={{ color: '#ffffff' }}>
          {title}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <Card.Description textAlign="center">
          {loading ? (
            <Loader active inline size="large" />
          ) : (
            <StyledText>{totalAmount}</StyledText>
          )}
          <StyledInfoContainer>
            {loading ? (
              <div
                style={{
                  width: '30%',
                  marginTop: '2rem',
                }}
              >
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line length="full" />
                    <Placeholder.Line length="full" />
                  </Placeholder.Header>
                </Placeholder>
              </div>
            ) : (
              <>
                <StyledLabelContainer>
                  <Label basic icon="user" content={totalPatients} />
                </StyledLabelContainer>
                <StyledLabelContainer>
                  <Label basic icon="syringe" content={totalVaccinations} />
                </StyledLabelContainer>
              </>
            )}
          </StyledInfoContainer>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default IncomingPaymentsCard;

const StyledText = styled.p`
  font-size: 32px;
  font-weight: 700;
`;

const StyledLabelContainer = styled.div`
  margin: 0.2rem;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
