import { useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Vaccination } from '@bluefox/models/Vaccination';
import { VaccinationsByTypeQuery } from '@bluefox/graphql/vaccinations';
import {
  RoutineTypes,
  NonRoutineTypes,
  VACCINE_TYPES,
  RecommendedImmunizations,
} from '@bluefox/store/vaccines';
import { differenceInMonths, RangeType } from '@utils/validations/inRange';
import VaccineCalendar, { VaccineCalendarCategory } from './VaccineCalendar';
import { getImmunization } from '@bluefox/store/vaccinations';
import { usePracticePatient } from '@bluefox/contexts/PracticePatientProvider';
import { Tab } from 'semantic-ui-react';

import VaxHistory from './VaxHistory/VaxHistory';

export type ColumnType = {
  id: number;
  label: string;
  start: number;
  end: number;
  rangeType?: string;
};

export type RowType = {
  vaccinationRange: RangeType[];
  content?: any[];
  id: string;
  saleNdc?: string;
  name: string;
  types: string[];
  aka?: string;
  manufacturer: string;
};

type VaccineTableComponentProps = {
  practicePatientId: string;
  isRefetchingVaccinations?: boolean;
};

export interface VaccinationsData {
  vaccinations: Vaccination[];
}

const columns: ColumnType[] = [
  {
    id: 1,
    label: 'BIRTH',
    start: 0,
    end: 1,
    rangeType: 'month',
  },
  {
    id: 2,
    label: '1 MONTH',
    start: 1,
    end: 2,
    rangeType: 'month',
  },
  {
    id: 3,
    label: '2 MONTHS',
    start: 2,
    end: 4,
    rangeType: 'month',
  },
  {
    id: 4,
    label: '4 MONTHS',
    start: 4,
    end: 6,
    rangeType: 'month',
  },
  {
    id: 5,
    label: '6 MONTHS',
    start: 6,
    end: 9,
    rangeType: 'month',
  },
  {
    id: 6,
    label: '9 MONTHS',
    start: 9,
    end: 12,
    rangeType: 'month',
  },
  {
    id: 7,
    label: '12 MONTHS',
    start: 12,
    end: 15,
    rangeType: 'month',
  },
  {
    id: 8,
    label: '15 MONTHS',
    start: 15,
    end: 18,
    rangeType: 'month',
  },
  {
    id: 9,
    label: '18 MONTHS',
    start: 18,
    end: 19,
    rangeType: 'month',
  },
  {
    id: 10,
    label: '19 - 23 MONTHS',
    start: 19,
    end: 24,
    rangeType: 'month',
  },
  {
    id: 11,
    label: '2 - 3 YEARS',
    start: 2,
    end: 3,
    rangeType: 'year',
  },
  {
    id: 12,
    label: '3 - 6 YEARS',
    start: 3,
    end: 7,
    rangeType: 'year',
  },
  {
    id: 13,
    label: '7 - 10 YEARS',
    start: 7,
    end: 11,
    rangeType: 'year',
  },
  {
    id: 14,
    label: '11 - 12 YEARS',
    start: 11,
    end: 13,
    rangeType: 'year',
  },
  {
    id: 15,
    label: '13 - 15 YEARS',
    start: 13,
    end: 16,
    rangeType: 'year',
  },
  {
    id: 16,
    label: '16 YEARS',
    start: 16,
    end: 17,
    rangeType: 'year',
  },
  {
    id: 17,
    label: '17 - 18 YEARS',
    start: 17,
    end: 18,
    rangeType: 'year',
  },
  {
    id: 18,
    label: '18+ YEARS',
    start: 18,
    end: 110,
    rangeType: 'year',
  },
];

const VaccineTableComponent = ({
  practicePatientId,
}: VaccineTableComponentProps) => {
  const practicePatient = usePracticePatient();
  const patientData = practicePatient?.patientData;

  const { data: vaccinationsData, refetch } = useQuery<VaccinationsData>(
    VaccinationsByTypeQuery,
    {
      variables: {
        practicePatientId,
        types: VACCINE_TYPES,
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [practicePatient?.immunizationSyncedAt, refetch]);

  const patientAgeInMonths = useMemo(
    () =>
      patientData?.birthdate
        ? differenceInMonths(patientData?.birthdate, new Date())
        : 0,
    [patientData]
  );

  const scheduleVaccines = useMemo(
    () =>
      patientData
        ? getImmunization(
            RoutineTypes,
            vaccinationsData?.vaccinations || [],
            patientData
          )
        : [],
    [vaccinationsData?.vaccinations, patientData]
  );

  const notScheduledVaccines = useMemo(
    () =>
      patientData
        ? getImmunization(
            NonRoutineTypes,
            vaccinationsData?.vaccinations || [],
            patientData
          )
        : [],
    [vaccinationsData?.vaccinations, patientData]
  );

  const dueVaccines = scheduleVaccines.filter((immunization) => {
    const isEligible =
      immunization?.ranges.length === 0 ||
      immunization?.ranges[0]?.start <= patientAgeInMonths;

    return (
      isEligible &&
      immunization.givenDosesCount < immunization.totalRecommentationsCount
    );
  });

  const notEligible = scheduleVaccines.filter(
    (immunization) =>
      patientAgeInMonths <
        RecommendedImmunizations[immunization.vaccineType].minimumAge ||
      immunization?.ranges[0]?.start > patientAgeInMonths
  );

  const completedVaccinations = scheduleVaccines.filter(
    (immunization) =>
      immunization.givenDosesCount > 0 &&
      immunization.totalRecommentationsCount <= immunization.givenDosesCount
  );

  const categories: VaccineCalendarCategory[] = [
    {
      id: 'due-vaccines',
      title: 'Due Vaccines',
      vaccines: dueVaccines,
      visible: true,
    },
    {
      id: 'not-eligible',
      title: 'Not Eligible',
      vaccines: notEligible,
      visible: false,
    },
    {
      id: 'completed',
      title: 'Completed immunization',
      vaccines: completedVaccinations,
      visible: false,
    },
    {
      id: 'not-scheduled',
      title: 'Non-Routine Vaccines',
      vaccines: notScheduledVaccines,
      visible: false,
    },
  ];

  const panes = [
    {
      menuItem: { key: 'vaxHistory', icon: 'table', content: 'Vax History' },
      render: () => (
        <Tab.Pane>
          <VaxHistory
            vaccinationsData={vaccinationsData}
            vaccines={[...scheduleVaccines, ...notScheduledVaccines]}
            patientAgeInMonths={patientAgeInMonths}
            onUpdatedVaccinations={refetch}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: 'vaxDash', icon: 'syringe', content: 'Vax Dash' },
      render: () => (
        <Tab.Pane>
          <VaccineCalendar
            categories={categories}
            patientAgeInMonths={patientAgeInMonths}
            columns={columns}
          />
        </Tab.Pane>
      ),
    },
  ];
  return <Tab panes={panes} />;
};

export default VaccineTableComponent;
