import React from 'react';
import { useQuery } from '@apollo/client';
import { GetVfcClaimsInconsistensiesForSummaryDetailsOnPracticePortal } from '@bluefox/graphql/billing';
import { VFCInconsistency } from '@bluefox/models/VFCInconsistency';
import { Message, Table } from 'semantic-ui-react';
import BorrowingSummaryDetailClaimRow from './BorrowingSummaryDetailClaimRow';

interface SummaryDetailsClaims {
  sourceClaims: VFCInconsistency[];
  targetClaims: VFCInconsistency[];
}

interface BorrowingSummaryDetailClaimsProps {
  inventoryAdjustmentId: string;
}

const BorrowingSummaryDetailClaims = ({
  inventoryAdjustmentId,
}: BorrowingSummaryDetailClaimsProps) => {
  const { data } = useQuery<SummaryDetailsClaims>(
    GetVfcClaimsInconsistensiesForSummaryDetailsOnPracticePortal,
    {
      variables: {
        _id: inventoryAdjustmentId,
      },
    }
  );

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
            Patient
          </Table.HeaderCell>
          <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
            Insurance
          </Table.HeaderCell>
          <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
            Service Date
          </Table.HeaderCell>
          <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
            Inventory Used <hr /> NDC
          </Table.HeaderCell>
          <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
            Vaccine <hr /> Lot
          </Table.HeaderCell>
          <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
            Redundant With
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {data?.sourceClaims.length || data?.targetClaims.length ? (
        <Table.Body>
          {data?.sourceClaims.map((inconsistency) => {
            return (
              <BorrowingSummaryDetailClaimRow
                inconsistency={inconsistency}
                sourceTarget="source"
              />
            );
          })}
          {data?.targetClaims.map((inconsistency) => {
            return (
              <BorrowingSummaryDetailClaimRow
                inconsistency={inconsistency}
                sourceTarget="target"
              />
            );
          })}
        </Table.Body>
      ) : (
        <Table.Body>
          <Table.Cell colSpan={9}>
            <Message>No inconsistencies found for this detail.</Message>
          </Table.Cell>
        </Table.Body>
      )}
    </Table>
  );
};

export default BorrowingSummaryDetailClaims;
