import { usePractice } from '@bluefox/contexts';
import { Grid, Image, Header } from 'semantic-ui-react';

const Welcome = () => {
  const practice = usePractice();

  let letterCount = practice.name.replace(/\s+/g, '').length;

  return (
    <Grid centered style={{ marginTop: '6vh' }}>
      <Grid.Column textAlign="center" verticalAlign="middle">
        {practice.profile.logo ? (
          <Image
            centered
            size="large"
            src="/canid-dark-logo.svg"
            style={{ marginBottom: '2rem' }}
          />
        ) : (
          <Header
            style={{
              marginBottom: '2rem',
              fontSize: letterCount > 24 ? '3.5rem' : '4.2rem',
            }}
          >
            {practice.name.toUpperCase()}
          </Header>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default Welcome;
