import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PDFPage, PDFDocument, rgb, PDFImage } from 'pdf-lib';
import moment from 'moment-timezone';
import { PatientData } from '@bluefox/models/Patient';
import {
  vaccinationsByDosesAndDatePartCoordsMap,
  totalDosesCoords,
} from './vaccinationCoords';
import { Vaccination } from '@bluefox/models/Vaccination';
import { calculateAge } from '@bluefox/lib/maths/calculateAgeInTime';
import { Appointment } from '@bluefox/models/Appointment';
import { Confirm } from 'semantic-ui-react';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import { useApplicationState, usePractice } from '@bluefox/contexts';
import { Practice } from '@bluefox/models/Practice';
import PdfDrawerPreviewModal from '@utils/PdfDrawerPreviewModal';

interface FormVaccinationTypes {
  'COVID-19': number;
  DTAP: number;
  FLU: number;
  HIB: number;
  HepA: number;
  HepB: number;
  MMR: number;
  MENING: number;
  MeningB: number;
  POLIO: number;
  PneumoPCV: number;
  ROTAVIRUS: number;
  TD: number;
  TDAP: number;
  VARICELLA: number;
}

const mmrTypes = ['MEASLES', 'MUMPS', 'RUBELLA'];

interface GeorgiaForm3231PdfDrawerProps {
  patientData: PatientData | undefined;
  vaccinations: Vaccination[] | undefined;
  vaccinationTypesReceivedArray: any;
  appointments: Appointment[];
  practice: Practice;
  pageBlurred?: boolean;
}

const GeorgiaForm3231PdfDrawer = ({
  patientData,
  vaccinations,
  vaccinationTypesReceivedArray,
  appointments,
  pageBlurred,
}: GeorgiaForm3231PdfDrawerProps) => {
  const { isEmbedded } = useApplicationState();
  const practice = usePractice();

  const [pdfURL, setPdfURL] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [expirationDate, setExpirationDate] = useState<Date | undefined>(
    undefined
  );
  const [expirationDateArr, setExpirationDateArr] = useState<string[]>([]);
  const [openPdfPreviewModal, setOpenPdfPreviewModal] = useState(false);
  const [error, setError] = useState<any>();

  //----------- DRAW PATIENT INFO -----------//
  const drawPatientInfo = (
    page: PDFPage,
    patientData: PatientData | undefined
  ) => {
    // Georgia 3231 form PDF patient's firstName lastName
    page.drawText(
      patientData ? `${patientData.lastName}, ${patientData.firstName}` : '',
      {
        x: 23,
        y: 749,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );

    // Georgia 3231 form PDF patient guardian's firstName lastName
    page.drawText(
      patientData?.guardians?.length
        ? `${patientData.guardians[0].guardian.lastName}, ${patientData.guardians[0].guardian.firstName}`
        : '',
      {
        x: 23,
        y: 714,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );

    // Georgia 3231 form PDF birthdate (month)
    page.drawText(
      patientData ? moment(patientData.birthdate).format('MM') : '',
      {
        x: 252,
        y: 751,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );

    // Georgia 3231 form PDF birthdate (day)
    page.drawText(
      patientData ? moment(patientData.birthdate).format('DD') : '',
      {
        x: 269,
        y: 751,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );

    // Georgia 3231 form PDF birthdate (year)
    page.drawText(
      patientData ? moment(patientData.birthdate).format('YYYY') : '',
      {
        x: 286,
        y: 751,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );
  };

  //----------- DRAW PRACTICE INFO AND SIGNATURE -----------//
  const drawPracticeInfo = (
    page: PDFPage,
    vaccinations: Vaccination[] | undefined,
    practice: Practice
  ) => {
    const lastVaccination = vaccinations?.slice(-1);

    //------------ Printed, Typed orStamped Name, ------------//
    page.drawText(
      lastVaccination && lastVaccination[0]?.prescriber
        ? `${lastVaccination[0].prescriber.account.firstName} ${lastVaccination[0].prescriber.account.lastName}`
        : '-',
      {
        x: 418,
        y: 157,
        size: 8,
        color: rgb(0, 0, 0),
      }
    );

    //------------ Address part 1 ------------//
    page.drawText(`${practice.name},`, {
      x: 418,
      y: 143,
      size: 8,
      color: rgb(0, 0, 0),
    });
    //------------ Address part 2 ------------//
    page.drawText(
      `${practice.profile.address?.street}, ${practice.profile.address?.city}, ${practice.profile.address?.state}`,
      {
        x: 418,
        y: 133,
        size: 8,
        color: rgb(0, 0, 0),
      }
    );

    //------------ Telephone ------------//
    page.drawText(
      practice.profile.phone?.work
        ? practice.profile.phone?.work.replace(/\D/g, '')
        : '-',
      {
        x: 432,
        y: 108,
        size: 8,
        color: rgb(0, 0, 0),
      }
    );

    //------------ Date of issue ------------//
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');

    page.drawText(month, {
      x: 520,
      y: 68,
      size: 8,
      color: rgb(0, 0, 0),
    });

    page.drawText(day, {
      x: 536,
      y: 68,
      size: 8,
      color: rgb(0, 0, 0),
    });

    page.drawText(year, {
      x: 552,
      y: 68,
      size: 8,
      color: rgb(0, 0, 0),
    });
  };

  //----------- DRAW CHECKBOXES -----------//
  const drawCheckboxesAndExpDate = (
    page: PDFPage,
    vaccinationTypes: any[],
    appointments: Appointment[] | undefined,
    expDate: string[]
  ) => {
    const groupedTypesDoses: FormVaccinationTypes = vaccinationTypes.reduce(
      (accumulator: any, currentValue) => {
        if (accumulator[currentValue]) {
          accumulator[currentValue]++;
        } else {
          accumulator[currentValue] = 1;
        }
        return accumulator;
      },
      {}
    );

    const checkBox1 =
      groupedTypesDoses.HepA >= 2 &&
      groupedTypesDoses.HepB >= 3 &&
      groupedTypesDoses.HIB >= 3 &&
      groupedTypesDoses.DTAP >= 5 &&
      groupedTypesDoses.VARICELLA >= 2 &&
      groupedTypesDoses.MMR >= 2 &&
      groupedTypesDoses.PneumoPCV >= 4 &&
      groupedTypesDoses.POLIO >= 4;

    const checkBox2 =
      checkBox1 && groupedTypesDoses.MENING >= 1 && groupedTypesDoses.TDAP >= 1;

    const checkBox3 = checkBox1 && checkBox2 && groupedTypesDoses.MENING >= 2;

    const [year, month, day] = expDate;

    if (checkBox1) {
      page.drawText('X', {
        x: 492,
        y: 762,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(month, {
        x: 332,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(day, {
        x: 351,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(year, {
        x: 370,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });
    }

    if (checkBox2) {
      page.drawText('X', {
        x: 492,
        y: 723,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(month, {
        x: 332,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(day, {
        x: 351,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(year, {
        x: 370,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });
    }

    if (checkBox3) {
      page.drawText('X', {
        x: 492,
        y: 684,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(month, {
        x: 332,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(day, {
        x: 351,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(year, {
        x: 370,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });
    }

    if (appointments && appointments.length > 0) {
      page.drawText(month, {
        x: 332,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(day, {
        x: 351,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(year, {
        x: 370,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });
    }

    if (!checkBox1 && !checkBox2 && !checkBox3 && appointments?.length === 0) {
      const [year, month, day] = expirationDateArr;

      page.drawText(month, {
        x: 332,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(day, {
        x: 351,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });

      page.drawText(year, {
        x: 370,
        y: 742,
        size: 10,
        color: rgb(0, 0, 0),
      });
    }
  };

  //----------- DRAW VACCINATION DOSES -----------//
  const drawDoses = (
    page: PDFPage,
    coords: any,
    month: string,
    day: string,
    year: string
  ) => {
    page.drawText(month, {
      x: coords.month.x,
      y: coords.month.y,
      size: 8,
      color: rgb(0, 0, 0),
    });

    page.drawText(day, {
      x: coords.day.x,
      y: coords.day.y,
      size: 8,
      color: rgb(0, 0, 0),
    });

    page.drawText(year, {
      x: coords.year.x,
      y: coords.year.y,
      size: 8,
      color: rgb(0, 0, 0),
    });
  };

  //----------- DRAW TOTAL DOSES FOR EACH TYPE -----------//
  const drawTotalDoses = (page: PDFPage, coords: any, totalDoses: number) => {
    if (!coords) return;

    page.drawText(totalDoses.toString(), {
      x: coords.x,
      y: coords.y,
      size: 8,
      color: rgb(0, 0, 0),
    });
  };

  //----------- DRAW VACCINATION DOSES LOGIC -----------//
  const drawVaccinations = (
    page: PDFPage,
    vaccinations: Vaccination[] | undefined,
    patientData: PatientData | undefined
  ) => {
    const groupedVaccinationsByType = vaccinations?.reduce(
      (vaccinationsObject, vaccination) => {
        if (
          vaccination?.vaccine?.types?.length === 2 &&
          vaccination.vaccine.types.includes('TETANUS') &&
          vaccination.vaccine.types.includes('DIPHTHERIA')
        ) {
          if (!vaccinationsObject.groupedTypes['DTAP']) {
            vaccinationsObject.groupedTypes['DTAP'] = [];
          }

          if (vaccinationsObject.groupedTypes['DTAP'].length >= 6) {
            return vaccinationsObject;
          }

          if (
            vaccinationsObject.groupedTypes['TDBooster'] &&
            vaccinationsObject.groupedTypes['TDBooster'].length >= 6
          ) {
            return vaccinationsObject;
          }

          if (vaccinationsObject.groupedTypes['DTAP'].length >= 4) {
            if (!vaccinationsObject.groupedTypes['TDBooster']) {
              vaccinationsObject.groupedTypes['TDBooster'] = [];
            }
            vaccinationsObject.groupedTypes['TDBooster'].push(vaccination);
            return vaccinationsObject;
          }

          vaccinationsObject.groupedTypes['DTAP'].push(vaccination);
          return vaccinationsObject;
        }

        for (const key in vaccination.vaccine?.types) {
          const type = vaccination.vaccine?.types[parseInt(key)] || 0;

          if (
            vaccinationsObject.groupedTypes['TDBooster'] &&
            vaccinationsObject.groupedTypes['TDBooster'].length >= 6
          ) {
            vaccinationsObject.totalDoses['TDBooster'] += 1;
            continue;
          }

          if (type === 'TDAP' || type === 'TD') {
            /**
             * si es tdap y es la primera , se pinta en tdap y boster
             * si es tdap y no es la primera , se pinta en booster
             */
            const tdapsVaccines = vaccinations.filter(
              (v) =>
                v.vaccine?.types?.includes('TDAP') ||
                v.vaccine?.types?.includes('TD')
            );

            let currentTdap = tdapsVaccines.findIndex(
              (v) => v.id === vaccination.id
            );

            currentTdap += 1;
            if (tdapsVaccines.length === 1) {
              if (!vaccinationsObject.groupedTypes['TDAP']) {
                vaccinationsObject.groupedTypes['TDAP'] = [];
                vaccinationsObject.totalDoses['TDAP'] = 0;
              }
              vaccinationsObject.groupedTypes['TDAP'].push(vaccination);
              vaccinationsObject.totalDoses['TDAP'] += 1;

              if (!vaccinationsObject.groupedTypes['TDBooster']) {
                vaccinationsObject.groupedTypes['TDBooster'] = [];
                vaccinationsObject.totalDoses['TDBooster'] = 0;
              }

              vaccinationsObject.totalDoses['TDBooster'] += 1;
              vaccinationsObject.groupedTypes['TDBooster'].push(vaccination);
              continue;
            } else {
              // hay más de una , y tengo que ver si es la primera u nth para ver donde la pinto.
              if (currentTdap === 1) {
                if (!vaccinationsObject.groupedTypes['TDAP']) {
                  vaccinationsObject.groupedTypes['TDAP'] = [];
                  vaccinationsObject.totalDoses['TDAP'] = 0;
                }
                vaccinationsObject.groupedTypes['TDAP'].push(vaccination);
                vaccinationsObject.totalDoses['TDAP'] += 1;

                if (!vaccinationsObject.groupedTypes['TDBooster']) {
                  vaccinationsObject.groupedTypes['TDBooster'] = [];
                  vaccinationsObject.totalDoses['TDBooster'] = 0;
                }

                vaccinationsObject.totalDoses['TDBooster'] += 1;
                vaccinationsObject.groupedTypes['TDBooster'].push(vaccination);
                continue;
              } else {
                vaccinationsObject.totalDoses['TDBooster'] += 1;
                vaccinationsObject.groupedTypes['TDBooster'].push(vaccination);
                continue;
              }
            }
            // if (
            //   vaccinationsObject.groupedTypes["TDAP"] &&
            //   vaccinationsObject.groupedTypes["TDAP"].length === 1
            // ) {
            //   if (!vaccinationsObject.groupedTypes["TDBooster"]) {
            //     vaccinationsObject.groupedTypes["TDBooster"] = [];
            //     vaccinationsObject.totalDoses["TDBooster"] = 0;
            //   }
            //   vaccinationsObject.totalDoses["TDBooster"] += 1;
            //   vaccinationsObject.groupedTypes["TDBooster"].push(vaccination);
            //   continue;
            // }
          }

          if (type === 'DTAP' || type === 'TD') {
            if (!vaccinationsObject.groupedTypes['DTAP']) {
              vaccinationsObject.groupedTypes['DTAP'] = [];
              vaccinationsObject.totalDoses['DTAP'] = 0;
            }

            if (vaccinationsObject.groupedTypes['DTAP'].length >= 6) {
              vaccinationsObject.totalDoses['DTAP'] += 1;
              continue;
            }

            if (vaccinationsObject.groupedTypes['DTAP'].length >= 4) {
              const dtapArray = vaccinationsObject.groupedTypes['DTAP'];

              const ageIn4thDose = calculateAge(
                dtapArray[3].givenAt,
                String(patientData?.birthdate)
              );

              if (ageIn4thDose >= 4) {
                if (!vaccinationsObject.groupedTypes['TDBooster']) {
                  vaccinationsObject.groupedTypes['TDBooster'] = [];
                  vaccinationsObject.totalDoses['TDBooster'] = 0;
                }
                vaccinationsObject.totalDoses['TDBooster'] += 1;
                vaccinationsObject.groupedTypes['TDBooster'].push(vaccination);
                continue;
              }

              if (vaccinationsObject.groupedTypes['DTAP'].length === 5) {
                if (!vaccinationsObject.groupedTypes['TDBooster']) {
                  vaccinationsObject.groupedTypes['TDBooster'] = [];
                  vaccinationsObject.totalDoses['TDBooster'] = 0;
                }
                vaccinationsObject.totalDoses['TDBooster'] += 1;
                vaccinationsObject.groupedTypes['TDBooster'].push(vaccination);
                continue;
              }

              vaccinationsObject.totalDoses['DTAP'] += 1;
              vaccinationsObject.groupedTypes['DTAP'].push(vaccination);
              continue;
            }
          }

          if (type === 'MMR') {
            for (let i = 0; i < mmrTypes.length; i++) {
              if (!vaccinationsObject.groupedTypes[mmrTypes[i]]) {
                vaccinationsObject.groupedTypes[mmrTypes[i]] = [];
                vaccinationsObject.totalDoses[mmrTypes[i]] = 0;
              }

              if (vaccinationsObject.groupedTypes[mmrTypes[i]].length >= 6) {
                vaccinationsObject.totalDoses[mmrTypes[i]] += 1;
                continue;
              }

              vaccinationsObject.totalDoses[mmrTypes[i]] += 1;
              vaccinationsObject.groupedTypes[mmrTypes[i]].push(vaccination);
            }
            continue;
          }

          if (type === 'MMR') {
            continue;
          }

          if (!vaccinationsObject.groupedTypes[type]) {
            vaccinationsObject.groupedTypes[type] = [];
            vaccinationsObject.totalDoses[type] = 0;
          }

          if (vaccinationsObject.groupedTypes[type].length >= 6) {
            vaccinationsObject.totalDoses[type] += 1;
            continue;
          }

          vaccinationsObject.totalDoses[type] += 1;
          vaccinationsObject.groupedTypes[type].push(vaccination);
        }

        return vaccinationsObject;
      },
      { groupedTypes: {}, totalDoses: {} } as any
    );

    for (const type in groupedVaccinationsByType.groupedTypes) {
      const coordsTotals = totalDosesCoords[type];

      drawTotalDoses(
        page,
        coordsTotals,
        groupedVaccinationsByType.totalDoses[type]
      );

      const vaccinations = groupedVaccinationsByType.groupedTypes[type];

      vaccinations.forEach((vaccination: Vaccination, index: number) => {
        const [year, month, day] = vaccination.historic
          ? vaccination.givenAt.toString().split('T')[0].split('-')
          : moment(vaccination.givenAt)
              .format('YYYY-MM-DDTHH:mm:ssZ')
              .split('T')[0]
              .split('-');
        const yearSliced = year.substring(2);

        const vaccinationCoordsByTypes =
          vaccinationsByDosesAndDatePartCoordsMap[type];

        if (!vaccinationCoordsByTypes) return;

        const pdfCoords = vaccinationCoordsByTypes[index];

        drawDoses(page, pdfCoords, month, day, yearSliced);
      });
    }
  };

  const drawSignature = async (pngSignature: PDFImage, page: PDFPage) => {
    if (pngSignature) {
      const { width, height } = pngSignature.scale(0.15);
      page.drawImage(pngSignature, {
        x: 380,
        y: 67,
        width: width,
        height: height,
      });
    } else {
      page.drawText('', {
        x: 380,
        y: 67,
        size: 10,
        color: rgb(0, 0, 0),
      });
    }
  };

  const downloadModifiedPDF = async () => {
    const lastVaccinationArr = vaccinations ? vaccinations.slice(-1) : [];
    const lastVaccination = lastVaccinationArr[0];

    try {
      const url = process.env.REACT_APP_GEORGIA_3231_FORM_URL as string;
      const response = await axios.get(url, { responseType: 'arraybuffer' });

      const pdfBytes = response.data;
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const page = pdfDoc.getPages()[0];

      const signatureUrl = lastVaccination?.prescriber?.account
        .signatureUrl as string;

      if (signatureUrl) {
        const signatureResponse = await fetch(signatureUrl);
        const pngImageUint8ArraySignature = new Uint8Array(
          await signatureResponse.arrayBuffer()
        );

        const pngSignature = await pdfDoc.embedPng(pngImageUint8ArraySignature);

        drawSignature(pngSignature, page);
      }

      drawPatientInfo(page, patientData);
      drawVaccinations(page, vaccinations, patientData);
      drawPracticeInfo(page, vaccinations, practice);
      drawCheckboxesAndExpDate(
        page,
        vaccinationTypesReceivedArray,
        appointments,
        expirationDateArr
      );

      const modifiedPdfBytes = await pdfDoc.save();

      const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
        type: 'application/pdf',
      });

      const downloadUrl = URL.createObjectURL(modifiedPdfBlob);

      // Creates a URL for te preview of the modified PDF file
      return setPdfURL(downloadUrl);
    } catch (error) {
      setError(`Error while processing the PDF. ${error}`);
    }
  };

  const handleExpirationDate = (
    vaccinationTypes: any[],
    patientData: PatientData | undefined,
    appointments: Appointment[] | undefined
  ) => {
    const groupedTypesDoses: FormVaccinationTypes = vaccinationTypes.reduce(
      (accumulator: any, currentValue) => {
        if (accumulator[currentValue]) {
          accumulator[currentValue]++;
        } else {
          accumulator[currentValue] = 1;
        }
        return accumulator;
      },
      {}
    );

    const nextAppointment =
      appointments && appointments.length > 0 ? appointments[0].time : [];

    const patientDob = patientData?.birthdate;

    const dateAt11 = moment(patientDob).add(133, 'months').format('YYYY-MM-DD');
    const dateAt16 = moment(patientDob).add(193, 'months').format('YYYY-MM-DD');
    const dateAt18 = moment(patientDob).add(217, 'months').format('YYYY-MM-DD');

    const checkBox1 =
      groupedTypesDoses.HepA >= 2 &&
      groupedTypesDoses.HepB >= 3 &&
      groupedTypesDoses.HIB >= 3 &&
      groupedTypesDoses.DTAP >= 5 &&
      groupedTypesDoses.VARICELLA >= 2 &&
      groupedTypesDoses.MMR >= 2 &&
      groupedTypesDoses.PneumoPCV >= 4 &&
      groupedTypesDoses.POLIO >= 4;

    const checkBox2 =
      checkBox1 && groupedTypesDoses.MENING >= 1 && groupedTypesDoses.TDAP >= 1;

    const checkBox3 = checkBox1 && checkBox2 && groupedTypesDoses.MENING >= 2;

    const expDate =
      checkBox1 && !checkBox2 && !checkBox3
        ? dateAt11
        : checkBox1 && checkBox2 && !checkBox3
        ? dateAt16
        : checkBox3
        ? dateAt18
        : nextAppointment;

    const _expirationDate = moment(expDate).format(DateFormats.DATE);

    setExpirationDate(new Date(_expirationDate));
  };

  useEffect(() => {
    if (!expirationDate && !patientData) return;

    const formattedDate = expirationDate?.toISOString();

    setExpirationDateArr(
      formattedDate ? formattedDate.toString().split('T')[0].split('-') : []
    );
  }, [expirationDate, patientData]);

  useEffect(() => {
    if (!patientData) return;
    handleExpirationDate(
      vaccinationTypesReceivedArray,
      patientData,
      appointments
    );
  }, [patientData, openConfirm]);

  return (
    <PdfDrawerPreviewModal
      buttonIcon="download"
      buttonLabel="Form 3231 PDF"
      onClickMenuButton={() => setOpenConfirm(true)}
      open={openPdfPreviewModal}
      onClose={() => setOpenPdfPreviewModal(false)}
      pdfURL={pdfURL}
      isEmbedded={isEmbedded}
      error={error}
      pageBlurred={pageBlurred}
    >
      <Confirm
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={() => {
          setOpenConfirm(false);
          setOpenPdfPreviewModal(true);
          downloadModifiedPDF();
        }}
        size="mini"
        content={
          <>
            <div style={{ margin: '1rem' }}>
              Sugessted expiration date is{' '}
              <b>{moment(expirationDateArr.join('-')).format('MM/DD/YYYY')}</b>
              . <br />
              Press OK to accept, or enter a new expiration date (MM/DD/YYYY).
            </div>
            <div style={{ margin: '1rem' }}>
              <DateTimePicker
                placeholderText="Select expiration date"
                selected={expirationDate}
                onChange={(d) => setExpirationDate(d ? (d as Date) : undefined)}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                dropdownMode="select"
                dateFormat={DateFormats.DATE}
                value={moment(expirationDateArr.join('-')).format('MM-DD-YYYY')}
              />
            </div>
          </>
        }
      />
    </PdfDrawerPreviewModal>
  );
};

export default GeorgiaForm3231PdfDrawer;
