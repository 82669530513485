import React from 'react';
import ReactToPrint from 'react-to-print';
import { useApplicationState } from '@bluefox/contexts';

const { REACT_APP_CHROME_EXTENSION_ID } = process.env;

const PrintDialogTrigger = ({
  documentTitle,
  trigger,
  contentNode,
}: {
  trigger: React.ReactElement;
  contentNode: React.ReactInstance | null;
  documentTitle: string;
}) => {
  const { isEmbedded } = useApplicationState();

  const printFn = isEmbedded
    ? (iframe: HTMLIFrameElement) => {
        if (!iframe.contentDocument) return Promise.reject();

        iframe.contentDocument.head.childNodes.forEach((c) => {
          if (c.nodeName === 'LINK') {
            (c as HTMLLinkElement).href = `${(c as HTMLLinkElement).href}`;
          }
        });

        const serializer = new XMLSerializer();
        const content = serializer.serializeToString(iframe.contentDocument);

        chrome.runtime.sendMessage(
          REACT_APP_CHROME_EXTENSION_ID,

          {
            action: 'print',
            title: documentTitle,
            content,
          }
        );

        return Promise.resolve();
      }
    : undefined;

  return (
    <ReactToPrint
      content={() => contentNode}
      trigger={() => trigger}
      pageStyle={`{ @page: { size: auto; margin: 20mm; }}`}
      copyStyles={true}
      // removeAfterPrint={false}
      // suppressErrors={false}
      print={printFn}
      documentTitle={documentTitle}
    />
  );
};

export default PrintDialogTrigger;
