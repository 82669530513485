import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Button,
  Form,
  Header,
  Icon,
  Label,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { OutputTicket } from './BorrowingRequestTicketsAccordion';
import { InsuranceCompanyById } from '@bluefox/graphql/insurances';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters';
import { humanizeText } from '@bluefox/lib/humanize';
import { GetVaccinationsByIdArray } from '@bluefox/graphql/vaccinations';
import { Vaccination } from '@bluefox/models/Vaccination';

type BorrowingRequestTicketsAccordionDetailsProps = {
  ticket: OutputTicket;
};

const BorrowingRequestTicketsAccordionDetails = ({
  ticket,
}: BorrowingRequestTicketsAccordionDetailsProps) => {
  const [open, setOpen] = useState(false);
  const [correctedInsuranceCompany, setCorrectedInsuranceCompany] =
    useState<string>('');
  const [vaccinations, setVaccinations] = useState<Vaccination[]>([]);

  useQuery(InsuranceCompanyById, {
    variables: {
      id: ticket.details.correctedInsurance,
    },
    skip: !ticket.details.correctedInsurance,
    onCompleted(data) {
      if (data.insuranceCompanies.length > 0) {
        setCorrectedInsuranceCompany(data.insuranceCompanies[0].name);
      }
    },
  });

  const vaccinationsIds = useMemo(
    () =>
      ticket.details.relevantVaccines?.filter((vaccine) => vaccine !== 'null'),
    [ticket]
  );

  useQuery(GetVaccinationsByIdArray, {
    variables: {
      id: vaccinationsIds,
    },
    skip: ticket.details.relevantVaccines?.length === 0,
    onCompleted(data) {
      if (data.vaccinations.length > 0) {
        setVaccinations(data.vaccinations);
      }
    },
  });

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Modal
        dimmer="blurring"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={<Button primary icon="eye" size="tiny" />}
      >
        <Modal.Header>
          <Icon name="bullhorn" />
          Requested borrowing case
        </Modal.Header>
        <Modal.Content>
          <Segment padded>
            <Form>
              <Form.Input
                fluid
                label={'Patient'}
                value={ticket.details.patient}
              />
              <Form.Input
                fluid
                label={'Date of Service'}
                value={
                  ticket.details.dateOfService
                    ? formatDateToMMDDYYYYV2(ticket.details.dateOfService)
                    : '-'
                }
              />
              <Form.Input
                fluid
                label={'Insurance'}
                value={ticket.details.insurance}
              />
              <Form.Input
                fluid
                label={'Issue Type'}
                value={
                  ticket.details.issueType &&
                  humanizeText(ticket.details.issueType, {
                    delimiter: '_',
                    capitalize: 'all',
                  })
                }
              />
              {(ticket.details.issueType === 'insurance' ||
                ticket.details.issueType === 'insurance_and_vaccine') && (
                <Form.Input
                  fluid
                  label={'Corrected Insurance'}
                  value={correctedInsuranceCompany}
                />
              )}
              {(ticket.details.issueType === 'vaccine' ||
                ticket.details.issueType === 'insurance_and_vaccine') && (
                <div>
                  <Label>Vaccinations with problems</Label>
                  <div
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                      margin: '0.5rem 0',
                    }}
                  >
                    {vaccinations.map((vaccination) => (
                      <div
                        key={vaccination.id}
                        style={{
                          padding: '5px',
                          border: '1px solid',
                          borderRadius: '0.5rem',
                        }}
                      >
                        <Header>
                          {vaccination.inventory?.vaccine?.name}
                          <Label
                            content={
                              vaccination.inventory?.vfc ? 'VFC' : 'Private'
                            }
                            size="tiny"
                            color={
                              vaccination.inventory?.vfc ? 'orange' : 'teal'
                            }
                          />
                          <Header.Subheader>
                            {vaccination.inventory?.vaccine?.saleNdc} <br />
                            {vaccination.inventory?.lot}
                            <br />
                            {vaccination.inventory?.vaccine?.types?.map(
                              (type) => (
                                <Label key={type} color="black" size="mini">
                                  {type}
                                </Label>
                              )
                            )}
                          </Header.Subheader>
                        </Header>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <Form.TextArea
                fluid
                label={'Description'}
                value={ticket.details.issueDescription}
              />
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default BorrowingRequestTicketsAccordionDetails;
