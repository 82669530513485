export const vaccinationsByDosesCoordsMap: {
  [key: string]: object[];
} = {
  DTAP: [
    {
      x: 130,
      y: 600,
    },
    {
      x: 130 + 75,
      y: 600,
    },
    {
      x: 130 + 150,
      y: 600,
    },
    {
      x: 130 + 225,
      y: 600,
    },
    {
      x: 130 + 300,
      y: 600,
    },
    {
      x: 130 + 375,
      y: 600,
    },
  ],
  TD_Group: [
    {
      x: 130,
      y: 569,
      TDAP: {
        x: 119,
        y: 586,
      },
      TD: {
        x: 146,
        y: 586,
      },
      DT: {
        x: 164,
        y: 586,
      },
    },
    {
      x: 130 + 75,
      y: 569,
      TDAP: {
        x: 198,
        y: 586,
      },
      TD: {
        x: 225,
        y: 586,
      },
      DT: {
        x: 243,
        y: 586,
      },
    },
    {
      x: 130 + 150,
      y: 569,
      TDAP: {
        x: 275,
        y: 586,
      },
      TD: {
        x: 301,
        y: 586,
      },
      DT: {
        x: 319,
        y: 586,
      },
    },
    {
      x: 130 + 225,
      y: 569,
      TDAP: {
        x: 351,
        y: 586,
      },
      TD: {
        x: 378,
        y: 586,
      },
      DT: {
        x: 396,
        y: 586,
      },
    },
    {
      x: 130 + 300,
      y: 569,
      TDAP: {
        x: 428,
        y: 586,
      },
      TD: {
        x: 454,
        y: 586,
      },
      DT: {
        x: 472,
        y: 586,
      },
    },
    {
      x: 130 + 375,
      y: 569,
      TDAP: {
        x: 504,
        y: 586,
      },
      TD: {
        x: 531,
        y: 586,
      },
      DT: {
        x: 549,
        y: 586,
      },
    },
  ],
  POLIO: [
    {
      x: 130,
      y: 537,
      route: {
        x: 123,
        y: 554,
      },
    },
    {
      x: 130 + 75,
      y: 537,
      route: {
        x: 126 + 75,
        y: 554,
      },
    },
    {
      x: 130 + 150,
      y: 537,
      route: {
        x: 128 + 150,
        y: 554,
      },
    },
    {
      x: 130 + 225,
      y: 537,
      route: {
        x: 129 + 225,
        y: 554,
      },
    },
    {
      x: 130 + 300,
      y: 537,
      route: {
        x: 131 + 300,
        y: 554,
      },
    },
    {
      x: 130 + 375,
      y: 537,
      route: {
        x: 135 + 375,
        y: 554,
      },
    },
  ],
  HIB: [
    {
      x: 130,
      y: 516,
    },
    {
      x: 130 + 75,
      y: 516,
    },
    {
      x: 130 + 150,
      y: 516,
    },
    {
      x: 130 + 225,
      y: 516,
    },
    {
      x: 130 + 300,
      y: 516,
    },
    {
      x: 130 + 375,
      y: 516,
    },
  ],
  HepB: [
    {
      x: 130,
      y: 477,
    },
    {
      x: 130 + 75,
      y: 477,
    },
    {
      x: 130 + 150,
      y: 477,
    },
    {
      x: 130 + 225,
      y: 477,
    },
    {
      x: 130 + 300,
      y: 477,
    },
    {
      x: 130 + 375,
      y: 477,
    },
  ],
  MMR: [
    {
      x: 130,
      y: 457,
    },
    {
      x: 130 + 75,
      y: 457,
    },
    {
      x: 130 + 150,
      y: 457,
    },
    {
      x: 130 + 225,
      y: 457,
    },
    {
      x: 130 + 300,
      y: 457,
    },
    {
      x: 130 + 375,
      y: 457,
    },
  ],
  VARICELLA: [
    {
      x: 130,
      y: 434,
    },
    {
      x: 130 + 75,
      y: 434,
    },
    {
      x: 130 + 150,
      y: 434,
    },
    {
      x: 130 + 225,
      y: 434,
    },
    {
      x: 130 + 300,
      y: 434,
    },
    {
      x: 130 + 375,
      y: 434,
    },
  ],
  MENING: [
    {
      x: 130,
      y: 412,
    },
    {
      x: 130 + 75,
      y: 412,
    },
    {
      x: 130 + 150,
      y: 412,
    },
    {
      x: 130 + 225,
      y: 412,
    },
    {
      x: 130 + 300,
      y: 412,
    },
    {
      x: 130 + 375,
      y: 412,
    },
  ],
  HepA: [
    {
      x: 130,
      y: 383,
    },
    {
      x: 130 + 75,
      y: 383,
    },
    {
      x: 130 + 150,
      y: 383,
    },
    {
      x: 130 + 225,
      y: 383,
    },
    {
      x: 130 + 300,
      y: 383,
    },
    {
      x: 130 + 375,
      y: 383,
    },
  ],
  HPV: [
    {
      x: 130,
      y: 366,
    },
    {
      x: 130 + 75,
      y: 366,
    },
    {
      x: 130 + 150,
      y: 366,
    },
    {
      x: 130 + 225,
      y: 366,
    },
    {
      x: 130 + 300,
      y: 366,
    },
    {
      x: 130 + 375,
      y: 366,
    },
  ],
  FLU: [
    {
      x: 130,
      y: 348,
    },
    {
      x: 130 + 75,
      y: 348,
    },
    {
      x: 130 + 150,
      y: 348,
    },
    {
      x: 130 + 225,
      y: 348,
    },
    {
      x: 130 + 300,
      y: 348,
    },
    {
      x: 130 + 375,
      y: 348,
    },
  ],
  PneumoPCV: [
    {
      x: 130,
      y: 495,
    },
    {
      x: 130 + 75,
      y: 495,
    },
    {
      x: 130 + 150,
      y: 495,
    },
    {
      x: 130 + 225,
      y: 495,
    },
    {
      x: 130 + 300,
      y: 495,
    },
    {
      x: 130 + 375,
      y: 495,
    },
  ],
};
