import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  updateNotifications,
  NotificationsQuery,
  getNotificationMoreDataQuery,
  PendingNotificationsCountQuery,
  updateNotificationComments,
  confirmOrderMutation,
  updateNotificationCommentsPendingReadByMutation,
} from '@bluefox/graphql/notifications';
import {
  Button,
  Card,
  Icon,
  Input,
  Message,
  Modal,
  Pagination,
} from 'semantic-ui-react';
import {
  Notification,
  NotificationComment,
  NotificationResponseType,
  NotificationStatus,
  NotificationStatusLogAction,
  NotificationType,
} from '@bluefox/models/Notification';
import PatientNotificationListTable from '@bluefox/ui/Notifications/NotificationListTable/PatientNotificationListTable';
import OrderNotificationListTable from '@bluefox/ui/Notifications/NotificationListTable/OrderNotificationListTable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Session, useApplicationState, usePractice } from '@bluefox/contexts';
import { toast } from 'react-semantic-toasts';
import NotificationListRowActions from './NotificationListRowActions';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import PatientNotificationListFilter, {
  buildPatientQuery,
  initialPatientQueryPP,
  SearchValuesProps,
} from '@bluefox/ui/Notifications/PatientNotificationListFilter';
import { debounce } from '@bluefox/lib/debounce';
import { BillingClaimStatus } from '@bluefox/models/Billing';
import { ALL } from '@bluefox/lib/options';
import OrderNotificationListRowActions from './OrderNotificationListRowActions';
import CommentsModal from '@bluefox/ui/Notifications/CommentsModal';
import InventoryOrderMissingDataModal, {
  FormValues,
} from './InventoryOrderMissingDataModal';
import { orderNotificationStatus_PP_ALL } from '@bluefox/constants/notifications';
import { BILLER_ROLE } from '../../../generalSettings';
import NotificationMessage from '@bluefox/ui/Notifications/NotificationMessage';

const pageLimit = 10;

type NotificationConfirmationModalType = {
  notification: Notification;
  note?: string;
};

const statusQuery = {
  status: {
    _in: [
      NotificationStatus.pending,
      NotificationStatus.rejected,
      NotificationStatus.resolved,
    ],
  },
};

function buildStatusLog(
  notification: Notification,
  newStatus: NotificationStatus,
  session?: Session,
  rejectionMessage?: string
) {
  const newStatusLog = [
    {
      action: NotificationStatusLogAction.changeStatus,
      account: session?.account,
      updatedAt: new Date(),
      status: newStatus,
      oldStatus: notification.status,
      ...(isStringNotEmpty(rejectionMessage) ? { rejectionMessage } : {}),
    },
    ...(notification?.statusLog ? notification?.statusLog : []),
  ];
  return newStatusLog;
}

function isNotificationValid(notification?: Notification) {
  if (!notification) {
    toast({
      title: `Failed to refresh notification status`,
      type: 'error',
      time: 5000,
    });
    return false;
  }
  if (notification.status !== NotificationStatus.pending) {
    toast({
      title: `The notification has already changed its status. Please refresh the page.`,
      type: 'error',
      time: 5000,
    });
    return false;
  }
  switch (notification.type) {
    case NotificationType.claim: {
      const claim = notification.claim;
      if (!claim) {
        toast({
          title: `Failed to get the claim related to the notification.`,
          type: 'error',
          time: 5000,
        });
        return false;
      }
      if (claim.status !== BillingClaimStatus.DIRECT_CHARGE) {
        toast({
          title: `The claim related to the notification has changed its status.`,
          type: 'error',
          time: 5000,
        });
        return false;
      }
      break;
    }
    default:
  }

  return true;
}

type Props = {};

const NotificationList = (props: Props) => {
  const [searchValues, setSearchValues] = useState<SearchValuesProps>(
    initialPatientQueryPP
  );
  const [queryValues, setQueryValues] = useState<SearchValuesProps>(
    initialPatientQueryPP
  );
  const [formValues, setFormValues] = useState<
    FormValues & { notification?: Notification }
  >();
  const [patientNotificationsActivePage, setPatientNotificationsActivePage] =
    useState(1);
  const [orderNotificationsActivePage, setOrderNotificationsActivePage] =
    useState(1);
  const [commentModal, setCommentModal] = useState<{
    notification: Notification;
  }>();
  const debouncedRef = useRef<ReturnType<typeof debounce>>();
  const [notificationConfirmationModal, setNotificationConfirmationModal] =
    useState<NotificationConfirmationModalType>();
  const { session } = useApplicationState();
  const practice = usePractice();

  const isBillerUserRole = useMemo(
    () => session?.account?.role === BILLER_ROLE,
    [session?.account?.role]
  );

  const {
    loading: patientNotificationsLoading,
    error: patientNotificationsError,
    data: patientNotificationsData,
    refetch: patientNotificationsRefetch,
  } = useQuery<NotificationResponseType>(NotificationsQuery, {
    variables: {
      limit: pageLimit,
      offset: (patientNotificationsActivePage - 1) * pageLimit,
      where: {
        ...statusQuery,
        ...buildPatientQuery(queryValues, 'PP'),
        type: isBillerUserRole
          ? { _eq: NotificationType.claim }
          : { _neq: NotificationType.order },
        practiceId: { _eq: practice.id },
      },
    },
    skip: !practice,
    fetchPolicy: 'no-cache',
  });
  const {
    loading: orderNotificationsLoading,
    error: orderNotificationsError,
    data: orderNotificationsData,
    refetch: orderNotificationsRefetch,
  } = useQuery<NotificationResponseType>(NotificationsQuery, {
    variables: {
      limit: pageLimit,
      offset: (orderNotificationsActivePage - 1) * pageLimit,
      where: {
        status: { _in: orderNotificationStatus_PP_ALL },
        ...(searchValues.orderTableStatus &&
        searchValues.orderTableStatus !== ALL
          ? {
              status: { _eq: searchValues.orderTableStatus },
            }
          : {}),
        type: { _eq: NotificationType.order },
        practiceId: { _eq: practice.id },
      },
    },
    skip: !practice || isBillerUserRole,
    fetchPolicy: 'no-cache',
  });

  const [updateNotification] = useMutation(updateNotifications, {
    refetchQueries: [PendingNotificationsCountQuery],
  });
  const [getNotificationMoreData] = useLazyQuery<{
    communication_notifications: Notification[];
  }>(getNotificationMoreDataQuery);
  const [updateNotificationComment] = useMutation(updateNotificationComments);
  const [updateNotificationCommentsPendingReadBy] = useMutation(
    updateNotificationCommentsPendingReadByMutation,
    {
      refetchQueries: [PendingNotificationsCountQuery],
    }
  );
  const [confirmOrder] = useMutation(confirmOrderMutation);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  const refreshNotificationData = useCallback(
    async (notification: Notification) => {
      try {
        const resp = await getNotificationMoreData({
          variables: {
            notificationId: notification.id,
            withClaim: notification.type === NotificationType.claim,
            withInventoryOrder: notification.type === NotificationType.order,
          },
        });
        const refreshedNotification = resp.data?.communication_notifications[0];
        return refreshedNotification;
      } catch (error) {
        toast({
          title: `Failed to refresh the notification information. Error: ${error}`,
          type: 'error',
          time: 5000,
        });
        return undefined;
      }
    },
    [getNotificationMoreData]
  );

  const changeNotificationStatus = useCallback(
    async (
      notification: Notification,
      newStatus: NotificationStatus,
      rejectionMessage?: string,
      confirmationMessage?: string
    ) => {
      const refreshedNotification = await refreshNotificationData(notification);
      const isValid = isNotificationValid(refreshedNotification);
      if (!isValid) return;

      const newStatusLog = buildStatusLog(
        notification,
        newStatus,
        session,
        rejectionMessage
      );
      try {
        await updateNotification({
          variables: {
            id: notification.id,
            status: newStatus,
            statusLog: newStatusLog,
            ...(isStringNotEmpty(rejectionMessage)
              ? { note: rejectionMessage }
              : {}),
            ...(newStatus === NotificationStatus.resolved ||
            newStatus === NotificationStatus.rejected
              ? { viewedBy: session?.account?.id }
              : {}),
          },
        });
      } catch (error) {
        toast({
          title: `Failed to update notification status. error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
      if (confirmationMessage) {
        toast({
          title: confirmationMessage,
          type: 'success',
          time: 3000,
        });
      }
      try {
        if (notification.type === NotificationType.order) {
          orderNotificationsRefetch();
        } else {
          patientNotificationsRefetch();
        }
      } catch (error) {
        toast({
          title: `Failed to refresh notification list. error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [
      orderNotificationsRefetch,
      patientNotificationsRefetch,
      refreshNotificationData,
      session,
      updateNotification,
    ]
  );

  const handleUpdateOrderIfNeededAndUpdateNotificationStatus = useCallback(
    async (notification: Notification, lot?: string, expiration?: Date) => {
      try {
        const response = await confirmOrder({
          variables: {
            orderId: notification.inventoryOrder?.id,
            ...(lot ? { lotNumber: lot } : {}),
            ...(expiration ? { inventoryExpiration: expiration } : {}),
          },
        });
        if (response?.data?.confirmOrder?.code !== 200) {
          toast({
            title: `Failed to confirm order or order information. Error: ${response?.data?.confirmOrder?.message}`,
            type: 'error',
            time: 5000,
          });
          return;
        }
      } catch (error) {
        toast({
          title: `Failed to confirm order or order information. Error: ${error}`,
          type: 'error',
          time: 5000,
        });
        return;
      }
      await changeNotificationStatus(
        notification,
        NotificationStatus.resolved,
        undefined,
        'Order received'
      );
    },
    [changeNotificationStatus, confirmOrder]
  );

  const hadleReceivedOrder = useCallback(
    async (notification: Notification) => {
      const refreshedNotification = await refreshNotificationData(notification);
      const isValid = isNotificationValid(refreshedNotification);
      if (!isValid) return;
      const inventoryOrder = refreshedNotification?.inventoryOrder;
      if (!inventoryOrder?.lot || !inventoryOrder.inventoryExpiration) {
        setFormValues({
          lot: inventoryOrder?.lot,
          fillLot: !inventoryOrder?.lot,
          expiration: inventoryOrder?.inventoryExpiration,
          fillExpiration: !inventoryOrder?.inventoryExpiration,
          timeZone: practice.timezone,
          notification: refreshedNotification,
        });
      } else {
        handleUpdateOrderIfNeededAndUpdateNotificationStatus(
          refreshedNotification as Notification
        );
      }
    },
    [
      handleUpdateOrderIfNeededAndUpdateNotificationStatus,
      practice.timezone,
      refreshNotificationData,
    ]
  );

  const handleOpenComments = useCallback(
    async (notification: Notification) => {
      const refreshedNotification = await refreshNotificationData(notification);
      if (!refreshedNotification) return;
      if (refreshedNotification.pendingReadBy === 'PP') {
        try {
          await updateNotificationCommentsPendingReadBy({
            variables: {
              id: refreshedNotification.id,
              pendingReadBy: null,
            },
          });
          if (notification.type === NotificationType.order) {
            await orderNotificationsRefetch();
          } else {
            await patientNotificationsRefetch();
          }
        } catch (error) {
          toast({
            title: `Failed to update notification pending read by. Error: ${error}`,
            type: 'error',
            time: 5000,
          });
        }
      }
      setCommentModal({ notification: refreshedNotification });
    },
    [
      orderNotificationsRefetch,
      patientNotificationsRefetch,
      refreshNotificationData,
      updateNotificationCommentsPendingReadBy,
    ]
  );

  return (
    <>
      {!isBillerUserRole && (
        <section style={{ marginBottom: '5rem', textAlign: 'center' }}>
          {orderNotificationsError ? (
            <Message error>{orderNotificationsError.message}</Message>
          ) : (
            <>
              <OrderNotificationListTable
                loading={orderNotificationsLoading}
                notifications={
                  orderNotificationsData?.communication_notifications
                }
                children={
                  <OrderNotificationListRowActions
                    onAccept={async (notification) => {
                      await hadleReceivedOrder(notification as Notification);
                    }}
                    onCancel={async (notification) => {
                      if (!notification) return;
                      await changeNotificationStatus(
                        notification,
                        NotificationStatus.rejected,
                        undefined,
                        'Order not received'
                      );
                    }}
                    onOpenComments={async (notification) => {
                      if (!notification) return;
                      await handleOpenComments(notification);
                    }}
                  />
                }
                notificationStatus={searchValues.orderTableStatus}
                notificationStatusOnChange={(value) => {
                  setSearchValues((prevValue) => ({
                    ...prevValue,
                    orderTableStatus: value,
                  }));
                }}
              />
              <Pagination
                activePage={orderNotificationsActivePage}
                totalPages={
                  orderNotificationsData?.communication_notifications_aggregate
                    .aggregate.count
                    ? Math.ceil(
                        orderNotificationsData
                          ?.communication_notifications_aggregate.aggregate
                          .count / pageLimit
                      )
                    : 1
                }
                onPageChange={(_, { activePage }) => {
                  setOrderNotificationsActivePage(activePage as number);
                }}
              />
            </>
          )}
        </section>
      )}
      <section style={{ marginBottom: '5rem', textAlign: 'center' }}>
        {patientNotificationsError ? (
          <Message error>{patientNotificationsError.message}</Message>
        ) : (
          <>
            <Card fluid>
              <Card.Content>
                <Card.Description>
                  <PatientNotificationListFilter
                    searchValues={searchValues}
                    setSearchValues={(value) => {
                      setSearchValues(value);
                      debouncedRef.current?.cancel();
                      debouncedRef.current = debounce(() => {
                        setQueryValues(value);
                        setPatientNotificationsActivePage(1);
                        setOrderNotificationsActivePage(1);
                      }, 500);
                      debouncedRef.current();
                    }}
                  />
                </Card.Description>
              </Card.Content>
            </Card>
            <PatientNotificationListTable
              loading={patientNotificationsLoading}
              notifications={
                patientNotificationsData?.communication_notifications
              }
              children={
                <NotificationListRowActions
                  onAccept={async (notification) => {
                    if (!notification) return;
                    await changeNotificationStatus(
                      notification,
                      NotificationStatus.resolved
                    );
                  }}
                  onCancel={(notification) => {
                    // THis is not used any more
                    if (!notification) return;
                    setNotificationConfirmationModal({
                      notification,
                    });
                  }}
                  onOpenComments={async (notification) => {
                    if (!notification) return;
                    await handleOpenComments(notification);
                  }}
                />
              }
            />
            <Pagination
              activePage={patientNotificationsActivePage}
              totalPages={
                patientNotificationsData?.communication_notifications_aggregate
                  .aggregate.count
                  ? Math.ceil(
                      patientNotificationsData
                        ?.communication_notifications_aggregate.aggregate
                        .count / pageLimit
                    )
                  : 1
              }
              onPageChange={(_, { activePage }) => {
                setPatientNotificationsActivePage(activePage as number);
              }}
            />
          </>
        )}
      </section>
      <Modal
        closeIcon
        onClose={() => setNotificationConfirmationModal(undefined)}
        open={!!notificationConfirmationModal}
      >
        <Modal.Header>Disagreement</Modal.Header>
        <Modal.Content>
          You are about to disagree with this notification. could you please let
          us know why?
          <Input
            type="text"
            fluid
            value={notificationConfirmationModal?.note}
            onChange={(_, { value }) => {
              setNotificationConfirmationModal(
                (prev) =>
                  ({
                    ...prev,
                    note: value,
                  }) as NotificationConfirmationModalType
              );
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setNotificationConfirmationModal(undefined)}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button
            primary
            onClick={async () => {
              if (notificationConfirmationModal?.notification) {
                await changeNotificationStatus(
                  notificationConfirmationModal?.notification,
                  NotificationStatus.rejected,
                  notificationConfirmationModal.note
                );
              }
              setNotificationConfirmationModal(undefined);
            }}
          >
            <Icon name="checkmark" /> Disagree
          </Button>
        </Modal.Actions>
      </Modal>
      <CommentsModal
        open={!!commentModal}
        notification={commentModal?.notification}
        onClose={() => setCommentModal(undefined)}
        onSendNewMessage={async (message) => {
          const refreshedNotification = await refreshNotificationData(
            commentModal?.notification as Notification
          );
          if (!refreshedNotification) return;
          const account = session?.account;
          const newComment: NotificationComment = {
            id: window.crypto.randomUUID(),
            date: new Date(),
            userId: account?.id || '',
            role: account?.role || '',
            name: `${account?.firstName} ${account?.lastName}`,
            message,
            origin: 'PP',
          };
          try {
            const response = await updateNotificationComment({
              variables: {
                id: refreshedNotification.id,
                comments: newComment,
                pendingReadBy: 'IT',
              },
            });
            const newComments =
              response.data.update_communication_notifications_by_pk
                ?.comments || [];
            setCommentModal({
              notification: { ...refreshedNotification, comments: newComments },
            });
          } catch (error) {
            toast({
              title: `Failed to send the comment`,
              type: 'error',
              time: 5000,
            });
            return;
          }
        }}
        source="PP"
        replaceNameForDifferentOrigin="Canid Physician Help"
      >
        <Card fluid>
          <Card.Content>
            <p style={{ margin: '0 0 0.5rem' }}>
              <b>Message:</b>
            </p>
            <NotificationMessage
              notification={commentModal?.notification as Notification}
            />
          </Card.Content>
        </Card>
      </CommentsModal>
      <InventoryOrderMissingDataModal
        open={!!formValues}
        formValues={formValues}
        setFormValues={setFormValues}
        onClose={() => {
          setFormValues(undefined);
        }}
        onSubmit={async () => {
          await handleUpdateOrderIfNeededAndUpdateNotificationStatus(
            formValues?.notification as Notification,
            formValues?.lot,
            formValues?.expiration
          );
          setFormValues(undefined);
        }}
      />
    </>
  );
};
export default NotificationList;
