import React from 'react';
import { Label, Table } from 'semantic-ui-react';
import { Inventory } from '@bluefox/models/Inventory';

type InventoryListPdfProps = {
  inventoryData?: {
    privateInventory: Inventory[];
    vfcInventory: Inventory[];
  };
};

const InventoryListPdf = ({ inventoryData }: InventoryListPdfProps) => {
  const inventory = inventoryData
    ? [...inventoryData?.privateInventory, ...inventoryData?.vfcInventory]
    : [];

  return (
    <Table striped>
      <Table.Header>
        <Table.HeaderCell>Vaccine</Table.HeaderCell>
        <Table.HeaderCell>NDC</Table.HeaderCell>
        <Table.HeaderCell>Lot</Table.HeaderCell>
        <Table.HeaderCell>Stock</Table.HeaderCell>
        <Table.HeaderCell>Expiration</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {inventory?.map((item) => {
          return <InventoryListPdfRow inventory={item} />;
        })}
      </Table.Body>
    </Table>
  );
};

//-----------------------------LIST ROW-----------------------------//

type InventoryListPdfRowProps = {
  inventory: Inventory;
};

const InventoryListPdfRow = ({ inventory }: InventoryListPdfRowProps) => {
  return (
    <Table.Row>
      <Table.Cell>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {inventory.vaccine.name}
          <Label
            basic
            size="small"
            content={inventory.vfc ? 'VFC' : 'Private'}
          />
        </div>
      </Table.Cell>
      <Table.Cell>{inventory.vaccine.saleNdc}</Table.Cell>
      <Table.Cell>{inventory.lot}</Table.Cell>
      <Table.Cell>{inventory.doses}</Table.Cell>
      <Table.Cell>
        {new Date(inventory.expiration).toLocaleDateString()}
      </Table.Cell>
    </Table.Row>
  );
};

export default InventoryListPdf;
