import { Button, Icon } from 'semantic-ui-react';
import { Notification, NotificationStatus } from '@bluefox/models/Notification';
import { NotificationListTableRowChild } from '@bluefox/ui/Notifications/NotificationListTable/common';
import { notificationStatusEnumToText } from '@bluefox/constants/notifications';

type Props = NotificationListTableRowChild & {
  onAccept?: (notification?: Notification) => void;
  onCancel?: (notification?: Notification) => void;
  onOpenComments?: (notification?: Notification) => void;
};

const NotificationListRowActions = ({
  notification,
  onAccept,
  onCancel,
  onOpenComments,
}: Props) => {
  return (
    <div>
      <Button.Group size="mini">
        <Button
          size="mini"
          icon="check"
          color="teal"
          disabled={notification?.status !== NotificationStatus.pending}
          onClick={() => onAccept?.(notification)}
        />
        {/**
         * 
         *         <Button
          size="mini"
          icon="cancel"
          color="red"
          style={{ marginLeft: '0.2rem' }}
          disabled={notification?.status !== NotificationStatus.pending}
          onClick={() => onCancel?.(notification)}
        />

         */}
      </Button.Group>
      <Button.Group size="mini" style={{ marginTop: '0.2rem' }}>
        <Button
          size="mini"
          icon="chat"
          color="teal"
          style={{ marginLeft: '0.2rem' }}
          onClick={() => {
            onOpenComments?.(notification);
          }}
        />
      </Button.Group>
      <p style={{ margin: '0.25rem 0' }}>
        <b>Status:</b>{' '}
        {
          notificationStatusEnumToText[
            notification?.status as NotificationStatus
          ]
        }
      </p>
      {notification?.pendingReadBy === 'PP' && (
        <p style={{ margin: '0.25rem 0' }}>
          <Icon name="flag" color="red" /> New comment
        </p>
      )}
    </div>
  );
};

export default NotificationListRowActions;
