import React, { useState, useEffect, useCallback } from 'react';
import UsersToLoginModal from '@bluefox/ui/UsersToLoginModal';
import { Image } from 'semantic-ui-react';

const SelectUserScreen = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpened = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  useEffect(() => {
    document.addEventListener('mousemove', handleModalOpened);
    document.addEventListener('click', handleModalOpened);
    document.addEventListener('keydown', handleModalOpened);

    return () => {
      document.removeEventListener('mousemove', handleModalOpened);
      document.removeEventListener('click', handleModalOpened);
      document.removeEventListener('keydown', handleModalOpened);
    };
  }, [handleModalOpened]);

  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: '#16324F',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image src="/canid-white-logo-full.png" size="large" />
      <UsersToLoginModal open={openModal} />
    </div>
  );
};

export default SelectUserScreen;
