import { usePatientData, usePractice } from '@bluefox/contexts';
import { Link } from 'react-router-dom';
import {
  Icon,
  SemanticICONS,
  Popup,
  Menu,
  MenuItemProps,
  Label,
} from 'semantic-ui-react';

interface PatientMenuButtonType {
  icon?: SemanticICONS;
  label: string;
  alert?: string;
  to?: string;
  onClick?: () => void;
  minimalistic?: boolean;
  itemProps?: MenuItemProps;
}

const PatientMenuButton = ({
  icon,
  label,
  alert,
  to,
  onClick,
  minimalistic,
  itemProps = {},
}: PatientMenuButtonType) => {
  const practice = usePractice();
  const patient = usePatientData();

  return (
    <Popup
      content={label}
      disabled={!minimalistic}
      trigger={
        <Menu.Item
          as={!!to ? Link : 'a'}
          onClick={onClick}
          to={
            !!to
              ? `/${practice.handler}/patients/${patient?.practicePatientId}/${to}`
              : undefined
          }
          {...itemProps}
        >
          {!!alert && (
            <Popup
              style={{ backgroundColor: '#FCF3CF' }}
              size="small"
              basic
              position="right center"
              content={alert}
              trigger={
                <Label
                  corner
                  size={minimalistic ? 'mini' : undefined}
                  content={
                    <Icon
                      name="warning circle"
                      size="large"
                      corner="top right"
                      color="red"
                    />
                  }
                />
              }
            />
          )}
          {icon && <Icon name={icon} />}
          {!minimalistic && label}
        </Menu.Item>
      }
    />
  );
};
export default PatientMenuButton;
