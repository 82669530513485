import FullLayout from '@components/FullLayout';
import FavoriteInsurances from './FavoriteInsurances';
import Availability from './Availability';
import PracticeAccounts from './PracticeAccounts';
import { Header, Icon, Segment, Tab } from 'semantic-ui-react';

const SettingsScreen = () => {
  const panes = [
    {
      menuItem: 'Availability',
      pane: (
        <Tab.Pane key="basic">
          <Availability />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        content: 'Favorite Insurances',
        key: 'insurance',
      },
      pane: (
        <Tab.Pane key="insurance">
          <FavoriteInsurances />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        content: 'Manage Users',
        key: 'accounts',
      },
      pane: (
        <Tab.Pane key="accounts">
          <PracticeAccounts />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <FullLayout>
      <Segment padded basic>
        <Header as="h1">
          <Icon name="settings" />
          User Settings
        </Header>
      </Segment>

      <Tab panes={panes} renderActiveOnly={false} />
    </FullLayout>
  );
};

export default SettingsScreen;
