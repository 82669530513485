export const vaccinationsByDosesCoordsMap: {
  [key: string]: object[];
} = {
  HepB: [
    {
      x: 204,
      y: 605,
    },
    {
      x: 204 + 53,
      y: 605,
    },

    {
      x: 204 + 106,
      y: 605,
    },

    {
      x: 204 + 159,
      y: 605,
    },

    {
      x: 204 + 212,
      y: 605,
    },

    {
      x: 204 + 265,
      y: 605,
    },
    {
      x: 204 + 318,
      y: 605,
    },
    {
      x: 204 + 365,
      y: 605,
    },
  ],
  VARICELLA: [
    {
      x: 204,
      y: 408,
    },
    {
      x: 204 + 53,
      y: 408,
    },
    {
      x: 204 + 106,
      y: 408,
    },
    {
      x: 204 + 159,
      y: 408,
    },
    {
      x: 204 + 212,
      y: 408,
    },
    {
      x: 204 + 265,
      y: 408,
    },
    {
      x: 204 + 318,
      y: 408,
    },
  ],
  PneumoPCV: [
    {
      x: 204,
      y: 498,
    },
    {
      x: 204 + 53,
      y: 498,
    },
    {
      x: 204 + 106,
      y: 498,
    },
    {
      x: 204 + 159,
      y: 498,
    },
    {
      x: 204 + 212,
      y: 498,
    },
    {
      x: 204 + 265,
      y: 498,
    },
    {
      x: 204 + 318,
      y: 498,
    },
  ],
  POLIO: [
    {
      x: 204,
      y: 516,
    },
    {
      x: 204 + 53,
      y: 516,
    },
    {
      x: 204 + 106,
      y: 516,
    },
    {
      x: 204 + 159,
      y: 516,
    },
    {
      x: 204 + 212,
      y: 516,
    },
    {
      x: 204 + 265,
      y: 516,
    },
    {
      x: 204 + 318,
      y: 516,
    },
  ],
  DTAP: [
    {
      x: 204,
      y: 587,
    },
    {
      x: 204 + 53,
      y: 587,
    },
    {
      x: 204 + 106,
      y: 587,
    },
    {
      x: 204 + 159,
      y: 587,
    },
    {
      x: 204 + 212,
      y: 587,
    },
    {
      x: 204 + 265,
      y: 587,
    },
    {
      x: 204 + 318,
      y: 587,
    },
  ],
  MMR: [
    {
      x: 204,
      y: 480,
    },
    {
      x: 204 + 53,
      y: 480,
    },
    {
      x: 204 + 106,
      y: 480,
    },
    {
      x: 204 + 159,
      y: 480,
    },
    {
      x: 204 + 212,
      y: 480,
    },
    {
      x: 204 + 265,
      y: 480,
    },
    {
      x: 204 + 318,
      y: 480,
    },
  ],
  MEASLES: [
    {
      x: 204,
      y: 462,
    },
    {
      x: 204 + 53,
      y: 462,
    },
    {
      x: 204 + 106,
      y: 462,
    },
    {
      x: 204 + 159,
      y: 462,
    },
    {
      x: 204 + 212,
      y: 462,
    },
    {
      x: 204 + 265,
      y: 462,
    },
    {
      x: 204 + 318,
      y: 462,
    },
  ],
  MUMPS: [
    {
      x: 204,
      y: 444,
    },
    {
      x: 204 + 53,
      y: 444,
    },
    {
      x: 204 + 106,
      y: 444,
    },
    {
      x: 204 + 159,
      y: 444,
    },
    {
      x: 204 + 212,
      y: 444,
    },
    {
      x: 204 + 265,
      y: 444,
    },
    {
      x: 204 + 318,
      y: 444,
    },
  ],
  RUBELLA: [
    {
      x: 204,
      y: 426,
    },
    {
      x: 204 + 53,
      y: 426,
    },
    {
      x: 204 + 106,
      y: 426,
    },
    {
      x: 204 + 159,
      y: 426,
    },
    {
      x: 204 + 212,
      y: 426,
    },
    {
      x: 204 + 265,
      y: 426,
    },
    {
      x: 204 + 318,
      y: 426,
    },
  ],
  TDAP: [
    {
      x: 204,
      y: 570,
    },
    {
      x: 204 + 53,
      y: 570,
    },
    {
      x: 204 + 106,
      y: 570,
    },
    {
      x: 204 + 159,
      y: 570,
    },
    {
      x: 204 + 212,
      y: 570,
    },
    {
      x: 204 + 265,
      y: 570,
    },
    {
      x: 204 + 318,
      y: 570,
    },
  ],
  HIB: [
    {
      x: 204,
      y: 533,
    },
    {
      x: 204 + 53,
      y: 533,
    },
    {
      x: 204 + 106,
      y: 533,
    },
    {
      x: 204 + 159,
      y: 533,
    },
    {
      x: 204 + 212,
      y: 533,
    },
    {
      x: 204 + 265,
      y: 533,
    },
    {
      x: 204 + 318,
      y: 533,
    },
  ],
  TD: [
    {
      x: 204,
      y: 553,
    },
    {
      x: 204 + 53,
      y: 553,
    },
    {
      x: 204 + 106,
      y: 553,
    },
    {
      x: 204 + 159,
      y: 553,
    },
    {
      x: 204 + 212,
      y: 553,
    },
    {
      x: 204 + 265,
      y: 553,
    },
    {
      x: 204 + 318,
      y: 553,
    },
  ],
};
