import { useMemo, useState } from 'react';
import { useLSHandler, usePractice } from '@bluefox/contexts';
import {
  Button,
  Container,
  Dimmer,
  Header,
  Icon,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import FullLayout from '@components/FullLayout';
import { AdjustmentKind } from '@bluefox/models/InventoryAdjustment';
import { GetNotAppliedAdjustmentsQuery } from '@bluefox/graphql/inventoryAdjustment';
import InventoryAdjustment from '@screens/inventory/InventoryAdjustment';

const AdjustmentSelector = () => {
  const practice = usePractice();
  const lsHandler = useLSHandler();

  const hasVFCAdjustmentsStarted = useMemo(
    () => !!lsHandler.getItem('adjustment_data_vfc'),
    [lsHandler]
  );
  const hasPrivateAdjustmentsStarted = useMemo(
    () => !!lsHandler.getItem('adjustment_data_private'),
    [lsHandler]
  );

  const [hasVFCPendingAdjustments, setHasVFCPendingAdjustments] =
    useState(false);
  const [hasPrivatePendingAdjustments, setHasPrivatePendingAdjustments] =
    useState(false);

  const [selectedAdjustmentKind, setSelectedAdjustmentKind] =
    useState<string>('');

  const { data, error, loading } = useQuery(GetNotAppliedAdjustmentsQuery, {
    variables: {
      id: practice.id,
    },
    onCompleted: (data) => {
      const { notAppliedVFCAdjustments, notAppliedPrivateAdjustments } = data;

      setHasPrivatePendingAdjustments(
        notAppliedPrivateAdjustments?.length !== 0
      );
      setHasVFCPendingAdjustments(notAppliedVFCAdjustments?.length !== 0);
    },
  });

  return (
    <>
      <FullLayout>
        <Segment padded basic>
          <Header as="h1">
            <Icon name="boxes" />
            Inventory Adjustment
          </Header>
        </Segment>
        <Container>
          {loading && (
            <Dimmer active>
              <Loader />
            </Dimmer>
          )}
          {error && (
            <Message error>
              Failed to get Adjusment Information. Error: {error?.message}
            </Message>
          )}
          {data && (
            <>
              {selectedAdjustmentKind === '' && (
                <>
                  {!hasPrivatePendingAdjustments ? (
                    <Button
                      secondary={!hasPrivateAdjustmentsStarted}
                      negative={hasPrivateAdjustmentsStarted}
                      content={
                        !hasPrivateAdjustmentsStarted
                          ? 'Private Inventory'
                          : 'Continue Private Inventory Adjustment'
                      }
                      onClick={() => {
                        setSelectedAdjustmentKind(AdjustmentKind.PRIVATE);
                      }}
                      basic
                    />
                  ) : (
                    <Button
                      disabled
                      negative
                      content="Private Inventory Adjustment pending approval"
                      basic
                    />
                  )}

                  {!hasVFCPendingAdjustments ? (
                    <Button
                      secondary={!hasVFCAdjustmentsStarted}
                      negative={hasVFCAdjustmentsStarted}
                      content={
                        !hasVFCAdjustmentsStarted
                          ? 'VFC Inventory'
                          : 'Continue VFC Inventory Adjustment'
                      }
                      onClick={() => {
                        setSelectedAdjustmentKind(AdjustmentKind.VFC);
                      }}
                      basic
                    />
                  ) : (
                    <Button
                      disabled
                      negative
                      content="VFC Inventory Adjustment pending approval"
                      basic
                    />
                  )}
                </>
              )}
              {selectedAdjustmentKind !== '' && (
                <InventoryAdjustment
                  kind={selectedAdjustmentKind as AdjustmentKind}
                />
              )}
            </>
          )}
        </Container>
      </FullLayout>
    </>
  );
};

export default AdjustmentSelector;
