import EchartsContainer from '@bluefox/ui/EchartsContainer';
import { SemanticColorToString } from '@bluefox/constants';

type Props = {
  paidTotal: number;
  inProgressTotal: number;
  deniedTotal: number;
};

const TotalClaimsChart = ({
  paidTotal,
  inProgressTotal,
  deniedTotal,
}: Props) => {
  return (
    <EchartsContainer
      options={{
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            top: '-15%',
            bottom: '-15%',
            name: 'Total claims',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: paidTotal,
                name: 'Paid',
                itemStyle: { color: SemanticColorToString('green') },
              },
              {
                value: inProgressTotal,
                name: 'In progress',
                itemStyle: { color: SemanticColorToString('blue') },
              },
              {
                value: deniedTotal,
                name: 'Denied',
                itemStyle: { color: SemanticColorToString('grey') },
              },
            ],
          },
        ],
      }}
    />
  );
};

export default TotalClaimsChart;
