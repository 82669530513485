import InvoiceList from '@components/NewBilling/InvoiceList/InvoiceList';
import { Header, Segment } from 'semantic-ui-react';
import OldBillingInvoiceListModal from './OldBillingInvoiceListModal';

type Props = {
  practiceIds: string[];
  pageBlurred?: boolean;
};

const NewInvoiceListScreen = ({ practiceIds, pageBlurred = false }: Props) => {
  return (
    <>
      <Segment padded basic>
        <Header as="h2">Invoices</Header>
      </Segment>
      <InvoiceList practiceIds={practiceIds} pageBlurred={pageBlurred} />
      <div
        style={{ display: 'flex', margin: '2rem 0', justifyContent: 'center' }}
      >
        <OldBillingInvoiceListModal practiceIds={practiceIds} />
      </div>
    </>
  );
};

export default NewInvoiceListScreen;
