import { Header, Image, Container } from 'semantic-ui-react';

const LandingPage = () => {
  return (
    <Container textAlign="center" style={{ padding: '10%' }}>
      <Header as="h2" style={{ color: '#16324F', marginBottom: '4rem' }}>
        Oops! It looks like your URL is incomplete. Please login using the
        complete URL for your practice.
      </Header>
      <Image src="/canid-dark-logo.png" fluid />
    </Container>
  );
};

export default LandingPage;
